import React from 'react';
import PropTypes from 'prop-types';

const MapStarMarkerIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.94,16.64a15.78,15.78,0,0,1,31.56,0c0,8.71-15.78,30.05-15.78,30.05S7.94,25.35,7.94,16.64Z" fill="currentColor" />
    <polygon fill="#fff" points="23.82 9.45 26.17 14.21 31.43 14.98 27.62 18.69 28.52 23.93 23.82 21.45 19.12 23.93 20.01 18.69 16.21 14.98 21.47 14.21" />
  </svg>
);

MapStarMarkerIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default MapStarMarkerIcon;
