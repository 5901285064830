import React from 'react';
import PropTypes from 'prop-types';

const KitchenIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <rect height="32" rx="2" width="40" x="4" y="14" />
      <rect height="10" rx="1" width="24" x="12" y="28" />
      <path d="m10 2h28c1.1046 0 2 0.89543 2 2v10h-32v-10c0-1.1046 0.89543-2 2-2z" />
      <line x1="4" x2="44" y1="21" y2="21" />
      <line x1="14" x2="21" y1="12" y2="12" />
      <line x1="27" x2="34" y1="12" y2="12" />
    </g>
  </svg>
);

KitchenIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default KitchenIcon;
