// TODO: move these into the Payment domain class
const round = (amount, numberOfDigits = 2) => {
  return +(
    Math.round(
      +amount * Math.pow(10, 2)
    ) / Math.pow(10, 2)
  ).toFixed(numberOfDigits);
};

// Return a formatted number with optional rounding
const format = (amount, isRounded = false) => {
  const roundedAmount = round(amount);
  const numFormatDigits = isRounded ? 0 : 2;
  const formattedAmount = roundedAmount.toLocaleString(undefined, { minimumFractionDigits: numFormatDigits, maximumFractionDigits: numFormatDigits });

  if (formattedAmount.includes('.00')) {
    return formattedAmount.substring(0, formattedAmount.length - 3);
  } else {
    return formattedAmount;
  }
};

export default {
  round,
  format,
};
