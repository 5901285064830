import { Map, List, fromJS } from 'immutable';
import { differenceInCalendarDays, differenceInMonths, parseISO, format, addMonths, startOfMonth } from 'date-fns';
import { v1 as uuidv1 } from 'uuid';
import DateUtility from '@utilities/DateUtility';
import PaymentUtility from '@utilities/PaymentUtility';
import GeneralStatic from '@utilities/static/GeneralStatic';
import ListingEditStatic from '@utilities/static/ListingEditStatic';

// This engine dispatches updates to listing units and builds bed/bedroom structures based on those
// units. There are 7 main listing edit inputs that change the units: is_price_per_tenant,
// bedrooms_for_rent, number of beds in a bedroom, the type of a bed and the price of a bed/unit,
// whether the bedroom has a private bathroom and whether their bedroom is in a converted room. Each method takes
// in the listing, the object and the value of the changed item. They all return the refreshed list
// of units, beds and bedrooms to be saved on the store. The units array on the listing is the
// source of truth and beds/bedrooms are built from the units for convenience. One key thing to
// note is that the store is immutable so all objects used in the engine are also immutable and
// returned as so.

const resetUnits = (listing, isPricePerTenant) => {
  let units = listing.get('units');
  const activeUnits = getActiveUnits(listing);

  // If the host is switching the listing from 'By bed' to 'By entire space' or vice versa and the listing
  // only had 1 bed, we'll let that unit/bed remain in tact.
  if ((activeUnits.size > 1) || (activeUnits.size === 1 && isPricePerTenant && activeUnits.getIn([0, 'beds']).size > 1)) {
    const bedList = getBedList(listing);
    const activeBeds = bedList.filter(bed => bed.get('_state') !== 'deleted');

    units = units
      .map((unit) => {
        unit = unit.set('_state', 'deleted');
        unit = unit.set('beds', unit.get('beds').map(
          (bed) => bed.set('_state', 'deleted')
        ));
        return unit;
      })
      .filter((unit) => unit.get('id')); // Filter out virtual units that haven't been saved on the server

    if (isPricePerTenant) {
      const existingUnit = activeUnits.get(0);
      const existingUnitSeasonalPricing = existingUnit.get('seasonal_pricing');
      const existingUnitJanuaryPrice = existingUnitSeasonalPricing.get('january_price');

      const newUnits = activeBeds.map(bed => {
        const tmpUnitId = generateIdentifier();
        const bedPrice = bed.get('price');

        return fromJS({
          _id: tmpUnitId,
          beds: [{
            _id: generateIdentifier(),
            _unit_id: tmpUnitId,
            bedroom: bed.get('bedroom'),
            price: bedPrice,
            nightly_price: bed.get('nightly_price'),
            type: bed.get('type'),
            index: bed.get('index'),
          }],
          price: bedPrice,
          seasonal_pricing: {
            _id: generateIdentifier(),
            _unit_id: tmpUnitId,
            january_price: bedPrice,
            february_price: PaymentUtility.round((existingUnitSeasonalPricing.get('february_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            march_price: PaymentUtility.round((existingUnitSeasonalPricing.get('march_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            april_price: PaymentUtility.round((existingUnitSeasonalPricing.get('april_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            may_price: PaymentUtility.round((existingUnitSeasonalPricing.get('may_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            june_price: PaymentUtility.round((existingUnitSeasonalPricing.get('june_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            july_price: PaymentUtility.round((existingUnitSeasonalPricing.get('july_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            august_price: PaymentUtility.round((existingUnitSeasonalPricing.get('august_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            september_price: PaymentUtility.round((existingUnitSeasonalPricing.get('september_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            october_price: PaymentUtility.round((existingUnitSeasonalPricing.get('october_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            november_price: PaymentUtility.round((existingUnitSeasonalPricing.get('november_price') / existingUnitJanuaryPrice) * bedPrice, 0),
            december_price: PaymentUtility.round((existingUnitSeasonalPricing.get('december_price') / existingUnitJanuaryPrice) * bedPrice, 0),
          },
        });
      });
      units = units.concat(newUnits);

    } else {
      const tmpUnitId = generateIdentifier();
      const consolidatedBeds = activeBeds.map(bed => {
        return fromJS({
          _id: generateIdentifier(),
          _unit_id: tmpUnitId,
          bedroom: bed.get('bedroom'),
          price: bed.get('price'),
          nightly_price: bed.get('nightly_price'),
          type: bed.get('type'),
          index: bed.get('index'),
        });
      });

      listing = listing.set('nightly_price', sum(consolidatedBeds.map(b => b.get('nightly_price'))));

      units = units.push(fromJS({
        _id: tmpUnitId,
        beds: consolidatedBeds,
        price: sum(consolidatedBeds.map(b => b.get('price'))),
        seasonal_pricing: {
          _id: generateIdentifier(),
          _unit_id: tmpUnitId,
          january_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('january_price'))),
          february_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('february_price'))),
          march_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('march_price'))),
          april_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('april_price'))),
          may_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('may_price'))),
          june_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('june_price'))),
          july_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('july_price'))),
          august_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('august_price'))),
          september_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('september_price'))),
          october_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('october_price'))),
          november_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('november_price'))),
          december_price: sum(activeUnits.map(u => u.get('seasonal_pricing').get('december_price'))),
        },
      }));
    }
  }

  const { beds, bedrooms } = getBedrooms(units);
  return { updatedListing: listing, units, beds, bedrooms };
};

const updateBedroomsForRent = (listing, bedroomsForRent) => {
  let units = listing.get('units');
  let { beds, bedrooms } = getBedrooms(units);
  if (bedroomsForRent < bedrooms.size) {
    // Remove rooms
    let bedroomNames = beds.map((bed) => bed.get('bedroom'));
    const bedroomsToRemove = bedrooms.size - bedroomsForRent;
    bedroomNames = bedroomNames.slice(bedroomNames.size - bedroomsToRemove, bedroomsToRemove + bedroomNames.size - bedroomsToRemove);

    if (listing.get('is_price_per_tenant')) {
      let unitsDeleted = List([]);
      beds.forEach((bed) => {
        if (bedroomNames.includes(bed.get('bedroom'))) {
          unitsDeleted = unitsDeleted.push(bed.get('unit_id') || bed.get('_unit_id'));
        }
      });
      units = units.map((unit) => {
        if (unitsDeleted.includes(getPermanentOrTemporaryId(unit))) {
          unit = unit.set('_state', 'deleted');
          let unitBeds = unit.get('beds');
          unitBeds = beds.map((bed) => bed.set('_state', 'deleted'));
          unit = unit.set('beds', unitBeds);
        }
        return unit;
      });
    } else {
      const index = units.findIndex((unit) => unit.get('_state') !== 'deleted');
      const unit = units.get(index);
      let unitBeds = unit.get('beds').map((bed) => {
        if (bedroomNames.includes(bed.get('bedroom'))) {
          bed = bed.set('_state', 'deleted');
        }
        return bed;
      });

      units = units.setIn([index, 'beds'], unitBeds);
    }
  } else if (bedroomsForRent > bedrooms.size) {
    // Add rooms
    const bedroomsToAdd = bedroomsForRent - bedrooms.size;
    for (let i = 0; i < bedroomsToAdd; i++) {
      const bedroomName = generateIdentifier();

      if (listing.get('is_price_per_tenant')) {
        const tmpUnitId = generateIdentifier();
        const tmpBedId = generateIdentifier();
        units = units.push(fromJS({
          _id: tmpUnitId,
          price: null,
          beds: [{
            _id: tmpBedId,
            _unit_id: tmpUnitId,
            bedroom: bedroomName,
            price: null,
            index: 1,
          }],
          seasonal_pricing: {
            _id: generateIdentifier(),
            _unit_id: tmpUnitId,
          },
        }));
      } else {
        const index = units.findIndex((unit) => unit.get('_state') !== 'deleted');
        let unit;
        if (index >= 0) {
          unit = units.get(index);
        } else {
          const tmpUnitId = generateIdentifier();
          // if no units found then create one
          unit = fromJS({
            _id: tmpUnitId,
            price: listing.get('average_price'),
            beds: [],
            seasonal_pricing: {
              _id: generateIdentifier(),
              _unit_id: tmpUnitId,
            },
          });
        }
        const unitBeds = unit.get('beds').push(fromJS({
          _id: generateIdentifier(),
          _unit_id: unit.get('_id'),
          unit_id: unit.get('id'),
          bedroom: bedroomName,
          price: null,
          index: 1,
        }));
        units = units.setIn([index, 'beds'], unitBeds);
      }
    }
  }

  ({ beds, bedrooms } = getBedrooms(units));
  return { units, beds, bedrooms };
};

const updateBedCount = (listing, bedroom, count) => {
  let units = listing.get('units');
  let { beds, bedrooms } = getBedrooms(units);
  const bedroomBeds = beds.filter((bed) => bed.get('bedroom') === bedroom.get('value') && bed.get('_state') !== 'deleted');
  if (listing.get('is_price_per_tenant')) {
    if (bedroomBeds.size > count) {
      // Remove beds from bedroom (and units)
      let unitsDeleted = List([]);
      bedroomBeds
        .sort((a, b) => a.get('index') - b.get('index'))
        .reverse().forEach((bed, i) => {
          if (i < (bedroomBeds.size - count)) {
            unitsDeleted = unitsDeleted.push(bed.get('unit_id') || bed.get('_unit_id'));
          }
        });
      units = units.map((unit) => {
        if (unitsDeleted.includes(getPermanentOrTemporaryId(unit))) {
          unit = unit.set('_state', 'deleted');
          const unitBeds = unit.get('beds').map((bed) => bed.set('_state', 'deleted'));
          unit = unit.set('beds', unitBeds);
        }
        return unit;
      });
    } else if (bedroomBeds.size < count) {
      // Add beds to bedroom (and units)
      for (let i = 0; i < (count - bedroomBeds.size); i++) {
        const tmpUnitId = generateIdentifier();
        const tmpBedId = generateIdentifier();
        units = units.push(fromJS({
          _id: tmpUnitId,
          price: null,
          beds: [{
            _id: tmpBedId,
            _unit_id: tmpUnitId,
            bedroom: bedroom.get('value'),
            price: null,
            index: bedroomBeds.size + i + 1,
          }],
          seasonal_pricing: {
            _id: generateIdentifier(),
            _unit_id: tmpUnitId,
          },
        }));
      }
    }
  } else if (bedroomBeds.size > count) {
    // Remove beds from bedroom
    let bedsDeleted = List([]);
    bedroomBeds
      .sort((a, b) => a.get('index') - b.get('index'))
      .reverse().forEach((bed, i) => {
        if (i < (bedroomBeds.size - count)) {
          bedsDeleted = bedsDeleted.push(getPermanentOrTemporaryId(bed));
        }
      });

    const index = units.findIndex((unit) => unit.get('_state') !== 'deleted');
    const unit = units.get(index);
    let unitBeds = unit.get('beds').map((bed) => {
      if (bedsDeleted.includes(getPermanentOrTemporaryId(bed))) {
        bed = bed.set('_state', 'deleted');
      }
      return bed;
    });

    units = units.setIn([index, 'beds'], unitBeds);
  } else if (bedroomBeds.size < count) {
    // Add beds to bedroom
    const index = units.findIndex((unit) => unit.get('_state') !== 'deleted');
    const unit = units.get(index);
    let unitBeds = unit.get('beds');
    for (let i = 0; i < (count - bedroomBeds.size); i++) {
      unitBeds = unitBeds.push(fromJS({
        _id: generateIdentifier(),
        _unit_id: unit.get('_id'),
        unit_id: unit.get('id'),
        bedroom: bedroom.get('value'),
        price: null,
        index: bedroomBeds.size + i + 1,
      }));
    }
    units = units.setIn([index, 'beds'], unitBeds);
  }

  ({ beds, bedrooms } = getBedrooms(units));
  return { units, beds, bedrooms };
};

const updateBedCountInSleepingArea = (listing, sleepingArea, count) => {
  let currentBeds = sleepingArea.get('beds') || List();
  let sleepingAreaBeds = [];

  if (currentBeds.size < count) {
    sleepingAreaBeds = currentBeds;
    const numberToAdd = count - sleepingAreaBeds.size;

    for (let i = 0; i < numberToAdd; i++) {
      sleepingAreaBeds = sleepingAreaBeds.push(fromJS({
        id: uuidv1(),
        sleeping_area_id: sleepingArea.get('id'),
        type: null,
        index: sleepingAreaBeds.size + i,
      }));
    }
  } else if (currentBeds.size > count) {
    sleepingAreaBeds = currentBeds.slice(0, count);
  }

  let sleepingAreas = listing.get('sleeping_areas');
  const index = sleepingAreas.findIndex((sa) => compareIds(sa, 'id', sleepingArea, 'id'));
  sleepingAreas = sleepingAreas.setIn([index, 'beds'], sleepingAreaBeds);

  return sleepingAreas;
};

const updateBedType = (listing, bed, type) => {
  let units = listing.get('units');

  // Index of unit
  const index = units.findIndex((unit) => compareIds(unit, 'id', bed, 'unit_id'));

  // Update bed type
  let unitBeds = units.get(index).get('beds');
  const bedsIndex = unitBeds.findIndex((b) => compareIds(b, 'id', bed, 'id'));
  unitBeds = unitBeds.setIn([bedsIndex, 'type'], type);
  units = units.setIn([index, 'beds'], unitBeds);

  const { beds, bedrooms } = getBedrooms(units);
  return { units, beds, bedrooms };
};

const updateSleepingAreaType = (listing, sleepingArea, type) => {
  let sleepingAreas = listing.get('sleeping_areas');

  const index = sleepingAreas.findIndex((existingSleepingArea) => compareIds(existingSleepingArea, 'id', sleepingArea, 'id'));
  sleepingAreas = sleepingAreas.setIn([index, 'type'], type);

  return sleepingAreas;
};

const updateSleepingAreaBedType = (listing, bed, type) => {
  let sleepingAreas = listing.get('sleeping_areas');

  const index = sleepingAreas.findIndex((sleepingArea) => compareIds(sleepingArea, 'id', bed, 'sleeping_area_id'));

  let sleepingAreaBeds = sleepingAreas.get(index).get('beds');
  const bedsIndex = sleepingAreaBeds.findIndex((b) => compareIds(b, 'id', bed, 'id'));
  sleepingAreaBeds = sleepingAreaBeds.setIn([bedsIndex, 'type'], type);
  sleepingAreas = sleepingAreas.setIn([index, 'beds'], sleepingAreaBeds);

  return sleepingAreas;
};

const updateBedroomAttribute = (listing, bedroom, attribute, value) => {
  const units = listing.get('units').map((unit) => {
    let beds = unit.get('beds');
    beds = beds.map((bed) => {
      if (bed.get('bedroom') === bedroom.get('value')) {
        bed = bed.set(attribute, value);
      }
      return bed;
    });
    unit = unit.set('beds', beds);

    return unit;
  });

  const { beds, bedrooms } = getBedrooms(units);
  return { units, beds, bedrooms };
};

const updateBedPrice = (units, bed, price, priceKey) => {
  // Index of unit
  const index = units.findIndex((unit) => compareIds(unit, 'id', bed, 'unit_id'));

  // Update bed price
  let unitBeds = units.get(index).get('beds');
  const bedsIndex = unitBeds.findIndex((b) => compareIds(b, 'id', bed, 'id'));
  unitBeds = unitBeds.setIn([bedsIndex, priceKey], +price);
  units = units.setIn([index, 'beds'], unitBeds);

  const { beds, bedrooms } = getBedrooms(units);
  return { units, beds, bedrooms };
};

const updateSleepingAreas = (listing, sleepingAreasCount) => {
  if (sleepingAreasCount === 0) {
    return List();
  }

  let sleepingAreas = listing.get('sleeping_areas') || List([]);

  if (sleepingAreasCount < sleepingAreas.size) {
    sleepingAreas = sleepingAreas.slice(0, sleepingAreas.size - sleepingAreasCount);
  } else if (sleepingAreasCount > sleepingAreas.size) {
    const sleepingAreasToAdd = sleepingAreasCount - sleepingAreas.size;
    for (let i = 0; i < sleepingAreasToAdd; i++) {
      const sleepingAreaId = uuidv1();
      sleepingAreas = sleepingAreas.push(fromJS({
        id: sleepingAreaId,
        type: null,
        index: i + sleepingAreas.size,
        state: 'active',
        beds: [{
          id: uuidv1(),
          sleeping_area_id: sleepingAreaId,
          index: 1,
          state: 'active',
        }],
      }));
    }
  }

  return sleepingAreas;
};

const getBedrooms = (units) => {
  const beds = getBeds(units);
  const bedrooms = beds.reduce((list, bed) => {
    if (bed && bed.get('_state') !== 'deleted') {
      let found = false;
      list = list.map((bedroom) => {
        if (bedroom.get('value') === bed.get('bedroom')) {
          const count = bedroom.get('count');
          bedroom = bedroom.set('count', count + 1);
          found = true;
        }
        return bedroom;
      });
      if (!found) {
        list = list.push(Map({
          value: bed.get('bedroom'),
          name: `Bedroom ${GeneralStatic.alphabet[list.size].toUpperCase()}`,
          is_common: bed.get('is_common'),
          ensuite: bed.get('ensuite'),
          count: 1,
        }));
      }
    }
    return list;
  }, List([]));

  return { beds, bedrooms };
};

// Immutable list of beds on the listing
const getBedList = (listing) => {
  return listing.get('units')
    .map((unit) => unit.get('beds'))
    .flatten(1);
};

// Array of bed objects used for the bed dropdown
const getBedOptions = (listing, showFullLabel = true) => {
  return getBedList(listing)
    .sort((a, b) => {
      if (a.get('bedroom') < b.get('bedroom')) { return -1; }
      if (a.get('bedroom') > b.get('bedroom')) { return 1; }
      if (a.get('bedroom') === b.get('bedroom')) { return 0; }
    })
    .map((bed) => {
      const bedroom = listing.get('sleeping_arrangements').find(b => b.get('name') === bed.get('bedroom'));
      return fromJS({
        bedroom,
        bedroomIdentifier: getBedroomIdentifier(listing, bed),
        label: buildBedString(listing, bed, showFullLabel),
        value: bed.get('id'),
        unitId: bed.get('unit_id'),
        index: bed.get('index'),
        price: bed.get('price'),
      });
    });
};

// Return the active unit (non-deleted) on an entire space listing
const getActiveUnit = (listing) => {
  return listing.get('units').find(u => u.get('_state') !== 'deleted');
};

const getActiveUnits = (listing) => {
  return listing.get('units').filter(u => u.get('_state') !== 'deleted');
};

const getBedroomIdentifier = (listing, bed) => {
  return listing.get('bedroom_mapping') && listing.get('bedroom_mapping').get(bed.get('bedroom'))
    ? listing.get('bedroom_mapping').get(bed.get('bedroom')).toUpperCase()
    : '';
};

const buildBedString = (listing, bed, showFullLabel) => {
  let unit = listing.get('units').find(u => compareIds(u, 'id', bed, 'unit_id'));
  let seasonalPricing = unit.get('seasonal_pricing');
  let price = PaymentUtility.format(calculateAveragePrice(seasonalPricing), true);

  if (showFullLabel) {
    if (listing.get('furnishings') === ListingEditStatic.tab1.furnishingsValues.fullyFurnished) {
      return `Bedroom ${getBedroomIdentifier(listing, bed)} (${ListingEditStatic.tab1.bedTypeMap[bed.get('type')]}, $${price}/month)`;
    }

    return `Bedroom ${getBedroomIdentifier(listing, bed)} ($${price}/month)`;
  }

  if (listing.get('furnishings') === ListingEditStatic.tab1.furnishingsValues.fullyFurnished) {
    return `Bedroom ${getBedroomIdentifier(listing, bed)} (${ListingEditStatic.tab1.bedTypeMap[bed.get('type')]})`;
  }

  return `Bedroom ${getBedroomIdentifier(listing, bed)}`;
};

const calculateAveragePrice = (seasonalPricing, start, end) => {
  const s = parseISO(start);
  const e = parseISO(end);
  const diffMonths = differenceInMonths(e, s) + 1;

  const prices = [];

  if (start && end) {
    let cur;
    for (var i = 0; i < diffMonths; i++) {
      cur = startOfMonth(addMonths(s, i));
      prices.push(
        seasonalPricing.get(`${format(cur, 'MMMM').toLowerCase()}_price`)
      );
    }
  } else {
    GeneralStatic.months.map(month => {
      prices.push(
        seasonalPricing.get(`${month}_price`)
      );
    });
  }

  const avg = (sum(prices) / prices.length) || 0;
  return PaymentUtility.round(avg);
};

const calculateTotalNightlyPrice = (nightlyPrice, moveIn, moveOut) => {
  const parsedMoveIn = parseISO(moveIn);
  const parsedMoveOut = parseISO(moveOut);
  const lengthOfStay = differenceInCalendarDays(parsedMoveOut, parsedMoveIn);

  return PaymentUtility.round(lengthOfStay * nightlyPrice);
};

const calculateDisplayPrice = (listing, unit, moveIn, moveOut) => {
  // INFO: This is extremely strange, but the listing from Elasticsearch also has fields
  // january_price, february_price, etc. and the system is using them for pricing. Keeping it
  // this way for now.
  if (!unit) {
    return DateUtility.isShortTermRental(moveIn, moveOut)
      ? calculateTotalNightlyPrice(listing.get('nightly_price'), moveIn, moveOut)
      : calculateAveragePrice(listing, moveIn, moveOut);
  }

  const unitSeasonalPricing = unit.get('seasonal_pricing');
  const unitBed = unit.getIn(['beds', 0]);

  return DateUtility.isShortTermRental(moveIn, moveOut)
    ? calculateTotalNightlyPrice(listing.get('is_price_per_tenant') ? unitBed.get('nightly_price') : listing.get('nightly_price'), moveIn, moveOut)
    : calculateAveragePrice(unitSeasonalPricing, moveIn, moveOut);
};

const calculatePerIntervalDisplayPrice = (listing, unit, moveIn, moveOut) => {
  // INFO: This is extremely strange, but the listing from Elasticsearch also has fields
  // january_price, february_price, etc. and the system is using them for pricing. Keeping it
  // this way for now.
  if (!unit) {
    return DateUtility.isShortTermRental(moveIn, moveOut)
      ? listing.get('nightly_price')
      : calculateAveragePrice(listing, moveIn, moveOut);
  }

  const unitSeasonalPricing = unit.get('seasonal_pricing');
  const unitBed = unit.getIn(['beds', 0]);

  return DateUtility.isShortTermRental(moveIn, moveOut)
    ? listing.get('is_price_per_tenant') ? unitBed.get('nightly_price') : listing.get('nightly_price')
    : calculateAveragePrice(unitSeasonalPricing, moveIn, moveOut);
};

const renderDisplayPrice = (listing, unit, moveIn, moveOut) => {
  const displayPrice = calculateDisplayPrice(listing, unit, moveIn, moveOut);
  let renderedPrice = PaymentUtility.format(displayPrice, true);

  if (!DateUtility.isShortTermRental(moveIn, moveOut)) {
    renderedPrice += '/month';
  }

  return '$' + renderedPrice;
};

const renderPerIntervalDisplayPrice = (listing, unit, moveIn, moveOut) => {
  const displayPrice = calculatePerIntervalDisplayPrice(listing, unit, moveIn, moveOut);
  let renderedPrice = PaymentUtility.format(displayPrice, true);

  if (DateUtility.isShortTermRental(moveIn, moveOut)) {
    renderedPrice += '/night';
  } else {
    renderedPrice += '/month';
  }

  return '$' + renderedPrice;
};

const buildSleepingArrangementDescription = (sleepingArrangement) => {
  let description = '';
  const bedTypes = sleepingArrangement.get('types');
  let i = 0;
  bedTypes.mapKeys((bedType, bedDetails) => {
    if (description.length) {
      description += ', ';
    }
    let count = bedDetails.get('count');
    let bedTypeLabel = ListingEditStatic.tab1.bedTypeMap[bedType] || ListingEditStatic.tab1.sleepingAreaBedTypeMap[bedType];

    // IMPORTANT: If there is no bed type, it's probably a space that isn't fully furnished.
    if (bedTypeLabel) {
      if (i !== 0 && bedTypeLabel) {
        bedTypeLabel = bedTypeLabel.toLowerCase();
      }

      if (count > 1) {
        description += count + ' ';

        if (bedTypeLabel.endsWith('s')) {
          description += bedTypeLabel + 'es';
        } else {
          description += bedTypeLabel + 's';
        }
      } else {
        description += bedTypeLabel;
      }

      if (sleepingArrangement.get('is_common')) {
        description += ' in a converted room';
      }

      if (sleepingArrangement.get('ensuite')) {
        description += ' with private bathroom';
      }
    } else {
      if (sleepingArrangement.get('is_common')) {
        description += ' Converted room';

        if (sleepingArrangement.get('ensuite')) {
          description += ' with private bathroom';
        }
      } else {
        if (sleepingArrangement.get('ensuite')) {
          description += ' Room with private bathroom';
        }
      }
    }

    if (sleepingArrangement.get('arrangement_sub_type') && i === bedTypes.size - 1) {
      description += ' in ' + ListingEditStatic.tab1.sleepingAreaTypeMap[sleepingArrangement.get('arrangement_sub_type')].toLowerCase();
    }

    i++;
  });

  return description.trim();
};

const buildSleepingArrangementPrice = (sleepingArrangement, listing, start, end) => {
  let pricesLabel = '';

  const bedTypes = sleepingArrangement.get('types');
  bedTypes.mapKeys((bedType, bedDetails) => {
    if (listing.get('is_price_per_tenant')) {
      const units = listing.get('units');
      let count = bedDetails.get('count');

      const bedsWithSameTypeInBedroom = units
        .map(unit => {
          let beds = unit.get('beds').filter(b => b.get('bedroom') === sleepingArrangement.get('name') && (b.get('type') === bedType || bedType === 'none'));
          beds = beds.map(bed => (
            bed.set('seasonal_pricing', unit.get('seasonal_pricing'))
          ));
          return beds;
        }).flatten(1);

      const prices = bedsWithSameTypeInBedroom.map(b => {
        const bedPrice = b.get('price');
        const seasonalPricing = b.get('seasonal_pricing');
        const averagePrice = calculateAveragePrice(seasonalPricing, start, end);
        return PaymentUtility.round(bedPrice / seasonalPricing.get('january_price') * averagePrice);
      });

      if (prices.size > 0) {
        if (count > 1) {
          let uniquePrices = prices.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
          if (uniquePrices.size > 1) {
            pricesLabel = formattedBedroomPrices(prices);
          } else {
            pricesLabel = `${PaymentUtility.format(prices.get(0), true)} each`;
          }
        } else {
          pricesLabel = PaymentUtility.format(prices.get(0), true);
        }
      }
    }
  });

  return pricesLabel;
};

const compareIds = (item1, item1Key, item2, item2Key) => (
  (item1.get(item1Key) && item1.get(item1Key) === item2.get(item2Key)) ||
  (item1.get(`_${item1Key}`) && item1.get(`_${item1Key}`) === item2.get(`_${item2Key}`))
);

// ===============
// Private
// ===============
const sum = (prices) => {
  return prices.reduce((a, b) => a + b, 0);
};

const getBeds = (units) => units.map((unit) => {
  if (!unit.get('beds')) return unit;
  return unit.get('beds')
    .filter((bed) => bed.get('_state') !== 'deleted')
    .map((bed) => {
      bed = bed.set('category', bed.get('bedroom'));
      bed = bed.set('price', bed.get('price'));
      bed = bed.set('value', `${bed.get('index')}_${bed.get('bedroom')}`);

      if (bed.get('type')) {
        bed = bed.set('display', `Bed #${bed.get('index')} (${ListingEditStatic.tab1.bedTypeMap[bed.get('type')]}${bed.get('price') ? `, $${bed.get('price')}` : ''})`);
      } else {
        bed = bed.set('display', `Bedroom #${bed.get('index')} ${bed.get('price') ? `($${bed.get('price')})` : ''}`);
      }
      return bed;
    });
})
  .flatten(1)
  .sort((a, b) => (
    a.get('bedroom') > b.get('bedroom') ? 1 : (b.get('bedroom') > a.get('bedroom') ? -1 : 0)
  ));

const formattedBedroomPrices = (prices) => {
  let pricesString = '';
  prices.forEach((price) => {
    if (price) {
      if (pricesString.length > 0) {
        pricesString += ', ';
      }
      pricesString += `$${PaymentUtility.format(price, true)}`;
    }
  });
  return pricesString;
};

const generateIdentifier = () => Math.random().toString(36).substr(6, 6);

const getPermanentOrTemporaryId = (object) => {
  return object.get('id') || object.get('_id');
};

const findPhoto = (photos, identifier) => {
  // Skip if bed is empty
  if (!identifier) return;

  let photo;

  // Try and find a photo of a bed in the bedroom
  photo = photos.find(p => {
    const regex = new RegExp('[0-9]_' + identifier, 'gi');
    return p.identifier && p.identifier.match(regex); // checks if the identifier matches x_bedroom
  });

  // Fallback to finding a photo of the room
  if (!photo) {
    photo = photos.find(p => {
      return p.identifier === identifier;
    });
  }

  // Fallback to finding a photo of the ensuite bathroom of the room
  if (!photo) {
    photo = photos.find(p => {
      const regex = new RegExp(identifier + '_ensuite', 'gi');
      return p.identifier && p.identifier.match(regex); // checks if the identifier matches bedroom_ensuite
    });
  }

  // If no photo is found then it will return null and show a default
  return photo;
};

const prettyifySleepingArrangements = (listing) => {
  let flat_beds_array = [].concat.apply([],
    listing.units.map((unit) => {
      return unit.beds.map((bed) => {
        return bed;
      });
    })
  );

  let formatted_arrangements = flat_beds_array.reduce((arr, bed) => {
    const bed_type = bed.type || 'none';
    let found = false;
    arr.forEach((bedroom, i) => {
      if (bedroom.name === bed.bedroom) {
        var types = arr[i].types;
        if (types[bed_type]) {
          types[bed_type].count++;
        } else {
          types[bed_type] = {
            count: 1,
          };
        }
        found = true;
      }
    });

    if (!found && bed.bedroom) {
      let photo = findPhoto(listing.photos, bed.bedroom);
      let obj = {
        name: bed.bedroom,
        count: 1,
        types: {},
        is_common: bed.is_common,
        ensuite: bed.ensuite,
        photo: photo ? photo.url_500 : null,
        arrangement_reference_id: bed.bedroom,
        arrangement_type: 'bedroom',
        arrangement_sub_type: null,
      };
      obj.types[bed_type] = {
        count: 1,
      };
      arr.push(obj);
    }

    return arr;
  }, []);

  let sleeping_areas_to_use = listing.sleeping_areas || [];
  let flat_sleeping_area_beds_array = [].concat.apply([],
    sleeping_areas_to_use.map((sleeping_area) => {
      return sleeping_area.beds.map((bed) => {
        return bed;
      });
    })
  );

  let formatted_sleeping_areas = flat_sleeping_area_beds_array.reduce((arr, bed) => {
    const bed_type = bed.type || 'none';
    let found = false;
    let bed_photo = findPhoto(listing.photos, bed.id.toString());

    arr.forEach((sleeping_area, i) => {
      if (sleeping_area.id === bed.sleeping_area_id) {

        if (!arr[i].photo || bed_photo) {
          const photo = bed_photo || findPhoto(listing.photos, sleeping_area.type);
          arr[i].photo = photo ? photo.url_500 : null;
        }

        var types = arr[i].types;
        if (types[bed_type]) {
          types[bed_type].count++;
        } else {
          types[bed_type] = {
            count: 1,
          };
        }
        found = true;
      }
    });

    if (!found && bed.sleeping_area_id) {
      const sleeping_area = listing.sleeping_areas.find(a => a.id === bed.sleeping_area_id);
      const photo = bed_photo || findPhoto(listing.photos, sleeping_area.type);

      let obj = {
        id: bed.sleeping_area_id,
        name: 'Extra sleeping area',
        count: 1,
        types: {},
        photo: photo ? photo.url_500 : null,
        arrangement_reference_id: bed.sleeping_area_id,
        arrangement_type: 'sleeping_area',
        arrangement_sub_type: sleeping_area ? sleeping_area.type : null,
      };
      obj.types[bed_type] = {
        count: 1,
      };
      arr.push(obj);
    }

    return arr;
  }, []);

  formatted_arrangements = formatted_arrangements
    .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    .concat(formatted_sleeping_areas.sort((a, b) => a.index - b.index));

  return formatted_arrangements.map(arrangement => {
    return {
      types: arrangement.types,
      is_common: arrangement.is_common,
      ensuite: arrangement.ensuite,
      name: arrangement.name,
      photo: arrangement.photo,
      arrangement_reference_id: arrangement.arrangement_reference_id,
      arrangement_type: arrangement.arrangement_type,
      arrangement_sub_type: arrangement.arrangement_sub_type,
    };
  });
};

export default {
  buildBedString,
  buildSleepingArrangementDescription,
  buildSleepingArrangementPrice,
  calculateAveragePrice,
  calculateDisplayPrice,
  calculateTotalNightlyPrice,
  compareIds,
  generateIdentifier,
  getActiveUnit,
  getBedList,
  getBedOptions,
  getBedrooms,
  getPermanentOrTemporaryId,
  prettyifySleepingArrangements,
  renderDisplayPrice,
  renderPerIntervalDisplayPrice,
  resetUnits,
  updateBedroomsForRent,
  updateBedCount,
  updateBedCountInSleepingArea,
  updateBedType,
  updateSleepingAreaBedType,
  updateSleepingAreaType,
  updateBedroomAttribute,
  updateBedPrice,
  updateSleepingAreas,
};
