import { Map, List, fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = Map({
  object: List([]),
  isLoading: false,
  error: null,
});

const AvailabilityEvents = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AvailabilityEvents').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AvailabilityEvents') : state)
      );
    } else {
      return state;
    }

    // Clear
  case 'CLEAR_AVAILABILITY_EVENTS':
    return initialState;

    // Get availability events
  case 'GET_AVAILABILITY_EVENTS_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'GET_AVAILABILITY_EVENTS_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.availabilityEvents),
      isLoading: false,
    });
  case 'GET_AVAILABILITY_EVENTS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Create availability event
  case 'CREATE_AVAILABILITY_EVENT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CREATE_AVAILABILITY_EVENT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').push(fromJS(action.availabilityEvent)),
      isLoading: false,
    });
  case 'CREATE_AVAILABILITY_EVENT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Update availability event
  case 'UPDATE_AVAILABILITY_EVENT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'UPDATE_AVAILABILITY_EVENT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').map((ae) => {
        if (ae.get('id') === action.availabilityEvent.id) {
          return fromJS(action.availabilityEvent);
        } else {
          return ae;
        }
      }),
      isLoading: false,
    });
  case 'UPDATE_AVAILABILITY_EVENT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Delete availability events
  case 'DELETE_AVAILABILITY_EVENT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'DELETE_AVAILABILITY_EVENT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').filter((ae) => ae.get('id') !== action.availabilityEventId),
      isLoading: false,
    });
  case 'DELETE_AVAILABILITY_EVENT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });
  default:
    return state;
  }
};

export default AvailabilityEvents;
