import React from 'react';
import PropTypes from 'prop-types';

const LinensIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <line x2="8" y1="14" y2="14" />
      <line x1="32" x2="48" y1="14" y2="14" />
      <line x1="8" x2="32" y1="28" y2="28" />
      <path d="m16 36v8c0 0.55228 0.44772 1 1 1h24c0.55228 0 1-0.44772 1-1v-38c0-1.6569-1.3431-3-3-3h-28c-1.6569 0-3 1.3431-3 3v29c0 0.55228 0.44772 1 1 1h22c0.55228 0 1-0.44772 1-1v-23c0-1.3333 1-2 3-2" />
    </g>
  </svg>
);

LinensIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default LinensIcon;
