import React from 'react';
import PropTypes from 'prop-types';

const DocumentIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 17 22" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g stroke="currentColor" transform="translate(-502 -769)">
        <g transform="translate(502 770)">
          <g strokeWidth="2" transform="translate(.77778 .61111)">
            <polygon points="14.401 12.173 14.401 0.36118 0.36126 0.36118 0.36126 18.768 7.8068 18.768" />
            <polyline points="7.7222 18.889 7.7222 11.889 14.222 11.889" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

DocumentIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default DocumentIcon;
