import ErrorHandler from '@utilities/ErrorHandler';

// ===================================
//   RETRIEVE GENERATED SEARCH PAGE REQUEST
// ===================================
const retrieveGeneratedSearchPageRequestLoading = () => ({
  type: 'RETRIEVE_GENERATED_SEARCH_PAGE_REQUEST_LOADING',
});

const retrieveGeneratedSearchPageRequestSuccess = (data) => ({
  type: 'RETRIEVE_GENERATED_SEARCH_PAGE_REQUEST_SUCCESS',
  data,
});

const retrieveGeneratedSearchPageRequestFailure = (error) => ({
  type: 'RETRIEVE_GENERATED_SEARCH_PAGE_REQUEST_FAILURE',
  error,
});

const retrieveGeneratedSearchPageRequest = (apiService, stateSlug, citySlug) => async (dispatch) => {
  if (!stateSlug || !citySlug) return;

  dispatch(retrieveGeneratedSearchPageRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/generated_search_pages/${stateSlug}/${citySlug}`,
      method: 'GET',
    });
    dispatch(retrieveGeneratedSearchPageRequestSuccess(response));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveGeneratedSearchPageRequestFailure
    );

    throw error;
  }
};

export default {
  retrieveGeneratedSearchPageRequest,
};
