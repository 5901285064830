import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  inviteLoading: false,
  invitesLoading: false,
  isLoading: false,
  member: {},
  members: [],
  memberLoading: false,
});

const CompanyMembers = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('CompanyMembers').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('CompanyMembers') : state)
      );
    } else {
      return state;
    }

    // Update company members in state
  case 'UPDATE_COMPANY_MEMBERS':
    return state.merge({
      members: state.get('members').merge(fromJS(action.members)),
    });

    // Company member invites actions
  case 'SEND_COMPANY_MEMBER_INVITES_LOADING':
    return state.merge({
      invitesLoading: true,
    });
  case 'SEND_COMPANY_MEMBER_INVITES_SUCCESS':
    return state.merge({
      members: fromJS(action.companyMemberInvites),
      invitesLoading: false,
    });
  case 'SEND_COMPANY_MEMBER_INVITES_FAILURE':
    return state.merge({
      invitesLoading: false,
      error: action.error,
    });

    // Company member invite actions
  case 'SEND_COMPANY_MEMBER_INVITE_LOADING':
    return state.merge({
      inviteLoading: true,
    });
  case 'SEND_COMPANY_MEMBER_INVITE_SUCCESS':
    return state.merge({
      inviteLoading: false,
    });
  case 'SEND_COMPANY_MEMBER_INVITE_FAILURE':
    return state.merge({
      inviteLoading: false,
      error: action.error,
    });

  case 'RESEND_COMPANY_MEMBER_INVITE_LOADING':
    return state.merge({
      inviteLoading: true,
    });
  case 'RESEND_COMPANY_MEMBER_INVITE_SUCCESS':
    return state.merge({
      inviteLoading: false,
    });
  case 'RESEND_COMPANY_MEMBER_INVITE_FAILURE':
    return state.merge({
      inviteLoading: false,
      error: action.error,
    });

  case 'CANCEL_COMPANY_MEMBER_INVITE_LOADING':
    return state.merge({
      inviteLoading: true,
    });
  case 'CANCEL_COMPANY_MEMBER_INVITE_SUCCESS':
    return state.merge({
      inviteLoading: false,
      members: fromJS(state.get('members').filter(element => element.get('id') !== action.memberId)),
    });
  case 'CANCEL_COMPANY_MEMBER_INVITE_FAILURE':
    return state.merge({
      inviteLoading: false,
      error: action.error,
    });

    // Company member update actions
  case 'UPDATE_COMPANY_MEMBER_ROLE_REQUEST_LOADING':
    return state.merge({
      memberLoading: true,
    });
  case 'UPDATE_COMPANY_MEMBER_ROLE_REQUEST_SUCCESS':
    return state.merge({
      members: state.get('members').map(member => {
        if (action.memberId === member.get('id')) {
          return member.setIn(['company_membership', 'role'], action.role);
        } else {
          return member;
        }
      }),
      memberLoading: false,
    });
  case 'UPDATE_COMPANY_MEMBER_ROLE_REQUEST_FAILURE':
    return state.merge({
      memberLoading: false,
      error: action.error,
    });

    // Company member get actions
  case 'GET_COMPANY_MEMBERS_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'GET_COMPANY_MEMBERS_SUCCESS':
    return state.merge({
      members: fromJS(action.members),
      isLoading: false,
    });
  case 'GET_COMPANY_MEMBERS_FAILURE':
    return state.merge({
      error: action.error,
      isLoading: false,
    });

    // Company member delete actions
  case 'DELETE_COMPANY_MEMBER_REQUEST_LOADING':
    return state.merge({
      memberLoading: true,
    });
  case 'DELETE_COMPANY_MEMBER_REQUEST_SUCCESS':
    return state.merge({
      members: state.get('members').filter(m => m.get('id') !== action.memberId),
      memberLoading: false,
    });
  case 'DELETE_COMPANY_MEMBER_REQUEST_FAILURE':
    return state.merge({
      memberLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default CompanyMembers;
