import React from 'react';
import PropTypes from 'prop-types';

const ArrowBackIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(1.5 1.5)">
      <polyline points="-3 3.5226 2.9887 9.5 9 3.5" transform="translate(3 6.5) scale(-1 1) rotate(-90) translate(-3 -6.5)" />
      <line transform="translate(7.625 6.5) rotate(-90) translate(-7.625 -6.5)" x1="7.625" x2="7.625" y1="13.375" y2="-.375" />
    </g>
  </svg>
);

ArrowBackIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ArrowBackIcon;
