import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: {},
  isLoading: false,
  error: null,
});

const CompanyReviews = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('CompanyReviews').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('CompanyReviews') : state)
      );
    } else {
      return state;
    }

    // Company
  case 'RETRIEVE_COMPANY_REVIEWS_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_COMPANY_REVIEWS_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.reviews),
      isLoading: false,
    });
  case 'RETRIEVE_COMPANY_REVIEWS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });
  default:
    return state;
  }
};

export default CompanyReviews;
