import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: {},
  isLoading: true,
  cancellationPolicyStatus: 'unknown',
  error: null,
});

const CancellationPolicy = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('CancellationPolicy').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('CancellationPolicy') : state)
      );
    } else {
      return state;
    }

    // Retrieve cancellation policy
  case 'RETRIEVE_CANCELLATION_POLICY_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_CANCELLATION_POLICY_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.cancellationPolicy || {}),
      cancellationPolicyStatus: action.cancellationPolicy ? 'exists' : 'blank',
      isLoading: false,
    });
  case 'RETRIEVE_CANCELLATION_POLICY_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Update cancellation policy
  case 'UPDATE_CANCELLATION_POLICY_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'UPDATE_CANCELLATION_POLICY_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.cancellationPolicy),
      isLoading: false,
    });
  case 'UPDATE_CANCELLATION_POLICY_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default CancellationPolicy;
