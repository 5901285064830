import { toast } from 'react-toastify';
import * as Sentry from '@sentry/node';

const requestError = async (dispatch, error, method, showToast = true, message = 'Uh oh! Looks like something went wrong. Please try again.') => {
  if (error.data && error.data.message) {
    message = typeof error.data.message === 'object' && error.data.message !== null ? error.data.message.message : error.data.message;
  }

  dispatch(method(message));

  if (showToast) {
    // API errors can be returned with a type, which we'll transpose into a toast ID
    // so they aren't duplicated on screen.
    const toastId = error.data && error.data.type ? error.data.type : 'unknown_error';
    setTimeout(() => {
      toast(message, {
        type: toast.TYPE.ERROR,
        toastId,
      });
    });
  }

  if (error.data) {
    Sentry.addBreadcrumb({
      message,
      category: 'error_handler',
      data: error.data,
    });
  }

  console.error(message);
};

const generalError = async (error, message = 'Uh oh! Looks like something went wrong. Please try again.', errorId) => {
  setTimeout(() => {
    toast(message, {
      type: toast.TYPE.ERROR,
      toastId: errorId,
    });
  });

  Sentry.captureException(error);
};

export default {
  requestError,
  generalError,
};
