import ErrorHandler from '@utilities/ErrorHandler';

// =================================
//   RETRIEVE REQUEST INVITES REQUEST
// ===================================
const retrieveRequestInvitesRequestLoading = () => ({
  type: 'RETRIEVE_REQUEST_INVITES_REQUEST_LOADING',
});

const retrieveRequestInvitesRequestSuccess = (invites) => ({
  type: 'RETRIEVE_REQUEST_INVITES_REQUEST_SUCCESS',
  invites,
});

const retrieveRequestInvitesRequestFailure = (error) => ({
  type: 'RETRIEVE_REQUEST_INVITES_REQUEST_FAILURE',
  error,
});

const retrieveRequestInvitesRequest = (apiService, requestId) => async (dispatch) => {
  dispatch(retrieveRequestInvitesRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${requestId}/invites`,
      method: 'GET',
    });
    dispatch(retrieveRequestInvitesRequestSuccess(response.booking_request_invites));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveRequestInvitesRequestFailure
    );
    throw error;
  }
};

export default {
  retrieveRequestInvitesRequest,
};
