import React from 'react';
import PropTypes from 'prop-types';

const CableIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <rect height="26" rx="1" width="44" x="2" y="6" />
      <line x1="28" x2="28" y1="32" y2="39" />
      <line x1="20" x2="20" y1="32" y2="39" />
      <polygon points="10.5 19.379 10.5 24.379 5.5 24.379" transform="translate(8 21.879) rotate(-45) translate(-8 -21.879)" />
      <line x1="15" x2="40" y1="22" y2="22" />
      <line x1="15" x2="32" y1="26" y2="26" />
      <rect height="4" transform="translate(24 40) scale(1 -1) translate(-24 -40)" width="24" x="12" y="38" />
    </g>
  </svg>
);

CableIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CableIcon;
