import React from 'react';
import PropTypes from 'prop-types';

const LaundryIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <rect height="32" rx="2" width="40" x="4" y="14" />
      <path d="m10 2h28c1.1046 0 2 0.89543 2 2v10h-32v-10c0-1.1046 0.89543-2 2-2z" />
      <circle cx="24" cy="30" r="8" />
      <circle cx="24" cy="30" r="12" />
      <line x1="12" x2="18" y1="8" y2="8" />
      <path d="m17 26c4 4 9 5.3333 15 4" />
      <circle cx="32" cy="8" r="2" />
      <line x1="7" x2="13" y1="19" y2="19" />
    </g>
  </svg>
);

LaundryIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default LaundryIcon;
