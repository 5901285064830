import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: {},
  isLoading: true,
});

const RequestView = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('RequestView').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('RequestView') : state)
      );
    } else {
      return state;
    }

  case 'RETRIEVE_VIEW_REQUEST_REQUEST_LOADING':
  case 'RETRIEVE_VIEW_CONVERSATION_REQUEST_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });

  case 'CREATE_REQUEST_REQUEST_SUCCESS':
  case 'CREATE_REQUEST_INVITES_REQUEST_SUCCESS':
  case 'RETRIEVE_VIEW_REQUEST_REQUEST_SUCCESS':
  case 'RETRIEVE_VIEW_CONVERSATION_REQUEST_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.request),
      isLoading: false,
    });
  case 'RETRIEVE_VIEW_REQUEST_REQUEST_FAILURE':
  case 'RETRIEVE_VIEW_CONVERSATION_REQUEST_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Update request
  case 'UPDATE_REQUEST_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'UPDATE_REQUEST_REQUEST_FAILURE':
  case 'UPDATE_REQUEST_PRICING_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
    });

  default:
    return state;
  }
};

export default RequestView;
