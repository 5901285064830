import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==================================
//   RETRIEVE DASHBOARD STATS
// ==================================
const retrieveDashboardStatsRequestLoading = () => ({
  type: 'RETRIEVE_DASHBOARD_STATS_REQUEST_LOADING',
});

const retrieveDashboardStatsRequestSuccess = (data) => ({
  type: 'RETRIEVE_DASHBOARD_STATS_REQUEST_SUCCESS',
  stats: data,
});

const retrieveDashboardStatsRequestFailure = () => ({
  type: 'RETRIEVE_DASHBOARD_STATS_REQUEST_FAILURE',
});

const retrieveDashboardStatsRequest = (companyId) => async (dispatch) => {
  dispatch(retrieveDashboardStatsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/dashboard_stats`,
      method: 'GET',
    });
    dispatch(retrieveDashboardStatsRequestSuccess(response));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveDashboardStatsRequestFailure
    );
  }
};

export default {
  retrieveDashboardStatsRequest,
};
