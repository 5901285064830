import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { fromJS } from 'immutable';
import queryString from 'qs';
import ApiService from '@services/ApiService';
import GoogleMapsService from '@services/GoogleMapsService';
import ListingSearchUtility from '@utilities/ListingSearchUtility';
import StringUtility from '@utilities/StringUtility';

const MinimumAge = 16;
const MaximumAge = 99;

const searchPageTypes = {
  state: 'state',
  city: 'city',
  tag: 'tag',
};

// Note: Add more keys here as we progress so we don't need to copy and paste
// the keys for the filters everywhere.
const filterKeys = {
  entireSpace: 'entire_space',
  individualBed: 'room',
};

const listingSearchChangedSource = {
  city: 'city',
  dates: 'dates',
  map: 'map',
  otherFilters: 'otherFilters',
  price: 'price',
  pagination: 'pagination',
  sort: 'sort',
};

const defaultZoomLevels = {
  city: 11,
  state: 6,
};

const defaultFilters = {
  // Search settings
  searchPageType: 'city',
  useCookies: true,
  // Page
  page: 1,
  // Address
  city: 'San Francisco',
  citySlug: 'san-francisco',
  state: 'California',
  stateSlug: 'california',
  stateShort: 'CA',
  citySearchText: 'San Francisco, CA',
  defaultLocationSet: true,
  // Dates
  start: null,
  end: null,
};

const defaultListingSearchFilters = {
  listingSearchChangedSource: '',
  bounds: '37.561325,-122.650128,37.987919,-122.188703',
  searchLatitude: 37.761776,
  searchLongitude: -122.4252905,
  zoom: defaultZoomLevels.city,
  sortType: 'price',
  sortOrder: 'asc',
  beds: 1,
  bedsExact: false,
  bedrooms: 1,
  bedroomsExact: false,
  bathrooms: 1,
  bathroomsExact: false,
  minimumWifiSpeed: null,
  priceMax: null,
  priceMin: null,
  pricePercentage: null,
  pricePercentageOperator: null,
  rentalType: [],
  amenities: [],
  facilities: [],
  rules: [],
  accessibility: [],
  furnishings: [],
};

const defaultHousemateSearchFilters = {
  matchGender: false,
  withinDays: 30,
  minAge: 16,
  maxAge: 99,
  cigarettes: null,
  alcohol: null,
  marijuana: null,
  cleanliness: null,
  gender: null,
};

const getSearchText = (searchPageType, city, state, stateShort) => {
  if (searchPageType === searchPageTypes.city || searchPageType === searchPageTypes.tag) {
    return `${city}, ${stateShort}`;
  } else if (searchPageType === 'state') {
    return state;
  }
  return '';
};

const parseCityDetails = (cityDetails, cookieFilters = {}, defaultFilters = {}) => {
  let { city = '', state, stateShort } = cityDetails;
  let defaultLocationSet = false;

  if (city || city.length === 0) {
    city = StringUtility.capitalizeFirstLetter(city.replace(/-/g, ' '));
  } else {
    city = getInputItemString({ city }, cookieFilters, defaultFilters, 'city');
  }

  if (state) {
    state = StringUtility.capitalizeFirstLetter(state.replace(/-/g, ' '));
  } else {
    state = getInputItemString({ state }, cookieFilters, defaultFilters, 'state');
    defaultLocationSet = true;
  }

  let citySlug = StringUtility.formatUrlSlug(city);
  let stateSlug = StringUtility.formatUrlSlug(state);

  return {
    city,
    citySlug,
    state,
    stateSlug,
    stateShort,
    defaultLocationSet,
  };
};

// Custom bounds parser
// query bounds = 'clear' is an override so it ignores existing bounds
const parseBoundsParam = async (query, cookieFilters = {}, defaultFilters = {}, city) => {
  if (query.bounds && query.bounds !== 'clear') {
    // From query params
    return query.bounds;
  } else if (cookieFilters && cookieFilters.bounds && query.bounds !== 'clear') {
    // From cookies
    return cookieFilters.bounds;
  } else if (city === defaultFilters.city) {
    // Default bounds if the city is default
    return defaultFilters.bounds;
  }

  return null;
};

// Return input item first as a string looking in params, then local storage and fallback to default
const getInputItemString = (query, cookieFilters = {}, defaultFilters, key) => {
  if (query[key]) {
    // From query params
    return query[key];
  } else if (cookieFilters && cookieFilters[key]) {
    // From cookies
    return cookieFilters[key];
  }
  // Default
  return defaultFilters[key];
};

// Return input item as an integer first looking in params, then local storage and fallback to default
const getInputItemNumber = (query, cookieFilters = {}, defaultFilters, key) => {
  if (query[key]) {
    // From query params
    return +query[key];
  } else if (cookieFilters && cookieFilters[key]) {
    // From cookies
    return +cookieFilters[key];
  }
  // Default
  return defaultFilters[key];
};

// Return input item as an boolean first looking in params, then local storage and fallback to default
const getInputItemBoolean = (query, cookieFilters = {}, defaultFilters, key) => {
  if (query[key]) {
    // From query params
    return query[key] === 'true';
  } else if (cookieFilters && cookieFilters[key]) {
    // From cookies
    return cookieFilters[key];
  }
  // Default
  return defaultFilters[key];
};

// Return input item as an array first looking in params, then local storage and fallback to default
const getInputItemArray = (query, cookieFilters = {}, defaultFilters, key) => {
  if (query[key]) {
    // From query params
    let array = query[key];
    if (Array.isArray(array)) {
      return query[key];
    }
    // When there is one param you need to conver to an array
    return [query[key]];
  } else if (cookieFilters && cookieFilters[key]) {
    // From cookies
    return cookieFilters[key];
  }
  // Default
  return defaultFilters[key];
};

// Return input item first looking in params, then local storage and fallback to default
const getInputItemDate = (query, cookieFilters = {}, defaultFilters, key) => {
  let paramDate;
  if (query[key] && isValidDate(query[key])) {
    // From query params
    paramDate = query[key];
  } else if (cookieFilters && cookieFilters[key] && isValidDate(cookieFilters[key])) {
    // From cookies
    paramDate = cookieFilters[key];
  }

  if (paramDate) {
    return paramDate;
  }
  // Default
  return defaultFilters[key];
};

const isValidDate = (dateStr) => {
  return dateStr.length === 10 && /\d{4}-\d{2}-\d{2}/.test(dateStr);
};

// Sets the the filters
// 1. Pull from the url
// 2. Pull from params stored in Cookies
// 3. Use defaults
const buildInitialFilters = async (query = {}, cookies, cityDetails = {}) => {
  const cookieFilters = getInputItemBoolean(query, {}, 'useCookies') === false ? {} : (cookies.search_filters || {});
  const { city, citySlug, state, stateSlug, stateShort, defaultLocationSet } = parseCityDetails(cityDetails, cookieFilters, defaultFilters);
  const bounds = await parseBoundsParam(query, cookieFilters, defaultListingSearchFilters, city, state);

  // Backfill new all pets rule (can bed removed in the future)
  const rules = getInputItemArray(query, cookieFilters, defaultListingSearchFilters, 'rules');
  var allPetsIndex = rules.indexOf('all');
  if (allPetsIndex >= 0) {
    rules[allPetsIndex] = 'all_pets';
  }

  return {
    // Search page type
    searchPageType: query.searchPageType || null,
    // Page
    page: getInputItemNumber(query, cookieFilters, defaultFilters, 'page'),
    // Address
    city: city || null,
    citySlug: citySlug || null,
    state: state || null,
    stateSlug: stateSlug || null,
    stateShort: stateShort || null,
    defaultLocationSet: defaultLocationSet,
    citySearchText: getSearchText(query.searchPageType, city, state, stateShort),
    // Dates
    start: getInputItemDate(query, cookieFilters, defaultFilters, 'start'),
    end: getInputItemDate(query, cookieFilters, defaultFilters, 'end'),

    // Listing search filters
    bounds: bounds || null,
    searchLatitude: getInputItemNumber(query, cookieFilters, defaultListingSearchFilters, 'searchLatitude'),
    searchLongitude: getInputItemNumber(query, cookieFilters, defaultListingSearchFilters, 'searchLongitude'),
    zoom: getInputItemNumber(query, cookieFilters, defaultListingSearchFilters, 'zoom'),
    sortType: getInputItemString(query, cookieFilters, defaultListingSearchFilters, 'sortType'),
    sortOrder: getInputItemString(query, cookieFilters, defaultListingSearchFilters, 'sortOrder'),
    beds: getInputItemNumber(query, cookieFilters, defaultListingSearchFilters, 'beds'),
    bedsExact: getInputItemBoolean(query, cookieFilters, defaultListingSearchFilters, 'bedsExact'),
    bedrooms: getInputItemNumber(query, cookieFilters, defaultListingSearchFilters, 'bedrooms'),
    bedroomsExact: getInputItemBoolean(query, cookieFilters, defaultListingSearchFilters, 'bedroomsExact'),
    bathrooms: getInputItemNumber(query, cookieFilters, defaultListingSearchFilters, 'bathrooms'),
    bathroomsExact: getInputItemBoolean(query, cookieFilters, defaultListingSearchFilters, 'bathroomsExact'),
    minimumWifiSpeed: getInputItemString(query, cookieFilters, defaultListingSearchFilters, 'minimumWifiSpeed'),
    priceMax: getInputItemNumber(query, cookieFilters, defaultListingSearchFilters, 'priceMax'),
    rentalType: getInputItemArray(query, cookieFilters, defaultListingSearchFilters, 'rentalType'),
    amenities: getInputItemArray(query, cookieFilters, defaultListingSearchFilters, 'amenities'),
    facilities: getInputItemArray(query, cookieFilters, defaultListingSearchFilters, 'facilities'),
    accessibility: getInputItemArray(query, cookieFilters, defaultListingSearchFilters, 'accessibility'),
    furnishings: getInputItemArray(query, cookieFilters, defaultListingSearchFilters, 'furnishings'),
    rules,

    // Housemate search filters
    withinDays: getInputItemNumber(query, cookieFilters, defaultHousemateSearchFilters, 'withinDays'),
    matchGender: getInputItemBoolean(query, cookieFilters, defaultHousemateSearchFilters, 'matchGender'),
    gender: getInputItemString(query, cookieFilters, defaultHousemateSearchFilters, 'gender'),
    minAge: getInputItemNumber(query, cookieFilters, defaultHousemateSearchFilters, 'minAge'),
    maxAge: getInputItemNumber(query, cookieFilters, defaultHousemateSearchFilters, 'maxAge'),
    cigarettes: getInputItemString(query, cookieFilters, defaultHousemateSearchFilters, 'cigarettes'),
    alcohol: getInputItemString(query, cookieFilters, defaultHousemateSearchFilters, 'alcohol'),
    marijuana: getInputItemString(query, cookieFilters, defaultHousemateSearchFilters, 'marijuana'),
    cleanliness: getInputItemString(query, cookieFilters, defaultHousemateSearchFilters, 'cleanliness'),
  };
};

const buildAppliedListingSearchFilters = (filters, isMobileSearch) => {
  const priceFilterApplied = !!(filters.get('priceMax'));
  const rentalTypeFilterApplied = !!(filters.get('rentalType') && filters.get('rentalType').size > 0);
  const bedsFilterApplied = filters.get('beds') > 1;
  const bedsExactFilterApplied = filters.get('bedsExact');
  const bedroomsFilterApplied = filters.get('bedrooms') > 1;
  const bedroomsExactFilterApplied = filters.get('bedroomsExact');
  const bathroomsFilterApplied = filters.get('bathrooms') > 1;
  const bathroomsExactFilterApplied = filters.get('bathroomsExact');
  const minimumWifiSpeedFilterApplied = filters.get('minimumWifiSpeed');
  const amenitiesFilterApplied = filters.get('amenities') && filters.get('amenities').size > 0;
  const facilitiesFilterApplied = filters.get('facilities') && filters.get('facilities').size > 0;
  const rulesFilterApplied = filters.get('rules') && filters.get('rules').size > 0;
  const accessibilityFilterApplied = filters.get('accessibility') && filters.get('accessibility').size > 0;
  const furnishingsFilterApplied = filters.get('furnishings') && filters.get('furnishings').size > 0;

  const otherFiltersApplied = !!(
    bedsFilterApplied || bedroomsFilterApplied || bathroomsFilterApplied ||
    bedsExactFilterApplied || bedroomsExactFilterApplied || bathroomsExactFilterApplied || minimumWifiSpeedFilterApplied ||
    amenitiesFilterApplied || facilitiesFilterApplied || rulesFilterApplied || accessibilityFilterApplied ||
    furnishingsFilterApplied ||
    (priceFilterApplied && isMobileSearch) ||
    (rentalTypeFilterApplied && isMobileSearch));

  let numberOfOtherFiltersApplied = 0;
  if (otherFiltersApplied) {
    if (bedsFilterApplied) numberOfOtherFiltersApplied++;
    if (bedsExactFilterApplied) numberOfOtherFiltersApplied++;
    if (bedroomsFilterApplied) numberOfOtherFiltersApplied++;
    if (bedroomsExactFilterApplied) numberOfOtherFiltersApplied++;
    if (bathroomsFilterApplied) numberOfOtherFiltersApplied++;
    if (bathroomsExactFilterApplied) numberOfOtherFiltersApplied++;
    if (minimumWifiSpeedFilterApplied) numberOfOtherFiltersApplied++;
    if (amenitiesFilterApplied) numberOfOtherFiltersApplied += filters.get('amenities').size;
    if (facilitiesFilterApplied) numberOfOtherFiltersApplied += filters.get('facilities').size;
    if (rulesFilterApplied) numberOfOtherFiltersApplied += filters.get('rules').size;
    if (accessibilityFilterApplied) numberOfOtherFiltersApplied += filters.get('accessibility').size;
    if (furnishingsFilterApplied) numberOfOtherFiltersApplied += filters.get('furnishings').size;
    if (priceFilterApplied && isMobileSearch) numberOfOtherFiltersApplied++;
    if (rentalTypeFilterApplied && isMobileSearch) numberOfOtherFiltersApplied += filters.get('rentalType').size;
  }

  return {
    anyFilterApplied: priceFilterApplied || rentalTypeFilterApplied || otherFiltersApplied,
    priceFilterApplied,
    rentalTypeFilterApplied,
    otherFiltersApplied,
    numberOfOtherFiltersApplied,
  };
};

const filterChangesCauseRedirect = (filters, nextFilters) => {
  const changedSource = nextFilters.get('listingSearchChangedSource');
  const searchPageTypeChanged = nextFilters.get('searchPageType') !== filters.get('searchPageType');
  if (searchPageTypeChanged) {
    return true;
  }

  const searchChangedForPagingOrSorting =
    changedSource === listingSearchChangedSource.pagination ||
    changedSource === listingSearchChangedSource.sort;

  if (searchChangedForPagingOrSorting) {
    return false;
  }

  const mapChangeCausedCityChange =
    changedSource === listingSearchChangedSource.map &&
    nextFilters.get('city') !== filters.get('city');

  const searchChangedForAnyOtherReason =
    changedSource !== listingSearchChangedSource.dates &&
    changedSource !== listingSearchChangedSource.map &&
    changedSource !== listingSearchChangedSource.pagination &&
    changedSource !== listingSearchChangedSource.sort;

  if (filters.get('searchPageType') === searchPageTypes.tag && (mapChangeCausedCityChange || searchChangedForAnyOtherReason)) {
    return true;
  }

  return false;
};

// Return a query param list of items for the listing request
const getListingSearchQueryFilterParams = (filters, screenSizes = fromJS({})) => {
  let params = {};

  let paramsStrings = [
    'page', 'sortType', 'sortOrder', 'beds', 'bedsExact', 'bedrooms',
    'bedroomsExact', 'bathrooms', 'bathroomsExact', 'minimumWifiSpeed', 'priceMax', 'priceMin',
    'pricePercentage', 'pricePercentageOperator', 'start', 'end',
  ];

  // If we are performing a state-wide search on mobile, restrict the listings by state name since the map
  // won't be shown and the bounds could be wider than the state
  if (screenSizes.get('xs') && filters.get('searchPageType') === searchPageTypes.state) {
    paramsStrings.push('state');
  }

  // In the case we don't have the bounds, use the city as the search reference
  if (!filters.get('bounds') && !filters.get('searchLatitude') && ! filters.get('searchLongitude')) {
    paramsStrings.push('city');
  } else {
    paramsStrings.push('bounds');
    paramsStrings.push('searchLatitude');
    paramsStrings.push('searchLongitude');
    paramsStrings.push('zoom');
  }

  paramsStrings.forEach(param => {
    if (filters.get(param)) {
      params[param] = filters.get(param);
    }
  });

  let paramsArrays = [
    'rentalType', 'amenities', 'facilities', 'rules', 'accessibility', 'furnishings',
  ];
  paramsArrays.forEach(param => {
    if (filters.get(param) && filters.get(param).size > 0) {
      params[param] = filters.get(param).toJS();
    }
  });

  return params;
};

const getHousemateSearchQueryFilterParams = (filters) => {
  let params = {};

  let paramsStrings = [
    'city', 'stateShort', 'start', 'end', 'page', 'withinDays', 'matchGender', 'minAge', 'maxAge',
    'cigarettes', 'alcohol', 'marijuana', 'cleanliness', 'moveIn', 'moveOut', 'gender',
  ];

  paramsStrings.forEach(param => {
    if (filters.get(param)) {
      params[param] = filters.get(param);
    }
  });

  return params;
};

const buildAppliedHousemateSearchFilters = (filters, isMobileSearch) => {
  const withinDaysFilterApplied = filters.get('withinDays') !== defaultHousemateSearchFilters.withinDays;
  const matchGenderFilterApplied = !!filters.get('matchGender');
  const ageFilterApplied = filters.get('minAge') !== MinimumAge || filters.get('maxAge') !== MaximumAge;
  const cigarettesFilterApplied = !!filters.get('cigarettes');
  const alcoholFilterApplied = !!filters.get('alcohol');
  const marijuanaFilterApplied = !!filters.get('marijuana');
  const cleanlinessFilterApplied = !!filters.get('cleanliness');
  const lifestyleFilterApplied =
    cigarettesFilterApplied ||
    alcoholFilterApplied ||
    marijuanaFilterApplied ||
    cleanlinessFilterApplied;

  const otherFiltersApplied = isMobileSearch &&
    (withinDaysFilterApplied || matchGenderFilterApplied || ageFilterApplied || lifestyleFilterApplied);

  let numberOfOtherFiltersApplied = 0;
  if (otherFiltersApplied) {
    if (withinDaysFilterApplied) numberOfOtherFiltersApplied++;
    if (matchGenderFilterApplied) numberOfOtherFiltersApplied++;
    if (ageFilterApplied) numberOfOtherFiltersApplied++;
    if (cigarettesFilterApplied) numberOfOtherFiltersApplied++;
    if (alcoholFilterApplied) numberOfOtherFiltersApplied++;
    if (marijuanaFilterApplied) numberOfOtherFiltersApplied++;
    if (cleanlinessFilterApplied) numberOfOtherFiltersApplied++;
  }

  return {
    anyFilterApplied: withinDaysFilterApplied || matchGenderFilterApplied || ageFilterApplied || lifestyleFilterApplied,
    ageFilterApplied,
    lifestyleFilterApplied,
    matchGenderFilterApplied,
    numberOfOtherFiltersApplied,
    otherFiltersApplied,
    withinDaysFilterApplied,
  };
};

const getListingSearchRedirect = (filters) => {
  if (filters.get('citySlug') && !filters.get('defaultLocationSet')) {
    return {
      pathname: ListingSearchUtility.buildSearchLink(filters.get('stateSlug'), filters.get('citySlug')),
      query: {
        start: filters.get('start'),
        end: filters.get('end'),
      },
    };
  }

  return '/c';
};

const buildFiltersFromGeneratedPageTag = async (tag, city, citySlug, stateSlug, query, cookieFilters) => {
  let rules = tag.rules ? [...tag.rules] : [];
  if (tag.pets) {
    if (tag.pets.includes('all_pets')) {
      rules.push('cats');
      rules.push('dogs');
      rules.push('all_pets');
    } else if (tag.pets.length > 0) {
      rules = rules.concat(tag.pets);
    }
  }

  let searchLatitude;
  let searchLongitude;
  let boundsToUse = city.bounds;

  if (!boundsToUse) {
    // IMPORTANT: Do NOT move this line outside this if statement. Bill will go up 10x
    const cityDetails = await GoogleMapsService.getCityDetails(city.name, stateSlug);
    searchLatitude = cityDetails.coords.latitude;
    searchLongitude = cityDetails.coords.longitude;
  } else {
    const boundsParts = boundsToUse.split(',');
    const southwestLat = Number(boundsParts[0]);
    const southwestLng = Number(boundsParts[1]);
    const northeastLat = Number(boundsParts[2]);
    const northeastLng = Number(boundsParts[3]);
    searchLatitude = (southwestLat + northeastLat) / 2;
    searchLongitude = (southwestLng + northeastLng) / 2;
  }

  let filters = fromJS({
    ...defaultListingSearchFilters,
    accessibility: tag.accessibility,
    amenities: tag.amenities,
    city: city.name,
    citySearchText: `${city.name}, ${city.state.short_name}`,
    citySlug: city.slug,
    end: getInputItemDate(query, cookieFilters, defaultListingSearchFilters, 'end') || null,
    facilities: tag.facilities,
    furnishings: [tag.furnishings],
    rules: rules,
    searchLatitude,
    searchLongitude,
    searchPageType: searchPageTypes.tag,
    state: city.state.name,
    stateSlug: city.state.slug,
    stateShort: city.state.short_name,
    start: getInputItemDate(query, cookieFilters, defaultListingSearchFilters, 'start') || null,
    zoom: city.zoom || defaultListingSearchFilters.zoom,
  });

  if (tag.bedrooms) {
    filters = filters.merge({
      bedrooms: tag.bedrooms,
      bedroomsExact: true,
    });
  }

  if (tag.rental_type) {
    filters = filters.merge(fromJS({
      rentalType: [tag.rental_type],
    }));
  }

  if (tag.min_stay && tag.min_stay > 0) {
    filters = filters.merge(fromJS({
      start: format(startOfMonth(addMonths(new Date(), 2)), 'yyyy-MM-dd'),
      end: format(endOfMonth(addMonths(new Date(), 1 + tag.min_stay)), 'yyyy-MM-dd'),
    }));
  }

  if (tag.max_stay && tag.max_stay > 0) {
    filters = filters.merge(fromJS({
      start: format(startOfMonth(addMonths(new Date(), 2)), 'yyyy-MM-dd'),
      end: format(endOfMonth(addMonths(new Date(), 1 + tag.max_stay)), 'yyyy-MM-dd'),
    }));
  }

  if (tag.price_percentage &&
    tag.price_percentage_operator) {

    // We are going to ask the percentiles API to give us the price at the specific percentile this tag cares about. (e.g. 50%)
    const queryObj = getListingSearchQueryFilterParams(filters);
    queryObj.percents = [tag.price_percentage];

    const query = queryString.stringify(queryObj);
    const apiService = new ApiService();

    const listingPercentiles = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings_percentiles?${query}`,
      method: 'GET',
    });

    const percentilePrice = listingPercentiles.aggregations.price_percentiles.values[tag.price_percentage + '.0'];
    if (listingPercentiles.aggregations.price_percentiles.values && percentilePrice) {

      if (tag.price_percentage_operator === 'lower') {
        filters = filters.merge({
          priceMax: Math.ceil(percentilePrice / 100) * 100,
        });
      } else if (tag.price_percentage_operator === 'upper') {
        filters = filters.merge({
          priceMin: Math.floor(percentilePrice / 100) * 100,
        });
      }
    }
  }

  if (tag.sort_type && tag.sort_order) {
    filters = filters.merge({
      sortType: tag.sort_type,
      sortOrder: tag.sort_order,
    });
  }

  return filters;
};

export default {
  buildAppliedHousemateSearchFilters,
  buildAppliedListingSearchFilters,
  buildFiltersFromGeneratedPageTag,
  buildInitialFilters,
  defaultFilters,
  defaultHousemateSearchFilters,
  defaultListingSearchFilters,
  defaultZoomLevels,
  filterKeys,
  filterChangesCauseRedirect,
  getInputItemBoolean,
  getInputItemDate,
  getInputItemNumber,
  getInputItemArray,
  getInputItemString,
  getHousemateSearchQueryFilterParams,
  getListingSearchQueryFilterParams,
  getListingSearchRedirect,
  getSearchText,
  listingSearchChangedSource,
  parseBoundsParam,
  parseCityDetails,
  MinimumAge,
  MaximumAge,
  searchPageTypes,
};
