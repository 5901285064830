import React from 'react';
import PropTypes from 'prop-types';

const BedIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <rect height="6" width="22" x="1" y="14" />
      <polyline points="1 13.298 3.9895 8.5 20.5 8.5 23 13.5" strokeLinecap="round" />
      <line x1="3" x2="3" y1="20" y2="24" />
      <line x1="21" x2="21" y1="20" y2="24" />
      <path d="m4 8.5v-5c0-1.1046 0.89543-2 2-2h12.5c1.1046 0 2 0.89543 2 2v5" />
    </g>
  </svg>
);

BedIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default BedIcon;
