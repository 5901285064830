import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==================================
//   RETRIEVE HOUSING SEARCH LISTINGS
// ==================================
const retrieveHousingSearchListingsRequestLoading = () => ({
  type: 'RETRIEVE_HOUSING_SEARCH_LISTINGS_REQUEST_LOADING',
});

const retrieveHousingSearchListingsRequestSuccess = (data) => ({
  type: 'RETRIEVE_HOUSING_SEARCH_LISTINGS_REQUEST_SUCCESS',
  ...data,
});

const retrieveHousingSearchListingsRequestFailure = () => ({
  type: 'RETRIEVE_HOUSING_SEARCH_LISTINGS_REQUEST_FAILURE',
});

const retrieveHousingSearchListingsRequest = (userId, page = 1) => async (dispatch) => {
  dispatch(retrieveHousingSearchListingsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/housing_search?page=${page}`,
      method: 'GET',
    });
    dispatch(retrieveHousingSearchListingsRequestSuccess(response));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveHousingSearchListingsRequestFailure
    );
  }
};

export default {
  retrieveHousingSearchListingsRequest,
};
