import { toast } from 'react-toastify';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   UPDATE HOUSEMATE POST
// ==========================
const updateHousematePostRequestLoading = () => ({
  type: 'UPDATE_HOUSEMATE_POST_REQUEST_LOADING',
});

const updateHousematePostRequestSuccess = (housematePost) => ({
  type: 'UPDATE_HOUSEMATE_POST_REQUEST_SUCCESS',
  housematePost,
});

const updateHousematePostRequestFailure = () => ({
  type: 'UPDATE_HOUSEMATE_POST_REQUEST_FAILURE',
});

const updateHousematePostRequest = (userId, housematePost) => async (dispatch) => {
  dispatch(updateHousematePostRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/housemate_posts/${housematePost.id}`,
      method: 'PUT',
      data: JSON.stringify(housematePost),
    });
    dispatch(updateHousematePostRequestSuccess(res));
  } catch (error) {
    return ErrorHandler.requestError(
      dispatch,
      error,
      updateHousematePostRequestFailure
    );
  }
};

// ==========================
//   CREATE HOUSEMATE POST
// ==========================
const createHousematePostRequestLoading = () => ({
  type: 'CREATE_HOUSEMATE_POST_REQUEST_LOADING',
});

const createHousematePostRequestSuccess = (housematePost) => ({
  type: 'CREATE_HOUSEMATE_POST_REQUEST_SUCCESS',
  housematePost,
});

const createHousematePostRequestFailure = () => ({
  type: 'CREATE_HOUSEMATE_POST_REQUEST_FAILURE',
});

const createHousematePostRequest = (userId, housematePost) => async (dispatch) => {
  dispatch(createHousematePostRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/housemate_posts`,
      method: 'POST',
      data: JSON.stringify(housematePost),
    });
    dispatch(createHousematePostRequestSuccess(res));
  } catch (error) {
    return ErrorHandler.requestError(
      dispatch,
      error,
      createHousematePostRequestFailure
    );
  }
};

// ==========================
//   DELETE HOUSEMATE POST
// ==========================
const deleteHousematePostRequestLoading = () => ({
  type: 'DELETE_HOUSEMATE_POST_REQUEST_LOADING',
});

const deleteHousematePostRequestSuccess = (housematePostId) => ({
  type: 'DELETE_HOUSEMATE_POST_REQUEST_SUCCESS',
  housematePostId,
});

const deleteHousematePostRequestFailure = () => ({
  type: 'DELETE_HOUSEMATE_POST_REQUEST_FAILURE',
});

const deleteHousematePostRequest = (userId, housematePostId) => async (dispatch) => {
  dispatch(deleteHousematePostRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/housemate_posts/${housematePostId}`,
      method: 'DELETE',
    });
    dispatch(deleteHousematePostRequestSuccess(housematePostId));

    // This is here because we catch and handle errors inside of these actions files. We don't have a consistent
    // way to inform the caller that the request failed.
    // TODO: Design a scheme to notify the caller of errors so toasts are not shown from here??
    toast(`You successfuly deleted your post to find housemates.`, {
      type: toast.TYPE.SUCCESS,
      toastId: 'delete-housemate-post-success',
    });

  } catch (error) {
    return ErrorHandler.requestError(
      dispatch,
      error,
      deleteHousematePostRequestFailure
    );
  }
};

export default {
  createHousematePostRequest,
  deleteHousematePostRequest,
  updateHousematePostRequest,
};
