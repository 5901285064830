import ApiService from '@services/ApiService';

import ErrorHandler from '@utilities/ErrorHandler';
import ListingEditStatic from '@utilities/static/ListingEditStatic';

const apiService = new ApiService();

// ==========================
//   RETRIEVE SAVED SEARCHES
// ==========================
const retrieveSavedSearchesRequestLoading = () => ({
  type: 'RETRIEVE_SAVED_SEARCHES_REQUEST_LOADING',
});

const retrieveSavedSearchesRequestSuccess = (data) => ({
  type: 'RETRIEVE_SAVED_SEARCHES_REQUEST_SUCCESS',
  ...data,
});

const retrieveSavedSearchesRequestFailure = () => ({
  type: 'RETRIEVE_SAVED_SEARCHES_REQUEST_FAILURE',
});

const retrieveSavedSearchesRequest = (userId) => async (dispatch) => {
  dispatch(retrieveSavedSearchesRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/saved_searches`,
      method: 'GET',
    });
    dispatch(retrieveSavedSearchesRequestSuccess(response));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveSavedSearchesRequestFailure
    );
  }
};

// ==========================
//   CREATE SAVED SEARCH
// ==========================
const createSavedSearchRequestLoading = () => ({
  type: 'CREATE_SAVED_SEARCH_REQUEST_LOADING',
});

const createSavedSearchRequestSuccess = (savedSearch) => ({
  type: 'CREATE_SAVED_SEARCH_REQUEST_SUCCESS',
  savedSearch,
});

const createSavedSearchRequestFailure = () => ({
  type: 'CREATE_SAVED_SEARCH_REQUEST_FAILURE',
});

const createSavedSearchRequest = (userId, filters) => async (dispatch) => {
  dispatch(createSavedSearchRequestLoading());

  try {
    let furnished_rental_selection = 'either';

    if (filters.get('furnishedRentalSelection')) {
      furnished_rental_selection = filters.get('furnishedRentalSelection');
    } else if (filters.get('furnishings')) {
      const furnishingsFilters = filters.get('furnishings');

      if (furnishingsFilters.size === 1) {
        if ((furnishingsFilters.includes(ListingEditStatic.tab1.furnishingsValues.fullyFurnished) ||
          furnishingsFilters.includes(ListingEditStatic.tab1.furnishingsValues.partiallyFurnished))) {
          furnished_rental_selection = 'furnished';
        } else {
          furnished_rental_selection = 'unfurnished';
        }
      } else {
        if (!furnishingsFilters.includes(ListingEditStatic.tab1.furnishingsValues.unfurnished)) {
          furnished_rental_selection = 'furnished';
        }
      }
    }

    const savedSearch = {
      user_id: userId,
      city: filters.get('city'),
      state: filters.get('state'),
      start: filters.get('start'),
      end: filters.get('end'),
      beds: filters.get('beds'),
      beds_exact: !!filters.get('bedsExact'),
      bedrooms: filters.get('bedrooms'),
      bedrooms_exact: !!filters.get('bedroomsExact'),
      bathrooms: filters.get('bathrooms'),
      bathrooms_exact: !!filters.get('bathroomsExact'),
      minimum_wifi_speed: filters.get('minimumWifiSpeed') ? +filters.get('minimumWifiSpeed') : null,
      rental_type: filters.get('rentalType') ? filters.get('rentalType').toJS() : ['either'],
      needs_housemates: filters.get('needsHousemates'),
      price_max: filters.get('priceMax'),
      commute_address: filters.get('commuteAddress'),
      pets: filters.get('pets') ? filters.get('pets').toJS() : [],
      amenities: filters.get('amenities') ? filters.get('amenities').toJS() : [],
      facilities: filters.get('facilities') ? filters.get('facilities').toJS() : [],
      accessibility: filters.get('accessibility') ? filters.get('accessibility').toJS() : [],
      furnishings: filters.get('furnishings') ? filters.get('furnishings').toJS() : [],
      rental_type_selection: filters.get('rentalTypeSelection') || 'either',
      furnished_rental_selection,
    };

    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/saved_searches`,
      method: 'POST',
      data: JSON.stringify(savedSearch),
    });
    dispatch(createSavedSearchRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createSavedSearchRequestFailure
    );
  }
};

// ==========================
//   DELETE SAVED SEARCH
// ==========================
const deleteSavedSearchRequestLoading = () => ({
  type: 'DELETE_SAVED_SEARCH_REQUEST_LOADING',
});

const deleteSavedSearchRequestSuccess = (savedSearchId) => ({
  type: 'DELETE_SAVED_SEARCH_REQUEST_SUCCESS',
  savedSearchId,
});

const deleteSavedSearchRequestFailure = () => ({
  type: 'DELETE_SAVED_SEARCH_REQUEST_FAILURE',
});

const deleteSavedSearchRequest = (userId, savedSearchId) => async (dispatch) => {
  dispatch(deleteSavedSearchRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/saved_searches/${savedSearchId}`,
      method: 'DELETE',
    });
    dispatch(deleteSavedSearchRequestSuccess(savedSearchId));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deleteSavedSearchRequestFailure
    );
  }
};

export default {
  retrieveSavedSearchesRequest,
  createSavedSearchRequest,
  deleteSavedSearchRequest,
};
