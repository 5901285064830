export default {
  mapStyles: [
    {
      'featureType': 'landscape.man_made',
      'elementType': 'geometry.fill',
      'stylers': [{
        'visibility': 'on',
      }, {
        'color': '#fffdfb',
      }],
    }, {
      'featureType': 'poi.attraction',
      'elementType': 'all',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'poi.attraction',
      'elementType': 'labels.text',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'poi.business',
      'elementType': 'all',
      'stylers': [{
        'visibility': 'off',
      }, {
        'saturation': '-63',
      }],
    }, {
      'featureType': 'poi.business',
      'elementType': 'labels.text',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'poi.government',
      'elementType': 'all',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'poi.medical',
      'elementType': 'all',
      'stylers': [{
        'visibility': 'on',
      }],
    }, {
      'featureType': 'poi.medical',
      'elementType': 'labels.text',
      'stylers': [{
        'visibility': 'simplified',
      }, {
        'color': '#B3ADA5',
      }],
    }, {
      'featureType': 'poi.medical',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'poi.park',
      'elementType': 'all',
      'stylers': [{
        'visibility': 'simplified',
      }, {
        'lightness': '-11',
      }],
    }, {
      'featureType': 'poi.park',
      'elementType': 'geometry.fill',
      'stylers': [{
        'color': '#b2dfc6',
      }],
    }, {
      'featureType': 'poi.park',
      'elementType': 'labels.text',
      'stylers': [{
        'visibility': 'on',
      }],
    }, {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#5a6849',
      }],
    }, {
      'featureType': 'poi.park',
      'elementType': 'labels.text.stroke',
      'stylers': [{
        'color': '#ffffff',
      }],
    }, {
      'featureType': 'poi.place_of_worship',
      'elementType': 'all',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'poi.school',
      'elementType': 'all',
      'stylers': [{
        'visibility': 'on',
      }],
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [{
        'color': '#e5ca89',
      }],
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry.stroke',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'road.arterial',
      'elementType': 'geometry.fill',
      'stylers': [{
        'visibility': 'on',
      }, {
        'color': '#efe9db',
      }],
    }, {
      'featureType': 'road.local',
      'elementType': 'geometry.fill',
      'stylers': [{
        'visibility': 'on',
      }, {
        'color': '#efe9db',
      }],
    }, {
      'featureType': 'road.local',
      'elementType': 'labels',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'transit',
      'elementType': 'geometry',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'transit',
      'elementType': 'geometry.fill',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'transit',
      'elementType': 'geometry.stroke',
      'stylers': [{
        'visibility': 'off',
      }, {
        'color': '#4e4e4e',
      }],
    }, {
      'featureType': 'transit',
      'elementType': 'labels',
      'stylers': [{
        'visibility': 'simplified',
      }],
    }, {
      'featureType': 'transit',
      'elementType': 'labels.text',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'transit',
      'elementType': 'labels.icon',
      'stylers': [{
        'visibility': 'off',
      }],
    }, {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#1a2b40',
      },
      {
        'visibility': 'off',
      },
      ],
    }, {
      'featureType': 'transit.line',
      'elementType': 'labels',
      'stylers': [{
        'visibility': 'on',
      }],
    }, {
      'featureType': 'transit.station.bus',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#1a2b40',
      }, {
        'visibility': 'off',
      }],
    }, {
      'featureType': 'transit.station.rail',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#1a2b40',
      }],
    }, {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [{
        'visibility': 'on',
      }, {
        'color': '#cfecfc',
      }],
    },
  ],
};
