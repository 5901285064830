import React from 'react';
import PropTypes from 'prop-types';

const CheckmarkCircleIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M23.997 11.73c.148 6.628-5.103 12.12-11.73 12.267C5.642 24.147.15 18.893.003 12.27-.147 5.64 5.106.15 11.73.002 18.36-.145 23.85 5.106 23.998 11.73" fill="currentColor" /><path d="M18.493 7.593L9.42 16.5l-3.823-3.756" stroke="#FFF" strokeLinecap="round" strokeWidth="2" />
    </g>
  </svg>
);

CheckmarkCircleIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CheckmarkCircleIcon;
