export default {
  degreeLevels: [
    {
      label: 'Undergraduate Degree',
      value: 'undergraduate',
    }, {
      label: 'Graduate Degree',
      value: 'graduate',
    }, {
      label: 'Doctoral Degree',
      value: 'doctoral',
    }, {
      label: 'High School Degree',
      value: 'high_school',
    },
  ],
  renterRoles: [
    {
      value: 'full_time',
      label: 'Full-time professional',
    }, {
      value: 'part_time',
      label: 'Part-time professional',
    }, {
      value: 'student',
      label: 'Student',
    }, {
      value: 'intern',
      label: 'Intern',
    }, {
      value: 'unemployed',
      label: 'Not employed',
    },
  ],
  educationLevels: [
    {
      label: 'Undergraduate',
      value: 'undergraduate',
    },
    {
      label: 'Graduate',
      value: 'graduate',
    },
    {
      label: 'Doctoral',
      value: 'doctoral',
    },
    {
      label: 'High School Degree',
      value: 'high_school',
    },
  ],
  genderOptions: [{
    value: 'male',
    label: 'Male',
  }, {
    value: 'female',
    label: 'Female',
  }, {
    value: 'agender',
    label: 'Agender',
  }, {
    value: 'androgyne',
    label: 'Androgyne',
  }, {
    value: 'bigender',
    label: 'Bigender',
  }, {
    value: 'genderfluid',
    label: 'Genderfluid',
  }, {
    value: 'gender_questioning',
    label: 'Gender Questioning',
  }, {
    value: 'intersex',
    label: 'Intersex',
  }, {
    value: 'non_binary',
    label: 'Non-Binary',
  }, {
    value: 'trans_male',
    label: 'Trans Male',
  }, {
    value: 'trans_female',
    label: 'Trans Female',
  }, {
    value: 'trans_person',
    label: 'Trans Person',
  }, {
    value: 'other',
    label: 'Other',
  }],
  personalPronounOptions: [{
    value: 'he_him_his',
    label: 'He/him/his',
  }, {
    value: 'she_her_hers',
    label: 'She/her/hers',
  }, {
    value: 'they_them_theirs',
    label: 'They/them/theirs',
  }],
  languageOptions: {
    abkhazian: 'Abkhazian',
    afar: 'Afar',
    afrikaans: 'Afrikaans',
    akan: 'Akan',
    albanian: 'Albanian',
    amharic: 'Amharic',
    arabic: 'Arabic',
    aragonese: 'Aragonese',
    armenian: 'Armenian',
    assamese: 'Assamese',
    avaric: 'Avaric',
    avestan: 'Avestan',
    aymara: 'Aymara',
    azerbaijani: 'Azerbaijani',
    bambara: 'Bambara',
    bashkir: 'Bashkir',
    basque: 'Basque',
    belarusian: 'Belarusian',
    bengali: 'Bengali',
    bihari: 'Bihari',
    bislama: 'Bislama',
    bosnian: 'Bosnian',
    breton: 'Breton',
    bulgarian: 'Bulgarian',
    burmese: 'Burmese',
    catalan: 'Catalan',
    chamorro: 'Chamorro',
    chechen: 'Chechen',
    chichewa: 'Chichewa',
    chinese: 'Chinese',
    chuvash: 'Chuvash',
    cornish: 'Cornish',
    corsican: 'Corsican',
    cree: 'Cree',
    croatian: 'Croatian',
    czech: 'Czech',
    danish: 'Danish',
    divehi: 'Divehi',
    dutch: 'Dutch',
    dzongkha: 'Dzongkha',
    english: 'English',
    esperanto: 'Esperanto',
    estonian: 'Estonian',
    ewe: 'Ewe',
    faroese: 'Faroese',
    fijian: 'Fijian',
    finnish: 'Finnish',
    french: 'French',
    frisian: 'Western Frisian',
    fulah: 'Fulah',
    galician: 'Galician',
    georgian: 'Georgian',
    german: 'German',
    greek: 'Greek',
    guarani: 'Guarani',
    gujarati: 'Gujarati',
    haitian: 'Haitian',
    hausa: 'Hausa',
    hebrew: 'Hebrew',
    herero: 'Herero',
    hindi: 'Hindi',
    hiri: 'Hiri Motu',
    hungarian: 'Hungarian',
    interlingua: 'Interlingua',
    indonesian: 'Indonesian',
    interlingue: 'Interlingue',
    irish: 'Irish',
    igbo: 'Igbo',
    inupiaq: 'Inupiaq',
    ido: 'Ido',
    icelandic: 'Icelandic',
    italian: 'Italian',
    inuktitut: 'Inuktitut',
    japanese: 'Japanese',
    javanese: 'Javanese',
    kalaallisut: 'Kalaallisut',
    kannada: 'Kannada',
    kanuri: 'Kanuri',
    kashmiri: 'Kashmiri',
    katanga: 'Luba-Katanga',
    kazakh: 'Kazakh',
    khmer: 'Central Khmer',
    kikuyu: 'Kikuyu',
    kinyarwanda: 'Kinyarwanda',
    kirghiz: 'Kirghiz',
    komi: 'Komi',
    kongo: 'Kongo',
    korean: 'Korean',
    kurdish: 'Kurdish',
    kuanyama: 'Kuanyama',
    latin: 'Latin',
    luxembourgish: 'Luxembourgish',
    gaelic: 'Gaelic',
    ganda: 'Ganda',
    limburgan: 'Limburgan',
    lingala: 'Lingala',
    lao: 'Lao',
    lithuanian: 'Lithuanian',
    latvian: 'Latvian',
    manx: 'Manx',
    macedonian: 'Macedonian',
    malagasy: 'Malagasy',
    malay: 'Malay',
    malayalam: 'Malayalam',
    maltese: 'Maltese',
    maori: 'Maori',
    marathi: 'Marathi',
    marshallese: 'Marshallese',
    mongolian: 'Mongolian',
    nauru: 'Nauru',
    navajo: 'Navajo, Navaho',
    north_ndebele: 'North Ndebele',
    nepali: 'Nepali',
    ndonga: 'Ndonga',
    bokmal: 'Norwegian Bokmål',
    nynorsk: 'Norwegian Nynorsk',
    norwegian: 'Norwegian',
    sichuan: 'Sichuan Yi',
    south_ndebele: 'South Ndebele',
    occitan: 'Occitan',
    ojibwa: 'Ojibwa',
    slavic: 'Church Slavic',
    oromo: 'Oromo',
    oriya: 'Oriya',
    ossetian: 'Ossetian, Ossetic',
    punjabi: 'Punjabi, Panjabi',
    pali: 'Pali',
    persian: 'Persian',
    polish: 'Polish',
    pashto: 'Pashto',
    portuguese: 'Portuguese',
    quechua: 'Quechua',
    romansh: 'Romansh',
    rundi: 'Rundi',
    romanian: 'Romanian',
    russian: 'Russian',
    sami: 'Northern Sami',
    sanskrit: 'Sanskrit',
    sardinian: 'Sardinian',
    sindhi: 'Sindhi',
    samoan: 'Samoan',
    sango: 'Sango',
    serbian: 'Serbian',
    shona: 'Shona',
    sinhala: 'Sinhala',
    slovak: 'Slovak',
    slovenian: 'Slovenian',
    somali: 'Somali',
    sotho: 'Southern Sotho',
    spanish: 'Spanish',
    sundanese: 'Sundanese',
    swahili: 'Swahili',
    swati: 'Swati',
    swedish: 'Swedish',
    tamil: 'Tamil',
    telugu: 'Telugu',
    tajik: 'Tajik',
    thai: 'Thai',
    tigrinya: 'Tigrinya',
    tibetan: 'Tibetan',
    turkmen: 'Turkmen',
    tagalog: 'Tagalog',
    tswana: 'Tswana',
    tonga: 'Tonga',
    turkish: 'Turkish',
    tsonga: 'Tsonga',
    tatar: 'Tatar',
    twi: 'Twi',
    tahitian: 'Tahitian',
    uighur: 'Uighur',
    ukrainian: 'Ukrainian',
    urdu: 'Urdu',
    uzbek: 'Uzbek',
    venda: 'Venda',
    vietnamese: 'Vietnamese',
    volapuk: 'Volapük',
    walloon: 'Walloon',
    welsh: 'Welsh',
    wolof: 'Wolof',
    xhosa: 'Xhosa',
    yiddish: 'Yiddish',
    yoruba: 'Yoruba',
    zhuang: 'Zhuang',
    zulu: 'Zulu',
  },
};
