import React from 'react';
import PropTypes from 'prop-types';

const StarHalfIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" preserveAspectRatio="xMidYMid meet" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <polygon fill="currentColor" points="7.8003 -2e-4 6.8193 6.9748 0.7383 5.6988 3e-4 8.0108 5.5453 10.777 2.3353 16.514 4.2823 17.952 8.9943 12.836 8.9943 -2e-4" />
    <polygon fill="#E8E4DF" points="8.9946 -2e-4 8.9946 12.836 8.9966 12.833 13.712 17.952 15.662 16.51 12.293 10.648 17.998 8.0228 17.26 5.7108 10.951 6.8268 10.205 -2e-4" />
  </svg>
);

StarHalfIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default StarHalfIcon;
