import Router from 'next/router';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ============================
//   UPDATE VALIDATION
// =============================
const updateValidation = (validation, showValidation) => ({
  type: 'UPDATE_VALIDATION',
  validation,
  showValidation,
});

// ============================
//   UPDATE LISTING DETAILS
// =============================
const updateListingSuccess = (key, value) => ({
  type: 'UPDATE_LISTING',
  key,
  value,
});

const updateListing = (key, value) => (dispatch) => (
  dispatch(updateListingSuccess(key, value))
);

// ============================
//   UPDATE BEDROOMS DETAILS
// =============================
const updateBedroomsSuccess = (bedrooms, beds) => ({
  type: 'UPDATE_BEDROOMS',
  bedrooms,
  beds,
});

const updateBedrooms = (bedrooms, beds) => (dispatch) => (
  dispatch(updateBedroomsSuccess(bedrooms, beds))
);

// =====================
//   CLEAR LISTING EDIT
// =====================
const clearListingEditSuccess = () => ({
  type: 'CLEAR_LISTING_EDIT',
});

const clearAvailabilityEvents = () => ({
  type: 'CLEAR_AVAILABILITY_EVENTS',
});

const clearListingEdit = () => (dispatch) => {
  dispatch(clearListingEditSuccess());
  dispatch(clearAvailabilityEvents());
};

// =================================
//   RETRIEVE LISTING REQUEST
// ===================================
const retrieveEditListingRequestLoading = () => ({
  type: 'RETRIEVE_EDIT_LISTING_REQUEST_LOADING',
});

const retrieveEditListingRequestSuccess = (listing) => ({
  type: 'RETRIEVE_EDIT_LISTING_REQUEST_SUCCESS',
  listing,
});

const retrieveEditListingRequestFailure = (error) => ({
  type: 'RETRIEVE_EDIT_LISTING_REQUEST_FAILURE',
  error,
});

const retrieveEditListingRequest = (listingId) => async (dispatch) => {
  dispatch(retrieveEditListingRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/edit`,
      method: 'GET',
    });
    dispatch(retrieveEditListingRequestSuccess(res));
  } catch (error) {
    // Push back to listings page
    Router.push('/account/listings');
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveEditListingRequestFailure,
      true,
      'There was an issue retrieving your listing.'
    );
  }
};

// ===========================
//   CREATE LISTING REQUEST
// ===========================
const createListingRequestLoading = () => ({
  type: 'CREATE_LISTING_REQUEST_LOADING',
});

const createListingRequestSuccess = (listing) => ({
  type: 'CREATE_LISTING_REQUEST_SUCCESS',
  listing,
});

const createListingRequestFailure = (error) => ({
  type: 'CREATE_LISTING_REQUEST_FAILURE',
  error,
});

const createListingRequest = (listing) => async (dispatch) => {
  dispatch(createListingRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings`,
      method: 'POST',
      data: JSON.stringify({
        ...listing.toJSON(),
        source: 'listing_edit',
      }),
    });
    dispatch(createListingRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createListingRequestFailure,
      true,
      'There was an issue creating your listing.'
    );
  }
};

// ===========================
//   UPDATE LISTING REQUEST
// ===========================
const updateListingRequestLoading = () => ({
  type: 'UPDATE_LISTING_REQUEST_LOADING',
});

const updateListingRequestSuccess = (listing) => ({
  type: 'UPDATE_LISTING_REQUEST_SUCCESS',
  listing,
});

const updateListingRequestFailure = (error) => ({
  type: 'UPDATE_LISTING_REQUEST_FAILURE',
  error,
});

const updateListingRequest = (listing) => async (dispatch) => {
  dispatch(updateListingRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listing.get('id')}`,
      method: 'PUT',
      data: JSON.stringify({
        ...listing.toJSON(),
        source: 'listing_edit',
      }),
    });

    dispatch(updateListingRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateListingRequestFailure,
      true,
      'There was an issue updating your listing.'
    );
  }
};

// ============================================
//   UPDATE LISTING NEXT AVAILABLE DATE REQUEST
// ============================================
const updateListingNextAvailableDateRequestLoading = () => ({
  type: 'UPDATE_LISTING_NEXT_AVAILABLE_DATE_REQUEST_LOADING',
});

const updateListingNextAvailableDateRequestSuccess = (listing) => ({
  type: 'UPDATE_LISTING_NEXT_AVAILABLE_DATE_REQUEST_SUCCESS',
  listing,
});

const updateListingNextAvailableDateRequestFailure = (error) => ({
  type: 'UPDATE_LISTING_NEXT_AVAILABLE_DATE_REQUEST_FAILURE',
  error,
});

const updateListingNextAvailableDateRequest = (listingId, unitId, nextAvailableDate) => async (dispatch) => {
  dispatch(updateListingNextAvailableDateRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/next_available_date`,
      method: 'PUT',
      data: JSON.stringify({
        unit_id: unitId,
        next_available_date: nextAvailableDate,
      }),
    });

    dispatch(updateListingNextAvailableDateRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateListingNextAvailableDateRequestFailure,
      true,
      'There was an issue updating your listing\'s next available date.'
    );
  }
};

// ===========================
//   PUBLISH LISTING REQUEST
// ===========================
const publishListingRequestLoading = () => ({
  type: 'PUBLISH_LISTING_REQUEST_LOADING',
});

const publishListingRequestSuccess = () => ({
  type: 'PUBLISH_LISTING_REQUEST_SUCCESS',
});

const publishListingRequestFailure = (error) => ({
  type: 'PUBLISH_LISTING_REQUEST_FAILURE',
  error,
});

const publishListingRequest = (listing) => async (dispatch) => {
  dispatch(publishListingRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listing.get('id')}/publish`,
      method: 'POST',
      data: JSON.stringify({
        source: 'listing_edit',
      }),
    });
    dispatch(publishListingRequestSuccess(response));
    return {
      response,
    };

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      publishListingRequestFailure,
      true,
      'There was an issue publishing your listing. Please try again.'
    );
    return {
      error: true,
    };
  }
};

export default {
  updateValidation,
  updateListing,
  updateBedrooms,
  clearListingEdit,
  retrieveEditListingRequest,
  updateListingRequest,
  createListingRequest,
  publishListingRequest,
  updateListingNextAvailableDateRequest,
};
