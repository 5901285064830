import qs from 'query-string';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// =============================
//   RETRIEVE ACCOUNT PAYMENTS
// =============================
const retrieveAccountPaymentsRequestLoading = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYMENTS_REQUEST_LOADING',
});

const retrieveAccountPaymentsRequestSuccess = (data) => ({
  type: 'RETRIEVE_ACCOUNT_PAYMENTS_REQUEST_SUCCESS',
  ...data,
});

const retrieveAccountPaymentsRequestFailure = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYMENTS_REQUEST_FAILURE',
});

const retrieveAccountPaymentsRequest = (userId, query = {}) => async (dispatch) => {
  dispatch(retrieveAccountPaymentsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/payments?${qs.stringify(query)}`,
      method: 'GET',
    });

    dispatch(retrieveAccountPaymentsRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveAccountPaymentsRequestFailure
    );
  }
};

const retrieveAccountPaymentRequestLoading = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYMENT_REQUEST_LOADING',
});

const retrieveAccountPaymentRequestSuccess = (data) => ({
  type: 'RETRIEVE_ACCOUNT_PAYMENT_REQUEST_SUCCESS',
  payment: data,
});

const retrieveAccountPaymentRequestFailure = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYMENT_REQUEST_FAILURE',
});

const retrieveAccountPaymentRequest = (userId, paymentId) => async (dispatch) => {
  dispatch(retrieveAccountPaymentRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/payments/${paymentId}`,
      method: 'GET',
    });

    dispatch(retrieveAccountPaymentRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveAccountPaymentRequestFailure
    );
  }
};

// =============================
//   CREATE ACCOUNT PAYMENT
// =============================
const createAccountPaymentRequestLoading = () => ({
  type: 'CREATE_ACCOUNT_PAYMENT_REQUEST_LOADING',
});

const createAccountPaymentRequestSuccess = (data) => ({
  type: 'CREATE_ACCOUNT_PAYMENT_REQUEST_SUCCESS',
  ...data,
});

const createAccountPaymentRequestFailure = () => ({
  type: 'CREATE_ACCOUNT_PAYMENT_REQUEST_FAILURE',
});

const createAccountPaymentRequest = (userId, paymentData) => async (dispatch) => {
  dispatch(createAccountPaymentRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/payments/rent`,
      method: 'POST',
      data: JSON.stringify(paymentData),
    });

    dispatch(createAccountPaymentRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createAccountPaymentRequestFailure
    );

    throw error;
  }
};

// =================================
//   RETRIEVE PAYMENT ESTIMATE REQUEST
// ===================================
const retrievePaymentEstimateRequestLoading = () => ({
  type: 'RETRIEVE_PAYMENT_ESTIMATE_REQUEST_LOADING',
});

const retrievePaymentEstimateRequestSuccess = (estimate) => ({
  type: 'RETRIEVE_PAYMENT_ESTIMATE_REQUEST_SUCCESS',
  estimate,
});

const retrievePaymentEstimateRequestFailure = (error) => ({
  type: 'RETRIEVE_PAYMENT_ESTIMATE_REQUEST_FAILURE',
  error,
});

const retrievePaymentEstimateRequest = (apiService, estimateRequestData) => async (dispatch) => {
  dispatch(retrievePaymentEstimateRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/payments/estimate`,
      method: 'POST',
      data: JSON.stringify(estimateRequestData),
    });

    dispatch(retrievePaymentEstimateRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrievePaymentEstimateRequestFailure
    );
    throw error;
  }
};

export default {
  createAccountPaymentRequest,
  retrieveAccountPaymentRequest,
  retrieveAccountPaymentsRequest,
  retrievePaymentEstimateRequest,
};
