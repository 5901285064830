import ErrorHandler from '@utilities/ErrorHandler';

// =================================
//   RETRIEVE LISTING REQUEST
// ===================================
const retrieveViewListingRequestLoading = () => ({
  type: 'RETRIEVE_VIEW_LISTING_REQUEST_LOADING',
});

const retrieveViewListingRequestSuccess = (listing) => ({
  type: 'RETRIEVE_VIEW_LISTING_REQUEST_SUCCESS',
  listing,
});

const retrieveViewListingRequestFailure = (error) => ({
  type: 'RETRIEVE_VIEW_LISTING_REQUEST_FAILURE',
  error,
});

const retrieveViewListingRequest = (apiService, listingId) => async (dispatch) => {
  dispatch(retrieveViewListingRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}`,
      method: 'GET',
    });
    dispatch(retrieveViewListingRequestSuccess(response));

    // Let the caller know that the listing the successfully retrieved
    return response;
  } catch (error) {
    console.error(error);
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveViewListingRequestFailure
    );
    return false;
  }
};

// =================================
//   RETRIEVE SIMILAR LISTINGS REQUEST
// ===================================
const retrieveSimilarListingsRequestLoading = () => ({
  type: 'RETRIEVE_SIMILAR_LISTINGS_REQUEST_LOADING',
});

const retrieveSimilarListingsRequestSuccess = (similarListings) => ({
  type: 'RETRIEVE_SIMILAR_LISTINGS_REQUEST_SUCCESS',
  similarListings,
});

const retrieveSimilarListingsRequestFailure = (error) => ({
  type: 'RETRIEVE_SIMILAR_LISTINGS_REQUEST_FAILURE',
  error,
});

const retrieveSimilarListingsRequest = (apiService, listingId, query) => async (dispatch) => {
  dispatch(retrieveSimilarListingsRequestLoading());
  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/similar?${query}`,
      method: 'GET',
    });
    dispatch(retrieveSimilarListingsRequestSuccess(response));

    // Let the caller know that the listing was successfully retrieved
    return true;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveSimilarListingsRequestFailure
    );
    return false;
  }
};

// =================================
//   RETRIEVE AVAILABLE UNITS REQUEST
// ===================================
const retrieveAvailableUnitsRequestLoading = () => ({
  type: 'RETRIEVE_AVAILABLE_UNITS_REQUEST_LOADING',
});

const retrieveAvailableUnitsRequestSuccess = (availableUnits) => ({
  type: 'RETRIEVE_AVAILABLE_UNITS_REQUEST_SUCCESS',
  availableUnits,
});

const retrieveAvailableUnitsRequestFailure = (error) => ({
  type: 'RETRIEVE_AVAILABLE_UNITS_REQUEST_FAILURE',
  error,
});

const retrieveAvailableUnitsRequest = (apiService, listingId, data) => async (dispatch) => {
  dispatch(retrieveAvailableUnitsRequestLoading());
  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/available_units`,
      method: 'POST',
      data: JSON.stringify(data),
    });
    dispatch(retrieveAvailableUnitsRequestSuccess(response));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveAvailableUnitsRequestFailure
    );
  }
};

export default {
  retrieveViewListingRequest,
  retrieveSimilarListingsRequest,
  retrieveAvailableUnitsRequest,
};
