import qs from 'query-string';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// =============================
//   RETRIEVE ACCOUNT PAYOUTS
// =============================
const retrieveAccountPayoutsRequestLoading = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYOUTS_REQUEST_LOADING',
});

const retrieveAccountPayoutsRequestSuccess = (data, queryIncluded) => ({
  type: 'RETRIEVE_ACCOUNT_PAYOUTS_REQUEST_SUCCESS',
  ...data,
  queryIncluded,
});

const retrieveAccountPayoutsRequestFailure = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYOUTS_REQUEST_FAILURE',
});

const retrieveAccountPayoutsRequest = (companyId, query = {}) => async (dispatch) => {
  dispatch(retrieveAccountPayoutsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/payouts?${qs.stringify(query)}`,
      method: 'GET',
    });
    const queryIncluded = query.payout_method_id || (query.search && query.search.length > 0);
    dispatch(retrieveAccountPayoutsRequestSuccess(response, queryIncluded));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveAccountPayoutsRequestFailure
    );
  }
};

const retrieveAccountPayoutRequestLoading = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYOUT_REQUEST_LOADING',
});

const retrieveAccountPayoutRequestSuccess = (data) => ({
  type: 'RETRIEVE_ACCOUNT_PAYOUT_REQUEST_SUCCESS',
  payout: data,
});

const retrieveAccountPayoutRequestFailure = () => ({
  type: 'RETRIEVE_ACCOUNT_PAYOUT_REQUEST_FAILURE',
});

const retrieveAccountPayoutRequest = (companyId, payoutId) => async (dispatch) => {
  dispatch(retrieveAccountPayoutRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/payouts/${payoutId}`,
      method: 'GET',
    });
    dispatch(retrieveAccountPayoutRequestSuccess(response));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveAccountPayoutRequestFailure
    );
  }
};

export default {
  retrieveAccountPayoutsRequest,
  retrieveAccountPayoutRequest,
};
