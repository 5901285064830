import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter } from 'next/router';

const AuthControls = ({ isDirectBookingNav, router }) => {

  const getRedirectDetails = () => {
    let redirectObject = {};

    if (router.pathname && router.pathname !== '/') {
      redirectObject.redirect = router.asPath;
    }

    return redirectObject;
  };

  return (
    <div className="hide-xs layout-row layout-align-start-center flex-no-shrink">
      <Link
        className="body-text magenta strong m-horizontal--x-small m-horizontal--small-gt-sm"
        href={{ pathname: '/login', query: getRedirectDetails() }}
      >

        Log in

      </Link>
      <Link
        className={`button magenta primary m-left--x-small ${isDirectBookingNav ? 'hide-xs' : ''}`}
        href={{ pathname: '/signup', query: getRedirectDetails() }}
      >

        Sign up

      </Link>
    </div>
  );
};

AuthControls.propTypes = {
  // Required
  router: PropTypes.object.isRequired,
  // Optional
  isDirectBookingNav: PropTypes.bool,
};

AuthControls.defaultProps = {
  isDirectBookingNav: false,
};

export default withRouter(AuthControls);
