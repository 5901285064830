import React, { Component } from 'react';
import Cookies from 'js-cookie';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import queryString from 'qs';
import withSizes from 'react-sizes';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fromJS } from 'immutable';
import CancellationPolicyAlert from '@components/Account/Dashboard/CancellationPolicyAlert';
import Loader from '@components/Core/Loader';
import Nav from '@components/Navigation/Nav';
import Zendesk from '@components/PageWrappers/Zendesk';
import ProductionOnlyComponent from '@components/Core/ProductionOnlyComponent';
import Actions from '@redux/actions';
import { wrapper } from '@redux/store';
import AnalyticsService from '@services/AnalyticsService';
import ApiService from '@services/ApiService';
import LocalStorageService from '@services/LocalStorageService';
import TermsOfServiceUtility from '@utilities/TermsOfServiceUtility';

const HIDE_NEWS_BANNER_KEY = '';
const HIDE_BANNER_PATHS = [
  '/account/inbox/',
  '/s/',
  '/c/',
  '/h/',
  '/housemate-posts/edit/',
  '/housemate-posts/new',
  '/rooms/',
  '/login',
  '/logout',
  '/signup',
  '/signup-confirmation',
  '/invite',
  '/password/',
  '/verification/',
  '/social-login/',
  '/setup/',
  '/maintenance',
];

const defaultProps = {
  isFullWidth: false,
  navClassName: '',
  pageClassName: '',
  showNav: true,
  showNavActions: true,
  showSearch: false,
};
const defaultMapStateToProps = () => { };
const defaultMapDispatchToProps = {};

export function withPageWrapper(WrappedComponent, args = {}) {
  // Assign defaults
  args = {
    props: {
      ...defaultProps,
      ...args.props,
    },
    mapStateToProps: args.mapStateToProps || defaultMapStateToProps,
    mapDispatchToProps: args.mapDispatchToProps || defaultMapDispatchToProps,
  };

  class CorePageWrapper extends Component {
    analyticsService = new AnalyticsService();
    state = {
      hideBannerFromRoute: true,
      showNewsBanner: false,
      // This is used to make sure the client is rendered before the cookies are checked
      clientSideRendered: false,
      // If the route is protected and the data was not loaded on the server side then show loader until the user authenticates
      isLoading: (WrappedComponent.protectedRoute && !this.props.loadedServerSide && !this.props.isLoggedIn),
    };

    async componentDidMount() {
      const { router, screenSizes, updateScreenSizes } = this.props;

      updateScreenSizes(screenSizes);

      this.setState({
        clientSideRendered: true,
        showNewsBanner: false, // Cookies.get(HIDE_NEWS_BANNER_KEY) !== 'true',
        hideBannerFromRoute: HIDE_BANNER_PATHS.find(p => router.pathname.includes(p)),
      });

      this.analyticsService.load();
      this.storeUtmCode();
      this.storeReferralCode();
      await this.handleAuthentication();
    }

    componentDidUpdate(prevProps) {
      const { screenSizes, updateScreenSizes } = this.props;
      if (prevProps.screenSizes && !prevProps.screenSizes.equals(screenSizes)) {
        updateScreenSizes(screenSizes);
      }
    }

    async handleAuthentication() {
      const { ctx, loadedServerSide, isLoggedIn, router, tokenRequest, user } = this.props;
      if (WrappedComponent.protectedRoute && !loadedServerSide && !isLoggedIn) {
        try {
          // Make the token request synchronously
          const authenticatedUser = await tokenRequest(new ApiService(ctx));
          if (authenticatedUser) {
            if (WrappedComponent.pageUserRole && WrappedComponent.pageUserRole !== authenticatedUser.role) {
              // Confirm the page is meant for the role of the current user
              await router.push('/');
            }
            await this.setState({
              isLoading: false,
            });
          } else {
            return this.handleRedirect();
          }
        } catch (error) {
          console.error(error);
        }
      } else if (!isLoggedIn && !WrappedComponent.ignoreTokenCheck) {
        // Make the token request asynchronously
        tokenRequest(new ApiService(ctx));
        this.setState({
          isLoading: false,
        });
      } else {
        if (isLoggedIn && !user.get('tos_acceptance')) {
          const isAllowedRoute = TermsOfServiceUtility.isAllowedRoute(document.location.pathname);
          if (!isAllowedRoute) {
            // Redirect when the user has not accepted the TOS
            document.location = '/terms-of-service-acceptance';
            return false;
          }
        }

        this.setState({
          isLoading: false,
        });
      }
    }

    storeUtmCode() {
      const query = queryString.parse(
        location.search.replace('?', '')
      );
      let results = {};

      // Filter out just UTM params
      for (var key in query) {
        if (Object.prototype.hasOwnProperty.call(query, key)) {
          if (key.substr(0, 4) === 'utm_') {
            results[key] = query[key];
          }
        }
      }

      // Only store if there are UTM params
      if (Object.keys(results).length > 0) {
        LocalStorageService.setItem('utmCode', JSON.stringify(results));
      }
    }

    storeReferralCode() {
      const query = queryString.parse(
        location.search.replace('?', '')
      );

      if (query.referral_code) {
        LocalStorageService.setItem('referralCode', query.referral_code);
      }

      if (query.referral_email) {
        LocalStorageService.setItem('referralEmail', query.referral_email);
      }
    }

    handleRedirect() {
      const { router } = this.props;
      if (WrappedComponent.protectedRedirectPath) {
        return router.push({
          pathname: WrappedComponent.protectedRedirectPath,
        });
      }

      return router.push({
        pathname: '/login',
        query: {
          redirect: encodeURIComponent(window.location),
        },
      });
    }

    closeBanner = () => {
      this.setState({
        showNewsBanner: false,
      });
      Cookies.set(HIDE_NEWS_BANNER_KEY, true);
    }

    // INFO: not used right now
    renderNewsBanner() {
      return (
        <div className="news-header relative bg--white w--100p p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--medium b-bottom--xs b--gray-2">
          { /* ... */ }
        </div>
      );
    }

    render() {
      const { isLoading, showNewsBanner, clientSideRendered, hideBannerFromRoute } = this.state;
      const { user } = this.props;

      if (isLoading) {
        return (
          <div className="mih--100vh layout-column layout-align-center-center">
            <Loader className="loader--large" />
          </div>
        );
      }

      return (
        <>
          { clientSideRendered && !hideBannerFromRoute && showNewsBanner && this.renderNewsBanner() }
          { user.get('is_host') && (
            <CancellationPolicyAlert />
          )}
          { args.props.showNav && (
            <Nav isFullWidth={args.props.isFullWidth} navClassName={args.props.navClassName} showNavActions={args.props.showNavActions} showSearch={args.props.showSearch} />
          )}
          <ProductionOnlyComponent>
            <Zendesk />
          </ProductionOnlyComponent>
          <div className={`${args.props.pageClassName} page-wrapper`}>
            <WrappedComponent {...this.props} />
          </div>
        </>
      );
    }
  }

  CorePageWrapper.propTypes = {
    // Required
    isLoggedIn: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    screenSizes: PropTypes.object.isRequired,
    tokenRequest: PropTypes.func.isRequired,
    updateScreenSizes: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    // Optional
    ctx: PropTypes.object,
    loadedServerSide: PropTypes.bool,
  };

  CorePageWrapper.defaultProps = {
    ctx: null,
    loadedServerSide: false,
  };

  const mapStateToProps = (state) => ({
    isLoggedIn: state.get('User').get('isLoggedIn'),
    screenSizes: state.get('ScreenSizes'),
    user: state.get('User').get('object'),
    ...args.mapStateToProps(state),
  });

  // Hoist the WrappedComponent statics
  hoistNonReactStatics(CorePageWrapper, WrappedComponent);

  const mapSizesToProps = ({ width }) => {
    if (!width) return {};
    return {
      screenSizes: fromJS({
        xs: width < 600,
        sm: width < 960 && width >= 600,
        md: width < 1280 && width >= 960,
        lg: width < 1920 && width >= 1280,
        gtXs: width >= 600,
        gtSm: width >= 960,
        gtMd: width >= 1280,
        gtLg: width >= 1920,
      }),
    };
  };

  const enhance = compose(
    withRouter,
    wrapper.withRedux,
    connect(mapStateToProps, {
      tokenRequest: Actions.tokenRequest,
      updateScreenSizes: Actions.updateScreenSizes,
      ...args.mapDispatchToProps,
    }),
    withSizes(mapSizesToProps)
  );

  return enhance(CorePageWrapper);
}
