
import React from 'react';
import PropTypes from 'prop-types';

const EllipsisIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 22 4" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" transform="translate(-1281 -635)">
        <g transform="translate(108 588)">
          <g transform="translate(1172 37)">
            <g transform="translate(1 10)">
              <circle cx="2" cy="2" r="2" />
              <circle cx="11" cy="2" r="2" />
              <circle cx="20" cy="2" r="2" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

EllipsisIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default EllipsisIcon;
