// =====================
//   UPDATE SCREEN SIZES
// =====================
const updateScreenSizesSuccess = (screenSizes) => ({
  type: 'UPDATE_SCREEN_SIZES',
  screenSizes,
});

const updateScreenSizes = (screenSizes) => (dispatch) => (
  dispatch(updateScreenSizesSuccess(screenSizes))
);

export default {
  updateScreenSizes,
};
