import React from 'react';
import PropTypes from 'prop-types';

const ArrowOutlineIcon = (props) => (
  <svg aria-hidden="true" className={props.className} xmlns="http://www.w3.org/2000/svg">
    <path d="M20.892 3L29 11l-8.108 8v-5H1V8h19.892V3z" fill="none" stroke="currentColor" strokeWidth="2" />
  </svg>
);

ArrowOutlineIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ArrowOutlineIcon;
