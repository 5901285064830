import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: [],
  isLoading: false,
  error: null,
});

const Mentions = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('Mentions').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('Mentions') : state)
      );
    } else {
      return state;
    }

  case 'GET_MENTIONS_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'GET_MENTIONS_SUCCESS':
    return state.merge({
      object: fromJS(action.mentions),
      isLoading: false,
    });
  case 'GET_MENTIONS_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });
  default:
    return state;
  }
};

export default Mentions;
