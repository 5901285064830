import React from 'react';
import PropTypes from 'prop-types';

const GymIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <g transform="translate(1 11)">
        <path d="m-2 9h22c0.55228 0 1 0.44772 1 1v4c0 0.55228-0.44772 1-1 1h-22c-0.55228 0-1-0.44772-1-1v-4c0-0.55228 0.44772-1 1-1z" transform="translate(9 12) rotate(90) translate(-9 -12)" />
        <path d="m-1 9h8c0.55228 0 1 0.44772 1 1v4c0 0.55228-0.44772 1-1 1h-8c-0.55228 0-1-0.44772-1-1v-4c0-0.55228 0.44772-1 1-1z" transform="translate(3 12) rotate(90) translate(-3 -12)" />
        <line x1="13" x2="33" y1="14" y2="14" />
        <line x1="13" x2="33" y1="10" y2="10" />
      </g>
      <g transform="translate(41 23) scale(-1 1) translate(-6 -12)">
        <path d="m-2 9h22c0.55228 0 1 0.44772 1 1v4c0 0.55228-0.44772 1-1 1h-22c-0.55228 0-1-0.44772-1-1v-4c0-0.55228 0.44772-1 1-1z" transform="translate(9 12) rotate(90) translate(-9 -12)" />
        <path d="m-1 9h8c0.55228 0 1 0.44772 1 1v4c0 0.55228-0.44772 1-1 1h-8c-0.55228 0-1-0.44772-1-1v-4c0-0.55228 0.44772-1 1-1z" transform="translate(3 12) rotate(90) translate(-3 -12)" />
      </g>
    </g>
  </svg>
);

GymIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default GymIcon;
