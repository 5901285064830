import React from 'react';
import PropTypes from 'prop-types';

const ElevatorIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <rect height="46" width="46" x="1" y="1" />
      <rect height="32" width="18" x="10" y="15" />
      <g transform="translate(33 18.414)">
        <polyline points="7.1438 7.6358 7.2562 13.148 1.7438 13.036" transform="translate(4.5 10.392) rotate(45) translate(-4.5 -10.392)" />
        <polyline points="7.1438 1.8517 7.2562 7.3642 1.7438 7.2517" transform="translate(4.5 4.608) scale(1 -1) rotate(45) translate(-4.5 -4.608)" />
      </g>
      <line x1="16" x2="22" y1="8" y2="8" />
      <line x1="19" x2="19" y1="15" y2="47" />
    </g>
  </svg>
);

ElevatorIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ElevatorIcon;
