import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  paymentLoading: false,
  paymentMethod: {},
});

const PaymentMethods = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('PaymentMethods').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('PaymentMethods') : state)
      );
    } else {
      return state;
    }

    // Create payment method actions
  case 'CREATE_PAYMENT_METHOD_REQUEST_LOADING':
    return state.merge({
      paymentLoading: true,
    });
  case 'CREATE_PAYMENT_METHOD_REQUEST_SUCCESS':
    return state.merge({
      paymentMethod: fromJS(action.paymentMethod),
      paymentLoading: false,
    });
  case 'CREATE_PAYMENT_METHOD_REQUEST_FAILURE':
    return state.merge({
      paymentLoading: false,
      error: action.error,
    });

    // Set default payment method actions
  case 'DEFAULT_PAYMENT_METHOD_REQUEST_LOADING':
    return state.merge({
      paymentLoading: true,
    });
  case 'DEFAULT_PAYMENT_METHOD_REQUEST_SUCCESS':
    return state.merge({
      paymentMethod: fromJS(action.paymentMethod),
      paymentLoading: false,
    });
  case 'DEFAULT_PAYMENT_METHOD_REQUEST_FAILURE':
    return state.merge({
      paymentLoading: false,
      error: action.error,
    });

    // Delete payment method actions
  case 'DELETE_PAYMENT_METHOD_REQUEST_LOADING':
    return state.merge({
      paymentLoading: true,
    });
  case 'DELETE_PAYMENT_METHOD_REQUEST_SUCCESS':
    return state.merge({
      paymentMethod: fromJS(action.paymentMethod),
      paymentLoading: false,
    });
  case 'DELETE_PAYMENT_METHOD_REQUEST_FAILURE':
    return state.merge({
      paymentLoading: false,
      error: action.error,
    });

  case 'UPDATE_ACTIVE_PAYMENT_METHOD_SUCCESS':
    return state.merge({
      paymentMethod: fromJS(action.paymentMethod),
      paymentLoading: false,
    });

  default:
    return state;
  }
};

export default PaymentMethods;
