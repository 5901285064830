import { fromJS } from 'immutable';
import Cookies from 'js-cookie';
import { HYDRATE } from 'next-redux-wrapper';
import SearchFilterUtility from '@utilities/SearchFilterUtility';

const cookieFilters = JSON.parse(Cookies.get('search_filters') || '{}');
const initialState = fromJS({
  filters: {
    ...SearchFilterUtility.defaultFilters,
    ...SearchFilterUtility.defaultListingSearchFilters,
    ...SearchFilterUtility.defaultHousemateSearchFilters,
    ...cookieFilters,
  },
});

const Search = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('Search').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('Search') : state)
      );
    } else {
      return state;
    }

  case 'UPDATE_FILTERS':
    Cookies.set('search_filters', JSON.stringify(state.get('filters').merge(fromJS(action.filters)).toJS()));

    return state.merge({
      filters: state.get('filters').merge(
        fromJS(action.filters)
      ),
    });
  default:
    return state;
  }
};

export default Search;
