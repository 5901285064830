import { combineReducers } from 'redux-immutable';

import AccountDashboard from './AccountDashboard';
import AccountHousingSearch from './AccountHousingSearch';
import AccountInbox from './AccountInbox';
import AccountListings from './AccountListings';
import AccountPayments from './AccountPayments';
import AccountPayouts from './AccountPayouts';
import AccountRequests from './AccountRequests';
import AvailabilityEvents from './AvailabilityEvents';
import CalendarImports from './CalendarImports';
import CancellationPolicy from './CancellationPolicy';
import CommuteDestinations from './CommuteDestinations';
import CompanyMembers from './CompanyMembers';
import CompanyReviews from './CompanyReviews';
import CountryCodes from './CountryCodes';
import GeneratedSearchPage from './GeneratedSearchPage';
import HousemateSearch from './HousemateSearch';
import ListingEdit from './ListingEdit';
import ListingReviews from './ListingReviews';
import ListingSearch from './ListingSearch';
import ListingView from './ListingView';
import Lead from './Lead';
import Mentions from './Mentions';
import OccupancyView from './OccupancyView';
import PaymentMethods from './PaymentMethods';
import PayoutMethods from './PayoutMethods';
import PhoneNumber from './PhoneNumber';
import RecommendationsSurvey from './RecommendationsSurvey';
import RequestDetailsSelection from './RequestDetailsSelection';
import RequestInvites from './RequestInvites';
import RequestView from './RequestView';
import SavedSearches from './SavedSearches';
import ScreenSizes from './ScreenSizes';
import Search from './Search';
import Sitemap from './Sitemap';
import Subscriptions from './Subscriptions';
import TenancyGroup from './TenancyGroup';
import UnitRequests from './UnitRequests';
import User from './User';
import UserReviews from './UserReviews';

export default combineReducers({
  AccountDashboard,
  AccountHousingSearch,
  AccountInbox,
  AccountListings,
  AccountPayments,
  AccountPayouts,
  AccountRequests,
  AvailabilityEvents,
  CalendarImports,
  CancellationPolicy,
  CommuteDestinations,
  CompanyMembers,
  CompanyReviews,
  CountryCodes,
  GeneratedSearchPage,
  HousemateSearch,
  ListingEdit,
  ListingReviews,
  ListingSearch,
  ListingView,
  Lead,
  Mentions,
  OccupancyView,
  PaymentMethods,
  PayoutMethods,
  PhoneNumber,
  RecommendationsSurvey,
  RequestDetailsSelection,
  RequestInvites,
  RequestView,
  SavedSearches,
  ScreenSizes,
  Search,
  Sitemap,
  Subscriptions,
  TenancyGroup,
  UnitRequests,
  User,
  UserReviews,
});
