import React from 'react';
import PropTypes from 'prop-types';

const CompanySymbolIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 178.7 249">
    <path d="m114.7 150c3.2-2.4 6.4-5.1 9.4-8 18.7-17.9 28.2-41.4 28.2-68.6h-54.3v0.1c0 13.2-3.5 24.2-10.8 32.6-6.9 8-14.2 13.5-27.2 13.5l-5.8-0.1v-119.5h-54.2v249h54.1v-69.2h16.5l43.6 69.2h64.6c-0.1 0-41.4-64.6-64.1-99z" fill="currentColor" />
  </svg>
);

CompanySymbolIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CompanySymbolIcon;
