import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  isLoading: false,
  error: null,
});

const PhoneNumber = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('PhoneNumber').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('PhoneNumber') : state)
      );
    } else {
      return state;
    }

    // Send phone verification code
  case 'SEND_PHONE_VERIFICATION_CODE_LOADING':
    return state.merge({
      isLoading: true,
      error: null,
    });
  case 'SEND_PHONE_VERIFICATION_CODE_SUCCESS':
    return state.merge({
      isLoading: false,
      error: null,
    });
  case 'SEND_PHONE_VERIFICATION_CODE_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Verify phone code
  case 'VERIFY_PHONE_CODE_LOADING':
    return state.merge({
      isLoading: true,
      error: null,
    });
  case 'VERIFY_PHONE_CODE_SUCCESS':
    return state.merge({
      isLoading: false,
      error: null,
    });
  case 'VERIFY_PHONE_CODE_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });
  default:
    return state;
  }
};

export default PhoneNumber;
