import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  list: [],
  meta: {},
  payoutMethods: [],
  queryIncluded: false, // This tracks whether or not the current listings in the store were filtered by a user query
  payout: {},
  payoutTotal: null,
  isLoading: true,
  listIsLoading: true,
  error: null,
});

const AccountPayouts = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AccountPayouts').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AccountPayouts') : state)
      );
    } else {
      return state;
    }

  // Retrieve account payouts actions
  case 'RETRIEVE_ACCOUNT_PAYOUTS_REQUEST_LOADING':
    return state.merge({
      listIsLoading: true,
    });
  case 'RETRIEVE_ACCOUNT_PAYOUTS_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: action.payouts,
      meta: action.meta,
      payoutMethods: action.payout_methods,
      payoutTotal: action.payout_total,
      queryIncluded: action.queryIncluded,
      listIsLoading: false,
    }));
  case 'RETRIEVE_ACCOUNT_PAYOUTS_REQUEST_FAILURE':
    return state.merge({
      listIsLoading: false,
      error: action.error,
    });

  // Retrieve account payout actions
  case 'RETRIEVE_ACCOUNT_PAYOUT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_ACCOUNT_PAYOUT_REQUEST_SUCCESS':
    return state.merge(fromJS({
      payout: action.payout,
      isLoading: false,
    }));
  case 'RETRIEVE_ACCOUNT_PAYOUT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default AccountPayouts;
