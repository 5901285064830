import Router from 'next/router';
import { toast } from 'react-toastify';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   RETRIEVE LISTING REVIEW
// ==========================
const retrieveListingReviewRequestLoading = () => ({
  type: 'RETRIEVE_LISTING_REVIEW_REQUEST_LOADING',
});

const retrieveListingReviewRequestSuccess = (review) => ({
  type: 'RETRIEVE_LISTING_REVIEW_REQUEST_SUCCESS',
  review,
});

const retrieveListingReviewRequestFailure = () => ({
  type: 'RETRIEVE_LISTING_REVIEW_REQUEST_FAILURE',
});

const retrieveListingReviewRequest = (userId, reviewId) => async (dispatch) => {
  dispatch(retrieveListingReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/listing_reviews/${reviewId}`,
      method: 'GET',
    });

    dispatch(retrieveListingReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveListingReviewRequestFailure
    );
  }
};

// ==========================
//   CHECK LISTING REVIEW
// ==========================
const checkListingReviewRequestLoading = () => ({
  type: 'CHECK_LISTING_REVIEW_REQUEST_LOADING',
});

const checkListingReviewRequestSuccess = (review) => ({
  type: 'CHECK_LISTING_REVIEW_REQUEST_SUCCESS',
  review,
});

const checkListingReviewRequestFailure = () => ({
  type: 'CHECK_LISTING_REVIEW_REQUEST_FAILURE',
});

const checkListingReviewRequest = (userId, tenancyId) => async (dispatch) => {
  dispatch(checkListingReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/try_get_listing_review/${tenancyId}`,
      method: 'GET',
    });

    if (response.is_editable) {
      Router.replace(`/account/listing-reviews/${response.id}/edit`);
    } else if (response.exists) {
      toast('This review can no longer be edited', {
        type: toast.TYPE.ERROR,
      });
      return Router.push('/account/reviews');
    }

    dispatch(checkListingReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      checkListingReviewRequestFailure
    );
  }
};

// ==========================
//   CREATE LISTING REVIEW
// ==========================
const createListingReviewRequestLoading = () => ({
  type: 'CREATE_LISTING_REVIEW_REQUEST_LOADING',
});

const createListingReviewRequestSuccess = (review) => ({
  type: 'CREATE_LISTING_REVIEW_REQUEST_SUCCESS',
  review,
});

const createListingReviewRequestFailure = () => ({
  type: 'CREATE_LISTING_REVIEW_REQUEST_FAILURE',
});

const createListingReviewRequest = (userId, data) => async (dispatch) => {
  dispatch(createListingReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      data: JSON.stringify(data),
      method: 'POST',
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/listing_reviews/${data.tenancy_id}`,
    });

    dispatch(createListingReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createListingReviewRequestFailure
    );
  }
};

// ==========================
//   UPDATE LISTING REVIEW
// ==========================
const updateListingReviewRequestLoading = () => ({
  type: 'UPDATE_LISTING_REVIEW_REQUEST_LOADING',
});

const updateListingReviewRequestSuccess = (review) => ({
  type: 'UPDATE_LISTING_REVIEW_REQUEST_SUCCESS',
  review,
});

const updateListingReviewRequestFailure = () => ({
  type: 'UPDATE_LISTING_REVIEW_REQUEST_FAILURE',
});

const updateListingReviewRequest = (userId, reviewId, data) => async (dispatch) => {
  dispatch(updateListingReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      data: JSON.stringify(data),
      method: 'PUT',
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/listing_reviews/${reviewId}`,
    });

    dispatch(updateListingReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateListingReviewRequestFailure
    );
  }
};

export default {
  checkListingReviewRequest,
  createListingReviewRequest,
  retrieveListingReviewRequest,
  updateListingReviewRequest,
};
