import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   CREATE DOCUMENT
// ==========================
const createDocumentLoading = () => ({
  type: 'CREATE_DOCUMENT_REQUEST_LOADING',
});

const createDocumentSuccess = (document) => ({
  type: 'CREATE_DOCUMENT_REQUEST_SUCCESS',
  document,
});

const createDocumentFailure = () => ({
  type: 'CREATE_DOCUMENT_REQUEST_FAILURE',
});

const createDocument = (userId, document) => async (dispatch) => {
  dispatch(createDocumentLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/documents`,
      method: 'POST',
      data: JSON.stringify(document),
    });
    dispatch(createDocumentSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createDocumentFailure
    );
  }
};

// ==========================
//   DELETE DOCUMENT
// ==========================
const deleteDocumentLoading = () => ({
  type: 'DELETE_DOCUMENT_REQUEST_LOADING',
});

const deleteDocumentSuccess = (documentId) => ({
  type: 'DELETE_DOCUMENT_REQUEST_SUCCESS',
  documentId,
});

const deleteDocumentFailure = () => ({
  type: 'DELETE_DOCUMENT_REQUEST_FAILURE',
});

const deleteDocument = (documentId) => async (dispatch) => {
  dispatch(deleteDocumentLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/documents/${documentId}`,
      method: 'DELETE',
    });
    dispatch(deleteDocumentSuccess(documentId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deleteDocumentFailure
    );
  }
};

export default {
  createDocument,
  deleteDocument,
};
