import React from 'react';
import PropTypes from 'prop-types';

const SquiggleIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 469 26" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 21c1.048-.956 4.881-4.018 11.5-9.186a27.438 27.438 0 0 1 33.778 0 27.438 27.438 0 0 0 33.778 0 27.438 27.438 0 0 1 33.777 0 27.438 27.438 0 0 0 33.778 0 27.438 27.438 0 0 1 33.778 0 27.438 27.438 0 0 0 33.778 0 27.438 27.438 0 0 1 33.777 0 27.438 27.438 0 0 0 33.778 0 27.438 27.438 0 0 1 33.778 0 27.438 27.438 0 0 0 33.778 0 27.438 27.438 0 0 1 33.778 0 27.438 27.438 0 0 0 33.777 0 27.438 27.438 0 0 1 33.778 0l10.111 7.894" fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="8" />
  </svg>
);

SquiggleIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default SquiggleIcon;
