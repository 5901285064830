import React from 'react';
import PropTypes from 'prop-types';

const CleaningSupplies = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 17 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" transform="translate(-530 -1455)">
      <g transform="translate(526 1455)">
        <polyline points="12 14 5 14 5 11 12 11" />
        <polyline points="6 14 6.7692 23 16.675 23 17 19.5" />
        <polyline points="8 11 8 8.4261 9.5 5 11.5 5 14.5 11" />
        <path d="m8 5h3.7396l2.2604-2.0132v-1.9868h-2.4712c-1.949 0-3.5288 1.7907-3.5288 4z" />
        <polygon points="14 3 16 3 16 1 14 1" />
        <path d="m18.548 19.448l-0.1068-0.049156c-0.29215-0.13333-0.64158-0.13164-0.93219 0.0047136l-1.0494 0.49291c-0.2879 0.13501-0.63345 0.13804-0.92406 0.0077438l-1.1346-0.5084c-0.2937-0.13164-0.64313-0.12693-0.93219 0.011447-0.66015 0.31682-1.4689-0.09865-1.4689-0.75452v-7.6529h6.79c0.66828 0 1.21 0.4717 1.21 1.0528v6.6324c0 0.64846-0.79211 1.0646-1.4519 0.76293z" />
      </g>
    </g>
  </svg>
);

CleaningSupplies.propTypes = {
  // required
  className: PropTypes.string.isRequired,
};

export default CleaningSupplies;
