import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  isLoading: false,
});

const Lead = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('Lead').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('Lead') : state)
      );
    } else {
      return state;
    }

    // Save landing page lead
  case 'SAVE_LANDING_PAGE_LEAD_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'SAVE_LANDING_PAGE_LEAD_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
    });
  case 'SAVE_LANDING_PAGE_LEAD_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
    });

    // Save lead
  case 'SAVE_LEAD_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'SAVE_LEAD_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
    });
  case 'SAVE_LEAD_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
    });

  default:
    return state;
  }
};

export default Lead;
