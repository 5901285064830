import React from 'react';
import PropTypes from 'prop-types';

const LightBulbIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m16 7.65a6.23 6.23 0 0 0 -8 9.59 3.25 3.25 0 0 1 1.11 2.54v2a2.19 2.19 0 0 0 2.19 2.18h1.4a2.19 2.19 0 0 0 2.19 -2.18v-2.1a3.23 3.23 0 0 1 1.11 -2.46 6.21 6.21 0 0 0 0 -9.57zm-2.06 12v0.79h-3.83v-0.69-0.22h3.79s-0.01 0.11-0.01 0.15zm-1.24 3.29h-1.4a1.19 1.19 0 0 1 -1.19 -1.18v-0.29h3.78v0.29a1.19 1.19 0 0 1 -1.19 1.18zm2.67-6.48a4.13 4.13 0 0 0 -1.32 2.1h-4.12a4 4 0 0 0 -1.28 -2.09 5.22 5.22 0 0 1 2.27 -9.12 5.47 5.47 0 0 1 1.08 -0.11 5.22 5.22 0 0 1 3.38 9.22z" />
    <path d="M11.1,9a.5.5,0,0,0-.39.59.49.49,0,0,0,.59.39,2.69,2.69,0,0,1,3.25,2.64.5.5,0,0,0,1,0A3.69,3.69,0,0,0,14.2,9.78,3.74,3.74,0,0,0,11.1,9Z" />
    <path d="M23.06,12.59h-2.8a.5.5,0,0,0-.5.5.51.51,0,0,0,.5.5h2.8a.5.5,0,0,0,.5-.5A.5.5,0,0,0,23.06,12.59Z" />
    <path d="M3.74,12.59H.94a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h2.8a.51.51,0,0,0,.5-.5A.5.5,0,0,0,3.74,12.59Z" />
    <path d="M6.09,6.5l-2-2a.5.5,0,1,0-.71.71l2,2a.5.5,0,0,0,.36.14.49.49,0,0,0,.35-.14A.51.51,0,0,0,6.09,6.5Z" />
    <path d="M12,4.32a.5.5,0,0,0,.5-.5V1a.5.5,0,1,0-1,0V3.82A.5.5,0,0,0,12,4.32Z" />
    <path d="M21.17,3.91a.5.5,0,0,0-.7,0l-2,2a.5.5,0,0,0,0,.71.52.52,0,0,0,.35.14.5.5,0,0,0,.35-.14l2-2A.5.5,0,0,0,21.17,3.91Z" />
  </svg>
);

LightBulbIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default LightBulbIcon;
