import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';

const HostMenu = ({ user }) => {
  if (!user.get('is_host')) return null;

  return (
    <Link
      className="hide-sm hide-xs secondary button hover-none m-right--small"
      href="/rooms/new"
      id="nav-list-space-button"
    >
      
      List a space
      
    </Link>
  );
};

HostMenu.propTypes = {
  // Required
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.get('User').get('object'),
});

export default connect(mapStateToProps, {})(HostMenu);
