import React from 'react';
import PropTypes from 'prop-types';

const MagicWandIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path d="M8.5 12.418L3.504 15.377 4.774 9.711.416 5.873 6.197 5.331 8.5 0 10.803 5.331 16.584 5.873 12.226 9.711 13.496 15.377zM8.5 13.077L8.5 24.192" transform="rotate(-35 14.672 3.985)" />
    </g>
  </svg>
);

MagicWandIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default MagicWandIcon;
