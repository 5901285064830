import React from 'react';
import PropTypes from 'prop-types';

const WifiIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <circle cx="24" cy="42" r="4" />
      <g transform="translate(1 4)">
        <path d="m22.729 21.706c-3.1944 0-6.1127 1.1658-8.3667 3.0888l3.2894 3.861c1.3679-1.1666 3.1386-1.8747 5.0773-1.8747 2.0511 0 3.9115 0.79407 5.3089 2.0843l3.4387-3.7252c-2.3015-2.1258-5.3684-3.4342-8.7475-3.4342z" />
        <path d="m0.53134 8.5579 3.3633 3.9477c5.0728-4.3248 11.646-6.9407 18.834-6.9407 7.6051 0 14.524 2.9251 19.702 7.7076l3.5179-3.812c-6.1029-5.6354-14.258-9.0839-23.22-9.0839-8.4715 0-16.219 3.0835-22.198 8.1812z" />
        <path d="m7.4969 16.734 3.3761 3.9635c3.1929-2.723 7.3306-4.37 11.856-4.37 4.787 0 9.1427 1.8408 12.403 4.8526l3.5314-3.8263c-4.1883-3.8678-9.7837-6.2334-15.934-6.2334-5.8133 0-11.13 2.116-15.232 5.6135z" />
      </g>
    </g>
  </svg>
);

WifiIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default WifiIcon;
