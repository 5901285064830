import React from 'react';
import PropTypes from 'prop-types';

const GiftIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.67,23.38H4.33a.51.51,0,0,1-.51-.51V8.57a.51.51,0,0,1,.51-.51H19.67a.51.51,0,0,1,.51.51v14.3A.51.51,0,0,1,19.67,23.38Z" fill="none" stroke="currentColor" strokeWidth="1.4" />
    <line fill="none" stroke="currentColor" strokeWidth="1.4" x1="12" x2="12" y1="23.31" y2="8.24" />
    <line fill="none" stroke="currentColor" strokeWidth="1.4" x1="4.46" x2="19.54" y1="15.78" y2="15.78" />
    <path d="M11.18,7.2c-.55.87-3.73-.17-4.6-.72a1.87,1.87,0,1,1,2-3.16C9.46,3.88,11.74,6.33,11.18,7.2Z" fill="none" stroke="currentColor" strokeWidth="1.4" />
    <path d="M12.82,7.2c.55.87,3.73-.17,4.6-.72a1.87,1.87,0,1,0-2-3.16C14.54,3.88,12.26,6.33,12.82,7.2Z" fill="none" stroke="currentColor" strokeWidth="1.4" />
    <path d="M12,6.91c1,0,1.87-3.24,1.87-4.27a1.87,1.87,0,1,0-3.74,0C10.13,3.67,11,6.91,12,6.91Z" fill="none" stroke="currentColor" strokeWidth="1.4" />
  </svg>
);

GiftIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default GiftIcon;
