import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  list: [],
  destination: {},
  travelMode: 'transit',
  isLoading: false,
  error: null,
});

const CommuteDestinations =  (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('CommuteDestinations').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('CommuteDestinations') : state)
      );
    } else {
      return state;
    }

  case 'TOKEN_REQUEST_SUCCESS':
    return state.merge({
      list: fromJS(action.user.commute_destinations || []),
      destination: fromJS(action.user.commute_destinations.find(cm => cm.active) || {}),
      travelMode: action.user.settings.travel_mode || 'transit',
    });

  case 'UPDATE_DESTINATION':
    return state.merge({
      destination: fromJS(action.destination || {}),
    });

  case 'UPDATE_TRAVEL_MODE':
    return state.merge({
      travelMode: action.travelMode,
    });

    // Create commute destinations actions
  case 'CREATE_COMMUTE_DESTINATION_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CREATE_COMMUTE_DESTINATION_SUCCESS':
    return state.merge({
      list: state.get('list').push(action.commuteDestination),
      isLoading: false,
    });
  case 'CREATE_COMMUTE_DESTINATION_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Update commute destinations actions
  case 'UPDATE_COMMUTE_DESTINATION_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'UPDATE_COMMUTE_DESTINATION_SUCCESS':
    return state.merge({
      list: state.get('list').map(we => (
        we.get('id') === action.commuteDestination.id ? fromJS(action.commuteDestination) : we
      )),
      isLoading: false,
    });
  case 'UPDATE_COMMUTE_DESTINATION_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default CommuteDestinations;
