import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '@components/Forms/Input';
import GoogleMapsService from '@services/GoogleMapsService';

let autocompleteFormField;

const componentRestrictions = { 'country': ['us'] };

class AddressAutoComplete extends Component {
  componentDidMount() {
  // Make sure Google is loaded before performing an calls to 'google'
    this.waitForGoogle = setInterval(() => {
      if (window.google === undefined) return;
      clearInterval(this.waitForGoogle);
      this.setupAutoCompleteInput();
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.waitForGoogle);
    if (typeof google === 'undefined' || google === null) {
      return;
    }
    google.maps.event.clearInstanceListeners(autocompleteFormField);
  }

  getFirstPrediction = async (searchText) => {
    const autocompleteOptions = this.getAutoCompleteOptions();
    return await GoogleMapsService.getFirstPrediction(searchText, autocompleteFormField, componentRestrictions, autocompleteOptions);
  }

  getAutoCompleteOptions = () => {
    const { geocodeOnly, regionsOnly } = this.props;

    let autocompleteOptions = {};
    if (regionsOnly) {
      autocompleteOptions.types = ['(regions)'];
    } else if (geocodeOnly) {
      autocompleteOptions.types = ['geocode'];
    }

    return autocompleteOptions;
  }

  setupAutoCompleteInput = () => {
    const { addressInputId, handleAddressChange } = this.props;
    autocompleteFormField = document.getElementById(`street-address-field-${addressInputId}`);

    if (typeof google === 'undefined' || google === null) {
      return;
    }

    const autocompleteOptions = this.getAutoCompleteOptions();
    const autocomplete = new google.maps.places.Autocomplete((autocompleteFormField), autocompleteOptions);
    autocomplete.setComponentRestrictions(componentRestrictions);
    autocomplete.setFields(GoogleMapsService.ApiFields);

    autocompleteFormField.onblur = this.enableScrolling;
    autocompleteFormField.onkeydown = this.determineScrollingProperties;
    autocompleteFormField.onfocus = this.determineScrollingProperties;

    // IMPORTANT: This needs to be after the original event handlers so it properly wraps them
    // If they didn't select an option from the dropdown, get the first prediction
    // from Google. Note that this logic intentionally doesn't trigger the callback method
    // when this component doesn't allow the enter key press, which means the responsibility
    // is on the parent component to use the correct place (e.g. Home Page)
    this.enableEnterKeyPress(autocompleteFormField);

    google.maps.event.addListener(autocomplete, 'place_changed', async () => {
      const place = autocomplete.getPlace();

      if (place && place.place_id) {
        const address = GoogleMapsService.parsePlacesResponse(place);
        handleAddressChange(address);
      }
    });
  }

  enableEnterKeyPress = (input) => {
    let eventListener = input.addEventListener
      ? input.addEventListener
      : input.attachEvent;

    function addEventListenerWrapper(type, listener) {
      if (type === 'keydown') {
        let originalListener = listener;
        listener = event => {

          let suggestionSelected =
            document.getElementsByClassName('pac-item-selected').length > 0;

          if (event.keyCode === 13 && !suggestionSelected) {
            const simulatedDownarrow = new KeyboardEvent('keydown', {
              bubbles: true,
              cancelable: true,
              keyCode: 40,
            });

            // INFO: Putting a 400ms delay on this to give the Google predictions API
            // time to respond and to update the drop down list. This will ensure that we
            // select the correct suggestion from the list.
            setTimeout(() => {
              originalListener.apply(input, [simulatedDownarrow]);
              originalListener.apply(input, [event]);
              input.blur();
            }, 400);
          } else {
            originalListener.apply(input, [event]);
          }
        };
      }

      eventListener.apply(input, [type, listener]);
    }

    if (input.addEventListener) input.addEventListener = addEventListenerWrapper;
    else if (input.attachEvent) input.attachEvent = addEventListenerWrapper;
  }

  determineScrollingProperties = (event) => {
    if (event && event.key === 'Escape') {
      this.enableScrolling();
      return;
    }

    setTimeout(() => {
      const pacContainers = document.getElementsByClassName('pac-container');
      let containerIsOpen = false;

      for (var i = 0; i < pacContainers.length; i++) {
        containerIsOpen |= !pacContainers[i].style.display.includes('none');
      }

      if (autocompleteFormField.value && autocompleteFormField.value.trim().length > 0 && containerIsOpen) {
        this.disableScrolling();
      } else {
        this.enableScrolling();
      }
    }, 400);
  };

  disableScrolling = () => {
    const { fixDropdown } = this.props;
    if (fixDropdown) {
      document.body.style.overflow = 'hidden';
    }
  }

  enableScrolling = () => {
    const { fixDropdown } = this.props;
    if (fixDropdown) {
      document.body.style.overflow = 'auto';
    }
  }

  render () {
    const {
      addressInputId, value, handleAddressItemChange, showErrors, error, label,
      placeholder, hasAsterisk, required, showSearchIcon, showClearButton, wrapperClassName,
    } = this.props;

    return (
      <div className={`${wrapperClassName} address-input-wrapper`}>
        <Input
          ariaLabel="City or town"
          className={(showErrors && error) ? 'error' : ''}
          error={error}
          handleChange={handleAddressItemChange}
          hasAsterisk={hasAsterisk}
          id={`street-address-field-${addressInputId}`}
          label={label || undefined}
          name="name"
          placeholder={placeholder}
          required={required}
          showClearButton={showClearButton}
          showErrors={showErrors}
          showSearchIcon={showSearchIcon}
          type="text"
          value={value}
        />
        <div id="placesServiceElement" />
      </div>
    );
  }
}

AddressAutoComplete.propTypes = {
  // Required
  addressInputId: PropTypes.string.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  handleAddressItemChange: PropTypes.func.isRequired,
  // Optional
  error: PropTypes.string,
  fixDropdown: PropTypes.bool,
  geocodeOnly: PropTypes.bool,
  label: PropTypes.string,
  hasAsterisk: PropTypes.bool,
  placeholder: PropTypes.string,
  regionsOnly: PropTypes.bool,
  required: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showErrors: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

AddressAutoComplete.defaultProps = {
  error: null,
  fixDropdown: true,
  geocodeOnly: false,
  label: null,
  hasAsterisk: true,
  placeholder: null,
  required: false,
  regionsOnly: false,
  showClearButton: true,
  showErrors: false,
  showSearchIcon: true,
  value: null,
  wrapperClassName: '',
};

export default AddressAutoComplete;
