import React from 'react';
import PropTypes from 'prop-types';

const FilterIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-354 -101) translate(341.5 88) translate(12.5 13) translate(0 1.5)">
      <circle cx="7.25" cy="2.5" r="2" />
      <path d="M9 2.5L24 2.5M0 2.5L5 2.5" />
    </g>
    <g transform="translate(-354 -101) translate(341.5 88) translate(12.5 13) translate(0 18)">
      <circle cx="7.25" cy="2.5" r="2" />
      <path d="M9 2.5L24 2.5M0 2.5L5 2.5" />
    </g>
    <g transform="translate(-354 -101) translate(341.5 88) translate(12.5 13) matrix(-1 0 0 1 24 10)">
      <circle cx="7.25" cy="2.5" r="2" />
      <path d="M9 2.5L24 2.5M0 2.5L5 2.5" />
    </g>
  </svg>
);

FilterIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default FilterIcon;
