import Router from 'next/router';
import { pathToRegexp } from 'path-to-regexp';
import ApiService from '@services/ApiService';
import ErrorHandlingService from '@services/ErrorHandlingService';
import Environment from '@utilities/Environment';

class ZendeskService {
  constructor() {
    this.pathsToHide = [
      '/account/inbox/:conversationId',
      '/book/:companySlug',
      '/book/:companySlug/listings',
      '/book/:companySlug/reviews',
      '/rooms/:listingSlug',
      '/rooms/:listingSlug/edit',
      '/rooms/new',
      '/s/:stateSlug',
      '/s/:stateSlug/:citySlug',
      '/s/:stateSlug/:citySlug/:tagSlug',
      '/signup-confirmation/:signupToken',
      '/social-login/callback',
      '/logout',
    ];
    this.apiService = new ApiService();
    this.user = null;
    this.booted = false;

    // Add router event listener
    Router.events.on('routeChangeComplete', this.handleRouteChange.bind(this));
  }

  /**
   * Start the Zendesk Chat
   */
  boot () {
    if (this.booted) return;
    const environment = new Environment();

    // When $zopim is loaded setup the authentication for auto associating the user in the chat
    var waitForZopim = setInterval(() => {
      if (window.$zopim === undefined || window.$zopim.livechat === undefined) return;

      // Setup the config for authenticating users
      $zopim(() => {
        $zopim.livechat.authenticate({
          jwtFn: async (callback) => {
            try {
              if (this.user) {
                const res = await this.apiService.fetch({
                  url: `${process.env.API_ENDPOINT}/v1/users/${this.user.get('id')}/zendesk_token`,
                  method: 'POST',
                });
                callback(res);
              }
            } catch (error) {
              ErrorHandlingService.captureMessageWithContext('Failed to generate Zendesk token', {}, error);
            }
          },
        });
      });
      clearInterval(waitForZopim);
      this.booted = true;

      // Show/hide based on route
      if (this.showOnPath(Router.asPath) && environment.isProduction()) {
        this.show();
      } else {
        this.hide();
      }
    }, 100);
  }

  /**
   * Start the Zendesk Chat
   */
  show () {
    if (window.$zopim === undefined || window.$zopim.livechat === undefined) return;
    try {
      $zopim(function() {
        $zopim.livechat.button.show();
      });
    } catch (error) { /* catch */ }
  }

  /**
   * Hides the Zendesk Chat icon. Does nothing if it is not started.
   */
  hide () {
    if (window.$zopim === undefined || window.$zopim.livechat === undefined) return;
    try {
      $zopim(function() {
        $zopim.livechat.button.hide();
      });
    } catch (error) { /* catch */ }
  }

  /**
   * Update the Zendesk Chat state based on a route change
   */
  handleRouteChange (route) {
    const environment = new Environment();
    if (this.showOnPath(route) && environment.isProduction()) {
      this.show();
    } else {
      this.hide();
    }
  }

  /**
   * Attach the user to the service for Zendesk jwt refreshes
   */
  attachUser(user) {
    this.user = user;
  }

  /**
   * Determine if Zendesk Chat should be shown on the route based on the whitelist
   */
  showOnPath (route) {
    // Remove any query params
    route = route.split("?")[0];

    const pathMatch = this.pathsToHide.find((path) => {
      const regexp = pathToRegexp(path);
      return !!regexp.exec(route);
    });

    return !pathMatch;
  }
}

export default ZendeskService;
