import React from 'react';
import PropTypes from 'prop-types';

const BathtubIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.73,10.42h-2V3.94a3.2,3.2,0,0,0-3.38-3A3.24,3.24,0,0,0,14,3.72,3.4,3.4,0,0,0,11.23,7a.68.68,0,0,0,.68.68h5.41A.69.69,0,0,0,18,7a3.41,3.41,0,0,0-2.68-3.31,1.92,1.92,0,0,1,2-1.43,1.87,1.87,0,0,1,2,1.64v6.48H1.08a.69.69,0,0,0-.68.68v2.71a.68.68,0,0,0,.68.67h.71a7.42,7.42,0,0,0,2.52,4.89L3.18,21.62a.69.69,0,0,0,.3.91.7.7,0,0,0,.31.07.67.67,0,0,0,.6-.37l1-2.06a7.58,7.58,0,0,0,3.9,1.08h5.17a7.58,7.58,0,0,0,3.9-1.08l1,2.06a.67.67,0,0,0,.61.37.66.66,0,0,0,.3-.07.68.68,0,0,0,.3-.91L19.5,19.37A7.43,7.43,0,0,0,22,14.48h.7a1,1,0,0,0,.18,0l.09,0,.05,0a.66.66,0,0,0,.36-.58V11.1A.68.68,0,0,0,22.73,10.42Zm-6.2-4.06H12.7a2,2,0,0,1,3.83,0ZM1.76,11.78h20.3v1.35H1.76ZM14.49,19.9H9.32a6.18,6.18,0,0,1-6.17-5.42H20.66A6.18,6.18,0,0,1,14.49,19.9Z" fill="currentColor" />
  </svg>
);

BathtubIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default BathtubIcon;
