import React from 'react';
import PropTypes from 'prop-types';

const HeatingIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m24 44c7.732 0 14-6.3251 14-14.127 0-3.9944-1.6428-7.6016-4.2838-10.171-0.82741-0.80504-5.27 2.7831-7.3829 1.9302-5.8333-2.3546-3.103-17.702-3.2087-17.604-2.1246 1.9721-13.125 16.972-13.125 25.845 0 7.8023 6.268 14.127 14 14.127z" />
      <path d="m24 44c3.4794 0 6.3-2.8463 6.3-6.3573 0-1.7975-0.73926-3.4207-1.9277-4.577-0.37234-0.36227-2.3715 1.2524-3.3223 0.86859-2.625-1.0596-1.1962-8.2752-1.4439-7.9217-1.379 1.9677-5.9061 8.6538-5.9061 11.63 0 3.5111 2.8206 6.3573 6.3 6.3573z" />
    </g>
  </svg>
);

HeatingIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default HeatingIcon;
