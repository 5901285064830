import React from 'react';
import PropTypes from 'prop-types';

const ApartmentIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <g transform="translate(5.5 .5)">
        <polygon points="-1.819e-12 22.5 13 22.5 13 0.5 -1.819e-12 0.5" strokeLinecap="round" />
        <rect height="4" width="2" x="5.5" y="17" />
        <rect height="1" width="1" x="4" y="4.5" />
        <rect height="1" width="1" x="4" y="8.5" />
        <rect height="1" width="1" x="8" y="4.5" />
        <rect height="1" width="1" x="8" y="8.5" />
        <rect height="1" width="1" x="4" y="12.5" />
        <rect height="1" width="1" x="8" y="12.5" />
      </g>
      <polyline points="1 5 6 5 6 23 1 23" transform="translate(3.5 14) scale(-1 1) translate(-3.5 -14)" />
      <polyline points="18 5 23 5 23 23 18 23" />
    </g>
  </svg>
);

ApartmentIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ApartmentIcon;
