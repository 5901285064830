import React from 'react';
import PropTypes from 'prop-types';

const DropdownIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(1 4)">
      <polyline fill="none" points="1.1018e-15 0.026403 6.9868 7 14 0" stroke="currentColor" strokeWidth="2" />
    </g>
  </svg>
);

DropdownIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default DropdownIcon;
