import React from 'react';
import PropTypes from 'prop-types';

const AttachmentIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 15 25" xmlns="http://www.w3.org/2000/svg">
    <path d="m12.09 5.48v12c0 3.0125-2.6456 5.4545-5.9091 5.4545-3.2635 0-5.9091-2.4421-5.9091-5.4545v-13.636c0-2.1087 1.8519-3.8182 4.1364-3.8182 2.2845 0 4.1364 1.7095 4.1364 3.8182v11.455c0 1.205-1.0582 2.1818-2.3636 2.1818s-2.3636-0.97683-2.3636-2.1818v-9.8182" fill="none" stroke="currentColor" strokeWidth="2" transform="translate(1 1)" />
  </svg>
);

AttachmentIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default AttachmentIcon;
