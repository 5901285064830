import React from 'react';
import PropTypes from 'prop-types';

const LeaseTermsIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <polygon points="4 1 20 1 20 17.5 15 23 4 23" />
      <polyline points="20 17.5 14.5 17.5 14.5 23.5" />
      <line x1="8" x2="16" y1="10" y2="10" />
      <line x1="8" x2="16" y1="14" y2="14" />
      <line x1="8" x2="12" y1="18" y2="18" />
      <line x1="8" x2="16" y1="6" y2="6" />
    </g>
  </svg>
);

LeaseTermsIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default LeaseTermsIcon;
