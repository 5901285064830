import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// =================================
//   SEND PHONE VERIFICATION CODE
// ================================
const sendPhoneVerificationCodeLoading = () => ({
  type: 'SEND_PHONE_VERIFICATION_CODE_LOADING',
});

const sendPhoneVerificationCodeSuccess = (countryCodes) => ({
  type: 'SEND_PHONE_VERIFICATION_CODE_SUCCESS',
  countryCodes,
});

const sendPhoneVerificationCodeFailure = (error) => ({
  type: 'SEND_PHONE_VERIFICATION_CODE_FAILURE',
  error,
});

const sendPhoneVerificationCode = (userId, phoneNumber) => async (dispatch) => {
  dispatch(sendPhoneVerificationCodeLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/phone_verification`,
      method: 'POST',
      data: JSON.stringify(phoneNumber),
    });
    dispatch(sendPhoneVerificationCodeSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      sendPhoneVerificationCodeFailure,
      true,
      'There was a problem sending your verification, please check to make sure your number is correct.'
    );
  }
};

// ======================
//   GET COUNTRY CODES
// ======================
const verifyPhoneCodeLoading = () => ({
  type: 'VERIFY_PHONE_CODE_LOADING',
});

const verifyPhoneCodeSuccess = (phoneNumber) => ({
  type: 'VERIFY_PHONE_CODE_SUCCESS',
  phoneNumber,
});

const verifyPhoneCodeFailure = (error) => ({
  type: 'VERIFY_PHONE_CODE_FAILURE',
  error,
});

const verifyPhoneCode = (userId, code) => async (dispatch) => {
  dispatch(verifyPhoneCodeLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/phone_verification/${code}`,
      method: 'POST',
    });
    dispatch(verifyPhoneCodeSuccess(res.phone_number));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      verifyPhoneCodeFailure,
      true,
      'The code you entered doesn\'t match what we sent. Please try again or contact support'
    );
  }
};

export default {
  sendPhoneVerificationCode,
  verifyPhoneCode,
};
