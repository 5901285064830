import ApiService from '@services/ApiService';

import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   RETRIEVE COMPANY REVIEWS
// ==========================
const retrieveCompanyReviewsRequestLoading = () => ({
  type: 'RETRIEVE_COMPANY_REVIEWS_REQUEST_LOADING',
});

const retrieveCompanyReviewsRequestSuccess = (data) => ({
  type: 'RETRIEVE_COMPANY_REVIEWS_REQUEST_SUCCESS',
  reviews: data.reviews,
});

const retrieveCompanyReviewsRequestFailure = () => ({
  type: 'RETRIEVE_COMPANY_REVIEWS_REQUEST_FAILURE',
});

const retrieveCompanyReviewsRequest = (companyId, minimumRating = 0) => async (dispatch) => {
  dispatch(retrieveCompanyReviewsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/reviews?minimum_rating=${minimumRating}`,
      method: 'GET',
    });

    dispatch(retrieveCompanyReviewsRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveCompanyReviewsRequestFailure
    );
  }
};

export default {
  retrieveCompanyReviewsRequest,
};
