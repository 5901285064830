import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
      <circle cx="6.5" cy="6.5" r="5.5" strokeLinecap="round" />
      <line x1="10.445" x2="14.462" y1="10.698" y2="15.428" />
    </g>
  </svg>
);

SearchIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default SearchIcon;
