import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  list: [],
  meta: {},
  queryIncluded: false, // This tracks whether or not the current requests in the store were filtered by a user query
  isLoading: false,
  requestActionLoading: false,
  error: null,
});

const AccountRequests = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AccountRequests').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AccountRequests') : state)
      );
    } else {
      return state;
    }

    // Retrieve account requests actions
  case 'RETRIEVE_ACCOUNT_REQUESTS_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_ACCOUNT_REQUESTS_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: action.requests,
      meta: action.meta,
      queryIncluded: action.queryIncluded,
      isLoading: false,
    }));
  case 'RETRIEVE_ACCOUNT_REQUESTS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Update request actions
  case 'UPDATE_REQUEST_REQUEST_LOADING':
    return state.merge({
      requestActionLoading: true,
    });
  case 'UPDATE_REQUEST_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: state.get('list').map(request => {
        if (action.id === request.get('id')) {
          return request.set('state', action.state);
        } else {
          return request;
        }
      }),
      requestActionLoading: false,
    }));
  case 'UPDATE_REQUEST_REQUEST_FAILURE':
    return state.merge({
      requestActionLoading: false,
    });

  default:
    return state;
  }
};

export default AccountRequests;
