import React from 'react';
import PropTypes from 'prop-types';

const UtiltiesIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m11.583 47c4.441 0 6.7688-3.1022 6.9836-9.3065 0.018701-0.54033 0.46348-0.96795 1.0041-0.96539l25.434 0.12017c0.55043 0.0026007 0.99528 0.44955 0.99528 0.99999v4.0782c0 2.802-2.2715 5.0735-5.0735 5.0735h-30.926c-3.3137 0-6-2.6863-6-6v-39c0-0.55228 0.44772-1 1-1h35.729c0.55228 0 1 0.44772 1 1v34.853" />
      <line x1="21" x2="10" y1="8" y2="8" />
      <line x1="15" x2="15" y1="26.5" y2="15" />
      <line x1="11" x2="11" y1="26.5" y2="20" />
      <line x1="23" x2="23" y1="26.5" y2="15" />
      <line x1="19" x2="19" y1="26.5" y2="16" />
      <line x1="27" x2="27" y1="26.5" y2="18" />
      <line x1="31" x2="31" y1="26.5" y2="20" />
      <line x1="36" x2="10" y1="29" y2="29" />
      <line x1="35" x2="35" y1="26.5" y2="22" />
    </g>
  </svg>
);

UtiltiesIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default UtiltiesIcon;
