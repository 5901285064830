import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ZendeskService from '@services/ZendeskService';

class ZendeskComponent extends Component {
  zendeskService = new ZendeskService();

  componentDidMount() {
    setTimeout(() => {
      this.renderZendesk();
    }, 10000);
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, user } = this.props;
    if (!prevProps.isLoggedIn && isLoggedIn) {
      this.zendeskService.attachUser(user);
    }
  }

  setCustomStyling () {
    window.zESettings = {
      webWidget: {
        launcher: {
          label: {
            '*': 'Get help',
          },
        },
        color: {
          launcher: '#FFFDFB',
          launcherText: '#185C97',
        },
      },
    };

    let launcherInterval = setInterval(() => {
      try {
        const iframeDocument = document.querySelector("#launcher").contentWindow.document;
        const launcherStyle = document.createElement('style');
        launcherStyle.innerHTML = `
          .u-userLauncherColor {
            box-shadow: inset 0 0 0 0.2rem #185C97 !important;
          }
          .u-userLauncherColor:not([disabled]):focus {
            box-shadow: inset 0 0 0 0.2rem #185C97 !important;
          }
        `;

        const launcherButton = iframeDocument.body.querySelector('.u-userLauncherColor');
        launcherButton.parentNode.insertBefore(launcherStyle, launcherButton);

        this.clearInterval(launcherInterval);
      } catch (error) { /* ignore */ }
    }, 500);
  }

  renderZendesk() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=36408275-de61-407b-a425-6a3a5363b2dc';
    document.getElementsByTagName('head')[0].appendChild(script);

    this.setCustomStyling();
    this.zendeskService.boot();
  }

  render() {
    return <div />;
  }
}

ZendeskComponent.propTypes = {
  // Required
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.get('User').get('isLoggedIn'),
  user: state.get('User').get('object'),
});

export default connect(mapStateToProps, {})(ZendeskComponent);
