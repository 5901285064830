import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ======================
//   GET COUNTRY CODES
// ======================
const getCountryCodesLoading = () => ({
  type: 'GET_COUNTRY_CODES_LOADING',
});

const getCountryCodesSuccess = (countryCodes) => ({
  type: 'GET_COUNTRY_CODES_SUCCESS',
  countryCodes,
});

const getCountryCodesFailure = (error) => ({
  type: 'GET_COUNTRY_CODES_FAILURE',
  error,
});

const getCountryCodes = () => async (dispatch) => {
  dispatch(getCountryCodesLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/country_codes`,
      method: 'GET',
    });
    dispatch(getCountryCodesSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      getCountryCodesFailure,
      true,
      'There was an issue getting country code data.'
    );
  }
};

export default {
  getCountryCodes,
};
