const isAllowedRoute = (route) => {
  const allowedRoutes = ['terms-of-service-acceptance', 'terms', 'privacy', 'subprocessors', 'security'];
  let isAllowedRoute = false;

  for (let i = 0; i < allowedRoutes.length; i++) {
    isAllowedRoute = isAllowedRoute || route.indexOf(allowedRoutes[i]) >= 0;
  }

  return isAllowedRoute;
};

export default {
  isAllowedRoute,
};
