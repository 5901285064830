import Router from 'next/router';
import { toast } from 'react-toastify';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   RETRIEVE USER REVIEWS
// ==========================
const retrieveUserReviewsRequestLoading = () => ({
  type: 'RETRIEVE_USER_REVIEWS_REQUEST_LOADING',
});

const retrieveUserReviewsRequestSuccess = (data) => ({
  type: 'RETRIEVE_USER_REVIEWS_REQUEST_SUCCESS',
  reviews: { ...data },
});

const retrieveUserReviewsRequestFailure = () => ({
  type: 'RETRIEVE_USER_REVIEWS_REQUEST_FAILURE',
});

const retrieveUserReviewsRequest = (userId) => async (dispatch) => {
  dispatch(retrieveUserReviewsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/reviews`,
      method: 'GET',
    });

    dispatch(retrieveUserReviewsRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveUserReviewsRequestFailure
    );
  }
};

const retrieveUserReviewRequestLoading = () => ({
  type: 'RETRIEVE_USER_REVIEW_REQUEST_LOADING',
});

const retrieveUserReviewRequestSuccess = (review) => ({
  type: 'RETRIEVE_USER_REVIEW_REQUEST_SUCCESS',
  review,
});

const retrieveUserReviewRequestFailure = () => ({
  type: 'RETRIEVE_USER_REVIEW_REQUEST_FAILURE',
});

const retrieveUserReviewRequest = (userId, reviewId) => async (dispatch) => {
  dispatch(retrieveUserReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/user_reviews/${reviewId}`,
      method: 'GET',
    });

    dispatch(retrieveUserReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveUserReviewRequestFailure
    );
  }
};

// ==========================
//   CHECK USER REVIEW
// ==========================
const checkUserReviewRequestLoading = () => ({
  type: 'CHECK_USER_REVIEW_REQUEST_LOADING',
});

const checkUserReviewRequestSuccess = (review) => ({
  type: 'CHECK_USER_REVIEW_REQUEST_SUCCESS',
  review,
});

const checkUserReviewRequestFailure = () => ({
  type: 'CHECK_USER_REVIEW_REQUEST_FAILURE',
});

const checkUserReviewRequest = (userId, tenancyId) => async (dispatch) => {
  dispatch(checkUserReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/try_get_user_review/${tenancyId}`,
      method: 'GET',
    });

    if (response.is_editable) {
      Router.replace(`/account/user-reviews/${response.id}/edit`);
    } else if (response.exists) {
      toast('This review can no longer be edited', {
        type: toast.TYPE.ERROR,
      });
      return Router.push('/account/reviews');
    }

    dispatch(checkUserReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      checkUserReviewRequestFailure
    );
  }
};

// ==========================
//   CREATE USER REVIEW
// ==========================
const createUserReviewRequestLoading = () => ({
  type: 'CREATE_USER_REVIEW_REQUEST_LOADING',
});

const createUserReviewRequestSuccess = (review) => ({
  type: 'CREATE_USER_REVIEW_REQUEST_SUCCESS',
  review,
});

const createUserReviewRequestFailure = () => ({
  type: 'CREATE_USER_REVIEW_REQUEST_FAILURE',
});

const createUserReviewRequest = (userId, data) => async (dispatch) => {
  dispatch(createUserReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      data: JSON.stringify(data),
      method: 'POST',
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/user_reviews/${data.tenancy_id}`,
    });

    dispatch(createUserReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createUserReviewRequestFailure
    );
  }
};

// ==========================
//   UPDATE USER REVIEW
// ==========================
const updateUserReviewRequestLoading = () => ({
  type: 'UPDATE_USER_REVIEW_REQUEST_LOADING',
});

const updateUserReviewRequestSuccess = (review) => ({
  type: 'UPDATE_USER_REVIEW_REQUEST_SUCCESS',
  review,
});

const updateUserReviewRequestFailure = () => ({
  type: 'UPDATE_USER_REVIEW_REQUEST_FAILURE',
});

const updateUserReviewRequest = (userId, reviewId, data) => async (dispatch) => {
  dispatch(updateUserReviewRequestLoading());

  try {
    const response = await apiService.fetch({
      data: JSON.stringify(data),
      method: 'PUT',
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/user_reviews/${reviewId}`,
    });

    dispatch(updateUserReviewRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateUserReviewRequestFailure
    );
  }
};

export default {
  checkUserReviewRequest,
  createUserReviewRequest,
  retrieveUserReviewRequest,
  retrieveUserReviewsRequest,
  updateUserReviewRequest,
};
