import React from 'react';
import PropTypes from 'prop-types';

const LinkedInIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 21h-4v-6.75c0-1.06-1.19-1.94-2.25-1.94S13 13.19 13 14.25V21H9V9h4v2c.66-1.07 2.36-1.76 3.5-1.76 2.5 0 4.5 2.04 4.5 4.51V21M7 21H3V9h4v12M5 3a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2 2 2 0 0 1 2-2z" />
  </svg>
);

LinkedInIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default LinkedInIcon;
