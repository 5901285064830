import React from 'react';
import PropTypes from 'prop-types';

const SecurityIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 23 26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path className={props.outlineClassName} d="m10.5 3.5527e-15c-2.25 4.25-5.7093 6.3452-10.378 6.2857 0 0-2.0244 14.286 10.411 17.714 11.857-3.4286 10.411-17.714 10.411-17.714-4.6272 0-8.1086-2.0952-10.444-6.2857z" stroke="currentColor" strokeLinejoin="round" />
        <g className={props.starClassName} fill="currentColor" transform="translate(4.5 6.75)">
          <path d="m6.5617 0 0.53631 4.917 4.5549-0.80582 0.34714 1.0869-4.125 1.8979 2.4326 4.2323-0.90884 0.67173-3.2928-3.676-3.5048 3.676-0.90884-0.67173 2.3126-4.1323-4.005-1.9979 0.34714-1.0869 4.3839 0.91982 0.70731-5.031h1.1234z" />
        </g>
      </g>
    </g>
  </svg>
);

SecurityIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,

  // Optional
  outlineClassName: PropTypes.string,
  starClassName: PropTypes.string,
};

SecurityIcon.defaultProps = {
  outlineClassName: 'black',
  starClassName: 'yellow',
};

export default SecurityIcon;
