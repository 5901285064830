import { List, Map, fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';
import HousemateSearchStatic from '@utilities/static/HousemateSearchStatic';

const initialState = fromJS({
  list: HousemateSearchStatic.fakeHousematePosts,
  isLoading: false,
  meta: Map({
    page: 0,
    offset: 0,
  }),
  housematesCount: null,
  housematesCountLoading: false,
  totalCount: 0,
  blacklist: List(),
});

const HousemateSearch = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('HousemateSearch').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('HousemateSearch') : state)
      );
    } else {
      return state;
    }
  case 'TOKEN_REQUEST_SUCCESS':
    return state.merge({
      blacklist: action.user ? fromJS(action.user.housemate_post_blacklist.map(l => l.housemate_post_id)) : List(),
    });
  case 'UNHIDE_HOUSEMATE_POST':
    return state.merge({
      blacklist: state.get('blacklist').filter(id => id !== action.housematePostId),
    });
  case 'HIDE_HOUSEMATE_POST':
    return state.merge({
      blacklist: List(state.get('blacklist').push(action.housematePostId)),
    });
  case 'DELETE_HOUSEMATE_POST_REQUEST_SUCCESS':
    return state.merge({
      list: fromJS(state.get('list').filter(element => element.get('id') !== action.housematePostId)),
      isLoading: false,
    });
  case 'RETRIEVE_HOUSEMATE_POSTS_LOADING':
    return state.merge({
      isLoading: true,
      list: List([]),
    });
  case 'RETRIEVE_HOUSEMATE_POSTS_COUNT_LOADING':
    return state.merge({
      isLoading: true,
    });

  case 'RETRIEVE_HOUSEMATE_POSTS_SUCCESS':
    return state.merge({
      list: fromJS(action.housematePosts),
      meta: Map(action.meta),
      isLoading: false,
    });

  case 'RETRIEVE_HOUSEMATE_POSTS_COUNT_SUCCESS':
    return state.merge({
      totalCount: fromJS(action.totalCount),
      isLoading: false,
    });

  case 'RETRIEVE_HOUSEMATE_POSTS_FAILURE':
  case 'RETRIEVE_HOUSEMATE_POSTS_COUNT_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default HousemateSearch;
