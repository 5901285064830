import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import Button from '@components/Core/Button';

class DropdownMenu extends Component {
  state = {
    isDropdownOpen: false,
  };

  componentDidMount() {
    const { closeOnBlur } = this.props;

    if (closeOnBlur) {
      // Close the dropdown if the user clicks outside of it
      window.addEventListener('click', this.handleNavOnClick);
    }

    this.rightSideStatus();
  }

  componentDidUpdate(prevProps) {
    const { closeOnBlur } = this.props;

    if (prevProps.closeOnBlur && !closeOnBlur) {
      // If the updates props have closeOnBlur = false then remove the event listener, this is a fix for mobile
      // on the mobile search filter model because sometimes when the screenSize map is instantiated
      // it does not pick up that the user since is on a mobile device since the page is server side rendered
      window.removeEventListener('click', this.handleNavOnClick);
    }
  }

  componentWillUnmount() {
    const { closeOnBlur } = this.props;

    if (closeOnBlur) {
      window.removeEventListener('click', this.handleNavOnClick);
    }
  }

  rightSideStatus () {
    const { identifier } = this.props;
    const dropdownIdentifier = `${identifier}-dropdown`;
    const bodyWidth = document.body.getBoundingClientRect().width;
    const dropdownContentsEl = document.getElementById(dropdownIdentifier);
    if (dropdownContentsEl) {
      const dropdownMenuRect = dropdownContentsEl.getBoundingClientRect();
      const right = bodyWidth - dropdownMenuRect.x - dropdownMenuRect.width;
      if (right < 0) {
        dropdownContentsEl.classList.toggle('pull-right');
      }
    }
  }

  handleNavOnClick = (event) => {
    const { identifier } = this.props;
    const dropdownIdentifier = `${identifier}-dropdown`;
    const dropdownTargetEl = document.getElementById(identifier);
    const dropdownContentsEl = document.getElementById(dropdownIdentifier);

    // Check if the element exists and whether or not the click on was itself
    if (dropdownTargetEl && !dropdownTargetEl.contains(event.target)) {
      // Toggle the dropdown if its open and the click was not on itself. Check to make sure the
      // target element still exists in the DOM. If it was removed via some other JS, we don't want
      // to close the dropdown.
      if (dropdownContentsEl &&
          dropdownContentsEl.classList.contains('show') &&
          !dropdownContentsEl.contains(event.target) &&
          document.contains(event.target)) {
        this.toggleMenu(false);
      }
    }
  }

  toggleMenu = async (show = false) => {
    await this.setState({
      isDropdownOpen: show,
    });
    this.rightSideStatus();

    const { onMenuToggle } = this.props;
    if (onMenuToggle) {
      onMenuToggle();
    }
  }

  render () {
    const {
      buttonTargetClassName, className, contentClassName, dropdownTarget,
      focusOutline, identifier, menuContents, targetClassName, usePortal,
    } = this.props;
    const { isDropdownOpen } = this.state;

    return (
      <div className={`dropdown relative ${className}`}>
        <Button
          className={`dropdown-target ${targetClassName}`}
          id={identifier}
          onClick={() => this.toggleMenu(!isDropdownOpen)}
          targetClassName={`${buttonTargetClassName} ${isDropdownOpen && focusOutline ? 'focus-visible' : ''}`}
        >
          { dropdownTarget(isDropdownOpen) }
        </Button>
        { usePortal ? (
          <>
            { isDropdownOpen && (
              <Portal>
                <div className={`show dropdown-content b--gray-2 b-around--xs ${contentClassName}`} id={`${identifier}-dropdown`} style={usePortal(identifier)}>
                  { menuContents(this.toggleMenu, isDropdownOpen) }
                </div>
              </Portal>
            )}
          </>
        ) : (
          <div className={`${isDropdownOpen ? 'show ' : ''}dropdown-content b--gray-2 b-around--xs ${contentClassName}`} id={`${identifier}-dropdown`}>
            { menuContents(this.toggleMenu, isDropdownOpen) }
          </div>
        )}
      </div>
    );
  }
}

DropdownMenu.propTypes = {
  // Required
  dropdownTarget: PropTypes.func.isRequired,
  menuContents: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
  // Optional
  buttonTargetClassName: PropTypes.string,
  className: PropTypes.string,
  closeOnBlur: PropTypes.bool,
  contentClassName: PropTypes.string,
  focusOutline: PropTypes.bool,
  onMenuToggle: PropTypes.func,
  targetClassName: PropTypes.string,
  usePortal: PropTypes.func, // this is a method that returns an object with top, left
};

DropdownMenu.defaultProps = {
  buttonTargetClassName: '',
  className: '',
  closeOnBlur: true,
  contentClassName: '',
  focusOutline: true,
  onMenuToggle: null,
  targetClassName: '',
  usePortal: null,
};

export default DropdownMenu;
