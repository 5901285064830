import Router from 'next/router';
import { toast } from 'react-toastify';
import UserRoleConstants from '@domain/Users/UserRoleConstants';
import Actions from '@redux/actions';
import AnalyticsService from '@services/AnalyticsService';
import ApiService from '@services/ApiService';
import AuthService from '@services/AuthService';
import LocalStorageService from '@services/LocalStorageService';
import ErrorHandler from '@utilities/ErrorHandler';
import TermsOfServiceUtility from '@utilities/TermsOfServiceUtility';

const apiService = new ApiService();
const authService = new AuthService(apiService);
const analyticsService = new AnalyticsService();

// ==========================
//   TOKEN CHECK
// ==========================
const tokenRequestLoading = () => ({
  type: 'TOKEN_REQUEST_LOADING',
});

const tokenRequestSuccess = (data) => ({
  type: 'TOKEN_REQUEST_SUCCESS',
  ...data,
});

const tokenRequestFailure = () => ({
  type: 'TOKEN_REQUEST_FAILURE',
});

const tokenRequest = (apiServiceWithContext) => async (dispatch) => {
  dispatch(tokenRequestLoading());

  try {
    // Make sure the user have a token before attempting the request
    let accessToken = apiServiceWithContext.getToken('access_token');
    let refreshToken = apiServiceWithContext.getToken('refresh_token');
    if (!accessToken && !refreshToken) {
      dispatch(tokenRequestFailure());

      // Pull the destination from the LocalStorage
      const destination = LocalStorageService.getItem('destination');
      if (destination) {
        dispatch(Actions.updateDestination(JSON.parse(destination)));
      }
      return false;
    }

    // Make the auth token request
    const authServiceWithContext = new AuthService(apiServiceWithContext);
    const res = await authServiceWithContext.token();
    authServiceWithContext.setTokens(res.access_token, res.refresh_token);
    analyticsService.identify(res.user);

    const isAllowedRoute = TermsOfServiceUtility.isAllowedRoute(document.location.pathname);

    if (!res.user.tos_acceptance && !isAllowedRoute) {
      // Redirect when the user has not accepted the TOS
      document.location = '/terms-of-service-acceptance';
      return false;
    }

    dispatch(tokenRequestSuccess(res));
    return res.user;
  } catch (error) {
    console.error(error);
    dispatch(tokenRequestFailure());

    // When token request fails, populate details from LS for users without accounts
    const destination = LocalStorageService.getItem('destination');
    if (destination) {
      dispatch(Actions.updateDestination(JSON.parse(destination)));
    }

    return false;
  }
};

// =====================
//   SKIP TOKEN CHECK
// =====================
const skipTokenRequestSuccess = () => ({
  type: 'SKIP_TOKEN_REQUEST',
});

const skipTokenRequest = () => (dispatch) => {
  dispatch(skipTokenRequestSuccess());
};

// ===========================
//   SOCIAL DISCONNECT REQUEST
// ===========================
const socialDisconnectRequestLoading = () => ({
  type: 'SOCIAL_DISCONNECT_REQUEST_LOADING',
});

const socialDisconnectRequestSuccess = (provider) => ({
  type: 'SOCIAL_DISCONNECT_REQUEST_SUCCESS',
  provider,
});

const socialDisconnectRequestFailure = (error) => ({
  type: 'SOCIAL_DISCONNECT_REQUEST_FAILURE',
  error,
});

const socialDisconnectRequest = (userId, provider) => async (dispatch) => {
  dispatch(socialDisconnectRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/${provider}/disconnect`,
      method: 'POST',
    });
    toast('Your social account was successfully disconnected.', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(socialDisconnectRequestSuccess(provider));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      socialDisconnectRequestFailure,
      true,
      'There was an issue disconnecting your social account.'
    );
  }
};

// =========================
//   SOCIAL LOGIN CALLBACK
// =========================
const socialLoginCallbackRequest = (query) => (dispatch) => {
  try {
    const accessToken = query.access_token;
    const refreshToken = query.refresh_token;
    const redirect = query.redirect;
    const userRole = query.user_role;
    const error = query.error;

    const userId = query.user_id;
    const signupCallback = query.signup;

    if (error) {
      // Error from the social login
      toast(error, {
        type: toast.TYPE.ERROR,
      });
      Router.push('/login');

    } else if (!accessToken || !refreshToken) {
      // The tokens are missing from the callback
      toast('An unexpected error occurred, please try again.', {
        type: toast.TYPE.ERROR,
      });
      Router.push('/login');

    } else {
      // The login was successful

      // Set the tokens to LocalStorage
      authService.setTokens(accessToken, refreshToken);

      // Only track a login / connection
      if (!signupCallback && userId) {
        analyticsService.identify({ id: userId });
      }

      if (signupCallback) {
        Router.push('/setup/welcome');
      } else if (redirect) {
        // Redirect to where the user started
        document.location = decodeURIComponent(redirect);
      } else {
        if (userRole === UserRoleConstants.HOST) {
          Router.push('/account/dashboard');
        } else {
          Router.push('/c');
        }
      }
    }
  } catch (error) {
    dispatch(tokenRequestFailure());
    Router.push('/login');
  }
};

// =====================
//   CLEAR LOGIN ERROR
// =====================
const clearLoginErrorSuccess = () => ({
  type: 'CLEAR_LOGIN_ERROR',
});

const clearLoginError = () => (dispatch) => {
  dispatch(clearLoginErrorSuccess());
};

// ===========================
//   LOGIN REQUEST
// ===========================
const loginRequestLoading = () => ({
  type: 'LOGIN_REQUEST_LOADING',
});

const loginRequestSuccess = (data) => ({
  type: 'LOGIN_REQUEST_SUCCESS',
  user: data.user,
});

const loginRequestFailure = (error) => ({
  type: 'LOGIN_REQUEST_FAILURE',
  error,
});

const loginRequest = (credentials, redirect) => async (dispatch) => {
  dispatch(loginRequestLoading());

  try {
    // Make the login request
    const res = await authService.login(credentials);
    authService.setTokens(res.access_token, res.refresh_token);
    analyticsService.identify(res.user);

    // Handle login redirect
    if (redirect) {
      // Note: redirect should already be decoded but this is a safety measure to confirm it is
      document.location = decodeURIComponent(redirect);
    } else {
      if (res.user.role === UserRoleConstants.HOST) {
        Router.push('/account/dashboard');
      } else {
        Router.push('/c');
      }
    }

    // Delay to make sure location is updated before isLoading stops
    setTimeout(() => {
      dispatch(loginRequestSuccess(res));
    }, 1000);
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      loginRequestFailure,
      false
    );
  }
};

// ===========================
//   ADMIN SU LOGIN REQUEST
// ===========================
const adminSudoUserLoginRequestLoading = () => ({
  type: 'ADMIN_SU_LOGIN_REQUEST_LOADING',
});

const adminSudoUserLoginRequestSuccess = (data) => ({
  type: 'ADMIN_SU_LOGIN_REQUEST_SUCCESS',
  user: data.user,
});

const adminSudoUserLoginRequestFailure = (error) => ({
  type: 'ADMIN_SU_LOGIN_REQUEST_FAILURE',
  error,
});

const adminSudoUserLoginRequest = (userId) => async (dispatch) => {
  dispatch(adminSudoUserLoginRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/su`,
      method: 'POST',
    });

    // Set the tokens and spoofing tracker
    authService.setTokens(res.access_token, res.refresh_token);
    analyticsService.identify(res.user);
    localStorage.setItem('spoofing', true);

    dispatch(adminSudoUserLoginRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      adminSudoUserLoginRequestFailure,
      false
    );
  }
};

// ===========================
//   LOGOUT REQUEST
// ===========================
const logoutRequestSuccess = () => ({
  type: 'LOGOUT_REQUEST_SUCCESS',
});

const logoutRequest = () => (dispatch) => {
  authService.removeTokens();
  LocalStorageService.removeItem('spoofing');

  // Confirm tokens are removed
  setTimeout(() => {
    Router.push('/login');
    dispatch(logoutRequestSuccess());
  }, 500);
};

// =====================
//   CLEAR INVITE ERROR
// =====================
const clearInviteErrorSuccess = () => ({
  type: 'CLEAR_INVITE_ERROR',
});

const clearInviteError = () => (dispatch) => {
  dispatch(clearInviteErrorSuccess());
};

// ===========================
//   INVITE SIGNUP REQUEST
// ===========================
const inviteSignupRequestLoading = () => ({
  type: 'INVITE_SIGNUP_REQUEST_LOADING',
});

const inviteSignupRequestSuccess = (user) => ({
  type: 'INVITE_SIGNUP_REQUEST_SUCCESS',
  user,
});

const inviteSignupRequestFailure = (error) => ({
  type: 'INVITE_SIGNUP_REQUEST_FAILURE',
  error,
});

const inviteSignupRequest = (data) => async (dispatch) => {
  dispatch(inviteSignupRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/signup/invite`,
      method: 'POST',
      data: JSON.stringify(data),
    });
    authService.setTokens(res.access_token, res.refresh_token);

    if (data.type === 'company') {
      await Router.push({
        pathname: '/setup/welcome',
        query: {
          invite: true,
        },
      });

    } else if (data.type === 'request_invite') {
      await Router.push('/setup/welcome');

    } else {
      await Router.push('/');
    }

    dispatch(inviteSignupRequestSuccess(res.user));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      inviteSignupRequestFailure,
      false
    );
  }
};

// =====================
//   CLEAR SIGNUP ERROR
// =====================
const clearSignupErrorSuccess = () => ({
  type: 'CLEAR_SIGNUP_ERROR',
});

const clearSignupError = () => (dispatch) => {
  dispatch(clearSignupErrorSuccess());
};

// ===========================
//   SIGNUP REQUEST
// ===========================
const signupRequestLoading = () => ({
  type: 'SIGNUP_REQUEST_LOADING',
});

const signupRequestSuccess = (user) => ({
  type: 'SIGNUP_REQUEST_SUCCESS',
  user,
});

const signupRequestFailure = (error) => ({
  type: 'SIGNUP_REQUEST_FAILURE',
  error,
});

const signupRequest = (inputs) => async (dispatch) => {
  dispatch(signupRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/signup`,
      method: 'POST',
      data: JSON.stringify(inputs),
    });
    dispatch(signupRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      signupRequestFailure,
      false
    );
  }
};

// ===========================
//   SIGNUP CONFIRMATION REQUEST
// ===========================
const signupConfirmationRequestLoading = () => ({
  type: 'SIGNUP_CONFIRMATION_REQUEST_LOADING',
});

const signupConfirmationRequestSuccess = (data) => ({
  type: 'SIGNUP_CONFIRMATION_REQUEST_SUCCESS',
  user: data.user,
});

const signupConfirmationRequestFailure = (error) => ({
  type: 'SIGNUP_CONFIRMATION_REQUEST_FAILURE',
  error,
});

const signupConfirmationRequest = (signupToken) => async (dispatch) => {
  dispatch(signupConfirmationRequestLoading());

  try {
    // Complete signup process
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/signup/confirmation/${signupToken}`,
      method: 'POST',
    });
    dispatch(signupConfirmationRequestSuccess(res));
    authService.setTokens(res.access_token, res.refresh_token);
    Router.push('/setup/welcome');
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      signupConfirmationRequestFailure,
      true,
      'There was an issue completing your sign up. Please try again.'
    );

    if (error.data.type === 'expired_signup_token') {
      Router.push('/signup');
    } else {
      Router.push('/login');
    }
  }
};

// ===========================
//   DIRECT SIGNUP REQUEST
// ===========================
const directSignupRequestLoading = () => ({
  type: 'DIRECT_SIGNUP_REQUEST_LOADING',
});

const directSignupRequestSuccess = (user) => ({
  type: 'DIRECT_SIGNUP_REQUEST_SUCCESS',
  user,
});

const directSignupRequestFailure = (error) => ({
  type: 'DIRECT_SIGNUP_REQUEST_FAILURE',
  error,
});

const directSignupRequest = (requestBody) => async (dispatch) => {
  dispatch(directSignupRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/signup/direct`,
      method: 'POST',
      data: JSON.stringify(requestBody),
    });
    authService.setTokens(response.access_token, response.refresh_token);
    dispatch(directSignupRequestSuccess(response.user));
    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      directSignupRequestFailure
    );
  }
};

// ===============================
//   UPDATE_UNREAD_MESSAGE_COUNT
// ===============================
const updateUnreadMessageCountSuccess = (unreadMessageCount) => ({
  type: 'UPDATE_UNREAD_MESSAGE_COUNT',
  unreadMessageCount,
});

const updateUnreadMessageCount = (unreadMessageCount) => (dispatch) => {
  return dispatch(updateUnreadMessageCountSuccess(unreadMessageCount));
};


// ==========================
//   UPDATE USER (in state)
// ==========================
const updateUser = (user) => ({
  type: 'UPDATE_USER',
  user,
});

// ==========================
//   UPDATE USER
// ==========================
const updateUserRequestLoading = () => ({
  type: 'UPDATE_USER_REQUEST_LOADING',
});

const updateUserRequestSuccess = (user) => ({
  type: 'UPDATE_USER_REQUEST_SUCCESS',
  user,
});

const updateUserRequestFailure = () => ({
  type: 'UPDATE_USER_REQUEST_FAILURE',
});

const updateUserRequest = (userId, data) => async (dispatch) => {
  dispatch(updateUserRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}`,
      method: 'PUT',
      data: JSON.stringify(data),
    });

    dispatch(updateUserRequestSuccess(res));
  } catch (error) {
    dispatch(updateUserRequestFailure());
  }
};

// =====================================
//   UPDATE USER SOCIAL LINKS
// ====================================
const updateUserSocialLinksLoading = () => ({
  type: 'UPDATE_USER_SOCIAL_LINKS_LOADING',
});

const updateUserSocialLinksSuccess = (profileImage) => ({
  type: 'UPDATE_USER_SOCIAL_LINKS_SUCCESS',
  profileImage,
});

const updateUserSocialLinksFailure = (error) => ({
  type: 'UPDATE_USER_SOCIAL_LINKS_FAILURE',
  error,
});

const updateUserSocialLinks = (userId, socialLinks) => async (dispatch) => {
  dispatch(updateUserSocialLinksLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/social`,
      method: 'POST',
      data: JSON.stringify(socialLinks.toJS()),
    });
    dispatch(updateUserSocialLinksSuccess(res));
  } catch (error) {
    return ErrorHandler.requestError(
      dispatch,
      error,
      updateUserSocialLinksFailure,
      'There was an issue updating your social links.'
    );
  }
};

// =====================================
//   UPDATE USER PROFILE IMAGE
// ====================================
const updateUserProfilePhotoLoading = () => ({
  type: 'UPDATE_USER_PROFILE_IMAGE_LOADING',
});

const updateUserProfilePhotoSuccess = (profileImage) => ({
  type: 'UPDATE_USER_PROFILE_IMAGE_SUCCESS',
  profileImage,
});

const updateUserProfilePhotoFailure = (error) => ({
  type: 'UPDATE_USER_PROFILE_IMAGE_FAILURE',
  error,
});

const updateUserProfilePhoto = (userId, profileImage) => async (dispatch) => {
  dispatch(updateUserProfilePhotoLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/profile_images`,
      method: 'POST',
      data: JSON.stringify(profileImage.toJS()),
    });

    dispatch(updateUserProfilePhotoSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateUserProfilePhotoFailure,
      true,
      'There was an issue uploading your profile image.'
    );
  }
};

// =====================================
//   UPDATE COMPANY PROFILE IMAGE
// ====================================
const updateCompanyProfilePhotoLoading = () => ({
  type: 'UPDATE_COMPANY_PROFILE_IMAGE_LOADING',
});

const updateCompanyProfilePhotoSuccess = (profileImage) => ({
  type: 'UPDATE_COMPANY_PROFILE_IMAGE_SUCCESS',
  profileImage,
});

const updateCompanyProfilePhotoFailure = (error) => ({
  type: 'UPDATE_COMPANY_PROFILE_IMAGE_FAILURE',
  error,
});

const updateCompanyProfilePhoto = (companyId, profileImage) => async (dispatch) => {
  dispatch(updateCompanyProfilePhotoLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/profile_images`,
      method: 'POST',
      data: JSON.stringify(profileImage.toJS()),
    });

    dispatch(updateCompanyProfilePhotoSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateCompanyProfilePhotoFailure,
      true,
      'There was an issue uploading your profile image.'
    );
  }
};

// ==========================
//   UPDATE COMPANY (in state)
// ==========================
const updateCompany = (company) => ({
  type: 'UPDATE_COMPANY',
  company,
});

// ==========================
//   UPDATE USER COMPANY (in state)
// ==========================
const updateUserCompany = (company) => ({
  type: 'UPDATE_USER_COMPANY',
  company,
});

// ===========================
//   UPDATE COMPANY REQUEST
// ===========================
const updateCompanyRequestLoading = () => ({
  type: 'UPDATE_COMPANY_REQUEST_LOADING',
});

const updateCompanyRequestSuccess = (company) => ({
  type: 'UPDATE_COMPANY_REQUEST_SUCCESS',
  company,
});

const updateCompanyRequestFailure = (error) => ({
  type: 'UPDATE_COMPANY_REQUEST_FAILURE',
  error,
});

const updateCompanyRequest = (companyId, data) => async (dispatch) => {
  dispatch(updateCompanyRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}`,
      method: 'PUT',
      data: JSON.stringify(data),
    });

    dispatch(updateCompanyRequestSuccess(data));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateCompanyRequestFailure,
      true,
      'There was an issue updating your account.'
    );
  }
};

// =====================================
//   UPDATE COMPANY SOCIAL LINKS
// ====================================
const updateCompanySocialLinksRequestLoading = () => ({
  type: 'UPDATE_COMPANY_SOCIAL_LINKS_LOADING',
});

const updateCompanySocialLinksRequestSuccess = (profileImage) => ({
  type: 'UPDATE_COMPANY_SOCIAL_LINKS_SUCCESS',
  profileImage,
});

const updateCompanySocialLinksRequestFailure = (error) => ({
  type: 'UPDATE_COMPANY_SOCIAL_LINKS_FAILURE',
  error,
});

const updateCompanySocialLinksRequest = (companyId, socialLinks) => async (dispatch) => {
  dispatch(updateCompanySocialLinksRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/social`,
      method: 'PUT',
      data: JSON.stringify(socialLinks.toJS()),
    });
    dispatch(updateCompanySocialLinksRequestSuccess(res));
  } catch (error) {
    return ErrorHandler.requestError(
      dispatch,
      error,
      updateCompanySocialLinksRequestFailure,
      `There was an issue updating your company's social links.`
    );
  }
};

// =====================================
//   GET COMPANY REQUEST
// ====================================
const getCompanyRequestLoading = () => ({
  type: 'GET_COMPANY_LOADING',
});

const getCompanyRequestSuccess = (company) => ({
  type: 'GET_COMPANY_SUCCESS',
  company: { ...company },
});

const getCompanyRequestFailure = (error) => ({
  type: 'GET_COMPANY_FAILURE',
  error,
});

const getCompanyRequest = (companyId) => async (dispatch) => {
  dispatch(getCompanyRequestLoading());
  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}`,
      method: 'GET',
    });

    dispatch(getCompanyRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      getCompanyRequestFailure,
      true,
      'There was an issue getting this company.'
    );
  }
};

// =====================================
//   GET PROFILE REQUEST
// ====================================
const getProfileRequestLoading = () => ({
  type: 'GET_PROFILE_LOADING',
});

const getProfileRequestSuccess = (profile) => ({
  type: 'GET_PROFILE_SUCCESS',
  profile: { ...profile },
});

const getProfileRequestFailure = (error) => ({
  type: 'GET_PROFILE_FAILURE',
  error,
});

const getProfileRequest = (userId) => async (dispatch) => {
  dispatch(getProfileRequestLoading());
  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}`,
      method: 'GET',
    });

    dispatch(getProfileRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      getProfileRequestFailure,
      true,
      'There was an issue getting this profile.'
    );
  }
};

// =====================================
//   GET USER VERIFICATION REQUEST
// ====================================
const getUserStatusLoading = () => ({
  type: 'GET_USER_STATUS_LOADING',
});

const getUserStatusSuccess = (status) => ({
  type: 'GET_USER_STATUS_SUCCESS',
  status,
});

const getUserStatusFailure = (error) => ({
  type: 'GET_USER_STATUS_FAILURE',
  error,
});

const getUserStatus = (userId) => async (dispatch) => {
  dispatch(getUserStatusLoading());
  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/setup_status`,
      method: 'GET',
    });

    dispatch(getUserStatusSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      getUserStatusFailure,
      true,
      'There was an issue getting your account status.'
    );
  }
};

// =====================================
//   START VERIFICATION REQUEST
// ====================================
const startVerificationLoading = () => ({
  type: 'START_VERIFICATION_LOADING',
});

const startVerificationSuccess = (res) => ({
  type: 'START_VERIFICATION_SUCCESS',
  ...res,
});

const startVerificationFailure = (error) => ({
  type: 'START_VERIFICATION_FAILURE',
  error,
});

const startVerification = (userId) => async (dispatch) => {
  dispatch(startVerificationLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/verification_start`,
      method: 'POST',
    });

    dispatch(startVerificationSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      startVerificationFailure,
      true,
      'There was an issue starting your verification.'
    );
  }
};

// =====================================
//   COMPLETE VERIFICATION REQUEST
// ====================================
const completeVerificationLoading = () => ({
  type: 'COMPLETE_VERIFICATION_LOADING',
});

const completeVerificationSuccess = (verificationStatus) => ({
  type: 'COMPLETE_VERIFICATION_SUCCESS',
  verificationStatus,
});

const completeVerificationFailure = (error) => ({
  type: 'COMPLETE_VERIFICATION_FAILURE',
  error,
});

const completeVerification = (userId) => async (dispatch) => {
  dispatch(completeVerificationLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/verification_complete`,
      method: 'POST',
    });

    dispatch(completeVerificationSuccess(res.status));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      completeVerificationFailure,
      true,
      'There was an issue starting your verification.'
    );
  }
};

// =============================
//   DELETE USER
// =============================
const deleteUserRequestLoading = () => ({
  type: 'DELETE_USER_REQUEST_LOADING',
});

const deleteUserRequestSuccess = (userId) => ({
  type: 'DELETE_USER_REQUEST_SUCCESS',
  id: userId,
});

const deleteUserRequestFailure = () => ({
  type: 'DELETE_USER_REQUEST_FAILURE',
});

const deleteUserRequest = (userId) => async (dispatch) => {
  dispatch(deleteUserRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}`,
      method: 'DELETE',
    });
    toast('Account successfully deleted', {
      type: toast.TYPE.SUCCESS,
    });
    Router.push('/logout');

    dispatch(deleteUserRequestSuccess(userId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deleteUserRequestFailure,
      true,
      'There was a problem deleting your account. Please contact support.'
    );
  }
};

// =====================================
//   RESEND EMAIL VERIFICATION EMAIL
// ====================================
const resendEmailVerificationLoading = () => ({
  type: 'RESEND_EMAIL_VERIFICATION_LOADING',
});

const resendEmailVerificationSuccess = () => ({
  type: 'RESEND_EMAIL_VERIFICATION_SUCCESS',
});

const resendEmailVerificationFailure = (error) => ({
  type: 'RESEND_EMAIL_VERIFICATION_FAILURE',
  error,
});

const resendEmailVerification = (userId) => async (dispatch) => {
  dispatch(resendEmailVerificationLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/change_email_resend`,
      method: 'POST',
    });

    dispatch(resendEmailVerificationSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      resendEmailVerificationFailure,
      true,
      'There was an issue sending your email verification.'
    );
  }
};

// =====================================
//   CHANGE EMAIL
// ====================================
const updateEmailRequestLoading = () => ({
  type: 'UPDATE_EMAIL_REQUEST_LOADING',
});

const updateEmailRequestSuccess = (email) => ({
  type: 'UPDATE_EMAIL_REQUEST_SUCCESS',
  email,
});

const updateEmailRequestFailure = (error) => ({
  type: 'UPDATE_EMAIL_REQUEST_FAILURE',
  error,
});

const updateEmailRequest = (userId, data) => async (dispatch) => {
  dispatch(updateEmailRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/change_email`,
      method: 'POST',
      data: JSON.stringify(data),
    });
    toast('Email address successfully changed', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(updateEmailRequestSuccess(data.new_email));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateEmailRequestFailure,
      true,
      'There was an issue sending your email verification.'
    );
    throw error;
  }
};

const updateEmailTokenRequest = (userId, token) => async (dispatch) => {
  dispatch(updateEmailRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/change_email/${token}`,
      method: 'POST',
    });
    toast('Email address successfully verified', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(updateEmailRequestSuccess());
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateEmailRequestFailure,
      true,
      'There was an issue verifying your new email address.'
    );
    throw error;
  }
};

const verifyEmailTokenRequest = (userId, token) => async (dispatch) => {
  dispatch(updateEmailRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/verify_email/${token}`,
      method: 'POST',
    });
    toast('Email address successfully verified', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(updateEmailRequestSuccess());
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateEmailRequestFailure,
      true,
      'There was an issue verifying your email address.'
    );
    throw error;
  }
};

const unsubscribeEmailRequest = (data) => async (dispatch) => {
  dispatch(updateEmailRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/unsubscribe`,
      method: 'POST',
      data: JSON.stringify(data),
    });

    dispatch(updateEmailRequestSuccess());
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateEmailRequestFailure,
      true,
      'There was a problem unsubscribing your email, please contact support.'
    );
    throw error;
  }
};

// ===========================
//   ADD PASSWORD REQUEST
// ===========================
const addPasswordRequestLoading = () => ({
  type: 'ADD_PASSWORD_REQUEST_LOADING',
});

const addPasswordRequestSuccess = () => ({
  type: 'ADD_PASSWORD_REQUEST_SUCCESS',
});

const addPasswordRequestFailure = (error) => ({
  type: 'ADD_PASSWORD_REQUEST_FAILURE',
  error,
});

const addPasswordRequest = (userId, data) => async (dispatch) => {
  dispatch(addPasswordRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/add_password`,
      method: 'POST',
      data: JSON.stringify(data),
    });
    toast('Your password has successfully been added.', {
      type: toast.TYPE.SUCCESS,
    });
    dispatch(addPasswordRequestSuccess());
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      addPasswordRequestFailure,
      true,
      'There was an issue adding your password.'
    );
    throw error;
  }
};

// ===========================
//   CHANGE PASSWORD REQUEST
// ===========================
const changePasswordRequestLoading = () => ({
  type: 'CHANGE_PASSWORD_REQUEST_LOADING',
});

const changePasswordRequestSuccess = () => ({
  type: 'CHANGE_PASSWORD_REQUEST_SUCCESS',
});

const changePasswordRequestFailure = (error) => ({
  type: 'CHANGE_PASSWORD_REQUEST_FAILURE',
  error,
});

const changePasswordRequest = (userId, data) => async (dispatch) => {
  dispatch(changePasswordRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/change_password`,
      method: 'POST',
      data: JSON.stringify(data),
    });
    authService.setTokens(res.access_token, res.refresh_token);
    Router.push('/settings');

    // Delay to make sure location is updated before isLoading stops
    setTimeout(() => {
      dispatch(changePasswordRequestSuccess());
    }, 250);
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      changePasswordRequestFailure,
      true,
      'There was an issue changing your password.'
    );
  }
};

// ===========================
//   RESET PASSWORD REQUEST
// ===========================
const resetPasswordRequestLoading = () => ({
  type: 'RESET_PASSWORD_REQUEST_LOADING',
});

const resetPasswordRequestSuccess = (data) => ({
  type: 'RESET_PASSWORD_REQUEST_SUCCESS',
  user: data.user,
});

const resetPasswordRequestFailure = (error) => ({
  type: 'RESET_PASSWORD_REQUEST_FAILURE',
  error,
});

const resetPasswordRequest = (data) => async (dispatch) => {
  dispatch(resetPasswordRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/reset_password`,
      method: 'POST',
      data: JSON.stringify(data),
    });

    dispatch(resetPasswordRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      resetPasswordRequestFailure,
      true,
      'There was an issue resetting your password.'
    );
  }
};

// ================================
//   RESET PASSWORD TOKEN REQUEST
// ================================
const resetPasswordTokenRequestLoading = () => ({
  type: 'RESET_PASSWORD_TOKEN_REQUEST_LOADING',
});

const resetPasswordTokenRequestSuccess = (data) => ({
  type: 'RESET_PASSWORD_TOKEN_REQUEST_SUCCESS',
  user: data.user,
});

const resetPasswordTokenRequestFailure = (error) => ({
  type: 'RESET_PASSWORD_TOKEN_REQUEST_FAILURE',
  error,
});

const resetPasswordTokenRequest = (token, data) => async (dispatch) => {
  dispatch(resetPasswordTokenRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/reset_password/${token}`,
      method: 'POST',
      data: JSON.stringify(data),
    });

    dispatch(resetPasswordTokenRequestSuccess(res));
    toast('Your password has successfully been reset, please login to continue.', {
      type: toast.TYPE.SUCCESS,
    });
    Router.push('/login');
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      resetPasswordTokenRequestFailure,
      true,
      'There was an issue resetting your password.'
    );
  }
};

// ================================
//   ACCEPT TOS REQUEST
// ================================
const acceptTosRequestLoading = () => ({
  type: 'ACCEPT_TOS_REQUEST_LOADING',
});

const acceptTosRequestSuccess = (tosAcceptance) => ({
  type: 'ACCEPT_TOS_REQUEST_SUCCESS',
  tosAcceptance,
});

const acceptTosRequestFailure = (error) => ({
  type: 'ACCEPT_TOS_REQUEST_FAILURE',
  error,
});

const acceptTosRequest = (userId) => async (dispatch) => {
  dispatch(acceptTosRequestLoading());

  try {
    const tos_acceptance_date = new Date();
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/tos_acceptance`,
      method: 'POST',
      data: {
        date: tos_acceptance_date,
      },
    });
    toast('Terms of service successfully accepted', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(acceptTosRequestSuccess(response.tos_acceptance));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      acceptTosRequestFailure,
      true,
      'There was an issue accepting the terms of service.'
    );
    throw error;
  }
};

export default {
  // authentication (token, login, signup, logout) actions
  directSignupRequest,
  tokenRequest,
  skipTokenRequest,
  clearLoginError,
  loginRequest,
  adminSudoUserLoginRequest,
  logoutRequest,
  socialDisconnectRequest,
  socialLoginCallbackRequest,
  signupRequest,
  clearSignupError,
  signupConfirmationRequest,
  clearInviteError,
  inviteSignupRequest,

  // get actions
  getCompanyRequest,
  getProfileRequest,
  getUserStatus,

  // update actions
  updateEmailRequest,
  updateEmailTokenRequest,
  verifyEmailTokenRequest,
  unsubscribeEmailRequest,
  updateUnreadMessageCount,
  updateUser,
  updateUserCompany,
  updateUserRequest,
  updateCompanyRequest,
  updateCompanySocialLinksRequest,
  updateUserProfilePhoto,
  updateUserSocialLinks,
  updateCompany,
  updateCompanyProfilePhoto,
  resendEmailVerification,
  startVerification,
  completeVerification,

  // delete actions
  deleteUserRequest,

  // password actions
  addPasswordRequest,
  changePasswordRequest,
  resetPasswordRequest,
  resetPasswordTokenRequest,

  // accept tos actions
  acceptTosRequest,
};
