import React from 'react';
import PropTypes from 'prop-types';

const FavoriteIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.528 2.28c-1.96 0-3.64 1.694-4.355 3.392-.718-1.698-2.397-3.393-4.358-3.393-2.61 0-4.726 2.115-4.726 4.725 0 4.633 9.083 11.818 9.083 11.818s9.08-6.702 9.08-11.818c0-2.61-2.115-4.726-4.725-4.726z" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

FavoriteIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default FavoriteIcon;
