import { fromJS } from 'immutable';
import { format } from 'date-fns';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  cities: [],
  draftListingCount: 0,
  error: null,
  list: [],
  listingActionLoading: false,
  isLoading: false,
  meta: {},
  queryIncluded: false, // This tracks whether or not the current listings in the store were filtered by a user query
});

const AccountListings = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AccountListings').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AccountListings') : state)
      );
    } else {
      return state;
    }

    // Retrieve account listings actions
  case 'RETRIEVE_ACCOUNT_LISTINGS_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_ACCOUNT_LISTINGS_REQUEST_SUCCESS':
    return state.merge(fromJS({
      cities: action.cities,
      draftListingCount: action.draft_listing_count ? action.draft_listing_count : 0,
      list: action.listings,
      isLoading: false,
      meta: action.meta,
      queryIncluded: action.queryIncluded,
    }));
  case 'RETRIEVE_ACCOUNT_LISTINGS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Publish listing actions
  case 'PUBLISH_ACCOUNT_LISTING_REQUEST_LOADING':
    return state.merge({
      listingActionLoading: true,
    });
  case 'PUBLISH_ACCOUNT_LISTING_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: state.get('list').map(listing => {
        if (action.id === listing.get('id')) {
          return listing.set('state', action.state);
        } else {
          return listing;
        }
      }),
      listingActionLoading: false,
      draftListingCount: Math.max(0, state.get('draftListingCount') - 1),
    }));
  case 'PUBLISH_ACCOUNT_LISTING_REQUEST_FAILURE':
    return state.merge({
      listingActionLoading: false,
    });

    // Delete listing actions
  case 'DELETE_ACCOUNT_LISTING_REQUEST_LOADING':
    return state.merge({
      listingActionLoading: true,
    });
  case 'DELETE_ACCOUNT_LISTING_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: state.get('list').filter(listing => {
        return (action.id !== listing.get('id'));
      }),
      listingActionLoading: false,
      draftListingCount: Math.max(0, state.get('draftListingCount') - 1),
      meta: state.setIn(['meta', 'count'], Math.max(0, state.getIn(['meta', 'count']) - 1)).get('meta'),
    }));
  case 'DELETE_ACCOUNT_LISTING_REQUEST_FAILURE':
    return state.merge({
      listingActionLoading: false,
    });

    // Unpublish listing actions
  case 'UNPUBLISH_ACCOUNT_LISTING_REQUEST_LOADING':
    return state.merge({
      listingActionLoading: true,
    });
  case 'UNPUBLISH_ACCOUNT_LISTING_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: state.get('list').map(listing => {
        if (action.id === listing.get('id')) {
          return listing.merge({
            state: 'draft',
            updated_at: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`),
          });
        } else {
          return listing;
        }
      }),
      listingActionLoading: false,
      draftListingCount: state.get('draftListingCount') + 1,
    }));
  case 'UNPUBLISH_ACCOUNT_LISTING_REQUEST_FAILURE':
    return state.merge({
      listingActionLoading: false,
    });

  default:
    return state;
  }
};

export default AccountListings;
