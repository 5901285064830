import React, { Component } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { isBefore, subDays, parseISO } from 'date-fns';
import Button from '@components/Core/Button';
import Dialog from '@components/Dialogs/Dialog';
import Tag from '@components/Text/Tag';
import Actions from '@redux/actions';

const HIDE_CANCELLATION_POLICY_MODEL = 'hideCancellationPolicyDialog';

class CancellationPolicyAlert extends Component {
  state = {
    showCancellationPolicyDialog: false,
  };

  async componentDidMount() {
    const { user, retrieveCancellationPolicyRequest } = this.props;
    retrieveCancellationPolicyRequest(user.getIn(['company', 'id']));
  }

  componentDidUpdate (prevProps) {
    const { cancellationPolicyStatus, user } = this.props;
    const previouslyHidden = Cookies.get(HIDE_CANCELLATION_POLICY_MODEL) === 'true';
    const userSignedUpMoreThenOneDayAgo = isBefore(parseISO(user.get('created_at')), subDays(new Date(), 1));
    // Show if they haven't previously hidden it, they signed up more than a day ago and that don't have a cancellation policy set
    if (
      !previouslyHidden &&
      userSignedUpMoreThenOneDayAgo &&
      prevProps.cancellationPolicyStatus !== cancellationPolicyStatus && cancellationPolicyStatus === 'blank'
    ) {
      this.setState({
        showCancellationPolicyDialog: true,
      });
    }
  }

  closeAlert = () => {
    Cookies.set(HIDE_CANCELLATION_POLICY_MODEL, true);
    this.setState({
      showCancellationPolicyDialog: false,
    });
  }

  render() {
    const { showCancellationPolicyDialog } = this.state;
    const { router } = this.props;

    return (
      <Dialog dialogClassName="dialog-content--medium ta-center" dialogId="cancellation-policy-alert" isOpen={showCancellationPolicyDialog} onClose={this.closeAlert}>
        <>
          <Tag state="light-blue" title="New" />
          <h2 className="m-top--medium">Add a cancellation policy</h2>
          <p className="m-horizontal--auto">This cancellation policy is shared with renters and lets them know how much they’ll get back from you if they need to cancel their stay.</p>
          <Button
            className="primary w--100p m-top--x-large"
            onClick={() =>  {
              this.closeAlert();
              router.push('/account/dashboard#booking-settings');
            }}
          >
            Add cancellation policy
          </Button>
        </>
      </Dialog>
    );
  }
}

CancellationPolicyAlert.propTypes = {
  // Required
  isLoading: PropTypes.bool.isRequired,
  cancellationPolicyStatus: PropTypes.string.isRequired,
  retrieveCancellationPolicyRequest: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.get('CancellationPolicy').get('isLoading'),
  cancellationPolicyStatus: state.get('CancellationPolicy').get('cancellationPolicyStatus'),
  user: state.get('User').get('object'),
});

export default withRouter(
  connect(mapStateToProps, {
    retrieveCancellationPolicyRequest: Actions.retrieveCancellationPolicyRequest,
  })(CancellationPolicyAlert)
);
