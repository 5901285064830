import React from 'react';
import PropTypes from 'prop-types';

const CashCardIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 20 26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(-138 -1176)">
      <g transform="translate(139 1177)">
        <rect height="24" rx="2" width="18" />
        <path d="m12.188 9.1739c0-1.2006-0.97329-2.1739-2.1739-2.1739h-1.6223c-1.3207 0-2.3913 1.0706-2.3913 2.3913 0 1.3207 1.0706 2.3913 2.3913 2.3913h1.1875c1.4407 0 2.6087 1.168 2.6087 2.6087 0 1.4407-1.168 2.6087-2.6087 2.6087h-1.4049c-1.2006 0-2.1739-0.97329-2.1739-2.1739" />
        <line x1="9.375" x2="9" y1="5" y2="19" />
      </g>
    </g>
  </svg>
);

CashCardIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CashCardIcon;
