import React from 'react';
import PropTypes from 'prop-types';

const RoomsIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path d="m23 1h-22v22h22v-22z" />
      <line x1="15" x2="15" y1=".5" y2="24" />
      <path d="m3 8c4-0.33333 6 1.6667 6 6" strokeDasharray="2" />
      <line x1="1" x2="15" y1="16" y2="16" />
      <line x1="15" x2="24" y1="8" y2="8" />
    </g>
  </svg>
);

RoomsIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default RoomsIcon;
