import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  list: [],
  meta: {},
  isLoading: true,
  error: null,
});

const AccountHousingSearch = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AccountHousingSearch').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AccountHousingSearch') : state)
      );
    } else {
      return state;
    }

    // Retrieve housing search listings actions
  case 'RETRIEVE_HOUSING_SEARCH_LISTINGS_REQUEST_LOADING':
    return state.merge(fromJS({
      list: [],
      isLoading: true,
    }));
  case 'RETRIEVE_HOUSING_SEARCH_LISTINGS_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: action.listings,
      meta: action.meta,
      isLoading: false,
    }));
  case 'RETRIEVE_HOUSING_SEARCH_LISTINGS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default AccountHousingSearch;
