import axios from 'axios';

const ApiFields = ['address_component', 'formatted_address', 'geometry', 'place_id', 'name'];

// Get the city / state from the a lat / lng coord
const getCityFromCoords = async (lat, lng) => {
  var location = new google.maps.LatLng(lat, lng);
  var geocoder = new google.maps.Geocoder();

  return await new Promise((resolve, reject) => {
    try {
      geocoder.geocode({ 'latLng': location }, (results) => {
        if (results && results.length) {
          let component = results[0].address_components;

          let cityObj = component.find(c => c.types.includes('locality') || c.types.includes('sublocality_level_1'));
          let stateObj = component.find(c => c.types.includes('administrative_area_level_1'));

          if (cityObj && stateObj) {
            resolve({
              city: cityObj.long_name,
              state: stateObj.long_name,
              state_short: stateObj.short_name, // The system is expecting this value throughout (including moca/db) to be state_short
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

// Get the lat / lng coords from the city / state, this makes a direct request since its used before the lib is loaded.
const getCoordsFromCity = async (city, state) => {
  const response = await axios({
    url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GOOGLE_MAPS_API_KEY_OPEN}&address=${city},${state}`,
    method: 'GET',
  });
  const result = response.data.results[0].geometry;
  let bounds = result.bounds || result.viewport;
  return `${bounds.southwest.lat},${bounds.southwest.lng},${bounds.northeast.lat},${bounds.northeast.lng}`;
};

const getAddressDetails = async (name, city, state, zipcode) => {
  const response = await axios({
    url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GOOGLE_MAPS_API_KEY_OPEN}&address=${name},${city},${state},${zipcode}`,
    method: 'GET',
  });

  return parseGeocodeResponse(response);
};

// Get the lat / lng coords from the city, this makes a direct request since its used before the lib is loaded.
const getCityDetails = async (city, state) => {
  const response = await axios({
    url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GOOGLE_MAPS_API_KEY_OPEN}&address=${city},${state}`,
    method: 'GET',
  });

  return parseGeocodeResponse(response);
};

// Get the lat / lng coords from the state this makes a direct request since its used before the lib is loaded.
const getStateDetails = async (state) => {
  const response = await axios({
    url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GOOGLE_MAPS_API_KEY_OPEN}&address=${state}`,
    method: 'GET',
  });

  return parseGeocodeResponse(response);
};

const parseAddressComponents = (addressComponents) => {
  // Address object structure
  // {
  //   suite: ... (optional)
  //   number: ...
  //   street: ...
  //   name: ... (street number + street name or brand name depending on the entry)
  //   neighborhood: ...
  //   state: ...
  //   zipcode: ...
  //   country: ...
  //   formatted: ...
  //   label: ... (optional)
  // }

  const googleComponents = [
    { googleComponent: 'street_number', name: 'number' },
    { googleComponent: 'route', name: 'street' },
    { googleComponent: 'sublocality_level_1', name: 'city' },
    { googleComponent: 'locality', name: 'city' },
    { googleComponent: 'neighborhood', name: 'neighborhood' },
    { googleComponent: 'administrative_area_level_1', name: 'state_short' },
    { googleComponent: 'administrative_area_level_1', name: 'state' },
    { googleComponent: 'postal_code', name: 'zipcode' },
    { googleComponent: 'country', name: 'country' },
  ];

  const addressObj = {};

  googleComponents.forEach((component) => {
    addressComponents.forEach((addressComponent) => {
      if (addressComponent.types.includes(component.googleComponent)) {
        let formValue;
        if (component.name === 'state_short') {
          formValue = addressComponent.short_name;
        } else {
          formValue = addressComponent.long_name;
        }

        if (!addressObj[component.name]) {
          addressObj[component.name] = formValue;
        }
      }
    });
  });

  return addressObj;
};

const parsePlacesResponse = (place) => {
  const parsedAddressComponents = parseAddressComponents(place.address_components);

  return {
    ...parsedAddressComponents,
    coords: {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    },
    formatted: place.formatted_address,
    name: place.name,
    placeId: place.place_id,
  };
};

const parseGeocodeResponse = (response) => {
  console.log('Geocode response from Google', response);
  if (response && response.data.results.length > 0) {
    const result = response.data.results[0];
    const parsedAddressComponents = parseAddressComponents(result.address_components);
    const formattedAddressSplit = result.formatted_address ? result.formatted_address.split(',') : [];
    let name;

    // We are only going to use the name from Google if what the user has entered properly
    // returns back both the number and street. In this case, we'll use the format provided
    // from Google as the first option. If a formatted address isn't returned, we'll manually create
    // the format ourselves as a backup.
    if (parsedAddressComponents.number && parsedAddressComponents.street) {
      if (formattedAddressSplit.length > 0) {
        name = formattedAddressSplit[0];
      } else {
        name = `${parsedAddressComponents.number} ${parsedAddressComponents.street}`;
      }
    }

    return {
      ...parsedAddressComponents,
      coords: {
        latitude: result.geometry.location.lat,
        longitude: result.geometry.location.lng,
      },
      formatted: result.formatted_address,
      name,
      placeId: result.place_id,
    };
  } else {
    console.log('Did not get a result from Google');
    return false;
  }
};

const getDirections = async (originCoords, destinationCoords, travelMode) => {
  const directionsService = new google.maps.DirectionsService();
  const request = {
    origin: new google.maps.LatLng(originCoords.get('latitude'), originCoords.get('longitude')),
    destination: new google.maps.LatLng(destinationCoords.get('latitude'), destinationCoords.get('longitude')),
    travelMode: travelMode,
  };

  return await new Promise((resolve, reject) => {
    directionsService.route(request, (response) => {
      if (response && response.routes && response.status === google.maps.DirectionsStatus.OK) {
        return resolve(response);
      } else {
        return reject();
      }
    });
  });
};

// The size of the marker depends on the length of the price. (e.g. 800, 2000, 11000)
// Since our prices fall into these 3 categories, this is a workable solution instead of
// attempting to calculate all of the widths based on font size, padding, margin, etc.
const determineLabelAnchorBasedOnPrice = (roundedPriceAsString) => {
  if (roundedPriceAsString.length === 3) {
    return new google.maps.Point(24.43, 36);
  } else if (roundedPriceAsString.length === 5) {
    return new google.maps.Point(35.28, 36);
  }

  // Our most common prices will be 4 digits in length, which is why this is the default
  return new google.maps.Point(30.8, 36);
};

const getFirstPrediction = async (searchText, searchField, componentRestrictions, autocompleteOptions) => {
  const autocompleteService = new google.maps.places.AutocompleteService();
  const sessionToken = new google.maps.places.AutocompleteSessionToken();

  return await new Promise((resolve, reject) => {
    try {
      autocompleteService.getPlacePredictions({
        input: searchText,
        offset: searchText.length,
        componentRestrictions,
        types: autocompleteOptions.types,
        fields: ApiFields,
        sessionToken,
      }, async (list) => {
        if (list && list.length > 0) {
          const response = await axios({
            url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GOOGLE_MAPS_API_KEY_OPEN}&place_id=${list[0].place_id}`,
            method: 'GET',
          });

          const parsedData = parseGeocodeResponse(response);
          resolve(parsedData);
        } else {
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export default {
  ApiFields,
  getAddressDetails,
  getCityFromCoords,
  getFirstPrediction,
  getCoordsFromCity,
  getCityDetails,
  getStateDetails,
  getDirections,
  determineLabelAnchorBasedOnPrice,
  parsePlacesResponse,
};
