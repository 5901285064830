import React from 'react';
import PropTypes from 'prop-types';

const ParkingIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <circle cx="24" cy="24" r="22" />
      <circle cx="24" cy="24" r="17" />
      <path d="m20 32v-14h6.2308c2.0817 0 3.7692 1.6875 3.7692 3.7692 0 2.0737-1.6811 3.7548-3.7548 3.7548-0.0048138 0-0.0096276-9.3e-6 -0.014441-2.78e-5l-6.2308-0.023965" />
    </g>
  </svg>
);

ParkingIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ParkingIcon;
