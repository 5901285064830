// ==========================
//   UPDATE FILTERS
// ==========================
const updateFilters = (filters) => ({
  type: 'UPDATE_FILTERS',
  filters,
});

export default {
  updateFilters,
};
