import React from 'react';
import PropTypes from 'prop-types';

const MapMarkerIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" strokeWidth="1">
      <g transform="translate(-426.000000, -482.000000)">
        <g transform="translate(416.000000, 471.000000)">
          <g transform="translate(9.000000, 9.000000)">
            <g transform="translate(2.000000, 3.000000)">
              <path d="M11.667,5.93396087 C11.667,9.00652609 6.021,16.3261348 6.021,16.3261348 C6.021,16.3261348 0.375,9.00652609 0.375,5.93396087 C0.375,2.86065652 2.9025,0.369786957 6.021,0.369786957 C9.1395,0.369786957 11.667,2.86065652 11.667,5.93396087" fill="none" />
              <path d="M11.667,5.93396087 C11.667,9.00652609 6.021,16.3261348 6.021,16.3261348 C6.021,16.3261348 0.375,9.00652609 0.375,5.93396087 C0.375,2.86065652 2.9025,0.369786957 6.021,0.369786957 C9.1395,0.369786957 11.667,2.86065652 11.667,5.93396087 Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.20799449,6.48856087 C8.20799449,7.67910407 7.22943216,8.64415543 6.0207,8.64415543 C4.81332979,8.64415543 3.83340551,7.67910407 3.83340551,6.48856087 C3.83340551,5.29801767 4.81332979,4.3329663 6.0207,4.3329663 C7.22943216,4.3329663 8.20799449,5.29801767 8.20799449,6.48856087 Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MapMarkerIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default MapMarkerIcon;
