import React from 'react';
import PropTypes from 'prop-types';

const FurnitureIcon = (props) => (
  <svg className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m11 32.6v-14.6c0-0.55228-0.44772-1-1-1h-7c-0.55228 0-1 0.44772-1 1v23h44v-23c0-0.55228-0.44772-1-1-1h-7c-0.55228 0-1 0.44772-1 1v14.6" />
      <path d="m6 16v-10c0-2.2091 1.7909-4 4-4h28c2.2091 0 4 1.7909 4 4v10" />
      <line x1="10" x2="38" y1="24" y2="24" />
      <line x1="10" x2="38" y1="32" y2="32" />
      <polyline points="7 41 7 47 11 47 11 41" />
      <polyline points="37 41 37 47 41 47 41 41" />
    </g>
  </svg>
);

FurnitureIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default FurnitureIcon;
