// ==========================================
//   UPDATE RECOMMENDATIONS SURVEY FILTERS
// ==========================================
const updateRecommendationsSurveyFilters = (filters) => ({
  type: 'UPDATE_RECOMMENDATIONS_SURVEY_FILTERS',
  filters,
});

// ==========================================
//   CLEAR RECOMMENDATIONS SURVEY FILTERS
// ==========================================
const clearRecommendationsSurveyFilters = () => ({
  type: 'CLEAR_RECOMMENDATIONS_SURVEY_FILTERS',
});

export default {
  updateRecommendationsSurveyFilters,
  clearRecommendationsSurveyFilters,
};
