import { toast } from 'react-toastify';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ====================================
//   UPDATE ACTIVE PAYMENT METHOD
// ====================================
const updateActivePaymentMethod = (paymentMethod) => (dispatch) => (
  dispatch(updateActivePaymentMethodSuccess(paymentMethod))
);

const updateActivePaymentMethodSuccess = (paymentMethod) => ({
  type: 'UPDATE_ACTIVE_PAYMENT_METHOD_SUCCESS',
  paymentMethod,
});

// ====================================
//   CREATE PAYMENT METHODS REQUEST
// ====================================
const createPaymentMethodRequestLoading = () => ({
  type: 'CREATE_PAYMENT_METHOD_REQUEST_LOADING',
});

const createPaymentMethodRequestSuccess = (paymentMethod) => ({
  type: 'CREATE_PAYMENT_METHOD_REQUEST_SUCCESS',
  paymentMethod,
});

const createPaymentMethodRequestFailure = (error) => ({
  type: 'CREATE_PAYMENT_METHOD_REQUEST_FAILURE',
  error,
});

const createPaymentMethodRequest = (userId, paymentMethod) => async (dispatch) => {
  dispatch(createPaymentMethodRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/payment_methods`,
      method: 'POST',
      data: JSON.stringify(paymentMethod),
    });
    dispatch(createPaymentMethodRequestSuccess(res));
    return { paymentMethod: res };
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createPaymentMethodRequestFailure,
      true,
      'There was an issue creating your payment method. Please try again.'
    );
    throw error;
  }
};

// ====================================
//   SET DEFAULT PAYMENT METHODS REQUEST
// ====================================
const defaultPaymentMethodRequestLoading = () => ({
  type: 'DEFAULT_PAYMENT_METHOD_REQUEST_LOADING',
});

const defaultPaymentMethodRequestSuccess = (paymentMethod) => ({
  type: 'DEFAULT_PAYMENT_METHOD_REQUEST_SUCCESS',
  paymentMethod,
});

const defaultPaymentMethodRequestFailure = (error) => ({
  type: 'DEFAULT_PAYMENT_METHOD_REQUEST_FAILURE',
  error,
});

const defaultPaymentMethodRequest = (userId, paymentMethodId) => async (dispatch) => {
  dispatch(defaultPaymentMethodRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/payment_methods/${paymentMethodId}/default`,
      method: 'POST',
    });
    dispatch(defaultPaymentMethodRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      defaultPaymentMethodRequestFailure,
      true,
      'There was an issue setting your default payment method. Please try again.'
    );
    throw error;
  }
};

// =============================
//   DELETE PAYMENT METHOD
// =============================
const deletePaymentMethodRequestLoading = () => ({
  type: 'DELETE_PAYMENT_METHOD_REQUEST_LOADING',
});

const deletePaymentMethodRequestSuccess = (paymentMethodId) => ({
  type: 'DELETE_PAYMENT_METHOD_REQUEST_SUCCESS',
  id: paymentMethodId,
});

const deletePaymentMethodRequestFailure = () => ({
  type: 'DELETE_PAYMENT_METHOD_REQUEST_FAILURE',
});

const deletePaymentMethodRequest = (userId, paymentMethodId) => async (dispatch) => {
  dispatch(deletePaymentMethodRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/payment_methods/${paymentMethodId}`,
      method: 'DELETE',
    });
    toast('Payment method deleted', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(deletePaymentMethodRequestSuccess(paymentMethodId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deletePaymentMethodRequestFailure,
      true,
      'There was an issue deleting your payment method. Please try again.'
    );
    throw error;
  }
};

export default {
  createPaymentMethodRequest,
  defaultPaymentMethodRequest,
  deletePaymentMethodRequest,
  updateActivePaymentMethod,
};
