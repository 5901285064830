import React from 'react';
import PropTypes from 'prop-types';

const Splat2Icon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" fillRule="evenodd" viewBox="0 0 487 443" xmlns="http://www.w3.org/2000/svg">
    <path d="M361.998 45.104c-16.502-60.365-84.98-54.09-135.498-16.636C175.982 65.923 111.676 154.63 44.13 154.63c-67.548 0-67.548 111.797 70.952 91.368 138.5-20.43-62.438 102.716 14.758 167.411 77.197 64.695 249.754 10.783 315.03-58.452 65.277-69.235 59.033-173.087-49.383-173.087-108.415 0-16.987-76.402-33.49-136.766z" transform="translate(-828 -171) translate(828 171.532)" />
  </svg>
);

Splat2Icon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default Splat2Icon;
