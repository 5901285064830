import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   RETRIEVE LISTINGS
// ==========================
const retrieveListingsLoading = () => ({
  type: 'RETRIEVE_LISTINGS_LOADING',
});

const retrieveListingsSuccess = (data) => ({
  type: 'RETRIEVE_LISTINGS_SUCCESS',
  ...data,
});

const retrieveListingsFailure = () => ({
  type: 'RETRIEVE_LISTINGS_FAILURE',
});

const retrieveListings = (query) => async (dispatch) => {
  dispatch(retrieveListingsLoading());
  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings?${query}`,
      method: 'GET',
    });

    dispatch(retrieveListingsSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveListingsFailure
    );
  }
};

// ==========================
//   RETRIEVE LISTINGS COUNT
// ==========================
const retrieveListingsCountLoading = () => ({
  type: 'RETRIEVE_LISTINGS_COUNT_LOADING',
});

const retrieveListingsCountSuccess = (data) => ({
  type: 'RETRIEVE_LISTINGS_COUNT_SUCCESS',
  ...data,
});

const retrieveListingsCountFailure = () => ({
  type: 'RETRIEVE_LISTINGS_COUNT_FAILURE',
});

const retrieveListingsCount = (query) => async (dispatch) => {
  dispatch(retrieveListingsCountLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings_count?${query}`,
      method: 'GET',
    });

    dispatch(retrieveListingsCountSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveListingsCountFailure
    );
  }
};

// ==========================
//   RETRIEVE COMMUTE TIMES
// ==========================
const retrieveCommuteTimesLoading = () => ({
  type: 'RETRIEVE_COMMUTE_TIMES_LOADING',
});

const retrieveCommuteTimesSuccess = (commuteTimes) => ({
  type: 'RETRIEVE_COMMUTE_TIMES_SUCCESS',
  commuteTimes,
});

const retrieveCommuteTimesFailure = () => ({
  type: 'RETRIEVE_COMMUTE_TIMES_FAILURE',
});

const retrieveCommuteTimes = (data) => async (dispatch) => {
  dispatch(retrieveCommuteTimesLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings_commute_times`,
      method: 'POST',
      data: JSON.stringify(data),
    });

    dispatch(retrieveCommuteTimesSuccess(response));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveCommuteTimesFailure,
      false
    );
  }
};

// ==========================
//   RETRIEVE FEATURED LISTINGS
// ==========================
const retrieveFeaturedListingsLoading = () => ({
  type: 'RETRIEVE_FEATURED_LISTINGS_LOADING',
});

const retrieveFeaturedListingsSuccess = (featuredListings) => ({
  type: 'RETRIEVE_FEATURED_LISTINGS_SUCCESS',
  featuredListings,
});

const retrieveFeaturedListingsFailure = () => ({
  type: 'RETRIEVE_FEATURED_LISTINGS_FAILURE',
});

const retrieveFeaturedListings = (query) => async (dispatch) => {
  dispatch(retrieveFeaturedListingsLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/featured_listings?${query}`,
      method: 'GET',
    });

    dispatch(retrieveFeaturedListingsSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveFeaturedListingsFailure,
      false
    );
  }
};

export default {
  retrieveListings,
  retrieveListingsCount,
  retrieveCommuteTimes,
  retrieveFeaturedListings,
};
