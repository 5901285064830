import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeftIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(2 1)">
      <polyline points="-3 3.0226 2.9887 9 9 3" transform="translate(3 6) rotate(90) translate(-3 -6)" />
    </g>
  </svg>
);

ArrowLeftIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ArrowLeftIcon;
