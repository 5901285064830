const hasFeatureFlag = (feature_flags, feature_flag) => {
  if (!feature_flags) {
    return null;
  }

  const flags = feature_flags.map(f => f.getIn(['feature_flag', 'key']));
  return flags.includes(feature_flag);
};

export default {
  hasFeatureFlag,
};
