import qs from 'query-string';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// =============================
//   RETRIEVE ACCOUNT LISTINGS
// =============================
const retrieveAccountListingsRequestLoading = () => ({
  type: 'RETRIEVE_ACCOUNT_LISTINGS_REQUEST_LOADING',
});

const retrieveAccountListingsRequestSuccess = (data, queryIncluded) => ({
  type: 'RETRIEVE_ACCOUNT_LISTINGS_REQUEST_SUCCESS',
  ...data,
  queryIncluded,
});

const retrieveAccountListingsRequestFailure = () => ({
  type: 'RETRIEVE_ACCOUNT_LISTINGS_REQUEST_FAILURE',
});

const retrieveAccountListingsRequest = (companyId, query = {}) => async (dispatch) => {
  dispatch(retrieveAccountListingsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/listings?${qs.stringify(query)}`,
      method: 'GET',
    });
    const queryIncluded = query.search && query.search.length > 0;
    dispatch(retrieveAccountListingsRequestSuccess(response, queryIncluded));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveAccountListingsRequestFailure
    );
  }
};

// =============================
//      PUBLISH ACCOUNT LISTING
// =============================
const publishAccountListingRequestLoading = () => ({
  type: 'PUBLISH_ACCOUNT_LISTING_REQUEST_LOADING',
});

const publishAccountListingRequestSuccess = (listingId, data) => ({
  type: 'PUBLISH_ACCOUNT_LISTING_REQUEST_SUCCESS',
  id: listingId,
  ...data,
});

const publishAccountListingRequestFailure = () => ({
  type: 'PUBLISH_ACCOUNT_LISTING_REQUEST_FAILURE',
});

const publishAccountListingRequest = (listingId) => async (dispatch) => {
  dispatch(publishAccountListingRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/publish`,
      method: 'POST',
      data: JSON.stringify({
        source: 'account_listings',
      }),
    });
    dispatch(publishAccountListingRequestSuccess(listingId, response));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      publishAccountListingRequestFailure
    );
  }
};

// =============================
//      DELETE ACCOUNT LISTING
// =============================
const deleteAccountListingRequestLoading = () => ({
  type: 'DELETE_ACCOUNT_LISTING_REQUEST_LOADING',
});

const deleteAccountListingRequestSuccess = (listingId) => ({
  type: 'DELETE_ACCOUNT_LISTING_REQUEST_SUCCESS',
  id: listingId,
});

const deleteAccountListingRequestFailure = () => ({
  type: 'DELETE_ACCOUNT_LISTING_REQUEST_FAILURE',
});

const deleteAccountListingRequest = (listingId) => async (dispatch) => {
  dispatch(deleteAccountListingRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}`,
      method: 'DELETE',
    });
    dispatch(deleteAccountListingRequestSuccess(listingId));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deleteAccountListingRequestFailure
    );
  }
};

// =============================
//      UNPUBLISH ACCOUNT LISTING
// =============================
const unpublishAccountListingRequestLoading = () => ({
  type: 'UNPUBLISH_ACCOUNT_LISTING_REQUEST_LOADING',
});

const unpublishAccountListingRequestSuccess = (listingId) => ({
  type: 'UNPUBLISH_ACCOUNT_LISTING_REQUEST_SUCCESS',
  id: listingId,
});

const unpublishAccountListingRequestFailure = () => ({
  type: 'UNPUBLISH_ACCOUNT_LISTING_REQUEST_FAILURE',
});

const unpublishAccountListingRequest = (listingId) => async (dispatch) => {
  dispatch(unpublishAccountListingRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}`,
      method: 'PUT',
      data: JSON.stringify({
        state: 'draft',
        source: 'account_listings',
      }),
    });
    dispatch(unpublishAccountListingRequestSuccess(listingId));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      unpublishAccountListingRequestFailure
    );
  }
};

export default {
  retrieveAccountListingsRequest,
  publishAccountListingRequest,
  deleteAccountListingRequest,
  unpublishAccountListingRequest,
};
