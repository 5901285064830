import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';
import { fromJS } from 'immutable';
import rootReducer from '@redux/reducers';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

// create a makeStore function
const makeStore = () => {
  return createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunkMiddleware)
    )
  );
};

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, {
  serializeState: state => state.toJS(),
  deserializeState: state => fromJS(state),
});
