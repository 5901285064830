import React from 'react';
import PropTypes from 'prop-types';

const SawtoothIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 87 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10.447L5.83 16.829 13.019 10.204 21.438 17.978 24.854 17.98 33.231 10.221 41.643 17.988 45.349 17.99 53.776 10.225 62.196 17.998 65.611 18 73.989 10.241 81.164 16.867 87 10.49 75.674 0.032 72.292 0.031 63.898 7.805 55.469 0.022 52.094 0.02 43.501 7.938 34.917 0.012 31.535 0.01 23.141 7.784 14.712 0.002 11.336 0z" fill="currentColor" fillRule="evenodd" />
  </svg>
);

SawtoothIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default SawtoothIcon;
