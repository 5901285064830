import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ className, state, title }) => (
  <p className={`${className} tag-${state} inline-block meta strong b--rounded-sm m-top--none p-vertical--xxx-small p-horizontal--x-small`}>{ title }</p>
);

Tag.propTypes = {
  state: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // Optional
  className: PropTypes.string,
};

Tag.defaultProps = {
  className: '',
};

export default Tag;
