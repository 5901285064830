import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ======================================
//   CREATE CALENDAR IMPORT REQUEST
// ======================================
const createCalendarImportRequestLoading = () => ({
  type: 'CREATE_CALENDAR_IMPORT_REQUEST_LOADING',
});

const createCalendarImportRequestSuccess = (calendarImport) => ({
  type: 'CREATE_CALENDAR_IMPORT_REQUEST_SUCCESS',
  calendarImport,
});

const createCalendarImportRequestFailure = (error) => ({
  type: 'CREATE_CALENDAR_IMPORT_REQUEST_FAILURE',
  error,
});

const createCalendarImportRequest = (listingId, calendarImport) => async (dispatch) => {
  dispatch(createCalendarImportRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/calendar_imports`,
      method: 'POST',
      data: JSON.stringify(calendarImport),
    });
    dispatch(createCalendarImportRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createCalendarImportRequestFailure,
      true,
      'There was an issue creating your calendar import.'
    );
    throw error;
  }
};

// ======================================
//   DELETE CALENDAR IMPORT REQUEST
// ======================================
const updateCalendarImportRequestLoading = () => ({
  type: 'UPDATE_CALENDAR_IMPORT_REQUEST_LOADING',
});

const updateCalendarImportRequestSuccess = (calendarImport) => ({
  type: 'UPDATE_CALENDAR_IMPORT_REQUEST_SUCCESS',
  calendarImport,
});

const updateCalendarImportRequestFailure = (error) => ({
  type: 'UPDATE_CALENDAR_IMPORT_REQUEST_FAILURE',
  error,
});

const updateCalendarImportRequest = (listingId, calendarImport) => async (dispatch) => {
  dispatch(updateCalendarImportRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/calendar_imports/${calendarImport.get('id')}`,
      method: 'PUT',
      data: JSON.stringify(calendarImport),
    });
    dispatch(updateCalendarImportRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateCalendarImportRequestFailure,
      true,
      'There was an issue updating your calendar import.'
    );
  }
};

// ======================================
//   DELETE CALENDAR IMPORT REQUEST
// ======================================
const deleteCalendarImportRequestLoading = () => ({
  type: 'DELETE_CALENDAR_IMPORT_REQUEST_LOADING',
});

const deleteCalendarImportRequestSuccess = (calendarImportId) => ({
  type: 'DELETE_CALENDAR_IMPORT_REQUEST_SUCCESS',
  calendarImportId,
});

const deleteCalendarImportRequestFailure = (error) => ({
  type: 'DELETE_CALENDAR_IMPORT_REQUEST_FAILURE',
  error,
});

const deleteCalendarImportRequest = (listingId, calendarImportId) => async (dispatch) => {
  dispatch(deleteCalendarImportRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/calendar_imports/${calendarImportId}`,
      method: 'DELETE',
    });
    dispatch(deleteCalendarImportRequestSuccess(calendarImportId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deleteCalendarImportRequestFailure,
      true,
      'There was an issue deleting your calendar import.'
    );
  }
};

export default {
  createCalendarImportRequest,
  updateCalendarImportRequest,
  deleteCalendarImportRequest,
};
