import JsCookies from 'js-cookie';
import LocalStorageService from '@services/LocalStorageService';

const COOKIE_OPTIONS = {
  secure: process.env.ENV !== 'development',
  sameSite: 'strict',
};

export default class AuthService {
  constructor(apiService) {
    this.apiService = apiService;
    this.domain = process.env.API_ENDPOINT;
  }

  async token() {
    // Check if token is valid and get user
    return await this.apiService.fetch({
      url: `${this.domain}/v1/auth`,
      method: 'POST',
      data: JSON.stringify({
        grant_type: 'token',
        spoofing: LocalStorageService.getItem('spoofing') === 'true',
      }),
    });
  }

  login(credentials) {
    // Check if token is valid and get user
    return this.apiService.fetch({
      url: `${this.domain}/v1/auth`,
      method: 'POST',
      data: JSON.stringify({
        grant_type: 'login',
        spoofing: LocalStorageService.getItem('spoofing') === 'true',
        ...credentials,
      }),
    }, true);
  }

  setTokens(accessToken, refreshToken) {
    // Sets tokens after success authentication
    JsCookies.set('access_token', accessToken, COOKIE_OPTIONS);
    JsCookies.set('refresh_token', refreshToken, COOKIE_OPTIONS);
  }

  removeTokens() {
    // Remove tokens after logout
    JsCookies.remove('access_token');
    JsCookies.remove('refresh_token');
  }
}
