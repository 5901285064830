import ErrorHandler from '@utilities/ErrorHandler';

// =================================
//   RETRIEVE OCCUPANCY REQUEST
// ===================================
const retrieveOccupancyRequestLoading = () => ({
  type: 'RETRIEVE_OCCUPANCY_REQUEST_LOADING',
});

const retrieveOccupancyRequestSuccess = (occupancy) => ({
  type: 'RETRIEVE_OCCUPANCY_REQUEST_SUCCESS',
  occupancy,
});

const retrieveOccupancyRequestFailure = (error) => ({
  type: 'RETRIEVE_OCCUPANCY_REQUEST_FAILURE',
  error,
});

const retrieveOccupancyRequest = (apiService, occupancyId) => async (dispatch) => {
  dispatch(retrieveOccupancyRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/occupancies/${occupancyId}`,
      method: 'GET',
    });
    dispatch(retrieveOccupancyRequestSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveOccupancyRequestFailure
    );
    throw error;
  }
};

export default {
  retrieveOccupancyRequest,
};
