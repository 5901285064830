import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '@components/Core/Button';
import { CloseIcon, SearchIcon } from '@components/Icons';
import GeneralStatic from '@utilities/static/GeneralStatic';

class Input extends Component {
  // Stop enter from submitting forms
  preventDefault (event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  render () {
    const {
      ariaLabel, autoResize, checked, disabled, disablePaste, error, handleChange,
      handleKeyDown, handleBlur, handleFocus, handleHeightChange, id,
      inputClassName, label, labelClassName, max, maxlength, maxRows,
      min, minlength, minRows, name, hasAsterisk, placeholder, required,
      readonly, rows, showErrors, showCharCount, showClearButton,
      showSearchIcon, type, value,
    } = this.props;
    let { className } = this.props;

    let InputComp;
    const renderClearButton = showClearButton && handleChange && (value && value.length > 0);
    const joinedClassNames = [inputClassName, showSearchIcon ? 'input-with-icon' : '', renderClearButton ? 'input-with-clear-button' : ''].join(' ').trim();
    const specialAttributes = {};

    if (readonly) {
      specialAttributes.readOnly = 'readonly';
    }

    if (type === 'number') {
      specialAttributes.step = '0.01';
    }

    switch (type) {
    case 'textarea':
      if (autoResize) {
        InputComp = (
          <TextareaAutosize
            aria-label={ariaLabel}
            className={inputClassName}
            disabled={disabled}
            id={id}
            maxRows={maxRows}
            maxlength={maxlength}
            minRows={minRows}
            minlength={minlength}
            name={name}
            onChange={handleChange ? handleChange.bind(this, name) : null}
            onHeightChange={handleHeightChange}
            placeholder={placeholder}
            value={value || ''}
            {...specialAttributes}
          />
        );
      } else {
        InputComp = (
          <textarea
            aria-label={ariaLabel}
            className={inputClassName}
            disabled={disabled}
            id={id}
            maxLength={maxlength}
            minLength={minlength}
            name={name}
            onChange={handleChange ? handleChange.bind(this, name) : null}
            placeholder={placeholder}
            rows={rows}
            value={value || ''}
            {...specialAttributes}
          />
        );
      }
      break;
    case 'checkbox':
      className += ' checkbox';
      InputComp = (
        <input
          aria-label={ariaLabel}
          checked={checked}
          className={inputClassName}
          disabled={disabled}
          id={id}
          name={name}
          onChange={handleChange ? handleChange.bind(this, name, !checked) : null}
          placeholder={placeholder}
          type={type}
          {...specialAttributes}
        />
      );
      break;
    default:
      InputComp = (
        <span className="pseudo-wrapper">
          { showSearchIcon && (
            <SearchIcon className="absolute a--vertical a--left-xs icon--black icon--16 inline-block m-left--medium" />
          )}

          <input
            aria-label={ariaLabel}
            className={joinedClassNames || null}
            disabled={disabled}
            id={id}
            max={max}
            maxLength={maxlength}
            min={min}
            minLength={minlength}
            name={name}
            onBlur={handleBlur ? handleBlur.bind(this, name) : null}
            onChange={handleChange ? handleChange.bind(this, name) : null}
            onFocus={handleFocus ? handleFocus.bind(this, name) : null}
            onKeyDown={handleKeyDown ? handleKeyDown.bind(this, name) : null}
            onPaste={disablePaste ? e => e.preventDefault() : null}
            placeholder={placeholder}
            required={required}
            type={type}
            value={value || ''}
            {...specialAttributes}
          />
          {/* Clear button: sends blank result on handle change */}
          { renderClearButton && (
            <Button
              ariaLabel="Clear input text"
              className="input-clear-button b--round button-icon center-items absolute a--vertical a--right-xs"
              onClick={(event) => {
                event.preventDefault();
                let fakeEvent = {
                  target: {
                    value: '',
                  },
                };
                handleChange(name, fakeEvent);
              }}
            >
              <CloseIcon className="icon--black icon--18 m-around--small" />
            </Button>
          )}
        </span>
      );
    }

    return (
      <div className={`${className} ${label ? '' : 'no-label '}input-wrapper ${showErrors && error ? 'error' : ''} ${disabled ? 'gray-0' : ''}`}>

        { label && type !== 'checkbox' && <label className={`no-select block m-bottom--xx-small ${required && hasAsterisk ? 'required' : ''}`} htmlFor={id}>{label}</label> }

        { InputComp }

        { type === 'checkbox' && <label className={`no-select ${required && hasAsterisk ? 'required' : ''} ${labelClassName}`} htmlFor={id}>{label || ''}</label> }

        { ((showErrors && error) || (showCharCount && value && (minlength || maxlength))) && (
          <div className="layout-row layout-align-space-between-center">
            <TransitionGroup component={null}>
              { showErrors && error && (
                <CSSTransition classNames="item--slide-short" timeout={GeneralStatic.transitionDefault}>
                  <p className="checkbox-error meta strong red m-top--xxx-small">{error}</p>
                </CSSTransition>
              )}
            </TransitionGroup>
            { showCharCount && minlength && value && value.length < minlength && <span className="meta gray-0 flex ta-right">{`${value.length} of ${minlength} required`}</span>}
            { showCharCount && maxlength && value && (!minlength || (minlength && value.length >= minlength)) && <span className="meta gray-0 flex ta-right">{`${value.length}/${maxlength}`}</span>}
          </div>
        )}
      </div>
    );
  }
}

Input.propTypes = {
  // Required
  name: PropTypes.string.isRequired,
  // Optional
  ariaLabel: PropTypes.string,
  autoResize: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disablePaste: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  handleHeightChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelClassName: PropTypes.string,
  maxlength: PropTypes.number,
  max: PropTypes.number,
  maxRows: PropTypes.number,
  min: PropTypes.number,
  minlength: PropTypes.number,
  minRows: PropTypes.number,
  hasAsterisk: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showErrors: PropTypes.bool,
  showCharCount: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Input.defaultProps = {
  ariaLabel: undefined,
  autoResize: false,
  checked: false,
  className: '',
  disabled: null,
  disablePaste: false,
  error: null,
  handleChange: null,
  handleHeightChange: null,
  handleKeyDown: null,
  handleBlur: null,
  handleFocus: null,
  id: Math.random().toString(36).substr(6, 6), // used for accessibility of matching label and input
  inputClassName: '',
  label: null,
  labelClassName: '',
  max: null,
  maxlength: null,
  maxRows: 5,
  min: null,
  minlength: null,
  minRows: 1,
  hasAsterisk: true,
  placeholder: '',
  rows: 3,
  readonly: false,
  required: false,
  showErrors: false,
  showClearButton: false,
  showCharCount: false,
  showSearchIcon: false,
  type: 'text',
  value: null,
};

export default Input;
