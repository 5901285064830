import React from 'react';
import PropTypes from 'prop-types';

const PlayIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" fillRule="evenodd" version="1.1" viewBox="0 0 64 65" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4.828v22.344c0 1.104.895 2 2 2 .53 0 1.04-.211 1.414-.586l11.172-11.172c.78-.78.78-2.047 0-2.828L3.414 3.414c-.78-.78-2.047-.78-2.828 0C.21 3.79 0 4.298 0 4.828z" transform="translate(27 16.5)" />
  </svg>
);

PlayIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default PlayIcon;
