import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  estimateLoading: false,
  estimate: {},
  list: [],
  meta: {},
  payment: {},
  listIsLoading: true,
  isLoading: true,
  error: null,
  paymentIsProcessing: false,
  paymentMade: {},
});

const AccountPayments = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AccountPayments').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AccountPayments') : state)
      );
    } else {
      return state;
    }

  case 'RETRIEVE_PAYMENT_ESTIMATE_REQUEST_LOADING':
    return state.merge({
      estimateLoading: true,
    });

  case 'RETRIEVE_PAYMENT_ESTIMATE_REQUEST_SUCCESS':
    return state.merge({
      estimate: fromJS(action.estimate),
      estimateLoading: false,
    });

  case 'RETRIEVE_PAYMENT_ESTIMATE_REQUEST_FAILURE':
    return state.merge({
      estimateLoading: false,
      error: action.error,
    });

  case 'RETRIEVE_ACCOUNT_PAYMENTS_REQUEST_LOADING':
    return state.merge({
      listIsLoading: true,
    });
  case 'RETRIEVE_ACCOUNT_PAYMENTS_REQUEST_SUCCESS':
    return state.merge(fromJS({
      list: action.payments,
      meta: action.meta,
      listIsLoading: false,
    }));
  case 'RETRIEVE_ACCOUNT_PAYMENTS_REQUEST_FAILURE':
    return state.merge({
      listIsLoading: false,
      error: action.error,
    });

  case 'RETRIEVE_ACCOUNT_PAYMENT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_ACCOUNT_PAYMENT_REQUEST_SUCCESS':
    return state.merge(fromJS({
      payment: action.payment,
      isLoading: false,
    }));
  case 'RETRIEVE_ACCOUNT_PAYMENT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'CREATE_ACCOUNT_PAYMENT_REQUEST_LOADING':
    return state.merge({
      paymentIsProcessing: true,
    });
  case 'CREATE_ACCOUNT_PAYMENT_REQUEST_SUCCESS':
    return state.merge(fromJS({
      paymentIsProcessing: false,
      paymentMade: action.payment,
    }));
  case 'CREATE_ACCOUNT_PAYMENT_REQUEST_FAILURE':
    return state.merge({
      paymentIsProcessing: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default AccountPayments;
