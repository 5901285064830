import React from 'react';
import PropTypes from 'prop-types';

const NewTabIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" stroke="currentColor" strokeWidth="2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 1 1 15 15 15 15 11M6.5 9.5L15 1M8.5 1L15 1 15 7.5" fill="none" />
  </svg>
);

NewTabIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default NewTabIcon;
