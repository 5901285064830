import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: [],
  isLoading: true,
});

const RequestInvites = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('RequestInvites').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('RequestInvites') : state)
      );
    } else {
      return state;
    }

  case 'RETRIEVE_REQUEST_INVITES_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });

  case 'RETRIEVE_REQUEST_INVITES_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.invites),
      isLoading: false,
    });

  case 'RETRIEVE_REQUEST_INVITES_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default RequestInvites;
