import { format } from 'date-fns';
import { fromJS, Map } from 'immutable';
import GoogleMapsStatic from '@utilities/static/GoogleMapsStatic';
import StringUtility from '@utilities/StringUtility';

const citySearchLinkTemplate = '/s/[stateSlug]/[citySlug]';
const tagSearchLinkTemplate = '/s/[stateSlug]/[citySlug]/[tagSlug]';

const buildSearchLink = (state, city, tag = '', path = 's') => {
  const stateSlug = StringUtility.formatUrlSlug(state);
  const citySlug = StringUtility.formatUrlSlug(city);
  const tagSlug = StringUtility.formatUrlSlug(tag);

  if (stateSlug && citySlug && tagSlug) return `/${path}/${stateSlug}/${citySlug}/${tagSlug}`;
  if (stateSlug && citySlug) return `/${path}/${stateSlug}/${citySlug}`;
  if (stateSlug) return `/${path}/${stateSlug}`;
};

const buildSearchLinkTemplate = (state, city, tag = '', path = 's') => {
  const stateSlug = StringUtility.formatUrlSlug(state);
  const citySlug = StringUtility.formatUrlSlug(city);
  const tagSlug = StringUtility.formatUrlSlug(tag);

  if (stateSlug && citySlug && tagSlug) return `/${path}/[stateSlug]/[citySlug]/[tagSlug]`;
  if (stateSlug && citySlug) return `/${path}/[stateSlug]/[citySlug]`;
  if (stateSlug) return `/${path}/[stateSlug]`;
};

const buildBreadcrumbSchema = (city, state, country) => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": country,
      "item": `${process.env.ESPRESSO_BASE}${buildSearchLink(state)}#country`,
    },{
      "@type": "ListItem",
      "position": 2,
      "name": state,
      "item": `${process.env.ESPRESSO_BASE}${buildSearchLink(state)}`,
    },{
      "@type": "ListItem",
      "position": 3,
      "name": city,
      "item": `${process.env.ESPRESSO_BASE}${buildSearchLink(state, city)}`,
    }],
  };
};

const buildPageMeta = (filters) => {
  let url = `${process.env.ESPRESSO_BASE}${buildSearchLink(filters.get('state'), filters.get('city'))}`;
  return {
    metaTitle: `${filters.get('city')} Monthly Rentals - Kopa`,
    metaDescription: `${format(new Date(), 'MMM dd, yyyy')} - Rent from verified hosts in ${filters.get('city')}, ${filters.get('state')}. Search, book, and pay for furnished rentals, sublets, and rooms for rent.`,
    metaCanonical: url,
    metaUrl: url,
    metaSchema: buildBreadcrumbSchema(
      filters.get('city'),
      filters.get('state'),
      'United States'
    ),
  };
};

const mapConfig = () => {
  return {
    fullscreenControl: false,
    mapTypeControl: false,
    maxZoom: 17,
    panControl: false,
    rotateControl: false,
    scrollwheel: true,
    scaleControl: false,
    streetViewControl: false,
    styles: GoogleMapsStatic.mapStyles,
    zoomControlOptions: {
      position: 9,
    },
  };
};

// This method checks if the currently applied filters match any of the
// existing already saved searches
const hasExistingSavedSearch = (filters, savedSearches) => {
  let whitelistedKeys = {
    city: 'city',
    start: 'start',
    end: 'end',
    bathrooms: 'bathrooms',
    beds: 'beds',
    bedrooms: 'bedrooms',
    bedrooms_exact: 'bedroomsExact',
    bedroomsExact: 'bedroomsExact',
    beds_exact: 'bedsExact',
    bedsExact: 'bedsExact',
    bathrooms_exact: 'bathroomsExact',
    bathroomsExact: 'bathroomsExact',
    accessibility: 'accessibility',
    facilities: 'facilities',
    amenities: 'amenities',
    rules: 'rules',
    pets: 'rules',
    rental_type: 'rentalType',
    rentalType: 'rentalType',
    price_max: 'priceMax',
    priceMax: 'priceMax',
    furnishings: 'furnishings',
  };

  let cleanFilters = Map({});
  filters.mapKeys((k, v) => {
    if (whitelistedKeys[k]) {
      cleanFilters = cleanFilters.set(whitelistedKeys[k], fromJS(whitelistedKeys[k] === 'priceMax' ? +v : v));
    }
  });

  const match = savedSearches.find(ss => {
    let cleanSavedSearch = fromJS({});
    ss.mapKeys((k, v) => {
      if (whitelistedKeys[k]) {
        cleanSavedSearch = cleanSavedSearch.set(whitelistedKeys[k], fromJS(whitelistedKeys[k] === 'priceMax' ? +v : v));
      }
    });
    return cleanSavedSearch.equals(cleanFilters);
  });

  return !!match;
};

const isSaveSearchDisabled = (filters, savedSearches) => {
  return hasExistingSavedSearch(filters, savedSearches) ||
    filters.get('searchPageType') === 'state' ||
    !filters.get('start') ||
    !filters.get('end');
};

export default {
  buildSearchLink,
  buildSearchLinkTemplate,
  buildPageMeta,
  citySearchLinkTemplate,
  isSaveSearchDisabled,
  mapConfig,
  tagSearchLinkTemplate,
};
