import React from 'react';
import PropTypes from 'prop-types';

const FlatIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <polygon points="3 23 21 23 21 1 3 1" strokeLinecap="round" />
      <rect height="5" width="2" x="15" y="17" />
      <rect height="4" width="5" x="6.5" y="16" />
      <rect height="4" width="5" x="6.5" y="5" />
      <rect height="1" width="1" x="16" y="6" />
      <line x1="3" x2="21" y1="12.5" y2="12.5" />
    </g>
  </svg>
);

FlatIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default FlatIcon;
