import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: {},
  error: null,
  isLoading: true,
});

const ListingReviews = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('ListingReviews').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('ListingReviews') : state)
      );
    } else {
      return state;
    }

  case 'RETRIEVE_LISTING_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_LISTING_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      object: state.get('object').merge(fromJS(action.review)),
    });
  case 'RETRIEVE_LISTING_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'CHECK_LISTING_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CHECK_LISTING_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      object: state.get('object').merge(fromJS(action.review)),
    });
  case 'CHECK_LISTING_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'CREATE_LISTING_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CREATE_LISTING_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      object: state.get('object').merge(fromJS(action.review)),
    });
  case 'CREATE_LISTING_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'UPDATE_LISTING_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'UPDATE_LISTING_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      object: state.get('object').merge(fromJS(action.review)),
    });
  case 'UPDATE_LISTING_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default ListingReviews;
