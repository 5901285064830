import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  filters: {
    // Address
    city: '',
    state: '',
    citySearchText: '',
    commuteAddress: '',
    commuteAddressSearchText: '',
    coords: null,
    zoom: 11,
    // Dates
    start: null,
    end: null,
    // Strings
    // Beds/Bedrooms/Bathrooms
    beds: 1,
    bedsExact: null,
    bedrooms: 1,
    bedroomsExact: null,
    bathrooms: 1,
    bathroomsExact: null,
    // Budget
    priceMax: null,
    // Rental type
    furnishedRentalSelection: '',
    rentalType: [],
    rentalTypeSelection: '',
    // Amenities/Facilities/Accessibility
    amenities: [],
    facilities: [],
    accessibility: [],
    pets: [],
    minimumWifiSpeed: null,
    // Pets
    noPetsSelected: false,
    // User information
    needsHousemates: null,
    searchSaved: false,
  },
});

const RecommendationsSurvey = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('RecommendationsSurvey').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('RecommendationsSurvey') : state)
      );
    } else {
      return state;
    }

  case 'UPDATE_FILTERS':
    return state.merge({
      filters: state.get('filters').merge(
        fromJS(action.filters)
      ),
    });
  case 'UPDATE_RECOMMENDATIONS_SURVEY_FILTERS':
    return state.merge({
      filters: state.get('filters').merge(
        fromJS(action.filters)
      ),
    });
  case 'CLEAR_RECOMMENDATIONS_SURVEY_FILTERS':
    return initialState;
  default:
    return state;
  }
};

export default RecommendationsSurvey;
