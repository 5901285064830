import * as Sentry from '@sentry/node';

const captureExceptionWithContext = (data, error) => {
  try {
    Sentry.configureScope(scope => {
      scope.setExtras({ data, error });
      Sentry.captureException(error);
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

const captureMessageWithContext = (msg, data, error) => {
  try {
    Sentry.configureScope(scope => {
      scope.setExtras({ data, error });
      Sentry.captureMessage(msg);
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export default {
  captureException: Sentry.captureException,
  captureMessage: Sentry.captureMessage,
  configureScope: Sentry.configureScope,
  captureExceptionWithContext,
  captureMessageWithContext,
};
