import React from 'react';
import PropTypes from 'prop-types';

const HairDryerIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2" transform="translate(2 8)">
      <path d="m9.3939 20c8.4545 0 21.606-6 21.606-6v-8s-13.152-6-21.606-6c-5.1881 0-9.3939 4.4772-9.3939 10s4.2058 10 9.3939 10z" />
      <rect height="7" rx="3.5" width="7" x="6" y="6" />
      <path d="m8 20 2.755 10.331c0.8914 3.3427 3.9187 5.6686 7.3783 5.6686 2.3793 0 4.3081-1.9288 4.3081-4.3081 0-0.4374-0.06661-0.87224-0.19754-1.2896l-3.5772-11.402" />
    </g>
    <path d="m36 17 2.5-2.5c0.82843-0.82843 2.1716-0.82843 3 0s2.1716 0.82843 3 0l2.5-2.5" stroke="currentColor" strokeLinejoin="round" transform="translate(41.5 14.5) rotate(19) translate(-41.5 -14.5)" />
    <path d="m36 23 2.5-2.5c0.82843-0.82843 2.1716-0.82843 3 0s2.1716 0.82843 3 0l2.5-2.5" stroke="currentColor" strokeLinejoin="round" transform="translate(41.5 20.5) rotate(19) translate(-41.5 -20.5)" />
  </svg>
);

HairDryerIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default HairDryerIcon;
