import React from 'react';
import PropTypes from 'prop-types';

const ArrowForwardIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(7 8) rotate(-90) translate(-6.5 -7.5)">
      <polyline points="0.5 9.0226 6.4887 15 12.5 9" />
      <line x1="6.5" x2="6.5" y1="14" y2=".5" />
    </g>
  </svg>
);

ArrowForwardIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ArrowForwardIcon;
