import React from 'react';
import PropTypes from 'prop-types';

const UploadIcon = (props) => (
  <svg aria-hidden="true" className={props.className} strokeWidth="2" viewBox="0 0 41 30" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" transform="translate(1 1)">
      <path d="M15.3331239,21 L5.1370309,21 C2.30007997,21 0,18.6660384 0,15.7859028 C0,12.9057671 2.30007997,10.5706747 5.1370309,10.5706747 C5.7607814,10.5706747 6.35557206,10.6894082 6.91026447,10.8963438 C6.9058091,10.7640407 6.89021534,10.6339992 6.89021534,10.5005654 C6.89021534,4.70184697 11.520449,0 17.2333352,0 C21.9682698,0 25.9513623,3.23520543 27.1788142,7.6453072 C28.4185183,6.67395401 29.9689838,6.08933283 31.6564517,6.08933283 C35.7119438,6.08933283 39,9.42744063 39,13.5446664 C39,17.663023 35.7119438,21 31.6564517,21 L23.4953447,21" />
      <path d="M19,28 L19,11" strokeLinecap="round" strokeLinejoin="round" />
      <polyline points="14 15 19.001 10 24 14.999" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);

UploadIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default UploadIcon;
