import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: {},
  isLoading: true,
});

const OccupancyView = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('OccupancyView').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('OccupancyView') : state)
      );
    } else {
      return state;
    }
  case 'RETRIEVE_OCCUPANCY_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });

  case 'RETRIEVE_OCCUPANCY_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.occupancy),
      isLoading: false,
    });

  case 'RETRIEVE_OCCUPANCY_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default OccupancyView;
