export default {
  acceptBookings: `${process.env.STATIC_IMAGES_URL}/general/accept-bookings.jpg`,
  beautifulFurnished: `${process.env.STATIC_IMAGES_URL}/general/beautiful-furnished-room-for-rent`,
  beaverOnLogs: `${process.env.STATIC_IMAGES_URL}/graphics/beaver-on-logs-2x.png`,
  bedPlaceholder: `${process.env.STATIC_IMAGES_URL}/general/bed-placeholder.png`,
  bedroomCalendar: `${process.env.STATIC_IMAGES_URL}/general/bedroom-calendar.jpg`,
  bedrooms: `${process.env.STATIC_IMAGES_URL}/graphics/bedrooms.png`,
  bookOnMobile: `${process.env.STATIC_IMAGES_URL}/graphics/mobile-phone-request-to-rent`,
  browseRentals: `${process.env.STATIC_IMAGES_URL}/graphics/house-with-person@3x.png`,
  calendar: `${process.env.STATIC_IMAGES_URL}/graphics/calendar.png`,
  camera: `${process.env.STATIC_IMAGES_URL}/graphics/camera.png`,
  cityFilters: `${process.env.STATIC_IMAGES_URL}/graphics/city-with-filters`,
  cityLandingPageDefaultMetaThumbnail: `${process.env.STATIC_IMAGES_URL}/cities/default-thumbnail-1200x630.jpg`,
  commute: `${process.env.STATIC_IMAGES_URL}/graphics/bike@2x.png`,
  compatibleHousemates: `${process.env.STATIC_IMAGES_URL}/graphics/housemates-high-fiving@3x.png`,
  contentCurators: `${process.env.STATIC_IMAGES_URL}/graphics/web-content`,
  corporatePartnerships: `${process.env.STATIC_IMAGES_URL}/landing-pages/corporate-partnerships`,
  courtney: `${process.env.STATIC_IMAGES_URL}/general/courtney`,
  creditCards: {
    Visa: `${process.env.STATIC_IMAGES_URL}/payment/Visa.png`,
    MasterCard: `${process.env.STATIC_IMAGES_URL}/payment/Mastercard.png`,
    'American Express': `${process.env.STATIC_IMAGES_URL}/payment/AMEX.png`,
    UnionPay: `${process.env.STATIC_IMAGES_URL}/payment/UnionPay.png`,
    'Diners Club': `${process.env.STATIC_IMAGES_URL}/payment/DinersClub.png`,
    Discover: `${process.env.STATIC_IMAGES_URL}/payment/Discover.png`,
    JCB: `${process.env.STATIC_IMAGES_URL}/payment/JCB.png`,
  },
  crosby: `${process.env.STATIC_IMAGES_URL}/general/crosby.png`,
  custom: `${process.env.STATIC_IMAGES_URL}/graphics/university-building`,
  customerSuccessRep: `${process.env.STATIC_IMAGES_URL}/landing-pages/customer-success-rep`,
  danielHeadshot: `${process.env.STATIC_IMAGES_URL}/general/daniel-headshot.jpg`,
  defaultBankLogo: `${process.env.STATIC_IMAGES_URL}/general/default-bank-logo.jpg`,
  digitalNomadWorkingRemotely: `${process.env.STATIC_IMAGES_URL}/landing-pages/digital-nomad-working-remotely@3x.jpg`,
  doctorsWithPatient: `${process.env.STATIC_IMAGES_URL}/landing-pages/doctors-with-patient`,
  doorPink: `${process.env.STATIC_IMAGES_URL}/general/door-pink`,
  doorsOfKopa: `${process.env.STATIC_IMAGES_URL}/landing-pages/may-doors-of-kopa@3x.png`,
  envelopeHeart: `${process.env.STATIC_IMAGES_URL}/graphics/letter`,
  explorerCheckingMap: `${process.env.STATIC_IMAGES_URL}/landing-pages/explorer-checking-map.gif`,
  freeDinner: `${process.env.STATIC_IMAGES_URL}/graphics/free-dinner`,
  fulltime: `${process.env.STATIC_IMAGES_URL}/graphics/fulltime`,
  housemateSign: `${process.env.STATIC_IMAGES_URL}/graphics/housemate-holding-housemate-post`,
  headshotDefaultCompany: `${process.env.STATIC_IMAGES_URL}/general/headshot-default-company.jpg`,
  headshotDefaultUser: `${process.env.STATIC_IMAGES_URL}/general/headshot-default-user@3x.png`,
  headshotDefaultUserSketch: `${process.env.STATIC_IMAGES_URL}/general/headshot-default-user-sketch@3x.png`,
  heart: `${process.env.STATIC_IMAGES_URL}/general/heart.png`,
  highFives: `${process.env.STATIC_IMAGES_URL}/graphics/hands-in`,
  homes: `${process.env.STATIC_IMAGES_URL}/graphics/homes`,
  hostGreet: `${process.env.STATIC_IMAGES_URL}/general/host-greeting-tenant`,
  hostWelcome: `${process.env.STATIC_IMAGES_URL}/general/host-welcome-tenant`,
  hostWelcomeBackground: `${process.env.STATIC_IMAGES_URL}/general/host-welcoming-renter`,
  home: `${process.env.STATIC_IMAGES_URL}/general/home`,
  house: `${process.env.STATIC_IMAGES_URL}/graphics/house.png`,
  housemateMessages: `${process.env.STATIC_IMAGES_URL}/graphics/3-messages`,
  housingSearch: `${process.env.STATIC_IMAGES_URL}/graphics/confused-coop-student`,
  influencers: `${process.env.STATIC_IMAGES_URL}/graphics/social-likes`,
  instagramLogo: `${process.env.STATIC_IMAGES_URL}/logos/instagram-logo.png`,
  joannaHeadshot: `${process.env.STATIC_IMAGES_URL}/general/joanna-headshot.jpg`,
  kendrickHeadshot: `${process.env.STATIC_IMAGES_URL}/general/kendrick-headshot.jpg`,
  kopaCustomWebsiteDesktop: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-custom-website-desktop`,
  kopaCustomWebsiteMobileFull: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-custom-website-mobile-full`,
  kopaCustomWebsiteResponsive: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-custom-website-mobile-friendly`,
  kopaHelpCenterOnPhone: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-help-center-on-phone`,
  kopaHostReviews: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-website-reviews`,
  kopaInbox: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-website-builder-inbox`,
  kopaMultiCalendar: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-website-multi-calendar`,
  kopaRequestsAndStays: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-website-requests-and-stays`,
  kopaTransactions: `${process.env.STATIC_IMAGES_URL}/landing-pages/kopa-website-transactions`,
  lease: `${process.env.STATIC_IMAGES_URL}/general/lease.jpg`,
  lighthouse: `${process.env.STATIC_IMAGES_URL}/general/lighthouse.gif`,
  listing: `${process.env.STATIC_IMAGES_URL}/graphics/listing`,
  listingPlaceholder: `${process.env.STATIC_IMAGES_URL}/general/listing-placeholder@3x.png`,
  manWithMask: `${process.env.STATIC_IMAGES_URL}/landing-pages/man-with-mask`,
  mediumTermKopaHost: `${process.env.STATIC_IMAGES_URL}/landing-pages/medium-term-kopa-host@3x.jpg`,
  message: `${process.env.STATIC_IMAGES_URL}/graphics/message.png`,
  michaelScottDancing: `${process.env.STATIC_IMAGES_URL}/landing-pages/michael-scott-dancing.gif`,
  mobileRent: `${process.env.STATIC_IMAGES_URL}/graphics/mobile-rent`,
  mobileSupport: `${process.env.STATIC_IMAGES_URL}/graphics/on-demand-support`,
  neighborhood: `${process.env.STATIC_IMAGES_URL}/graphics/neighborhood.png`,
  newMail: `${process.env.STATIC_IMAGES_URL}/general/new-mail-2x.png`,
  nextjs: `${process.env.STATIC_IMAGES_URL}/general/nextjs-logo.png`,
  nodejs: `${process.env.STATIC_IMAGES_URL}/general/nodejs.png`,
  paymentHistory: `${process.env.STATIC_IMAGES_URL}/graphics/payment-history`,
  pencilPaper: `${process.env.STATIC_IMAGES_URL}/graphics/pencil-paper.png`,
  piggyBank: `${process.env.STATIC_IMAGES_URL}/graphics/piggy-bank`,
  pots: `${process.env.STATIC_IMAGES_URL}/graphics/pots.png`,
  premiumSupport: `${process.env.STATIC_IMAGES_URL}/graphics/premium-support`,
  renterFillingOutRentalApplication: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-filling-out-rental-application`,
  ramen: `${process.env.STATIC_IMAGES_URL}/graphics/ramen`,
  react: `${process.env.STATIC_IMAGES_URL}/general/react.png`,
  recommendations: `${process.env.STATIC_IMAGES_URL}/graphics/recommendations`,
  referralLink: `${process.env.STATIC_IMAGES_URL}/graphics/referral-program`,
  relocation: `${process.env.STATIC_IMAGES_URL}/graphics/relocation`,
  rentalAmenities: `${process.env.STATIC_IMAGES_URL}/graphics/bedroom-furniture@3x.png`,
  rentEntireSpace: `${process.env.STATIC_IMAGES_URL}/graphics/request-to-rent`,
  renter1: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-1`,
  renter2: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-2`,
  renter3: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-3`,
  renter4: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-4`,
  renter5: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-5`,
  renter6: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-6`,
  renter7: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-7`,
  renter8: `${process.env.STATIC_IMAGES_URL}/landing-pages/renter-8`,
  review: `${process.env.STATIC_IMAGES_URL}/graphics/review-2x.png`,
  rocketship: `${process.env.STATIC_IMAGES_URL}/graphics/rocketship`,
  roommatesInApartment: `${process.env.STATIC_IMAGES_URL}/landing-pages/roommates-in-apartment`,
  roommatesSmiling: `${process.env.STATIC_IMAGES_URL}/landing-pages/roommates-sharing-photos-at-home`,
  secure: `${process.env.STATIC_IMAGES_URL}/graphics/secure`,
  signLease: `${process.env.STATIC_IMAGES_URL}/graphics/lease`,
  simple: `${process.env.STATIC_IMAGES_URL}/graphics/simple-booking`,
  simpleOrange: `${process.env.STATIC_IMAGES_URL}/graphics/simple-booking-orange`,
  studentHousing: `${process.env.STATIC_IMAGES_URL}/general/amazing-student-housing`,
  suitcase: `${process.env.STATIC_IMAGES_URL}/graphics/suitcase`,
  support: `${process.env.STATIC_IMAGES_URL}/graphics/customer-support`,
  tenantHeadshots: `${process.env.STATIC_IMAGES_URL}/general/tenant-headshots.jpg`,
  tenants: `${process.env.STATIC_IMAGES_URL}/graphics/tenants`,
  thumbsUpKid: `${process.env.STATIC_IMAGES_URL}/landing-pages/kid-giving-thumbs-up.gif`,
  verified: `${process.env.STATIC_IMAGES_URL}/graphics/verified`,
  verifiedUsers: `${process.env.STATIC_IMAGES_URL}/graphics/verification@3x.png`,
  womanHoldingBaby: `${process.env.STATIC_IMAGES_URL}/landing-pages/woman-holding-baby`,
  womanLivingRoom: `${process.env.STATIC_IMAGES_URL}/general/woman-enjoying-living-room`,
  womanWritingInBook: `${process.env.STATIC_IMAGES_URL}/landing-pages/woman-writing-in-book`,
  ycBadge: `${process.env.STATIC_IMAGES_URL}/graphics/yc-badge`,
};
