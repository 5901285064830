import React from 'react';
import PropTypes from 'prop-types';

const GuestHouseIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <polyline points="21 6.5 21 23.5 3 23.5 3 6.5" />
      <polygon points="9 14.029 15 14.029 15 23.5 9 23.5" />
      <polyline points="23.5 7.5 11.865 1 0.5 7.5" />
    </g>
  </svg>
);

GuestHouseIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default GuestHouseIcon;
