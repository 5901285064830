import { differenceInCalendarDays, isBefore, isAfter, isSameSecond, isSameDay, isSameMonth, parseISO, format, subDays } from 'date-fns';
import GeneralStatic from '@utilities/static/GeneralStatic';

const formatLongSentDate = (sentAt) => {
  let now = Date.now();
  let date = new Date(sentAt).getTime();

  // Over a year old
  if (now - date > 31556952000)
    return format(date, 'MMM d, yyyy');

  // Over a week old
  if (now - date > 604800000)
    return format(date, 'MMM d');

  // Today
  let d = new Date();
  d.setHours(0,0,0,0);
  if (date > +d)
    return `Today at ${format(date, 'h:mm aaaa')}`;

  // Over a day old
  return format(date, 'EEEE') + ' at ' + format(date, 'h:mm aaaa');
};

const formatShortSentDate = (sentAt) => {
  let now = Date.now();
  let date = new Date(sentAt).getTime();

  // Over a year old
  if (now - date > 31556952000)
    return format(date, 'MMM d, yyyy');

  // Today
  let d = new Date();
  d.setHours(0,0,0,0);
  if (date > +d)
    return format(date, 'h:mm aaaa');

  d = subDays(d, 1);
  if (date > +d)
    return 'Yesterday';

  return format(date, 'MMM d');
};

const formatResponseDuration = (duration) => {
  let minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor(duration / (1000 * 60 * 60 * 24));

  if (days >= 4)
    return '4+ days';

  if (days >= 2)
    return `${days} days`;

  if (hours < 2)
    return `${minutes} minutes`;

  return `${hours} hours`;
};

const formatDateOnly = (date) => {
  if (!date) {
    return '';
  }

  return format(date, 'yyyy-MM-dd');
};

const getIsSameFunction = (sameType) => {
  let func;

  switch (sameType) {
  case 'second':
    func = isSameSecond;
    break;
  case 'day':
    func = isSameDay;
    break;
  case 'month':
    func = isSameMonth;
    break;
  default:
    func = isSameDay;
  }

  return func;
};

const isBetween = (day, ra, rb, sameType)  => {
  return sameType
    ? sameOrBefore(sameType, day, rb) && sameOrAfter(sameType, day, ra)
    : isBefore(day, rb) && isAfter(day, ra);
};

const isShortTermRental = (moveIn, moveOut)  => {
  return differenceInCalendarDays(parseISODate(moveOut), parseISODate(moveIn)) < GeneralStatic.minimumDaysForNonShortTermRental;
};

const parseISODate = (date) => {
  if (date) {
    return parseISO(date);
  } else {
    return null;
  }
};

const sameOrBefore = (sameType, d1, d2) => {
  return getIsSameFunction(sameType)(d1, d2) ? true : (isBefore(d1, d2) ? true : false);
};

const sameOrAfter = (sameType, d1, d2) => {
  return getIsSameFunction(sameType)(d1, d2) ? true : (isAfter(d1, d2) ? true : false);
};

export default {
  formatDateOnly,
  formatLongSentDate,
  formatShortSentDate,
  formatResponseDuration,
  parseISODate,
  isBetween,
  isShortTermRental,
  sameOrBefore,
  sameOrAfter,
};
