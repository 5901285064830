import ConversationConstants from '@domain/Account/Inbox/ConversationConstants';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ===============================
//   APPEND MESSAGES TO CURRENT CONVERSATION
// ===============================
const appendMessagesToCurrentConversationSuccess = (messages) => ({
  type: 'APPEND_MESSAGES_TO_CURRENT_CONVERSATION',
  messages,
});

const appendMessagesToCurrentConversation = (messages) => (dispatch) => {
  return dispatch(appendMessagesToCurrentConversationSuccess(messages));
};

// ===============================
//   PREPEND MESSAGES TO CURRENT CONVERSATION
// ===============================
const prependMessagesToCurrentConversationSuccess = (messages) => ({
  type: 'PREPEND_MESSAGES_TO_CURRENT_CONVERSATION',
  messages,
});

const prependMessagesToCurrentConversation = (messages) => (dispatch) => {
  return dispatch(prependMessagesToCurrentConversationSuccess(messages));
};

// ===============================
//   UPDATE HAS MORE SEND BIRD MESSAGES
// ===============================
const updateHasMoreSendBirdMessagesSuccess = (hasMoreSendBirdMessages) => ({
  type: 'UPDATE_HAS_MORE_SEND_BIRD_MESSAGES',
  hasMoreSendBirdMessages,
});

const updateHasMoreSendBirdMessages = (hasMoreSendBirdMessages) => (dispatch) => {
  return dispatch(updateHasMoreSendBirdMessagesSuccess(hasMoreSendBirdMessages));
};

// ===============================
//   UPDATE HAS MORE KOPA MESSAGES
// ===============================
const updateHasMoreKopaMessagesSuccess = (hasMoreKopaMessages) => ({
  type: 'UPDATE_HAS_MORE_KOPA_MESSAGES',
  hasMoreKopaMessages,
});

const updateHasMoreKopaMessages = (hasMoreKopaMessages) => (dispatch) => {
  return dispatch(updateHasMoreKopaMessagesSuccess(hasMoreKopaMessages));
};

// ===============================
//   UPDATE HAS MORE CONVERSATIONS
// ===============================
const updateHasMoreConversationsSuccess = (hasMoreConversations) => ({
  type: 'UPDATE_HAS_MORE_CONVERSATIONS',
  hasMoreConversations,
});

const updateHasMoreConversations = (hasMoreConversations) => (dispatch) => {
  return dispatch(updateHasMoreConversationsSuccess(hasMoreConversations));
};

// ===============================
//   UPDATE CONVERSATION LIST LOADING
// ===============================
const updateConversationsLoadingSuccess = (isLoading) => ({
  type: 'UPDATE_CONVERSATIONS_LOADING',
  isLoading,
});

const updateConversationsLoading = (isLoading) => (dispatch) => {
  return dispatch(updateConversationsLoadingSuccess(isLoading));
};

// ===============================
//   UPDATE CONVERSATION LIST
// ===============================
const updateConversationListSuccess = (conversations, initial) => ({
  type: 'UPDATE_CONVERSATION_LIST',
  conversations,
  initial,
});

const updateConversationList = (conversations, initial = false) => (dispatch) => {
  return dispatch(updateConversationListSuccess(conversations, initial));
};

// ===============================
//   SET CURRENT CONVERSATION
// ===============================
const setCurrentConversationSuccess = (conversation) => ({
  type: 'SET_CURRENT_CONVERSATION',
  conversation,
});

const setCurrentConversation = (conversation) => async (dispatch) => {
  await dispatch(updateConversationInListSuccess(conversation));
  return dispatch(setCurrentConversationSuccess(conversation));
};

// ===============================
//   UPDATE CURRENT CONVERSATION
// ===============================
const updateCurrentConversationSuccess = (conversation) => ({
  type: 'UPDATE_CURRENT_CONVERSATION',
  conversation,
});

const updateCurrentConversation = (conversation) => async (dispatch) => {
  await dispatch(updateConversationInListSuccess(conversation));
  return dispatch(updateCurrentConversationSuccess(conversation));
};

// ===============================
//   UPDATE CONVERSATION IN LIST
// ===============================
const updateConversationInListSuccess = (conversation) => ({
  type: 'UPDATE_CONVERSATION_IN_LIST',
  conversation,
});

const updateConversationInList = (conversation) => (dispatch) => {
  return dispatch(updateConversationInListSuccess(conversation));
};

// ===============================
//   ADD CONVERSATION TO LIST
// ===============================
const addConversationToListSuccess = (conversation) => ({
  type: 'ADD_CONVERSATION_TO_LIST',
  conversation,
});

const addConversationToList = (conversation) => (dispatch) => {
  return dispatch(addConversationToListSuccess(conversation));
};

// ===============================
//   UPDATE CURRENT CONVERSATION LOADING
// ===============================
const updateCurrentConversationLoadingSuccess = (isLoading) => ({
  type: 'UPDATE_CURRENT_CONVERSATION_LOADING',
  isLoading,
});

const updateCurrentConversationLoading = (isLoading) => (dispatch) => {
  return dispatch(updateCurrentConversationLoadingSuccess(isLoading));
};

// =================================
//   FETCH MESSAGES REQUEST
// ===================================
const fetchConversationMessagesRequest = (conversationId, afterTimestamp, returnMessages = false) => async (dispatch) => {
  // Note there is no isLoading dispatches because it is done in the component page but infinite scroll component
  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/conversations/${conversationId}/messages?afterTimestamp=${afterTimestamp}&limit=${ConversationConstants.NUMBER_OF_MESSAGES_TO_LOAD}`,
      method: 'GET',
    });

    if (response.length < ConversationConstants.NUMBER_OF_MESSAGES_TO_LOAD) {
      dispatch(updateHasMoreKopaMessages(false));
    }

    if (returnMessages) {
      return response.reverse();
    } else {
      await dispatch(appendMessagesToCurrentConversationSuccess(
        response.reverse()
      ));
    }

  } catch (error) {
    ErrorHandler.generalError(error, 'An error occurred while retrieving the messages. Please reload the page to try again.', 'error-retrieving-messages');
  }
};

export default {
  updateHasMoreSendBirdMessages,
  updateHasMoreKopaMessages,
  updateHasMoreConversations,
  updateConversationList,
  updateConversationsLoading,
  updateCurrentConversation,
  setCurrentConversation,
  updateConversationInList,
  addConversationToList,
  updateCurrentConversationLoading,
  appendMessagesToCurrentConversation,
  prependMessagesToCurrentConversation,
  fetchConversationMessagesRequest,
};
