import qs from 'query-string';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// =============================
//   RETRIEVE ACCOUNT REQUESTS
// =============================
const retrieveAccountRequestsRequestLoading = () => ({
  type: 'RETRIEVE_ACCOUNT_REQUESTS_REQUEST_LOADING',
});

const retrieveAccountRequestsRequestSuccess = (data, queryIncluded) => ({
  type: 'RETRIEVE_ACCOUNT_REQUESTS_REQUEST_SUCCESS',
  ...data,
  queryIncluded,
});

const retrieveAccountRequestsRequestFailure = () => ({
  type: 'RETRIEVE_ACCOUNT_REQUESTS_REQUEST_FAILURE',
});

const retrieveAccountRequestsRequest = (userId, query = {}) => async (dispatch) => {
  dispatch(retrieveAccountRequestsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/requests?${qs.stringify(query)}`,
      method: 'GET',
    });
    const queryIncluded = query.search && query.search.length > 0;
    dispatch(retrieveAccountRequestsRequestSuccess(response, queryIncluded));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveAccountRequestsRequestFailure
    );
  }
};

export default {
  retrieveAccountRequestsRequest,
};
