import React from 'react';
import PropTypes from 'prop-types';

const WheelchairIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="transparent" stroke="currentColor" strokeWidth="2" transform="translate(4 2)">
      <polyline points="12 10 13.352 25.854 29.023 25.854 35.946 40.886 41.102 38.768" />
      <circle cx="12" cy="5" r="5" />
      <path d="m22.82 42.333c2.8945-2.762 4.6979-6.6575 4.6979-10.974 0-8.3763-6.7903-15.167-15.167-15.167-5.0937 0-9.6009 2.511-12.351 6.3628" transform="translate(13.759 29.263) rotate(180) translate(-13.759 -29.263)" />
      <line x1="12" x2="22.987" y1="18" y2="17.927" />
    </g>
  </svg>
);

WheelchairIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default WheelchairIcon;
