import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==================================
//   RETRIEVE SITEMAP
// ==================================
const retrieveSitemapRequestLoading = () => ({
  type: 'RETRIEVE_SITEMAP_REQUEST_LOADING',
});

const retrieveSitemapRequestSuccess = (sitemap) => ({
  type: 'RETRIEVE_SITEMAP_REQUEST_SUCCESS',
  sitemap,
});

const retrieveSitemapRequestFailure = () => ({
  type: 'RETRIEVE_SITEMAP_REQUEST_FAILURE',
});

const retrieveSitemapRequest = () => async (dispatch) => {
  dispatch(retrieveSitemapRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/sitemap`,
      method: 'GET',
    });
    dispatch(retrieveSitemapRequestSuccess(response.sitemap));

  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveSitemapRequestFailure
    );
    throw error;
  }
};

export default {
  retrieveSitemapRequest,
};
