import React from 'react';
import PropTypes from 'prop-types';

const PencilIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.67,6,18,.36a.51.51,0,0,0-.71,0L14.15,3.52l-.06,0,0,0L3.17,14.51h0l-.06.08a.41.41,0,0,0-.05.09h0L.22,23.19a.49.49,0,0,0,.12.51.5.5,0,0,0,.35.14.44.44,0,0,0,.16,0L9.34,21h0l.09-.05.08-.05h0L23.67,6.73A.5.5,0,0,0,23.67,6ZM14.46,4.63l4.95,5L9.18,19.81,4.23,14.86Zm-10.13,17a3.54,3.54,0,0,0-1.9-1.9l1.31-3.93L8.25,20.3Zm-2.22-.93a2.48,2.48,0,0,1,1.24,1.25l-1.87.62Zm18-11.81L15.16,3.92l2.5-2.5,4.95,5Z" />
  </svg>
);

PencilIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default PencilIcon;
