import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  stats: [],
  isLoading: true,
  error: null,
});

const AccountDashboard = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AccountDashboard').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AccountDashboard') : state)
      );
    } else {
      return state;
    }

    // Retrieve dashboard stats actions
  case 'RETRIEVE_DASHBOARD_STATS_REQUEST_LOADING':
    return state.merge(fromJS({
      isLoading: true,
    }));
  case 'RETRIEVE_DASHBOARD_STATS_REQUEST_SUCCESS':
    return state.merge(fromJS({
      stats: action.stats,
      isLoading: false,
    }));
  case 'RETRIEVE_DASHBOARD_STATS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default AccountDashboard;
