import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import Button from '@components/Core/Button';
import { CloseIcon } from '@components/Icons';

class Dialog extends Component {
  state = {
    mounted: false,
  };
  componentDidMount() {
    const { isOpen } = this.props;
    this.setState({
      mounted: true,
    });

    if (isOpen) {
      this.addEventListener();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props;
    if (nextProps.isOpen && !isOpen) {
      this.addEventListener();
    } else if (!nextProps.isOpen && isOpen) {
      this.removeEventListener();
    }
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  addEventListener () {
    // Make body overflow hidden when open so the background can't be scrolled
    document.body.style.overflow = 'hidden';

    const { canClose, onClose } = this.props;
    if (canClose && onClose) {
      document.addEventListener('keydown', this.handleKeyPress);
    }
  }

  removeEventListener () {
    // Reset the body overflow when closed so the background can be scrolled
    document.body.style.overflow = 'auto';

    const { canClose, onClose } = this.props;
    if (canClose && onClose) {
      document.removeEventListener('keydown', this.handleKeyPress);
    }
  }

  handleKeyPress = (event) => {
    const { canClose, onClose } = this.props;
    if (canClose && onClose && event.key === 'Escape') {
      onClose();
    }
  }

  render () {
    const { canClose, children, dialogId, onClose, isOpen, dialogClassName, wrapperClassName } = this.props;

    return (
      <Portal>
        { this.state.mounted && (
          <div className={`dialog ${isOpen ? 'dialog-open' : ''} ${wrapperClassName}`} id={dialogId}>
            { isOpen && (
              <div className={`dialog-content relative p-horizontal--medium p-horizontal--xx-large-gt-xs p-vertical--x-large p-vertical--xx-large-gt-xs ${dialogClassName}`}>
                { children }
                { canClose && onClose && (
                  <Button className="button-icon z--1 absolute a--top a--right m-around--medium" onClick={onClose}>
                    <CloseIcon className="icon--black icon--hover icon--18" />
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </Portal>
    );
  }
}

Dialog.propTypes = {
  // Required
  children: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dialogId: PropTypes.string.isRequired,
  // Optional
  canClose: PropTypes.bool,
  dialogClassName: PropTypes.string,
  onClose: PropTypes.func,
  wrapperClassName: PropTypes.string,
};

Dialog.defaultProps = {
  canClose: true,
  dialogClassName: '',
  onClose: null,
  wrapperClassName: '',
};

export default Dialog;
