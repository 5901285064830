import React from 'react';
import PropTypes from 'prop-types';

const Cigarette = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 13" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g stroke="currentColor" transform="translate(-526 -1238)">
        <g transform="translate(526 1234)">
          <polygon points="1 16 23 16 23 12 1 12" />
          <line strokeLinecap="round" strokeLinejoin="round" x1="8.2031" x2="8.2031" y1="12" y2="15.745" />
          <path d="m20.5 9c0.6665-0.69058 0.6665-1.8096 0-2.5002-0.66683-0.69025-0.66683-1.8099 0-2.4998" strokeLinejoin="round" />
          <path d="m17.5 9c0.66667-0.69058 0.66667-1.8096 0-2.5002-0.66667-0.69025-0.66667-1.8099 0-2.4998" strokeLinejoin="round" />
          <line strokeLinecap="round" strokeLinejoin="round" x1="19.203" x2="19.203" y1="12" y2="15.745" />
        </g>
      </g>
    </g>
  </svg>
);

Cigarette.propTypes = {
  // required
  className: PropTypes.string.isRequired,
};

export default Cigarette;
