import { fromJS, List } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';
import UnitEngine from '@utilities/UnitEngine';

const initialState = fromJS({
  object: {
    title: '',
    description: '',
    address: {},
    price: null,
    is_price_per_tenant: null,
    required_fee: null,
    security_deposit: null,
    currency: 'USD',
    property_type: null,
    ownership: null,
    bedroom_total: null,
    bedrooms_for_rent: null,
    bathrooms: null,
    amenities: [],
    wifi_speed: null,
    units: [],
    rules: [],
    guidelines: {},
    photos: [],
    video: null,
    rating: 0,
    verified: false,
    hidden: false,
    state: 'draft',
    accommodates: 0,
    sleeping_areas: null,
    furnishings: null,
  },
  bedrooms: [],
  beds: [],
  isLoading: false,
  error: null,
  validation: {
    errors: {},
    currentAllowedTab: 0,
    valid: true,
  },
  showValidation: false,
});

const ListingEdit = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('ListingEdit').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('ListingEdit') : state)
      );
    } else {
      return state;
    }

  case 'CREATE_LISTING_REQUEST_LOADING':
  case 'PUBLISH_LISTING_REQUEST_LOADING':
  case 'RETRIEVE_EDIT_LISTING_REQUEST_LOADING':
  case 'UPDATE_LISTING_REQUEST_LOADING':
  case 'UPDATE_LISTING_NEXT_AVAILABLE_DATE_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CREATE_LISTING_REQUEST_FAILURE':
  case 'PUBLISH_LISTING_REQUEST_FAILURE':
  case 'RETRIEVE_EDIT_LISTING_REQUEST_FAILURE':
  case 'UPDATE_LISTING_REQUEST_FAILURE':
  case 'UPDATE_LISTING_NEXT_AVAILABLE_DATE_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });
  case 'UPDATE_VALIDATION':
    return state.merge({
      validation: state.get('validation').merge(fromJS(action.validation)),
      showValidation: action.showValidation,
    });
  case 'UPDATE_BEDROOMS':
    return state.merge({
      bedrooms: fromJS(action.bedrooms),
      beds: fromJS(action.beds),
      object: state.get('object').set('accommodates', action.beds.size),
    });
  case 'UPDATE_LISTING':
    return state.merge({
      object: state.get('object').set(action.key, action.value),
    });
  case 'CREATE_LISTING_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.listing),
      isLoading: false,
    });
  case 'UPDATE_LISTING_REQUEST_SUCCESS': {
    const { beds, bedrooms } = UnitEngine.getBedrooms(fromJS(action.listing.units));
    return state.merge({
      beds: List(beds),
      bedrooms: List(bedrooms),
      object: fromJS(action.listing),
      isLoading: false,
    });
  }
  case 'UPDATE_LISTING_NEXT_AVAILABLE_DATE_REQUEST_SUCCESS': {
    return state.merge({
      object: fromJS(action.listing),
      isLoading: false,
    });
  }
  case 'RETRIEVE_EDIT_LISTING_REQUEST_SUCCESS': {
    const { beds: retrievedBeds, bedrooms: retrievedBedrooms } = UnitEngine.getBedrooms(fromJS(action.listing.units));
    return state.merge({
      beds: List(retrievedBeds),
      bedrooms: List(retrievedBedrooms),
      object: fromJS(action.listing),
      isLoading: false,
    });
  }
  case 'CLEAR_LISTING_EDIT':
    return initialState;
  case 'PUBLISH_LISTING_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').merge(fromJS(action.listing)),
      isLoading: false,
    });
  case 'CREATE_CALENDAR_IMPORT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set(
        'calendar_imports',
        state.get('object').get('calendar_imports').push(fromJS(action.calendarImport))
      ),
      isLoading: false,
    });
  case 'DELETE_CALENDAR_IMPORT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set(
        'calendar_imports',
        state.get('object').get('calendar_imports').filter((ci) => ci.get('id') !== action.calendarImportId)
      ),
      isLoading: false,
    });
  case 'UPDATE_CALENDAR_IMPORT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set(
        'calendar_imports',
        state.get('object').get('calendar_imports').map((ci) => ci.get('id') === action.calendarImport.id ? fromJS(action.calendarImport) : ci)
      ),
      isLoading: false,
    });
  default:
    return state;
  }
};

export default ListingEdit;
