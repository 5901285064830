import { fromJS, List } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';
import MessagesBatch from '@domain/Account/Inbox/MessagesBatch';

// Note SendBird objects (conversations and messages) do not use ImmutableJS
const initialState = fromJS({
  conversations: [],
  isLoading: true,
  hasMoreConversations: true,
  currentConversation: {
    conversation: null,
    isLoading: true,
    messages: [],
    hasMoreSendBirdMessages: true,
    hasMoreKopaMessages: true,
  },
});

// This function merges two conversation lists on conversation pagination, this is used to
// make sure none of the conversations coming in list2 aren't already in list1. This happens
// when a message was received on a conversation that was not already loaded
const uniqueConversationList = (conversationList1, conversationList2) => {
  const conversationList1Urls = conversationList1.map(c => c.url);
  conversationList2.map(conversation => {
    if (!conversationList1Urls.includes(conversation.url)) {
      conversationList1 = conversationList1.concat(conversation);
    }
  });

  return conversationList1;
};

const AccountInbox = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('AccountInbox').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('AccountInbox') : state)
      );
    } else {
      return state;
    }

    // Append message to current conversation (when messages need to be concatenated to the back)
  case 'APPEND_MESSAGES_TO_CURRENT_CONVERSATION':
    return state.setIn(
      ['currentConversation', 'messages'],
      List(
        new MessagesBatch(
          action.messages.concat(
            state.getIn(['currentConversation', 'messages']).toJS()
          )
        ).groupByTimeAndSenders()
      )
    );

    // Prepend message to current conversation (when a message needs to be pushed to the front)
  case 'PREPEND_MESSAGES_TO_CURRENT_CONVERSATION':
    return state.setIn(
      ['currentConversation', 'messages'],
      List(
        new MessagesBatch(
          state.getIn(['currentConversation', 'messages']).toJS().concat(
            action.messages
          )
        ).groupByTimeAndSenders()
      )
    );

  case 'UPDATE_HAS_MORE_SEND_BIRD_MESSAGES':
    return state.setIn(['currentConversation', 'hasMoreSendBirdMessages'], action.hasMoreSendBirdMessages);

  case 'UPDATE_HAS_MORE_KOPA_MESSAGES':
    return state.setIn(['currentConversation', 'hasMoreKopaMessages'], action.hasMoreKopaMessages);

  case 'UPDATE_HAS_MORE_CONVERSATIONS':
    return state.set('hasMoreConversations', action.hasMoreConversations);

    // Update conversation list isLoading
  case 'UPDATE_CONVERSATIONS_LOADING':
    return state.set('isLoading', action.isLoading);

    // Update conversation list
  case 'UPDATE_CONVERSATION_LIST':
    return state.set(
      'conversations',
      action.initial
        ? action.conversations.map(c => fromJS(c))
        : uniqueConversationList(state.get('conversations'), action.conversations.map(c => fromJS(c)))
    );

    // Set current conversation
  case 'SET_CURRENT_CONVERSATION':
    return state.set(
      'currentConversation',
      initialState.get('currentConversation').merge({
        conversation: fromJS(action.conversation),
      })
    );

    // Update current conversation
  case 'UPDATE_CURRENT_CONVERSATION':
    return state.setIn(['currentConversation', 'conversation' ], fromJS(action.conversation));

    // Update non current conversation
  case 'UPDATE_CONVERSATION_IN_LIST':
    return state.set(
      'conversations',
      state.get('conversations').map(conversation => {
        if (conversation.url === action.conversation.url) {
          return fromJS(action.conversation);
        } else {
          return conversation;
        }
      })
    );

    // Update non current conversation
  case 'ADD_CONVERSATION_TO_LIST':
    return state.set(
      'conversations',
      state.get('conversations').concat([fromJS(action.conversation)])
    );

    // Update current conversation isLoading
  case 'UPDATE_CURRENT_CONVERSATION_LOADING':
    return state.setIn(['currentConversation', 'isLoading'], action.isLoading);

  default:
    return state;
  }
};

export default AccountInbox;
