export default {
  alphabet: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
  companyMembershipRoles: [
    { value: 'owner', label: 'Homeowner' },
    { value: 'property_manager', label: 'Property Manager' },
    { value: 'subletter', label: 'Subletter' },
    { value: 'lease_holder', label: 'Lease Holder' },
    { value: 'housemate', label: 'Housemate' },
  ],
  minimumDaysForNonShortTermRental: 28,
  months: ['january', 'february', 'march', 'april', 'may', 'june', 'july','august', 'september', 'october', 'november', 'december'],
  transitionDefault: 300,
};
