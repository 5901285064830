import React from 'react';
import PropTypes from 'prop-types';

const CheckmarkIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="currentColor" />
  </svg>
);

CheckmarkIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CheckmarkIcon;
