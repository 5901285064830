import React from 'react';
import PropTypes from 'prop-types';

const CondoIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <polyline points="3 11.5 3 23.5 22 23.5 22 3.5 11.5 3.5" strokeLinecap="round" />
      <rect height="8" width="5" x="7" y="15" />
      <rect height="10" width="9" x="2" y="1" />
      <rect height="2" width="1" x="16" y="8" />
      <rect height="2" width="1" x="6" y="5" />
      <rect height="2" width="1" x="16" y="16" />
    </g>
  </svg>
);

CondoIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CondoIcon;
