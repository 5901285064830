import { fromJS, Map } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  adminSudoLoginLoading: false,
  company: {},
  companyLoading: false,
  destination: null,
  documentLoading: false,
  isEmailLoading: false,
  error: null,
  housematePostLoading: false,
  inviteError: null,
  isLoggedIn: false,
  loginError: null,
  loginLoading: false,
  object: {},
  passwordLoading: false,
  pendingRequestCount: 0,
  profile: {},
  profileLoading: false,
  isResendEmailLoading: false,
  signupError: null,
  signupLoading: false,
  socialConnectionLoading: false,
  status: {},
  isTokenLoading: true,
  isTosLoading: false,
  unreadMessageCount: 0,
  userLoading: false,
  userStatusLoading: false,
  verificationLoading: false,
});

const User = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('User').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('User') : state)
      );
    } else {
      return state;
    }

    // Token actions
  case 'TOKEN_REQUEST_LOADING':
    return state.merge({
      isTokenLoading: true,
    });
  case 'TOKEN_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.user),
      unreadMessageCount: action.unread_message_count || 0,
      pendingRequestCount: action.pending_request_count || 0,
      isTokenLoading: false,
      isLoggedIn: true,
    });
  case 'TOKEN_REQUEST_FAILURE':
    return state.merge({
      isTokenLoading: false,
    });
  case 'SKIP_TOKEN_REQUEST':
    return state.merge({
      isTokenLoading: false,
    });

    // Login actions
  case 'LOGIN_REQUEST_LOADING':
    return state.merge({
      loginLoading: true,
    });
  case 'LOGIN_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.user),
      unreadMessageCount: action.unread_message_count || 0,
      pendingRequestCount: action.pending_request_count || 0,
      loginLoading: false,
      isLoggedIn: true,
      loginError: null,
    });
  case 'LOGIN_REQUEST_FAILURE':
    return state.merge({
      loginLoading: false,
      loginError: action.error,
      isLoggedIn: false,
    });
  case 'CLEAR_LOGIN_ERROR':
    return state.merge({
      loginError: null,
    });

    // Admin SU login actions
  case 'ADMIN_SU_LOGIN_REQUEST_LOADING':
    return state.merge({
      adminSudoLoginLoading: true,
    });
  case 'ADMIN_SU_LOGIN_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.user),
      unreadMessageCount: action.unread_message_count || 0,
      pendingRequestCount: action.pending_request_count || 0,
      adminSudoLoginLoading: false,
      isLoggedIn: true,
    });
  case 'ADMIN_SU_LOGIN_REQUEST_FAILURE':
    return state.merge({
      adminSudoLoginLoading: false,
      loginError: action.error,
      isLoggedIn: false,
    });

    // Logout actions
  case 'LOGOUT_REQUEST_SUCCESS':
    return initialState;

    // Signup actions
  case 'SIGNUP_REQUEST_LOADING':
    return state.merge({
      signupLoading: true,
    });
  case 'SIGNUP_REQUEST_SUCCESS':
    return state.merge({
      signupLoading: false,
      signupError: null,
    });
  case 'SIGNUP_REQUEST_FAILURE':
    return state.merge({
      signupLoading: false,
      signupError: action.error,
    });
  case 'CLEAR_SIGNUP_ERROR':
    return state.merge({
      signupError: null,
    });
  case 'SIGNUP_CONFIRMATION_REQUEST_LOADING':
    return state.merge({
      signupLoading: true,
    });
  case 'SIGNUP_CONFIRMATION_REQUEST_SUCCESS':
    return state.merge({
      signupLoading: false,
      object: fromJS(action.user),
      isLoggedIn: true,
    });
  case 'SIGNUP_CONFIRMATION_REQUEST_FAILURE':
    return state.merge({
      signupLoading: false,
      error: action.error,
    });

    // Invite actions
  case 'CLEAR_INVITE_ERROR':
    return state.merge({
      inviteError: null,
    });
  case 'INVITE_SIGNUP_REQUEST_LOADING':
    return state.merge({
      signupLoading: true,
    });
  case 'INVITE_SIGNUP_REQUEST_SUCCESS':
    return state.merge({
      signupLoading: false,
      object: fromJS(action.user),
      isLoggedIn: true,
    });
  case 'INVITE_SIGNUP_REQUEST_FAILURE':
    return state.merge({
      signupLoading: false,
      inviteError: action.error,
    });

    // Recommendations signup actions
  case 'DIRECT_SIGNUP_REQUEST_LOADING':
    return state.merge({
      signupLoading: true,
      isLoggedIn: false,
    });
  case 'DIRECT_SIGNUP_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').merge(fromJS(action.user)),
      unreadMessageCount: 0,
      signupLoading: false,
      isLoggedIn: true,
    });
  case 'DIRECT_SIGNUP_REQUEST_FAILURE':
    return state.merge({
      error: action.error,
      signupLoading: false,
      isLoggedIn: false,
    });

    // Update conversation list isLoading
  case 'UPDATE_UNREAD_MESSAGE_COUNT':
    return state.set('unreadMessageCount', action.unreadMessageCount);

    // Update user in state
  case 'UPDATE_USER':
    return state.merge({
      object: state.get('object').merge(fromJS(action.user)),
    });

    // Update user actions
  case 'UPDATE_USER_REQUEST_LOADING':
    return state.merge({
      userLoading: true,
    });
  case 'UPDATE_USER_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.user),
      userLoading: false,
    });
  case 'UPDATE_USER_REQUEST_FAILURE':
    return state.merge({
      userLoading: false,
      error: action.error,
    });

    // Disconnect social actions
  case 'SOCIAL_DISCONNECT_REQUEST_LOADING':
    return state.merge({
      socialConnectionLoading: true,
    });
  case 'SOCIAL_DISCONNECT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set(`connected_to_${action.provider}`, false),
      socialConnectionLoading: false,
    });
  case 'SOCIAL_DISCONNECT_REQUEST_FAILURE':
    return state.merge({
      socialConnectionLoading: false,
      error: action.error,
    });

    // Update company in state
  case 'UPDATE_COMPANY':
    return state.merge({
      company: state.get('company').merge(fromJS(action.company)),
      companyLoading: false,
    });

    // Update company in user state
  case 'UPDATE_USER_COMPANY': {
    const company = state.getIn(['object', 'company']) || Map({});
    const mergedUserCompany = company.merge(fromJS(action.company));
    return state.merge({
      object: state.get('object').set('company', mergedUserCompany),
    });
  }

  // Update company actions
  case 'UPDATE_COMPANY_REQUEST_LOADING':
    return state.merge({
      userLoading: true,
    });
  case 'UPDATE_COMPANY_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set('company', state.get('object').get('company').merge(fromJS(action.company))),
      userLoading: false,
      isLoggedIn: true,
    });
  case 'UPDATE_COMPANY_REQUEST_FAILURE':
    return state.merge({
      userLoading: false,
      error: action.error,
      isLoggedIn: false,
    });

    // Company actions
  case 'GET_COMPANY_LOADING':
    return state.merge({
      companyLoading: true,
    });
  case 'GET_COMPANY_SUCCESS':
    return state.merge({
      company: fromJS(action.company),
      companyLoading: false,
    });
  case 'GET_COMPANY_FAILURE':
    return state.merge({
      error: action.error,
      companyLoading: false,
    });

    // User actions
  case 'GET_PROFILE_LOADING':
    return state.merge({
      profileLoading: true,
    });
  case 'GET_PROFILE_SUCCESS':
    return state.merge({
      profile: fromJS(action.profile),
      profileLoading: false,
    });
  case 'GET_PROFILE_FAILURE':
    return state.merge({
      error: action.error,
      profileLoading: false,
    });

    // User status actions
  case 'GET_USER_STATUS_LOADING':
    return state.merge({
      userStatusLoading: true,
    });
  case 'GET_USER_STATUS_SUCCESS':
    return state.merge({
      status: fromJS(action.status),
      userStatusLoading: false,
    });
  case 'GET_USER_STATUS_FAILURE':
    return state.merge({
      error: action.error,
      userStatusLoading: false,
    });

    // Email verification actions
  case 'RESEND_EMAIL_VERIFICATION_LOADING':
    return state.merge({
      isResendEmailLoading: true,
    });
  case 'RESEND_EMAIL_VERIFICATION_SUCCESS':
    return state.merge({
      isResendEmailLoading: false,
    });
  case 'RESEND_EMAIL_VERIFICATION_FAILURE':
    return state.merge({
      isResendEmailLoading: false,
      error: action.error,
    });

    // Start verification actions
  case 'START_VERIFICATION_LOADING':
    return state.merge({
      verificationLoading: true,
    });
  case 'START_VERIFICATION_SUCCESS':
    return state.merge({
      verificationLoading: false,
      berbixClientToken: action.client_token,
    });
  case 'START_VERIFICATION_FAILURE':
    return state.merge({
      verificationLoading: false,
      error: action.error,
    });

    // Complete verification actions
  case 'COMPLETE_VERIFICATION_LOADING':
    return state.merge({
      verificationLoading: true,
    });
  case 'COMPLETE_VERIFICATION_SUCCESS':
    return state.merge({
      verificationLoading: false,
      verificationStatus: action.verificationStatus,
    });
  case 'COMPLETE_VERIFICATION_FAILURE':
    return state.merge({
      verificationLoading: false,
      error: action.error,
    });

    // Delete user actions
  case 'DELETE_USER_REQUEST_LOADING':
    return state.merge({
      userLoading: true,
    });
  case 'DELETE_USER_REQUEST_SUCCESS':
    return initialState;
  case 'DELETE_USER_REQUEST_FAILURE':
    return state.merge({
      userLoading: false,
      error: action.error,
    });

    // Update email actions
  case 'UPDATE_EMAIL_REQUEST_LOADING':
    return state.merge({
      isEmailLoading: true,
    });
  case 'UPDATE_EMAIL_REQUEST_SUCCESS':
    return state.merge({
      isEmailLoading: false,
      object: fromJS(state.get('object').set('email', action.email)),

    });
  case 'UPDATE_EMAIL_REQUEST_FAILURE':
    return state.merge({
      isEmailLoading: false,
      error: action.error,
    });

  // Add password actions
  case 'ADD_PASSWORD_REQUEST_LOADING':
    return state.merge({
      passwordLoading: true,
    });
  case 'ADD_PASSWORD_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set('has_password', true),
      passwordLoading: false,
    });
  case 'ADD_PASSWORD_REQUEST_FAILURE':
    return state.merge({
      passwordLoading: false,
      error: action.error,
    });

    // Change password actions
  case 'CHANGE_PASSWORD_REQUEST_LOADING':
    return state.merge({
      passwordLoading: true,
    });
  case 'CHANGE_PASSWORD_REQUEST_SUCCESS':
    return state.merge({
      passwordLoading: false,
    });
  case 'CHANGE_PASSWORD_REQUEST_FAILURE':
    return state.merge({
      passwordLoading: false,
      error: action.error,
    });

    // Reset password actions
  case 'RESET_PASSWORD_REQUEST_LOADING':
    return state.merge({
      passwordLoading: true,
    });
  case 'RESET_PASSWORD_REQUEST_SUCCESS':
    return state.merge({
      passwordLoading: false,
    });
  case 'RESET_PASSWORD_REQUEST_FAILURE':
    return state.merge({
      passwordLoading: false,
      error: action.error,
    });
  case 'RESET_PASSWORD_TOKEN_REQUEST_LOADING':
    return state.merge({
      passwordLoading: true,
    });
  case 'RESET_PASSWORD_TOKEN_REQUEST_SUCCESS':
    return state.merge({
      passwordLoading: false,
    });
  case 'RESET_PASSWORD_TOKEN_REQUEST_FAILURE':
    return state.merge({
      passwordLoading: false,
      error: action.error,
    });

    // Housemate post actions
  case 'CREATE_HOUSEMATE_POST_REQUEST_FAILURE':
  case 'DELETE_HOUSEMATE_POST_REQUEST_FAILURE':
  case 'UPDATE_HOUSEMATE_POST_REQUEST_FAILURE':
    return state.merge({
      userLoading: false,
      error: action.error,
    });
  case 'CREATE_HOUSEMATE_POST_REQUEST_LOADING':
  case 'DELETE_HOUSEMATE_POST_REQUEST_LOADING':
  case 'UPDATE_HOUSEMATE_POST_REQUEST_LOADING':
    return state.merge({
      housematePostLoading: true,
    });
  case 'CREATE_HOUSEMATE_POST_REQUEST_SUCCESS':
  case 'UPDATE_HOUSEMATE_POST_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set('housemate_post', fromJS(action.housematePost)),
      housematePostLoading: false,
    });
  case 'DELETE_HOUSEMATE_POST_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set('housemate_post', Map()),
      profile: state.get('profile').set('housemate_post', Map()),
      userLoading: false,
    });

    // Document actions
    // Create document actions
  case 'CREATE_DOCUMENT_REQUEST_LOADING':
    return state.merge({
      documentLoading: true,
    });
  case 'CREATE_DOCUMENT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').update('documents', documents => (
        documents.push(
          fromJS(action.document)
        )
      )),
      documentLoading: false,
    });
  case 'CREATE_DOCUMENT_REQUEST_FAILURE':
    return state.merge({
      documentLoading: false,
      error: action.error,
    });

    // Delete document actions
  case 'DELETE_DOCUMENT_REQUEST_LOADING':
    return state.merge({
      documentLoading: true,
    });
  case 'DELETE_DOCUMENT_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').update('documents', documents => (
        documents.filter(d => d.get('id') !== action.documentId)
      )),
      documentLoading: false,
    });
  case 'DELETE_DOCUMENT_REQUEST_FAILURE':
    return state.merge({
      documentLoading: false,
      error: action.error,
    });

    // Create work experience actions
  case 'CREATE_WORK_EXPERIENCE_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').update('work_experiences', workExperiences => (
        workExperiences.push(
          fromJS(action.workExperience)
        )
      )),
    });

    // Update work experience actions
  case 'UPDATE_WORK_EXPERIENCE_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').update('work_experiences', workExperiences => (
        workExperiences.map(we => (
          we.get('id') === action.workExperience.id ? fromJS(action.workExperience) : we
        ))
      )),
    });

    // Create education actions
  case 'CREATE_EDUCATION_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').update('education', () => fromJS(action.education)),
    });

    // Update education actions
  case 'UPDATE_EDUCATION_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').update('education', () => fromJS(action.education)),
    });

  case 'CREATE_PAYMENT_METHOD_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set('payment_methods', state.get('object').get('payment_methods').push(fromJS(action.paymentMethod))),
    });

  // Accept TOS actions
  case 'ACCEPT_TOS_REQUEST_LOADING':
    return state.merge({
      isTosLoading: true,
    });
  case 'ACCEPT_TOS_REQUEST_SUCCESS':
    return state.merge({
      object: state.get('object').set('tos_acceptance', action.tosAcceptance),
      isTosLoading: false,
    });
  case 'ACCEPT_TOS_REQUEST_FAILURE':
    return state.merge({
      isTosLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default User;
