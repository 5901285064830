import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  list: [],
  isLoading: false,
  error: null,
});

const SavedSearches = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('SavedSearches').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('SavedSearches') : state)
      );
    } else {
      return state;
    }

    // Retrieve saved search actions
  case 'RETRIEVE_SAVED_SEARCHES_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_SAVED_SEARCHES_REQUEST_SUCCESS':
    return state.merge({
      list: fromJS(action.saved_searches),
      isLoading: false,
    });
  case 'RETRIEVE_SAVED_SEARCHES_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Create saved search actions
  case 'CREATE_SAVED_SEARCH_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CREATE_SAVED_SEARCH_REQUEST_SUCCESS':
    return state.merge({
      list: state.get('list').push(fromJS(action.savedSearch)),
      isLoading: false,
    });
  case 'CREATE_SAVED_SEARCH_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Delete saved search actions
  case 'DELETE_SAVED_SEARCH_REQUEST_SUCCESS':
    return state.merge({
      list: fromJS(state.get('list').filter(element => element.get('id') !== action.savedSearchId)),
      isLoading: false,
    });
  case 'DELETE_SAVED_SEARCH_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default SavedSearches;
