import React from 'react';
import PropTypes from 'prop-types';

const SmokingIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <rect height="7" transform="translate(17 24.5) scale(1 -1) translate(-17 -24.5)" width="32" x="1" y="21" />
      <line x1="28" x2="28" y1="21.5" y2="27.5" />
      <path d="m37 28.5v-1.75c0-1.2426 1.0074-2.25 2.25-2.25s2.25-1.0074 2.25-2.25v-0.25c0-1.3807 1.1193-2.5 2.5-2.5h0.25c1.5188 0 2.75-1.2312 2.75-2.75v-1.75" />
      <path d="m36.25 20.5c1.2426 0 2.25-1.0074 2.25-2.25v-0.25c0-1.3807 1.1193-2.5 2.5-2.5h0.25c1.5188 0 2.75-1.2312 2.75-2.75v-1.75" />
      <line x1="9" x2="9" y1="21.5" y2="27.5" />
    </g>
  </svg>
);

SmokingIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default SmokingIcon;
