import React from 'react';
import PropTypes from 'prop-types';

const CleaningIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <polyline points="13.545 10 1 10 1 1 47 1 47 10 31.841 10" />
      <polyline points="4 47 6.2222 10 41.778 10 44 47" transform="translate(24 28.5) scale(1 -1) translate(-24 -28.5)" />
      <path d="m15.994 34h13.606c1.1046 0 2-0.89543 2-2v-8.6429l2.3899 2.2355c0.95064 0.88921 2.4421 0.8394 3.3313-0.11124 0.02698-0.028844 0.053231-0.058362 0.078728-0.088526 0.89521-1.0591 0.7992-2.6349-0.21797-3.5775l-5.582-5.1725-0.1-15.643h-17.6l0.093958 31.006c0.00334 1.1022 0.89779 1.9939 2 1.9939z" />
      <line transform="translate(27.5 28.5) rotate(90) translate(-27.5 -28.5)" x1="21" x2="34" y1="28.5" y2="28.5" />
      <line transform="translate(23 28.5) rotate(90) translate(-23 -28.5)" x1="16.5" x2="29.5" y1="28.5" y2="28.5" />
      <line transform="translate(18.5 28.5) rotate(90) translate(-18.5 -28.5)" x1="12" x2="25" y1="28.5" y2="28.5" />
    </g>
  </svg>
);

CleaningIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CleaningIcon;
