import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

// =================================
//   RETRIEVE REQUEST REQUEST
// ===================================
const retrieveViewRequestRequestLoading = () => ({
  type: 'RETRIEVE_VIEW_REQUEST_REQUEST_LOADING',
});

const retrieveViewRequestRequestSuccess = (request) => ({
  type: 'RETRIEVE_VIEW_REQUEST_REQUEST_SUCCESS',
  request,
});

const retrieveViewRequestRequestFailure = (error) => ({
  type: 'RETRIEVE_VIEW_REQUEST_REQUEST_FAILURE',
  error,
});

const retrieveViewRequestRequest = (apiService, requestId) => async (dispatch) => {
  dispatch(retrieveViewRequestRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${requestId}`,
      method: 'GET',
    });
    dispatch(retrieveViewRequestRequestSuccess(response));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveViewRequestRequestFailure
    );
    throw error;
  }
};

// =================================
//   RETRIEVE CONVERSATION REQUEST REQUEST
// ===================================
const retrieveViewConversationRequestRequestLoading = () => ({
  type: 'RETRIEVE_VIEW_CONVERSATION_REQUEST_REQUEST_LOADING',
});

const retrieveViewConversationRequestRequestSuccess = (request) => ({
  type: 'RETRIEVE_VIEW_CONVERSATION_REQUEST_REQUEST_SUCCESS',
  request,
});

const retrieveViewConversationRequestRequestFailure = (error) => ({
  type: 'RETRIEVE_VIEW_CONVERSATION_REQUEST_REQUEST_FAILURE',
  error,
});

const retrieveViewConversationRequestRequest = (conversationId) => async (dispatch) => {
  dispatch(retrieveViewConversationRequestRequestLoading());

  try {
    const apiService = new ApiService();
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/conversations/${conversationId}/booking_request`,
      method: 'GET',
    });

    dispatch(retrieveViewConversationRequestRequestSuccess(response));
    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveViewConversationRequestRequestFailure
    );

    throw error;
  }
};

// =============================
//   UPDATE REQUEST
// =============================
const updateRequestRequestLoading = () => ({
  type: 'UPDATE_REQUEST_REQUEST_LOADING',
});

const updateRequestRequestSuccess = (requestId, data) => ({
  type: 'UPDATE_REQUEST_REQUEST_SUCCESS',
  id: requestId,
  request: { ...data },
});

const updateRequestRequestFailure = () => ({
  type: 'UPDATE_REQUEST_REQUEST_FAILURE',
});

const updateRequestRequest = (requestData) => async (dispatch) => {
  dispatch(updateRequestRequestLoading());

  try {
    const apiService = new ApiService();
    const transitionResponse = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${requestData.booking_request_id}/transition`,
      method: 'POST',
      data: JSON.stringify(requestData),
    });

    dispatch(updateRequestRequestSuccess(requestData.booking_request_id, transitionResponse));
    await retrieveViewRequestRequest(apiService, requestData.booking_request_id)(dispatch);
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateRequestRequestFailure
    );

    throw error;
  }
};

const updateRequestDetailsRequest = (requestData) => async (dispatch) => {
  dispatch(updateRequestRequestLoading());

  try {
    const apiService = new ApiService();
    const transitionResponse = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${requestData.booking_request_id}`,
      method: 'PUT',
      data: JSON.stringify(requestData),
    });

    dispatch(updateRequestRequestSuccess(requestData.booking_request_id, transitionResponse));
    await retrieveViewRequestRequest(apiService, requestData.booking_request_id)(dispatch);
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateRequestRequestFailure
    );
  }
};

// =============================
//   UPDATE REQUEST PRICING
// =============================
const updateRequestPricingRequestLoading = () => ({
  type: 'UPDATE_REQUEST_PRICING_REQUEST_LOADING',
});

const updateRequestPricingRequestSuccess = (requestId, data) => ({
  type: 'UPDATE_REQUEST_PRICING_REQUEST_SUCCESS',
  id: requestId,
  request: { ...data },
});

const updateRequestPricingRequestFailure = () => ({
  type: 'UPDATE_REQUEST_PRICING_REQUEST_FAILURE',
});

const updateRequestPricingRequest = (requestId, pricingData) => async (dispatch) => {
  dispatch(updateRequestPricingRequestLoading());

  try {
    const apiService = new ApiService();
    const updateResponse = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${requestId}/pricing`,
      method: 'POST',
      data: JSON.stringify(pricingData),
    });

    const hydratedBookingRequest = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${requestId}`,
      method: 'GET',
    });

    dispatch(updateRequestPricingRequestSuccess(requestId, updateResponse));
    dispatch(retrieveViewRequestRequestSuccess(hydratedBookingRequest));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateRequestPricingRequestFailure
    );
  }
};

// =============================
//   UPDATE TENANCY GROUP PRICING
// =============================
const updateTenancyGroupPricingRequestLoading = () => ({
  type: 'UPDATE_TENANCY_GROUP_PRICING_REQUEST_LOADING',
});

const updateTenancyGroupPricingRequestSuccess = (data) => ({
  type: 'UPDATE_TENANCY_GROUP_PRICING_REQUEST_SUCCESS',
  tenancyGroup: { ...data },
});

const updateTenancyGroupPricingRequestFailure = () => ({
  type: 'UPDATE_TENANCY_GROUP_PRICING_REQUEST_FAILURE',
});

const updateTenancyGroupPricingRequest = (tenancyGroupId, pricingData) => async (dispatch) => {
  dispatch(updateTenancyGroupPricingRequestLoading());

  try {
    const apiService = new ApiService();
    const updateResponse = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/tenancies/${tenancyGroupId}/pricing`,
      method: 'POST',
      data: JSON.stringify(pricingData),
    });

    const hydratedBookingRequest = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${updateResponse.booking_request_id}`,
      method: 'GET',
    });

    dispatch(updateTenancyGroupPricingRequestSuccess(updateResponse));
    dispatch(retrieveViewRequestRequestSuccess(hydratedBookingRequest));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateTenancyGroupPricingRequestFailure
    );
  }
};


export default {
  updateRequestPricingRequest,
  updateRequestDetailsRequest,
  updateRequestRequest,
  updateTenancyGroupPricingRequest,
  retrieveViewConversationRequestRequest,
  retrieveViewRequestRequest,
};
