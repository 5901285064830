import React from 'react';
import PropTypes from 'prop-types';

const PersonIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(-135 -1098)">
      <g transform="translate(136 1099)">
        <g transform="translate(5.5 6)">
          <rect height="8" rx="3" width="6" x="3.5" />
          <path d="m0 16.267c-3.9003e-16 -3.1848 2.5818-5.7667 5.7667-5.7667h1.4667c3.1848 0 5.7667 2.5818 5.7667 5.7667" />
        </g>
        <circle cx="12" cy="12" r="12" />
      </g>
    </g>
  </svg>
);

PersonIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default PersonIcon;
