import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   UPDATE DESTINATION (in state)
// ==========================
const updateDestination = (destination) => ({
  type: 'UPDATE_DESTINATION',
  destination,
});

// ==========================
//   UPDATE TRAVEL MODE (in state)
// ==========================
const updateTravelMode = (travelMode) => ({
  type: 'UPDATE_TRAVEL_MODE',
  travelMode,
});

// ==========================
//   CREATE COMMUTE DESTINATION
// ==========================
const createCommuteDestinationLoading = () => ({
  type: 'CREATE_COMMUTE_DESTINATION_REQUEST_LOADING',
});

const createCommuteDestinationSuccess = (commuteDestination) => ({
  type: 'CREATE_COMMUTE_DESTINATION_REQUEST_SUCCESS',
  commuteDestination,
});

const createCommuteDestinationFailure = () => ({
  type: 'CREATE_COMMUTE_DESTINATION_REQUEST_FAILURE',
});

const createCommuteDestination = (userId, commuteDestination) => async (dispatch) => {
  dispatch(createCommuteDestinationLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/commute_destinations`,
      method: 'POST',
      data: JSON.stringify(commuteDestination),
    });
    dispatch(createCommuteDestinationSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createCommuteDestinationFailure
    );
  }
};

// ==========================
//   UPDATE COMMUTE DESTINATION
// ==========================
const updateCommuteDestinationLoading = () => ({
  type: 'UPDATE_COMMUTE_DESTINATION_REQUEST_LOADING',
});

const updateCommuteDestinationSuccess = (commuteDestination) => ({
  type: 'UPDATE_COMMUTE_DESTINATION_REQUEST_SUCCESS',
  commuteDestination,
});

const updateCommuteDestinationFailure = () => ({
  type: 'UPDATE_COMMUTE_DESTINATION_REQUEST_FAILURE',
});

const updateCommuteDestination = (commuteDestinationId, commuteDestination) => async (dispatch) => {
  dispatch(updateCommuteDestinationLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/commute_destinations/${commuteDestinationId}`,
      method: 'PUT',
      data: JSON.stringify(commuteDestination),
    });
    dispatch(updateCommuteDestinationSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateCommuteDestinationFailure
    );
  }
};

export default {
  updateDestination,
  updateTravelMode,
  createCommuteDestination,
  updateCommuteDestination,
};
