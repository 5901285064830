import React from 'react';
import PropTypes from 'prop-types';

const VerifiedIcon = (props) => (
  <svg aria-hidden="true" className={props.className} stroke="currentColor" strokeWidth="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="11" />
      <polyline points="6 12 10.251 16.5 18 7.5" />
    </g>
  </svg>
);

VerifiedIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default VerifiedIcon;
