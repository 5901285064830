import { toast } from 'react-toastify';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ====================================
//   CREATE PAYOUT METHODS REQUEST
// ====================================
const createPayoutMethodRequestLoading = () => ({
  type: 'CREATE_PAYOUT_METHOD_REQUEST_LOADING',
});

const createPayoutMethodRequestSuccess = (payoutMethod) => ({
  type: 'CREATE_PAYOUT_METHOD_REQUEST_SUCCESS',
  payoutMethod,
});

const createPayoutMethodRequestFailure = (error) => ({
  type: 'CREATE_PAYOUT_METHOD_REQUEST_FAILURE',
  error,
});

const createPayoutMethodRequest = (companyId, payoutMethod) => async (dispatch) => {
  dispatch(createPayoutMethodRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/payout_methods`,
      method: 'POST',
      data: JSON.stringify(payoutMethod),
    });
    dispatch(createPayoutMethodRequestSuccess(res));

    return { payoutMethod: res };
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createPayoutMethodRequestFailure,
      true,
      'There was an issue creating your payout method. Please try again.'
    );
    throw error;
  }
};

// =============================
//   DELETE PAYOUT METHOD
// =============================
const deletePayoutMethodRequestLoading = () => ({
  type: 'DELETE_PAYOUT_METHOD_REQUEST_LOADING',
});

const deletePayoutMethodRequestSuccess = (payoutMethodId) => ({
  type: 'DELETE_PAYOUT_METHOD_REQUEST_SUCCESS',
  id: payoutMethodId,
});

const deletePayoutMethodRequestFailure = () => ({
  type: 'DELETE_PAYOUT_METHOD_REQUEST_FAILURE',
});

const deletePayoutMethodRequest = (companyId, payoutMethodId) => async (dispatch) => {
  dispatch(deletePayoutMethodRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/payout_methods/${payoutMethodId}`,
      method: 'DELETE',
    });
    toast('Payout method deleted', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(deletePayoutMethodRequestSuccess(payoutMethodId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deletePayoutMethodRequestFailure,
      true,
      'There was an issue deleting your payout method. Please try again.'
    );
    throw error;
  }
};

export default {
  createPayoutMethodRequest,
  deletePayoutMethodRequest,
};
