import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: [],
  isLoading: false,
  error: null,
});

const CountryCodes = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('CountryCodes').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('CountryCodes') : state)
      );
    } else {
      return state;
    }

    // Get country codes
  case 'GET_COUNTRY_CODES_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'GET_COUNTRY_CODES_SUCCESS':
    return state.merge({
      object: fromJS(action.countryCodes),
      isLoading: false,
    });
  case 'GET_COUNTRY_CODES_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });
  default:
    return state;
  }
};

export default CountryCodes;
