import React from 'react';
import PropTypes from 'prop-types';

const Martini = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 22 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g stroke="currentColor" transform="translate(-527 -1308)">
        <g transform="translate(526 1308)">
          <g transform="translate(2 1)">
            <polygon points="10 12 20 0 0 0" />
            <line x1="4.5" x2="15.442" y1="5.5" y2="5.4333" />
            <line x1="4.5" x2="15.5" y1="22.5" y2="22.5" />
            <line x1="10" x2="10" y1="12.13" y2="22.13" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Martini.propTypes = {
  // required
  className: PropTypes.string.isRequired,
};

export default Martini;
