import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// =================================
//   GET AVAILABILITY EVENTS REQUEST
// ===================================
const getAvailabilityEventsRequestLoading = () => ({
  type: 'GET_AVAILABILITY_EVENTS_REQUEST_LOADING',
});

const getAvailabilityEventsRequestSuccess = (availabilityEvents) => ({
  type: 'GET_AVAILABILITY_EVENTS_REQUEST_SUCCESS',
  availabilityEvents,
});

const getAvailabilityEventsRequestFailure = (error) => ({
  type: 'GET_AVAILABILITY_EVENTS_REQUEST_FAILURE',
  error,
});

const getAvailabilityEventsRequest = (listingId) => async (dispatch) => {
  dispatch(getAvailabilityEventsRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/availability_events`,
      method: 'GET',
    });
    dispatch(getAvailabilityEventsRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      getAvailabilityEventsRequestFailure,
      true,
      'There was an issue getting your listing availability.'
    );
  }
};

// ======================================
//   CREATE AVAILABILITY EVENT REQUEST
// ======================================
const createAvailabilityEventRequestLoading = () => ({
  type: 'CREATE_AVAILABILITY_EVENT_REQUEST_LOADING',
});

const createAvailabilityEventRequestSuccess = (availabilityEvent) => ({
  type: 'CREATE_AVAILABILITY_EVENT_REQUEST_SUCCESS',
  availabilityEvent,
});

const createAvailabilityEventRequestFailure = (error) => ({
  type: 'CREATE_AVAILABILITY_EVENT_REQUEST_FAILURE',
  error,
});

const createAvailabilityEventRequest = (listingId, availabilityEvent) => async (dispatch) => {
  dispatch(createAvailabilityEventRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/availability_events`,
      method: 'POST',
      data: JSON.stringify(availabilityEvent),
    });
    dispatch(createAvailabilityEventRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createAvailabilityEventRequestFailure,
      true,
      'There was an issue creating your listing availability event.'
    );
  }
};

// ======================================
//   UPDATE AVAILABILITY EVENT REQUEST
// ======================================
const updateAvailabilityEventRequestLoading = () => ({
  type: 'UPDATE_AVAILABILITY_EVENT_REQUEST_LOADING',
});

const updateAvailabilityEventRequestSuccess = (availabilityEvent) => ({
  type: 'UPDATE_AVAILABILITY_EVENT_REQUEST_SUCCESS',
  availabilityEvent,
});

const updateAvailabilityEventRequestFailure = (error) => ({
  type: 'UPDATE_AVAILABILITY_EVENT_REQUEST_FAILURE',
  error,
});

const updateAvailabilityEventRequest = (listingId, availabilityEvent) => async (dispatch) => {
  dispatch(updateAvailabilityEventRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/availability_events/${availabilityEvent.get('id')}`,
      method: 'PUT',
      data: JSON.stringify(availabilityEvent.toJS()),
    });
    dispatch(updateAvailabilityEventRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateAvailabilityEventRequestFailure,
      true,
      'There was an issue updating your listing availability.'
    );
  }
};

// ======================================
//   DELETE AVAILABILITY EVENT REQUEST
// ======================================
const deleteAvailabilityEventRequestLoading = () => ({
  type: 'DELETE_AVAILABILITY_EVENT_REQUEST_LOADING',
});

const deleteAvailabilityEventRequestSuccess = (availabilityEventId) => ({
  type: 'DELETE_AVAILABILITY_EVENT_REQUEST_SUCCESS',
  availabilityEventId,
});

const deleteAvailabilityEventRequestFailure = (error) => ({
  type: 'DELETE_AVAILABILITY_EVENT_REQUEST_FAILURE',
  error,
});

const deleteAvailabilityEventRequest = (listingId, availabilityEventId) => async (dispatch) => {
  dispatch(deleteAvailabilityEventRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/listings/${listingId}/availability_events/${availabilityEventId}`,
      method: 'DELETE',
    });
    dispatch(deleteAvailabilityEventRequestSuccess(availabilityEventId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deleteAvailabilityEventRequestFailure,
      true,
      'There was an issue deleting your listing availability.'
    );
  }
};

export default {
  getAvailabilityEventsRequest,
  createAvailabilityEventRequest,
  updateAvailabilityEventRequest,
  deleteAvailabilityEventRequest,
};
