import React from 'react';
import PropTypes from 'prop-types';

const PoolIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m2 27.188 1.2174-1.1878c2.2804-2.2249 5.9192-2.2249 8.1996 0 2.2804 2.2249 5.9192 2.2249 8.1996 0 2.2804-2.2249 5.9192-2.2249 8.1996 0 2.2804 2.2249 5.9192 2.2249 8.1996 0 2.2804-2.2249 5.9192-2.2249 8.1996 0l1.7848 1.7413" />
      <path d="m2 36.485 1.2174-1.4848c1.8566-2.2642 5.1972-2.5947 7.4614-0.73814 0.26973 0.22117 0.51698 0.46841 0.73814 0.73814 1.8566 2.2642 5.1972 2.5947 7.4614 0.73814 0.26973-0.22117 0.51698-0.46841 0.73814-0.73814 1.8566-2.2642 5.1972-2.5947 7.4614-0.73814 0.26973 0.22117 0.51698 0.46841 0.73814 0.73814 1.8566 2.2642 5.1972 2.5947 7.4614 0.73814 0.26973-0.22117 0.51698-0.46841 0.73814-0.73814 1.8566-2.2642 5.1972-2.5947 7.4614-0.73814 0.26973 0.22117 0.51698 0.46841 0.73814 0.73814l1.7848 2.1767" />
      <path d="m2 45.188 1.2174-1.1878c2.2804-2.2249 5.9192-2.2249 8.1996 0 2.2804 2.2249 5.9192 2.2249 8.1996 0 2.2804-2.2249 5.9192-2.2249 8.1996 0 2.2804 2.2249 5.9192 2.2249 8.1996 0 2.2804-2.2249 5.9192-2.2249 8.1996 0l1.7848 1.7413" />
      <path d="m16 28v-17c0-4.4183 3.5817-8 8-8" />
      <path d="m30 28v-17c0-4.4183 3.5817-8 8-8" />
      <line x1="16" x2="30" y1="10" y2="10" />
      <line x1="16" x2="30" y1="18" y2="18" />
    </g>
  </svg>
);

PoolIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default PoolIcon;
