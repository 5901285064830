import React from 'react';
import PropTypes from 'prop-types';

const ArmchairIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g stroke="currentColor" strokeWidth="2" transform="translate(-136 -1028)">
        <g transform="translate(137 1029)">
          <path d="m4.5 15.3v-6.8c0-0.55228-0.44772-1-1-1h-2.5c-0.55228 0-1 0.44772-1 1v11h22v-11c0-0.55228-0.44772-1-1-1h-2.5c-0.55228 0-1 0.44772-1 1v6.8h-13z" />
          <path d="m2 7v-3c0-2.2091 1.7909-4 4-4h10c2.2091-4.0581e-16 4 1.7909 4 4v3" />
          <line x1="4" x2="18" y1="11" y2="11" />
          <line x1="4.5" x2="4.5" y1="23.5" y2="20.5" />
          <line x1="17.5" x2="17.5" y1="20.5" y2="23.5" />
        </g>
      </g>
    </g>
  </svg>
);

ArmchairIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default ArmchairIcon;
