import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-869 -4844)">
      <g transform="translate(119 4429)">
        <g transform="translate(0 409)">
          <g transform="translate(751 7)">
            <line x1=".54888" x2="17.473" y1="17.247" y2=".54847" />
            <path d="m0.54888 0.54903 16.924 16.699-8.6708-8.6708" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CloseIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CloseIcon;
