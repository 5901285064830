export default {
  fakeHousematePosts:  [{
    move_in: '2020-09-06',
    move_out: '2020-12-15',
    updated_at: '2020-06-04T12:34:24Z',
    cities: [{
      name: 'San Francisco',
      neighborhoods: 'Mission, Potrero Hill, SOMA',
    }, {
      name: 'Oakland',
    }],
    user: {
      id: 'b9405792-2059-497f-8781-d701cac11a60',
      first_name: 'Courtney',
      profile_image: {
        url_160: 'https://cdn.kopa.co/ecc2bfbe-69d2-4c54-8622-7e43a0c3bc98/-/quality/smart/-/resize/x160/-/progressive/yes/',
      },
      gender: 'female',
      languages: [
        'English',
        'Spanish',
      ],
      personal_pronoun: 'she_her_hers',
    },
    age: '24',
    cigarettes: 'never',
    alcohol: 'sometimes',
    marijuana: 'never',
    cleanliness: 'weekly',
    looking_for: 'Hoping to find some chill people in their 20s with a similar lifestyle that I can become friends with.',
    budget: 2000,
    room_type: ['private'],
  }, {
    move_in: '2020-09-06',
    move_out: '2020-12-15',
    updated_at: '2020-06-01T12:34:24Z',
    cities: [{
      name: 'San Francisco',
      neighborhoods: 'Mission, Potrero Hill, SOMA',
    }, {
      name: 'Oakland',
    }],
    user: {
      id: '410b06b0-590d-11ea-a7ed-af45ca3976c0',
      first_name: 'Renter',
      profile_image: {
        url_160: 'https://cdn.kopa.co/fe456a87-f89d-4cae-87cf-caf8344f5632/-/quality/smart/-/resize/x160/-/progressive/yes/',
      },
      gender: 'male',
      languages: [
        'English',
      ],
      personal_pronoun: 'he_him_his',
    },
    age: '25',
    cigarettes: 'never',
    alcohol: 'sometimes',
    marijuana: 'never',
    cleanliness: 'weekly',
    looking_for: 'Hoping to find some chill people in their 20s with a similar lifestyle that I can become friends with.',
    budget: 2000,
    room_type: ['private'],
  }, {
    move_in: '2020-09-06',
    move_out: '2020-12-20',
    updated_at: '2020-04-01T12:34:24Z',
    cities: [{
      name: 'San Francisco',
      neighborhoods: 'Mission, Potrero Hill, Lower Haight',
    }],
    user: {
      id: 'b9405792-2059-497f-8781-d701cac11a61',
      first_name: 'Keith',
      profile_image: {
        url_160: 'https://cdn.kopa.co/c727f664-b505-4f8e-921e-11e53fa299dd/-/quality/smart/-/resize/x160/-/progressive/yes/',
      },
      languages: [
        'English',
        'Spanish',
      ],
      gender: 'male',
      personal_pronoun: 'he_him_his',
    },
    age: '33',
    cigarettes: 'never',
    alcohol: 'sometimes',
    marijuana: 'never',
    cleanliness: 'weekly',
    looking_for: 'Hoping to find some chill people in their 20s with a similar lifestyle that I can become friends with.',
    budget: 2000,
    room_type: ['private'],
  }, {
    move_in: '2020-09-06',
    move_out: '2020-12-20',
    updated_at: '2020-04-01T12:34:24Z',
    cities: [{
      name: 'San Francisco',
      neighborhoods: 'Potrero Hill',
    }],
    user: {
      id: '410b7be0-590d-11ea-a7ed-af45ca3976c0',
      first_name: 'Jack',
      profile_image: {
        url_160: 'https://cdn.kopa.co/ef6e3118-730e-4dbd-8e40-77806d0e621a/-/quality/smart/-/resize/x160/-/progressive/yes/',
      },
      languages: [
        'English',
      ],
      gender: 'male',
      personal_pronoun: 'he_him_his',
    },
    age: '26',
    cigarettes: 'never',
    alcohol: 'sometimes',
    marijuana: 'never',
    cleanliness: 'weekly',
    looking_for: 'Hoping to find some chill people in their 20s with a similar lifestyle that I can become friends with.',
    budget: 2000,
    room_type: ['private'],
  }],
  habitOptions: [{
    value: 'never',
    label: 'Never',
  }, {
    value: 'sometimes',
    label: 'Sometimes',
  }, {
    value: 'regularly',
    label: 'Regularly',
  }],
  habitFilterOptions: [{
    value: 'never',
    label: 'Never',
  }, {
    value: 'sometimes_less',
    label: 'Sometimes or less',
  }, {
    value: 'regularly',
    label: 'Regularly',
  }],
  cleanOptions: [{
    value: 'never',
    label: 'Never',
  }, {
    value: 'daily',
    label: 'Daily',
  }, {
    value: 'weekly',
    label: 'Weekly',
  }, {
    value: 'monthly',
    label: 'Monthly',
  }],
};
