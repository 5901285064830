import PropTypes from 'prop-types';

const ProductionOnlyComponent = ({ children }) => {
  if (process.env.ENV !== 'production') { return null; }

  return (children);
};

ProductionOnlyComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductionOnlyComponent;
