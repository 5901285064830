import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ======================
//   GET MENTIONS
// ======================
const getMentionsLoading = () => ({
  type: 'GET_MENTIONS_LOADING',
});

const getMentionsSuccess = (mentions) => ({
  type: 'GET_MENTIONS_SUCCESS',
  mentions,
});

const getMentionsFailure = (error) => ({
  type: 'GET_MENTIONS_FAILURE',
  error,
});

const getMentionsRequest = (user) => async (dispatch) => {
  dispatch(getMentionsLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${user.get('id')}/conversation_mentions`,
      method: 'GET',
    });
    dispatch(getMentionsSuccess(response));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      getMentionsFailure,
      true,
      'There was an issue getting mention data.'
    );
  }
};

export default {
  getMentionsRequest,
};
