import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: {},
  error: null,
  isLoading: false,
  review: {},
});

const UserReviews = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('UserReviews').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('UserReviews') : state)
      );
    } else {
      return state;
    }

  // Company
  case 'RETRIEVE_COMPANY_REVIEWS_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_COMPANY_REVIEWS_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      object: fromJS(action.reviews),
    });
  case 'RETRIEVE_COMPANY_REVIEWS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  // User
  case 'RETRIEVE_USER_REVIEWS_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_USER_REVIEWS_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      object: fromJS(action.reviews),
    });
  case 'RETRIEVE_USER_REVIEWS_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'RETRIEVE_USER_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'RETRIEVE_USER_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      review: fromJS(action.review),
    });
  case 'RETRIEVE_USER_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'CHECK_USER_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CHECK_USER_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      review: state.get('review').merge(fromJS(action.review)),
    });
  case 'CHECK_USER_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'CREATE_USER_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CREATE_USER_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      review: state.get('review').merge(fromJS(action.review)),
    });
  case 'CREATE_USER_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  case 'UPDATE_USER_REVIEW_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'UPDATE_USER_REVIEW_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
      review: state.get('review').merge(fromJS(action.review)),
    });
  case 'UPDATE_USER_REVIEW_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default UserReviews;
