import React from 'react';
import PropTypes from 'prop-types';

const SquiggleArrowRightIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" fillRule="evenodd" stroke="currentColor" viewBox="0 0 77 80" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.609 78.5c-5.957-18.951 17.87-48.958 35.342-41.061 17.472 7.896 13.898 21.715 0 18.161-13.899-3.553-10.722-28.427 5.162-39.877C52.703 8.09 63.556 5.195 74.675 7.037" transform="translate(-334 -557) translate(220 557.5) translate(114.325)" />
    <path d="M69.037.83l5.526 6.714c-2.663.475-5.032 1.528-7.106 3.161" transform="translate(-334 -557) translate(220 557.5) translate(114.325) rotate(-10 71.01 5.768)" />
  </svg>
);

SquiggleArrowRightIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default SquiggleArrowRightIcon;
