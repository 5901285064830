export default {
  tabs: [
    { id: 1, key: 'basics', title: '1. Basics', value: 'Basics' },
    { id: 2, key: 'availability', title: '2. Availability', value: 'Availability' },
    { id: 3, key: 'pricing', title: '3. Pricing', value: 'Pricing' },
    { id: 4, key: 'details', title: '4. Details', value: 'Details' },
    { id: 5, key: 'visuals', title: '5. Visuals', value: 'Visuals' },
    { id: 6, key: 'title_description', title: '6. Title & Description', value: 'Title & Description' },
    { id: 7, key: 'management', title: '7. Management', value: 'Management' },
    { id: 8, key: 'verification', title: '8. Verification', value: 'Verification' },
  ],
  tab1: {
    bathroomChoices: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8],
    bedroomChoices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    sleepingAreaChoices: [0, 1, 2, 3, 4, 5],
    sleepingAreaTypeMap: {
      basement: 'Basement',
      bedroom: 'Bedroom',
      den: 'Den',
      dining: 'Dining room', // This is 'dining' to match the photo identifier value
      garage: 'Garage',
      family_room: 'Family room',
      loft: 'Loft',
      living: 'Living room', // This is 'living' to match the photo identifier value
      mudroom: 'Mudroom',
      office: 'Office',
    },
    propertyOwnershipOptions: [
      { value: 'live', label: 'I own it, and I will be living in and sharing the space with renters.' },
      { value: 'own', label: 'I own it, but I will not be living in and sharing the space with renters. ' },
      { value: 'manage', label: 'I\'m the property manager.' },
      { value: 'sublet', label: 'I\'m subleasing it, and I live there.' },
      { value: 'sublet_live', label: 'I\'m subleasing it, and I don\'t live there.' },
    ],
    furnishingsOptions: [
      { value: 'fully_furnished', label: 'Fully furnished' },
      { value: 'partially_furnished', label: 'Partially furnished' },
      { value: 'unfurnished', label: 'Unfurnished' },
    ],
    furnishingsValues: {
      fullyFurnished: 'fully_furnished',
      partiallyFurnished: 'partially_furnished',
      unfurnished: 'unfurnished',
    },
    propertyOwnershipValues: {
      live: 'live',
      own: 'own',
      manage: 'manage',
      sublet: 'sublet',
      subletLive: 'sublet_live',
    },
    propertyTypeMap: {
      apartment: 'Apartment',
      guest: 'Guest house',
      house: 'House',
      loft: 'Loft',
      condo: 'Condo',
      townhouse: 'Townhouse',
      hotel: 'Hotel',
      duplex: 'Duplex',
      floor: 'Flat',
      mobile: 'Mobile home',
      boat: 'House boat',
      other: 'Other',
    },
    propertyTypeMapPlural: {
      apartment: 'Apartments',
      guest: 'Guest houses',
      house: 'Houses',
      loft: 'Lofts',
      condo: 'Condos',
      townhouse: 'Townhouses',
      hotel: 'Hotels',
      duplex: 'Duplexes',
      floor: 'Flats',
      mobile: 'Mobile homes',
      boat: 'House boats',
      other: 'Other',
    },
    rentalTypeOptionsFurnished: [
      { value: 'true', label: 'By bed', subLabel: 'Renters can book an individual bed in your space. All beds are booked and paid for separately.' },
      { value: 'false', label: 'By entire space', subLabel: 'A renter must book the entire space you’re renting.' },
    ],
    rentalTypeOptionsNotFurnished: [
      { value: 'true', label: 'By bedroom', subLabel: 'Renters can book an individual bedroom in your space. All bedrooms are booked and paid for separately. ' },
      { value: 'false', label: 'By entire space', subLabel: 'A renter must book the entire space you’re renting.' },
    ],
    bedTypeMap: {
      single: 'Single',
      singlexl: 'Single XL',
      double: 'Double',
      queen: 'Queen',
      king: 'King',
      bunk: 'Bunk Bed',
      air: 'Air mattress',
      water: 'Water Bed',
      sofa: 'Sofa bed',
    },
    sleepingAreaBedTypeMap: {
      air: 'Air mattress',
      daybed: 'Daybed',
      sofa: 'Sofa bed',
    },
  },
  tab4: {
    accessibleOptions: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
    amenityOptions: [
      { id: 'cookware', title: 'Cookware', icon: 'cookware' },
      { id: 'tableware', title: 'Tableware', icon: 'tableware' },
      { id: 'bedding', title: 'Bedding', icon: 'bedding' },
      { id: 'linens', title: 'Linens', icon: 'linens' },
      { id: 'wifi', title: 'Wifi', icon: 'wifi' },
      { id: 'kitchen', title: 'Kitchen', icon: 'kitchen' },
      { id: 'tv', title: 'Television', icon: 'television' },
      { id: 'cable', title: 'Cable', icon: 'cable' },
      { id: 'hair_dryer', title: 'Hair dryer', icon: 'hairDryer' },
      { id: 'iron', title: 'Iron', icon: 'iron' },
      { id: 'heating', title: 'Heating', icon: 'heating' },
      { id: 'air_conditioning', title: 'Air conditioning', icon: 'airConditioning' },
      { id: 'elevator', title: 'Elevator', icon: 'elevator' },
      { id: 'pool', title: 'Pool', icon: 'pool' },
      { id: 'gym', title: 'Gym', icon: 'gym' },
      { id: 'smoking', title: 'Smoking allowed', icon: 'smoking' },
      { id: 'cleaning', title: 'Professional cleaning', icon: 'cleaning' },
    ],
    garbageDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'N/A'],
    hours: ['12:00 a.m.', '1:00 a.m.', '2:00 a.m.', '3:00 a.m.', '4:00 a.m.', '5:00 a.m.', '6:00 a.m.', '7:00 a.m.', '8:00 a.m.', '9:00 a.m.', '10:00 a.m.', '11:00 a.m.', '12:00 p.m.', '1:00 p.m.', '2:00 p.m.', '3:00 p.m.', '4:00 p.m.', '5:00 p.m.', '6:00 p.m.', '7:00 p.m.', '8:00 p.m.', '9:00 p.m.', '10:00 p.m.', '11:00 p.m.'],
    hoursReverseed: ['12:00 a.m.', '11:00 p.m.', '10:00 p.m.', '9:00 p.m.', '8:00 p.m.', '7:00 p.m.', '6:00 p.m.', '5:00 p.m.', '4:00 p.m.', '3:00 p.m.', '2:00 p.m.', '1:00 p.m.', '12:00 p.m.', '11:00 a.m.', '10:00 a.m.', '9:00 a.m.', '8:00 a.m.', '7:00 a.m.', '6:00 a.m.', '5:00 a.m.', '4:00 a.m.', '3:00 a.m.', '2:00 a.m.', '1:00 a.m.'],
    laundryOptions: [
      { value: 'unit', label: 'Laundry in-unit' },
      { value: 'building', label: 'Laundry in-building' },
      { value: 'on_site', label: 'Laundry on-site' },
      { value: 'none', label: 'No laundry on premises' },
    ],
    parkingOptions: [
      { value: 'garage', label: 'Garage parking' },
      { value: 'private_driveway', label: 'Private driveway' },
      { value: 'free_street', label: 'Free street parking' },
      { value: 'paid_street', label: 'Paid street parking' },
      { value: 'carport', label: 'Carport parking' },
      { value: 'none', label: 'No parking' },
    ],
    petsOptions: [
      { value: 'all_pets', label: 'All pets are allowed' },
      { value: 'cats_dogs', label: 'Cats and dogs' },
      { value: 'dogs', label: 'Dogs' },
      { value: 'cats', label: 'Cats' },
      { value: 'none', label: 'No pets allowed' },
    ],
    ruleOptions: [
      { id: 'shoes', type: 'stock', title: 'Please do not wear shoes in the space.' },
      { id: 'candles', type: 'stock', title: 'Do not light candles or use incense.' },
      { id: 'smoking', type: 'stock', title: 'No smoking inside.' },
      { id: 'smokingOutside', type: 'stock', title: 'No smoking around premises.' },
      { id: 'parties', type: 'stock', title: 'No parties.' },
      { id: 'quiet', type: 'stock', title: 'Please respect quiet hours from ', hasStart: true, hasEnd: true, start: '10:00 p.m.', end: '8:00 a.m.' },
    ],
    utilityOptions: [
      { value: 'wifi', label: 'Wifi' },
      { value: 'electric', label: 'Electric' },
      { value: 'gas', label: 'Gas' },
      { value: 'water', label: 'Water' },
      { value: 'garbage', label: 'Garbage' },
    ],
    wifi: [5, 25, 50, 80, 100, 150, 200, 1000, 1500],
  },
};
