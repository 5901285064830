import { toast } from 'react-toastify';
import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   UPDATE COMPANY MEMBER (in state)
// ==========================
const updateCompanyMembers = (members) => ({
  type: 'UPDATE_COMPANY_MEMBERS',
  members,
});

// ====================================
//   COMPANY MEMBER INVITES
// ====================================
const sendCompanyMemberInvitesLoading = () => ({
  type: 'SEND_COMPANY_MEMBER_INVITES_LOADING',
});

const sendCompanyMemberInvitesSuccess = (companyMemberInvites) => ({
  type: 'SEND_COMPANY_MEMBER_INVITES_SUCCESS',
  companyMemberInvites,
});

const sendCompanyMemberInvitesFailure = (error) => ({
  type: 'SEND_COMPANY_MEMBER_INVITES_FAILURE',
  error,
});

const sendCompanyMemberInvites = (companyId, companyMemberInvites) => async (dispatch) => {
  dispatch(sendCompanyMemberInvitesLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/company_members/bulk_invites`,
      method: 'POST',
      data: JSON.stringify({ invites: companyMemberInvites }),
    });

    dispatch(sendCompanyMemberInvitesSuccess(companyMemberInvites));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      sendCompanyMemberInvitesFailure,
      true,
      'There was an issue sending your invites.'
    );
  }
};

// ====================================
//   COMPANY MEMBER INVITE
// ====================================
const sendCompanyMemberInviteLoading = () => ({
  type: 'SEND_COMPANY_MEMBER_INVITE_LOADING',
});

const sendCompanyMemberInviteSuccess = (companyMemberInvite) => ({
  type: 'SEND_COMPANY_MEMBER_INVITE_SUCCESS',
  companyMemberInvite,
});

const sendCompanyMemberInviteFailure = (error) => ({
  type: 'SEND_COMPANY_MEMBER_INVITE_FAILURE',
  error,
});

const sendCompanyMemberInvite = (companyId, companyMemberInvite) => async (dispatch) => {
  dispatch(sendCompanyMemberInviteLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/company_members/invite`,
      method: 'POST',
      data: JSON.stringify(companyMemberInvite),
    });
    toast('Company member invite sent', {
      type: toast.TYPE.SUCCESS,
    });
    dispatch(sendCompanyMemberInviteSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      sendCompanyMemberInviteFailure,
      true,
      'There was an issue sending your invites.'
    );
    throw error;
  }
};

// ====================================
//   COMPANY MEMBER RESEND INVITE
// ====================================
const resendCompanyMemberInviteLoading = () => ({
  type: 'RESEND_COMPANY_MEMBER_INVITE_LOADING',
});

const resendCompanyMemberInviteSuccess = () => ({
  type: 'RESEND_COMPANY_MEMBER_INVITE_SUCCESS',
});

const resendCompanyMemberInviteFailure = (error) => ({
  type: 'RESEND_COMPANY_MEMBER_INVITE_FAILURE',
  error,
});

const resendCompanyMemberInvite = (companyId, memberId) => async (dispatch) => {
  dispatch(resendCompanyMemberInviteLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/company_members/${memberId}/resend_invite`,
      method: 'POST',
    });
    toast('Member invite resent', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(resendCompanyMemberInviteSuccess());
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      resendCompanyMemberInviteFailure,
      true,
      'There was an issue resending your invite.'
    );
  }
};

// ====================================
//   COMPANY MEMBER CANCEL INVITE
// ====================================
const cancelCompanyMemberInviteLoading = () => ({
  type: 'CANCEL_COMPANY_MEMBER_INVITE_LOADING',
});

const cancelCompanyMemberInviteSuccess = (memberId) => ({
  type: 'CANCEL_COMPANY_MEMBER_INVITE_SUCCESS',
  memberId,
});

const cancelCompanyMemberInviteFailure = (error) => ({
  type: 'CANCEL_COMPANY_MEMBER_INVITE_FAILURE',
  error,
});

const cancelCompanyMemberInvite = (companyId, memberId) => async (dispatch) => {
  dispatch(cancelCompanyMemberInviteLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/company_members/${memberId}/cancel_invite`,
      method: 'POST',
    });

    dispatch(cancelCompanyMemberInviteSuccess(memberId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      cancelCompanyMemberInviteFailure,
      true,
      'There was an issue cancelling your invite.'
    );
  }
};

// =====================================
//   UPDATE COMPANY MEMBER REQUEST
// =====================================
const updateCompanyMemberRoleRequestLoading = () => ({
  type: 'UPDATE_COMPANY_MEMBER_ROLE_REQUEST_LOADING',
});

const updateCompanyMemberRoleRequestSuccess = (companyId, memberId, role) => ({
  type: 'UPDATE_COMPANY_MEMBER_ROLE_REQUEST_SUCCESS',
  companyId,
  memberId,
  role,
});

const updateCompanyMemberRoleRequestFailure = (error) => ({
  type: 'UPDATE_COMPANY_MEMBER_ROLE_REQUEST_FAILURE',
  error,
});

const updateCompanyMemberRoleRequest = (companyId, memberId, data) => async (dispatch) => {
  dispatch(updateCompanyMemberRoleRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/company_members/${memberId}/role`,
      method: 'POST',
      data: JSON.stringify(data),
    });
    toast('Company member role updated', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(updateCompanyMemberRoleRequestSuccess(companyId, memberId, data.role));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateCompanyMemberRoleRequestFailure,
      true,
      'There was an issue updating member role.'
    );
  }
};

// ====================================
//   GET COMPANY MEMBERS REQUEST
// ====================================
const getCompanyMembersRequestLoading = () => ({
  type: 'GET_COMPANY_MEMBERS_LOADING',
});

const getCompanyMembersRequestSuccess = (members) => ({
  type: 'GET_COMPANY_MEMBERS_SUCCESS',
  members: { ...members },
});

const getCompanyMembersRequestFailure = (error) => ({
  type: 'GET_COMPANY_MEMBERS_FAILURE',
  error,
});

const getCompanyMembersRequest = (companyId) => async (dispatch) => {
  dispatch(getCompanyMembersRequestLoading());
  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/company_members`,
      method: 'GET',
    });

    dispatch(getCompanyMembersRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      getCompanyMembersRequestFailure,
      true,
      'There was an issue getting your fellow property managers.'
    );
  }
};

// =============================
//   DELETE MEMBER
// =============================
const deleteCompanyMemberRequestLoading = () => ({
  type: 'DELETE_COMPANY_MEMBER_REQUEST_LOADING',
});

const deleteCompanyMemberRequestSuccess = (memberId) => ({
  type: 'DELETE_COMPANY_MEMBER_REQUEST_SUCCESS',
  memberId,
});

const deleteCompanyMemberRequestFailure = () => ({
  type: 'DELETE_COMPANY_MEMBER_REQUEST_FAILURE',
});

const deleteCompanyMemberRequest = (companyId, memberId) => async (dispatch) => {
  dispatch(deleteCompanyMemberRequestLoading());

  try {
    await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/company_members/${memberId}`,
      method: 'DELETE',
    });
    toast('Member removed', {
      type: toast.TYPE.SUCCESS,
    });

    dispatch(deleteCompanyMemberRequestSuccess(memberId));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      deleteCompanyMemberRequestFailure
    );
    throw error;
  }
};

export default {
  // get actions
  getCompanyMembersRequest,

  // update actions
  sendCompanyMemberInvites,
  sendCompanyMemberInvite,
  resendCompanyMemberInvite,
  cancelCompanyMemberInvite,
  updateCompanyMemberRoleRequest,
  updateCompanyMembers,

  // delete actions
  deleteCompanyMemberRequest,
};
