import React from 'react';
import PropTypes from 'prop-types';

const LoftIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <polyline points="21 1 21 23.5 3 23.5 3 4.3333" />
      <rect height="8" width="5" x="12" y="15" />
      <line x1="23.5" x2=".5" y1="1" y2="4.5" />
      <rect height="4" width="7" x="6.5" y="7" />
    </g>
  </svg>
);

LoftIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default LoftIcon;
