import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  commuteTimes: {},
  commuteTimesLoading: false,
  featuredListings: [],
  featuredListingsLoading: true,
  list: [],
  listingsCount: null,
  listingsCountLoading: false,
  isLoading: true,
  meta: {
    page: 0,
    offset: 0,
  },
});

const ListingSearch = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('ListingSearch').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('ListingSearch') : state)
      );
    } else {
      return state;
    }
    // Retrieve listings
  case 'RETRIEVE_LISTINGS_LOADING':
    return state.merge({
      isLoading: true,
      list: fromJS([]),
    });

  case 'RETRIEVE_LISTINGS_SUCCESS':
    return state.merge({
      list: fromJS(action.listings),
      meta: fromJS(action.meta),
      isLoading: false,
    });

  case 'RETRIEVE_LISTINGS_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Retrieve listings count
  case 'RETRIEVE_LISTINGS_COUNT_LOADING':
    return state.merge({
      listingsCountLoading: true,
    });

  case 'RETRIEVE_LISTINGS_COUNT_SUCCESS':
    return state.merge({
      listingsCount: fromJS(action.count),
      listingsCountLoading: false,
    });

  case 'RETRIEVE_LISTINGS_COUNT_FAILURE':
    return state.merge({
      listingsCountLoading: false,
      error: action.error,
    });

    // Retrieve commute times
  case 'RETRIEVE_COMMUTE_TIMES_LOADING':
    return state.merge({
      commuteTimesLoading: true,
    });

  case 'RETRIEVE_COMMUTE_TIMES_SUCCESS':
    return state.merge({
      commuteTimes: fromJS(action.commuteTimes),
      commuteTimesLoading: false,
    });

  case 'RETRIEVE_COMMUTE_TIMES_FAILURE':
    return state.merge({
      commuteTimesLoading: false,
      error: action.error,
    });

    // Retrieve featured listings
  case 'RETRIEVE_FEATURED_LISTINGS_LOADING':
    return state.merge({
      featuredListingsLoading: true,
    });

  case 'RETRIEVE_FEATURED_LISTINGS_SUCCESS':
    return state.merge({
      featuredListings: fromJS(action.featuredListings),
      featuredListingsLoading: false,
    });

  case 'RETRIEVE_FEATURED_LISTINGS_FAILURE':
    return state.merge({
      featuredListingsLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default ListingSearch;
