import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M 2 5 h19 M 2 12 h19 M 2 19 h11" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2px" />
  </svg>
);

MenuIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default MenuIcon;
