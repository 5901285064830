import isUUID from 'validator/lib/isUUID';

const capitalizeFirstLetter = (string, lowerCaseOtherLetters = false) => {
  if (!string || string.length === 0) {
    return '';
  }

  return string.charAt(0).toUpperCase() + (lowerCaseOtherLetters ? string.slice(1).toLowerCase() : string.slice(1));
};

const formatPhoneNumber = (number) => {
  return number.toString().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

const formatUrlSlug = (string) => {
  if (!string) return string;

  return string.replace(/\s/g, '-').replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/gi, '').toLowerCase();
};

const getLastMessageSnippet = (lastMessage) => {
  const messageType = lastMessage.messageType || lastMessage.type; // This is different for Sendbird/Kopa messages;

  if (messageType === 'file' && messageType === 'image') {
    return 'Uploaded an image';
  } else if (messageType === 'file') {
    return 'Uploaded a file';
  } else {
    return lastMessage.message;
  }
};

const listConcatenation = (list = [], capitalizeFirstLetter = false, useAnd = true) => {
  let str = '';
  list.forEach((item, i) => {
    if (list.length > 1 && i === list.length - 1) {
      str += `${useAnd ? 'and ' : ''}${item}`;
    } else {
      if (list.length === 2) {
        str += `${item}${useAnd ? '' : ','} `;
      } else {
        str += `${item}, `;
      }
    }
    if (list.length === 1) {
      str = str.substring(0, str.length - 2); // remove last comma
    }
  });
  if (capitalizeFirstLetter) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

const pluralize = (string, count) => {
  if (count && count === 1) return string;

  return `${string}s`;
};

const sortByString = (a, b) => {
  if (!a || !b) return 0;

  return a.localeCompare(b, undefined, { sensitivity: 'base' });
};

const validateId = (id) => {
  if (!id || typeof id !== 'string') return false;

  if (isUUID(id) || isSlug(id)) return id;

  // If its a slug url then pull the slug out
  let paramsArray = id.split('-');
  if (isSlug(paramsArray[paramsArray.length - 1])) {
    return paramsArray[paramsArray.length - 1];
  }

  return false;
};

// PRIVATE
const isSlug = (str) => {
  return (str.length === 5 || str.length === 6);
};

export default {
  capitalizeFirstLetter,
  formatPhoneNumber,
  formatUrlSlug,
  getLastMessageSnippet,
  listConcatenation,
  pluralize,
  sortByString,
  validateId,
};
