import React from 'react';
import PropTypes from 'prop-types';

const CircleDollar = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.63 15.272V14.06c1.271-.216 2.423-1.008 2.423-2.496 0-1.38-.996-1.968-1.968-2.34-.948-.372-1.884-.54-1.884-1.056 0-.48.432-.732.852-.732.684 0 1.224.336 1.728.732l1.044-1.296c-.684-.504-1.344-.816-2.112-.936V4.76h-1.08v1.152c-1.308.12-2.292.888-2.292 2.364 0 1.296.936 1.812 1.872 2.16.936.348 1.872.552 1.872 1.236 0 .54-.36.888-1.284.888-.78 0-1.368-.564-1.836-.996l-1.2 1.164c.828.816 1.668 1.296 2.784 1.38v1.164h1.08z" fill="currentColor" fillRule="nonzero" />
    <circle cx="10" cy="10" r="9" stroke="currentColor" strokeWidth="2" />
  </svg>
);

CircleDollar.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CircleDollar;
