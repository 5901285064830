import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  object: {},
  isLoading: true,
  paymentIsLoading: false,
});

const TenancyGroup = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('TenancyGroup').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('TenancyGroup') : state)
      );
    } else {
      return state;
    }

  case 'RETRIEVE_TENANCY_GROUP_REQUEST_LOADING':
  case 'CREATE_TENANCY_GROUP_DEPOSIT_RETURN_LOADING':
    return state.merge({
      isLoading: true,
      paymentIsLoading: true,
    });

  case 'RETRIEVE_TENANCY_GROUP_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.data),
      isLoading: false,
      paymentIsLoading: false,
    });
  case 'CREATE_TENANCY_GROUP_DEPOSIT_RETURN_SUCCESS':
    return state.merge({
      isLoading: false,
      paymentIsLoading: false,
    });
  case 'RETRIEVE_TENANCY_GROUP_REQUEST_FAILURE':
  case 'CREATE_TENANCY_GROUP_DEPOSIT_RETURN_FAILURE':
    return state.merge({
      isLoading: false,
      paymentIsLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default TenancyGroup;
