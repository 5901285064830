import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@components/Core/Loader';

const isLoadingAnimation = (isSecondary = false) => <Loader className={`loader--x-small m-horizontal--auto ${isSecondary ? '' : 'loader--white'}`} />;

const Button = ({
  children, className, disabled, id, isLoading, type, ariaLabel, targetClassName,
  onClick, onMouseEnter, onMouseLeave, onTouchStart, onTouchEnd,
}) => (
  <div className={`button-wrapper ${className}`} id={id}>
    <button
      aria-label={ariaLabel}
      className={targetClassName}
      disabled={isLoading || disabled}
      onClick={onClick ? (e) => onClick(e) : null}
      onMouseEnter={onMouseEnter ? (e) => onMouseEnter(e) : null}
      onMouseLeave={onMouseLeave ? (e) => onMouseLeave(e) : null}
      onTouchEnd={onTouchEnd ? (e) => onTouchEnd(e) : () => {}}
      onTouchStart={onTouchStart ? (e) => onTouchStart(e) : () => {}} /* Note: this function needs to be called even if onTouchStart is not passed in to improve mobile button handling */
      type={type} /* Note: this function needs to be called even if onTouchEnd is not passed in to improve mobile button handling */
    >
      { isLoading ? isLoadingAnimation(className && (className.includes('secondary') || className.includes('link'))) : children }
    </button>
  </div>
);

Button.propTypes = {
  // Optional
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  targetClassName: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  type: PropTypes.string,
};

Button.defaultProps = {
  ariaLabel: undefined,
  children: null,
  className: '',
  targetClassName: '',
  disabled: false,
  id: '',
  isLoading: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  onTouchStart: null,
  onTouchEnd: null,
  type: 'button',
};

export default Button;
