import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { fromJS } from 'immutable';
import AddressAutoComplete from '@components/Forms/AddressAutoComplete';
import Actions from '@redux/actions';
import AnalyticsService from '@services/AnalyticsService';
import SearchFilterUtility from '@utilities/SearchFilterUtility';
import StringUtility from '@utilities/StringUtility';

class LocationSearch extends Component {
  constructor(props) {
    super(props);

    this.analyticsService = new AnalyticsService();
  }

  handleLocationInputChange = (key, event) => {
    const { value } = event.target;
    const { updateFiltersAction } = this.props;

    updateFiltersAction(fromJS({ citySearchText: value }));
  }

  handleLocationSelect = (result) => {
    let { updateFiltersAction } = this.props;

    if (!result.city && !result.state_short) {
      return;
    }

    const searchPageType = result.city
      ? SearchFilterUtility.searchPageTypes.city
      : SearchFilterUtility.searchPageTypes.state;

    const filtersToUpdate = {
      city: result.city,
      citySearchText: SearchFilterUtility.getSearchText(searchPageType, result.city, result.state, result.state_short),
      citySlug: StringUtility.formatUrlSlug(result.city),
      listingSearchChangedSource: SearchFilterUtility.listingSearchChangedSource.city,
      searchLatitude: result.coords.latitude,
      searchLongitude: result.coords.longitude,
      searchPageType: searchPageType,
      state: result.state,
      stateSlug: StringUtility.formatUrlSlug(result.state),
      stateShort: result.state_short,
      zoom: searchPageType === SearchFilterUtility.searchPageTypes.city
        ? SearchFilterUtility.defaultZoomLevels.city
        : SearchFilterUtility.defaultZoomLevels.state,
    };

    updateFiltersAction(
      fromJS(filtersToUpdate)
    );

    this.analyticsService.track('Location Filter Selected', {
      filter: 'location',
      city: result.city,
      state: result.state_short,
      country: result.country,
    });
  }

  render() {
    const { filters, identifier } = this.props;

    return (
      <AddressAutoComplete
        addressInputId={identifier}
        handleAddressChange={this.handleLocationSelect}
        handleAddressItemChange={this.handleLocationInputChange}
        name="city"
        placeholder="City or town"
        regionsOnly
        value={filters.get('citySearchText')}
      />
    );
  }
}

LocationSearch.propTypes = {
  // Required
  filters: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  updateFiltersAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.get('Search').get('filters'),
});

export default withRouter(
  connect(mapStateToProps, {
    updateFiltersAction: Actions.updateFilters,
  })(LocationSearch)
);
