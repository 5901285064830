import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   CREATE EDUCATION
// ==========================
const createEducationLoading = () => ({
  type: 'CREATE_EDUCATION_REQUEST_LOADING',
});

const createEducationSuccess = (education) => ({
  type: 'CREATE_EDUCATION_REQUEST_SUCCESS',
  education,
});

const createEducationFailure = () => ({
  type: 'CREATE_EDUCATION_REQUEST_FAILURE',
});

const createEducationRequest = (userId, education) => async (dispatch) => {
  dispatch(createEducationLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/education`,
      method: 'POST',
      data: JSON.stringify(education),
    });
    dispatch(createEducationSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createEducationFailure
    );
  }
};

// ==========================
//   UPDATE EDUCATION
// ==========================
const updateEducationLoading = () => ({
  type: 'UPDATE_EDUCATION_REQUEST_LOADING',
});

const updateEducationSuccess = (education) => ({
  type: 'UPDATE_EDUCATION_REQUEST_SUCCESS',
  education,
});

const updateEducationFailure = () => ({
  type: 'UPDATE_EDUCATION_REQUEST_FAILURE',
});

const updateEducationRequest = (educationId, education) => async (dispatch) => {
  dispatch(updateEducationLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/education/${educationId}`,
      method: 'PUT',
      data: JSON.stringify(education),
    });
    dispatch(updateEducationSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateEducationFailure
    );
  }
};

export default {
  createEducationRequest,
  updateEducationRequest,
};
