import React from 'react';
import PropTypes from 'prop-types';

const TooltipIcon = (props) => (
  <svg aria-hidden="true" className={props.className} onClick={props.onClick} version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
      <path d="m8.8957 9.132v-0.948c0.936-0.492 1.74-1.008 1.74-2.436 0-1.092-0.96-2.292-2.532-2.292-1.452 0-2.556 0.984-2.556 2.484v0.204h1.668v-0.252c0-0.636 0.324-1.02 0.888-1.02 0.576 0 0.876 0.432 0.876 0.912 0 0.9-0.864 1.2-1.824 1.524v1.824h1.74zm0.048 2.868v-1.788h-1.836v1.788h1.836z" fill="currentColor" fillRule="nonzero" />
    </g>
  </svg>
);

TooltipIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,

  // Optional
  onClick: PropTypes.func,
};

TooltipIcon.defaultProps = {
  onClick: () => {},
};

export default TooltipIcon;
