import React from 'react';
import PropTypes from 'prop-types';

const StarIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" viewBox="0 0 45 43" xmlns="http://www.w3.org/2000/svg">
    <path d="M260.87 351.103l-11.182 9.09c-.857.696-2.117.566-2.813-.291-.455-.56-.573-1.32-.31-1.991l5.234-13.357-12.113-7.734c-.93-.595-1.204-1.831-.61-2.762.39-.61 1.078-.962 1.8-.92l14.388.819 3.672-13.892c.282-1.068 1.377-1.705 2.445-1.423.695.184 1.238.727 1.422 1.423l3.672 13.892 14.388-.82c1.103-.062 2.048.78 2.11 1.883.041.722-.31 1.41-.92 1.8l-12.113 7.734 5.235 13.357c.403 1.028-.104 2.188-1.133 2.591-.67.264-1.432.145-1.991-.31l-11.181-9.089z" transform="translate(-238 -318)" />
  </svg>
);

StarIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default StarIcon;
