import React from 'react';
import PropTypes from 'prop-types';

const IronIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m25 10h20v22h-42c0-12.15 9.8497-22 22-22z" />
      <path d="m31 16h8v6h-14c0-3.3137 2.6863-6 6-6z" />
      <polyline points="7.9214 9.8097 12.317 9.5915 12.317 17.592 7.1905 17.682" transform="translate(9.7537 13.637) rotate(230) translate(-9.7537 -13.637)" />
      <polyline points="3 32.5 3 36 45 36 45 32" />
    </g>
  </svg>
);

IronIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default IronIcon;
