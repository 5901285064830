import { format } from 'date-fns';
import FeatureFlagConstants from '@domain/FeatureFlags/FeatureFlagConstants';
import FeatureFlags from '@domain/FeatureFlags/FeatureFlags';
import DateUtility from '@utilities/DateUtility';
import ProfileStatic from '@utilities/static/ProfileStatic';
import StringUtility from '@utilities/StringUtility';

const filterEmployers = (employers) => {
  if (!employers) {
    return null;
  }

  return employers.filter(experience => {
    return experience.get('position') &&
      experience.get('position').length > 0 &&
      experience.get('employer_name') &&
      experience.get('employer_name').length > 0 &&
      experience.get('start');
  });
};

const getCompanyCitiesString = (cities) => {
  if (!cities) {
    return null;
  }

  return StringUtility.listConcatenation(cities.map(c => c.get('name')).toJS());
};

const getDegreeType = (education) => {
  const levelValue = education.get('level');
  const levelOption = ProfileStatic.degreeLevels.find(l => l.value === levelValue);

  if (levelOption) {
    return levelOption.label;
  }

  return '';
};

const getEducationProgramAndGraduationDate = (education) => {
  const program = education.get('program');
  const endDate = education.get('graduation_year');

  if (program && endDate) {
    return program + ', ' + endDate;
  }

  return program;
};

const getEmployerDates = (employer) => {
  const formattedStartDate = format(DateUtility.parseISODate(employer.get('start')), 'MMMM yyyy');

  if (employer.get('end')) {
    return formattedStartDate + ' - ' + format(DateUtility.parseISODate(employer.get('end')), 'MMMM yyyy');
  }

  return formattedStartDate + ' - present';
};

const getGenderAndAgeLabel = (gender, age) => {
  const genderObj = ProfileStatic.genderOptions.find(o => o.value === gender);
  let genderText;
  let ageText;

  if (genderObj) {
    genderText = genderObj.label;
  }

  if (age) {
    ageText = age + ' years old';
  }

  if (genderText && ageText) {
    return genderText + ', ' + ageText;
  } else if (genderText) {
    return genderText;
  }

  return ageText;
};

const getLanguagesLabel = (languages) => {
  if (!languages) {
    return '';
  }

  const languageLabels = languages
    .filter(l => !!ProfileStatic.languageOptions[l])
    .map(l => ProfileStatic.languageOptions[l])
    .toJS();

  if (languageLabels.length > 0) {
    return 'Speaks ' + StringUtility.listConcatenation(languageLabels);
  }

  return '';
};

const getProfileBreadcrumbTitle = (firstName, isRenter) => {
  const pageViewTitle = isRenter ? 'Profile' : 'Rental Application';

  if (firstName.endsWith('s')) {
    return `${firstName}' ${pageViewTitle}`;
  }

  return `${firstName}'s ${pageViewTitle}`;
};

const getRenterRoleLabel = (renterRole) => {
  if (renterRole === 'full_time') {
    return 'Full-time professional';
  } else if (renterRole === 'part_time') {
    return 'Part-time professional';
  } else if (renterRole === 'student') {
    return 'Student';
  } else if (renterRole === 'intern') {
    return 'Intern';
  } else if (renterRole === 'unemployed') {
    return 'Not employed';
  }

  return null;
};

const hasSocialConnections = (socialConnections) => {
  return socialConnections && !!(
    socialConnections.get('instagram') ||
    socialConnections.get('twitter') ||
    socialConnections.get('linkedin') ||
    socialConnections.get('facebook')
  );
};

const isDirectBookingCompany = (company) => {
  return company &&
    FeatureFlags.hasFeatureFlag(company.get('company_feature_flags'), FeatureFlagConstants.DIRECT_BOOKING);
};

const isDirectBookingUser = (user) => {
  return user &&
    isDirectBookingCompany(user.get('company'));
};

export default {
  filterEmployers,
  getCompanyCitiesString,
  getDegreeType,
  getEducationProgramAndGraduationDate,
  getEmployerDates,
  getGenderAndAgeLabel,
  getLanguagesLabel,
  getProfileBreadcrumbTitle,
  getRenterRoleLabel,
  hasSocialConnections,
  isDirectBookingCompany,
  isDirectBookingUser,
};
