import { getUnixTime, parseISO } from 'date-fns';
import UserIdConstants from '@domain/Users/UserIdConstants';
import ImageService from '@services/ImageService';

const THIRTY_MINUTES_IN_SECONDS = 1800000;
const MILLISECONDS_IN_ONE_SECOND = 1000;

class MessagesBatch {
  constructor(messages) {
    this.messages = [...messages];
  }

  groupByTimeAndSenders = () => {
    let showProfilePictureIfNextSenderIsDifferent = false;

    return this.messages
      .map(message => this._formatMessage(message))
      .map((message, index) => {
        if (message.messageType === 'admin') {
          return message;
        }

        message.showDate = false;
        message.showProfilePicture = false;

        let senderId, messageTime;
        if (message && message.sender) {
          senderId = message.sender.userId;
          messageTime = new Date(message.createdAt).getTime();
        }

        const prevMessage = this.messages[index - 1];
        let prevMessageSenderId, prevMessageTime;
        if (prevMessage && prevMessage.sender) {
          prevMessageSenderId = prevMessage.sender.userId;
          prevMessageTime = new Date(prevMessage.createdAt).getTime();
        }

        const nextMessage = this.messages[index + 1];
        let nextMessageSenderId, nextMessageTime;
        if (nextMessage && nextMessage.sender) {
          nextMessageSenderId = nextMessage.sender.userId;
          nextMessageTime = new Date(nextMessage.createdAt).getTime();
        }

        if ((messageTime - prevMessageTime > THIRTY_MINUTES_IN_SECONDS || !prevMessageTime) || prevMessageSenderId !== senderId) {
          message.showDate = true;
          showProfilePictureIfNextSenderIsDifferent = true;
        }

        if ((nextMessageTime - messageTime > THIRTY_MINUTES_IN_SECONDS || !nextMessageTime)) {
          message.showProfilePicture = true;
          showProfilePictureIfNextSenderIsDifferent = false;
        }

        if ((showProfilePictureIfNextSenderIsDifferent && nextMessageSenderId !== senderId) || index === this.messages.length - 1) {
          message.showProfilePicture = true;
          showProfilePictureIfNextSenderIsDifferent = false;
        }

        return message;
      });
  };

  _formatMessage = (message) => {
    // From Kopa server
    if (message.is_kopa_message) {
      if (message.type === 'event') {
        return {
          userId: message.user_id,
          createdAt: getUnixTime(parseISO(message.created_at)) * MILLISECONDS_IN_ONE_SECOND,
          message: message.message,
          messageType: 'admin',
          customType: 'event',
        };

      } else {
        return {
          userId: message.user_id,
          createdAt: getUnixTime(parseISO(message.created_at)) * MILLISECONDS_IN_ONE_SECOND,
          message: message.message,
          url: ['image', 'file'].includes(message.type) ? message.data.url : undefined,
          name: ['image', 'file'].includes(message.type) ? message.data.filename : undefined,
          mimeType: ['image', 'file'].includes(message.type) ? message.data.mimeType : undefined,
          customType: message.type,
          sender: {
            isActive: message.user.id !== UserIdConstants.DELETED_RENTER_ID,
            nickname: message.user.first_name,
            profileUrl: message.user.profile_image ? message.user.profile_image.url_160 : ImageService.headshotDefaultUser,
            userId: message.user_id,
          },
        };
      }
    }

    // From SendBird
    return message;
  };
}

export default MessagesBatch;
