import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  availableUnits: [],
  isLoading: true,
  isLoadingAvailableUnits: true,
  isLoadingSimilarListings: true,
  object: {},
  similarListings: [],
});

const ListingView = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('ListingView').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('ListingView') : state)
      );
    } else {
      return state;
    }

  // Retrieve listing
  case 'RETRIEVE_VIEW_LISTING_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });

  case 'RETRIEVE_VIEW_LISTING_REQUEST_SUCCESS':
    return state.merge({
      object: fromJS(action.listing),
      isLoading: false,
    });

  case 'RETRIEVE_VIEW_LISTING_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

  // Retrieve listing
  case 'RETRIEVE_SIMILAR_LISTINGS_REQUEST_LOADING':
    return state.merge({
      isLoadingSimilarListings: true,
    });

  case 'RETRIEVE_SIMILAR_LISTINGS_REQUEST_SUCCESS':
    return state.merge({
      similarListings: fromJS(action.similarListings),
      isLoadingSimilarListings: false,
    });

  case 'RETRIEVE_SIMILAR_LISTINGS_REQUEST_FAILURE':
    return state.merge({
      isLoadingSimilarListings: false,
      error: action.error,
    });

  // Retrieve available units
  case 'RETRIEVE_AVAILABLE_UNITS_REQUEST_LOADING':
    return state.merge({
      isLoadingAvailableUnits: true,
    });

  case 'RETRIEVE_AVAILABLE_UNITS_REQUEST_SUCCESS':
    return state.merge({
      availableUnits: fromJS(action.availableUnits),
      isLoadingAvailableUnits: false,
    });

  case 'RETRIEVE_AVAILABLE_UNITS_REQUEST_FAILURE':
    return state.merge({
      isLoadingAvailableUnits: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default ListingView;
