import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ className, layoutAlign }) => (
  <div className={`${className || ''} loader-wrapper layout-row layout-align-${layoutAlign}-center`}>
    <div className="loader" />
    <div className="loader" />
    <div className="loader" />
  </div>
);

Loader.propTypes = {
  // Optional
  className: PropTypes.string,
  layoutAlign: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
  layoutAlign: 'center',
};

export default Loader;
