import React from 'react';
import PropTypes from 'prop-types';

const EmailIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12,16.17,23.92,7.23V19.45a1.51,1.51,0,0,1-1.49,1.49H1.57a1.43,1.43,0,0,1-1-.44,1.43,1.43,0,0,1-.44-1V7.23Zm0-2.68L.08,4.55a1.43,1.43,0,0,1,.44-1,1.43,1.43,0,0,1,1-.44H22.43a1.51,1.51,0,0,1,1.49,1.49Z" />
  </svg>
);

EmailIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default EmailIcon;
