import React from 'react';
import PropTypes from 'prop-types';

const CookwareIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m4 13v30c0 1.6569 1.3431 3 3 3h34c1.6569 0 3-1.3431 3-3v-30" />
      <path d="m3 13h42c1.1046 0 2-0.89543 2-2s-0.89543-2-2-2h-42c-1.1046 0-2 0.89543-2 2s0.89543 2 2 2z" strokeLinecap="round" />
      <path d="m16 9c0-4.4183 3.5817-8 8-8s8 3.5817 8 8" />
      <path d="m20 9c0-2.2091 1.7909-4 4-4s4 1.7909 4 4" />
      <line x1="37" x2="37" y1="17" y2="39" />
    </g>
  </svg>
);

CookwareIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CookwareIcon;
