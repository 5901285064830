import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" fillRule="evenodd" preserveAspectRatio="xMidYMid meet" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.972 21.906H4.936C3.866 21.906 3 21.03 3 19.95V5.756C3 4.677 3.867 3.8 4.936 3.8h14.036c1.068 0 1.935.876 1.935 1.957V19.95c0 1.08-.867 1.956-1.935 1.956zM7.154 1v4.416M16.846 1v4.416M3 9.4h17.907" />
  </svg>
);

CalendarIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CalendarIcon;
