import ErrorHandler from '@utilities/ErrorHandler';

// =================================
//   RETRIEVE TENANCY GROUP REQUEST
// ===================================
const retrieveTenancyGroupRequestLoading = () => ({
  type: 'RETRIEVE_TENANCY_GROUP_REQUEST_LOADING',
});

const retrieveTenancyGroupRequestSuccess = (data) => ({
  type: 'RETRIEVE_TENANCY_GROUP_REQUEST_SUCCESS',
  data,
});

const retrieveTenancyGroupRequestFailure = (error) => ({
  type: 'RETRIEVE_TENANCY_GROUP_REQUEST_FAILURE',
  error,
});

const retrieveTenancyGroupRequest = (apiService, tenancyGroupId) => async (dispatch) => {
  dispatch(retrieveTenancyGroupRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/tenancies/${tenancyGroupId}`,
      method: 'GET',
    });
    dispatch(retrieveTenancyGroupRequestSuccess(response));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveTenancyGroupRequestFailure
    );
    throw error;
  }
};

// ===================================
//   CREATE TENANCY GROUP DEPOSIT RETURN
// ===================================
const createTenancyGroupDepositReturnLoading = () => ({
  type: 'CREATE_TENANCY_GROUP_DEPOSIT_RETURN_LOADING',
});

const createTenancyGroupDepositReturnSuccess = (data) => ({
  type: 'CREATE_TENANCY_GROUP_DEPOSIT_RETURN_SUCCESS',
  data,
});

const createTenancyGroupDepositReturnFailure = (error) => ({
  type: 'CREATE_TENANCY_GROUP_DEPOSIT_RETURN_FAILURE',
  error,
});

const createTenancyGroupDepositReturn = (apiService, tenancyGroupId, requestData) => async (dispatch) => {
  dispatch(createTenancyGroupDepositReturnLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/tenancy_groups/${tenancyGroupId}/return_security_deposit`,
      method: 'POST',
      data: JSON.stringify(requestData),
    });
    dispatch(createTenancyGroupDepositReturnSuccess(response));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createTenancyGroupDepositReturnFailure
    );
    throw error;
  }
};

// ===================================
//   UPDATE TENANCY GROUP
// ===================================
const updateTenancyGroupRequestLoading = () => ({
  type: 'UPDATE_TENANCY_GROUP_LOADING',
});

const updateTenancyGroupRequestSuccess = (data) => ({
  type: 'UPDATE_TENANCY_GROUP_SUCCESS',
  data,
});

const updateTenancyGroupRequestFailure = (error) => ({
  type: 'UPDATE_TENANCY_GROUP_FAILURE',
  error,
});

const updateTenancyGroupRequest = (apiService, tenancyGroupId, tenancyGroupData) => async (dispatch) => {
  dispatch(updateTenancyGroupRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/tenancy_groups/${tenancyGroupId}`,
      method: 'PUT',
      data: JSON.stringify(tenancyGroupData),
    });
    dispatch(updateTenancyGroupRequestSuccess(response));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateTenancyGroupRequestFailure
    );
    throw error;
  }
};

export default {
  createTenancyGroupDepositReturn,
  retrieveTenancyGroupRequest,
  updateTenancyGroupRequest,
};
