// ====================================
//   UPDATE REQUEST HOUSEMATES
// ====================================
const updateRequestHousematesSuccess = (housemates) => ({
  type: 'UPDATE_REQUEST_HOUSEMATES',
  housemates,
});

const updateRequestHousemates = (housemates) => (dispatch) => (
  dispatch(updateRequestHousematesSuccess(housemates))
);

// ====================================
//   UPDATE REQUEST DETAILS SELECTION
// ====================================
const updateRequestDetailsSelectionSuccess = (details) => ({
  type: 'UPDATE_REQUEST_DETAILS_SELECTION',
  details,
});

const updateRequestDetailsSelection = (details) => (dispatch) => (
  dispatch(updateRequestDetailsSelectionSuccess(details))
);

export default {
  updateRequestHousemates,
  updateRequestDetailsSelection,
};
