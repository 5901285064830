import React from 'react';
import PropTypes from 'prop-types';

const CompanyLogoIcon = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 400 168">
    <g fill="currentColor">
      <path d="m172.5 48.8c-9.6-9.2-21.3-13.9-34.7-13.9-13.4 0.1-25.1 4.8-34.7 13.9-9.7 9.4-14.6 21.2-14.6 35.2 0 14.1 4.9 25.9 14.7 35.2 9.7 9.2 21.4 13.8 34.7 13.8s25-4.7 34.6-13.9c9.7-9.3 14.6-21.1 14.6-35.2s-4.9-25.9-14.6-35.1zm-19.2 18.7c3.8 4.3 5.6 9.8 5.6 16.6s-1.8 12.2-5.6 16.6c-3.6 4.1-8.6 6.1-15.4 6.1s-11.9-2-15.5-6.2c-3.8-4.3-5.7-9.8-5.7-16.5s1.9-12.2 5.7-16.5c3.7-4.2 8.7-6.2 15.5-6.2 6.7-0.1 11.8 1.9 15.4 6.1z" />
      <path d="m246.1 35c-9.8 0-17.6 4.4-22.5 8.4l-2.1-5-22.9-0.1v129.6h28.2v-39.9c5.8 3.4 12.2 5.2 19.2 5.2 13.3 0 24.7-4.3 33.8-13.5s13.7-21.1 13.7-35.4-4.6-26.1-13.7-35.3c-9.1-9.4-20.4-14-33.7-14zm0.4 26.3c6 0 10.4 1.9 13.6 5.9 3.5 4.4 5.3 10 5.3 17s-1.7 12.5-5.3 16.8c-3.4 4-8 6-14.3 6-6.2 0-10.9-2-14.3-6.2-3.6-4.5-5.4-10-5.4-16.6s1.9-12 5.7-16.5c3.6-4.3 8.4-6.4 14.5-6.4h0.2z" />
      <path d="m376.4 38.3-2 5c-4.9-3.9-12.2-8.2-22.5-8.2-13.7 0-23.9 2.9-34.1 12.3-9.9 9.1-14.6 19.8-14.6 35.2 0 17.3 5.1 30 12.9 37.4 10.1 9.6 22.1 13.2 35.8 13.2 14.7 0 21.5-8.9 21.5-8.9l2 5h24.6v-91h-23.6zm-3.8 44.3c0 6.4-1.9 11.7-5.9 16.1-3.6 3.9-9.3 6.2-14.8 6.2-5.7 0-10.7-2.2-14.3-6-3.9-4.2-5.8-9.5-5.8-16.3s1.9-12.2 5.8-16.4c3.5-3.8 8.8-6 14.4-6 5.3 0 10.7 2.5 14.5 6.4 4.1 4.4 6.1 9.6 6.1 16z" />
      <path d="m59.6 78.1c1.7-1.3 3.3-2.6 4.9-4.2 9.7-9.3 14.6-21.5 14.6-35.6h-28.3c0 6.8-1.8 12.6-5.6 16.9-3.6 4.1-7.4 7-14.1 7h-3v-62h-28.1v129.2h28.1v-35.8h8.5l22.6 35.9h33.5c0.1 0-21.4-33.6-33.1-51.4z" />
    </g>
  </svg>
);

CompanyLogoIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default CompanyLogoIcon;
