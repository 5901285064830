import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';
import {
  isMobileOnly,
  isTablet,
  isBrowser
} from 'react-device-detect';

const initialState = fromJS({
  xs:   !!isMobileOnly,
  gtXs: !isMobileOnly,
  sm:   !!isTablet,
  gtSm: !!isBrowser,
  md:   !!isBrowser,
  gtMd: !!isBrowser,
  lg:   false,
  gtLg: false,
});

const ScreenSizes = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('ScreenSizes').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('ScreenSizes') : state)
      );
    } else {
      return state;
    }

  case 'UPDATE_SCREEN_SIZES':
    return state.merge(action.screenSizes);

  default:
    return state;
  }
};

export default ScreenSizes;
