import React from 'react';
import PropTypes from 'prop-types';

const BedroomIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M1001-850.18H-177.18a.5.5,0,0,0-.5.5v4381a.5.5,0,0,0,.5.5H1001a.5.5,0,0,0,.5-.5v-4381A.5.5,0,0,0,1001-850.18Zm-.5,4381H-176.68v-4380H1000.5Z" />
    <path d="M14.72,14.48a2,2,0,1,0-2-2A2,2,0,0,0,14.72,14.48Zm0-2.7a.68.68,0,1,1-.67.67A.67.67,0,0,1,14.72,11.78Z" />
    <path d="M18.21,1H6a.67.67,0,0,0-.68.68v20.3A.67.67,0,0,0,6,22.6H18.21a.67.67,0,0,0,.67-.67V1.63A.67.67,0,0,0,18.21,1Zm-.68,20.3H6.7V2.3H17.53Z" />
  </svg>
);

BedroomIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default BedroomIcon;
