import React from 'react';
import PropTypes from 'prop-types';

const SplatIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" fillRule="evenodd" viewBox="0 0 466 429" xmlns="http://www.w3.org/2000/svg">
    <path d="M1028.5 196.5c-70.83 11.06-99.5 10-145 0s-122 36.5-89 147.5S915 588.5 1082 588.5 1289 431 1217 364s28-140-25-185.5-92.67 6.94-163.5 18z" transform="translate(-786 -160)" />
  </svg>
);

SplatIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default SplatIcon;
