import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  isLoading: false,
  error: null,
});

const CalendarImports = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('CalendarImports').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('CalendarImports') : state)
      );
    } else {
      return state;
    }

    // Create calendar import
  case 'CREATE_CALENDAR_IMPORT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'CREATE_CALENDAR_IMPORT_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
    });
  case 'CREATE_CALENDAR_IMPORT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Delete calendar import
  case 'DELETE_CALENDAR_IMPORT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'DELETE_CALENDAR_IMPORT_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
    });
  case 'DELETE_CALENDAR_IMPORT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });

    // Update calendar import
  case 'UPDATE_CALENDAR_IMPORT_REQUEST_LOADING':
    return state.merge({
      isLoading: true,
    });
  case 'UPDATE_CALENDAR_IMPORT_REQUEST_SUCCESS':
    return state.merge({
      isLoading: false,
    });
  case 'UPDATE_CALENDAR_IMPORT_REQUEST_FAILURE':
    return state.merge({
      isLoading: false,
      error: action.error,
    });
  default:
    return state;
  }
};

export default CalendarImports;
