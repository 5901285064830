import React from 'react';
import PropTypes from 'prop-types';

const SquiggleArrowRightIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" fillRule="evenodd" stroke="currentColor" viewBox="0 0 53 78" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.488 76.545c1.451-26.426-3.85-40.79-15.905-43.092-18.083-3.453-12.725 21.773 0 10.886 12.724-10.886 4.998-25.402-5.908-31.752C21.404 8.353 12.012 7.446.5 9.865" transform="translate(-1038 -557) translate(1039.5 558)" />
    <path d="M9.5 0L0 10 12 15" transform="translate(-1038 -557) translate(1039.5 558)" />
  </svg>
);

SquiggleArrowRightIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default SquiggleArrowRightIcon;
