import React from 'react';
import PropTypes from 'prop-types';

const TrashIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" onClick={props.onClick} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <rect height="2" width="14" x="2" y="2.3" />
    <polygon points="15 4.3 3 4.3 4 16.8 14 16.8" />
    <line x1="9" x2="9" y1="6.8" y2="13.8" />
    <line x1="9" x2="9" y1=".8" y2="1.8" />
    <line x1="11.5" x2="11.5" y1="6.8" y2="13.8" />
    <line x1="6.5" x2="6.5" y1="6.8" y2="13.8" />
  </svg>
);

TrashIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,

  // Optional
  onClick: PropTypes.func,
};

TrashIcon.defaultProps = {
  onClick: () => {},
};

export default TrashIcon;
