import React from 'react';
import PropTypes from 'prop-types';

const Splat3Icon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" fillRule="evenodd" viewBox="0 0 467 418" xmlns="http://www.w3.org/2000/svg">
    <path d="M376.875 87.571c-22.5-53.5-82-100.5-162.5-67-75.792 31.54-118.16 102.083-158.5 106.5-68.5 7.5-75.5 98-6.5 126s-18.704 75.98 58.492 140.675c77.197 64.694 261.16 32.558 334.008-95.175 72.847-127.733-36.825-144.006-65-211z" transform="translate(-837 -174) translate(837 165.532) rotate(180 233.687 217.77)" />
  </svg>
);

Splat3Icon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default Splat3Icon;
