import axios from 'axios';
import NextCookies from 'next-cookies';
import JsCookies from 'js-cookie';
import Environment from '@utilities/Environment';

export default class ApiService {
  constructor(serverSideContext = null) {
    this.domain = process.env.API_ENDPOINT;
    this.fetch = this.fetch.bind(this);
    this.serverSideContext = serverSideContext;
  }

  getToken (name) {
    let token = JsCookies.get(name);

    if (token) {
      return token;
    }

    if (this.serverSideContext) {
      const cookies = NextCookies(this.serverSideContext);
      return cookies[name];
    }

    return null;
  }

  setAccessToken (accessToken) {
    JsCookies.set('access_token', accessToken, {
      secure: process.env.ENV !== 'development',
      sameSite: 'strict',
    });
  }

  removeTokens () {
    JsCookies.remove('access_token');
    JsCookies.remove('refresh_token');
  }

  async handleError (error, refresh) {
    if (error.response && error.response.status === 401 && !refresh && this.getToken('refresh_token')) {
      // Only try and refresh token if its the initial request and it 401'd
      return await this.refreshAccessToken();
    } else if (error.response && error.response.status === 401 && refresh) {
      // If the refresh 401'd then remove the auth tokens
      this.removeTokens();
    }
    throw error.response || error;
  }

  // Core ApiService fetch
  //  - url: String - whole URL to make request to
  //  - options: Object - axios options (https://github.com/axios/axios#request-config)
  //  - subsequent: Boolean - determine if a subsequent should be made to refresh the token and retry
  async fetch (options, subsequent) {
    try {
      const environment = new Environment();

      // Set to scope
      this.options = options;

      // Create header object
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'kopa-api-key': process.env.KOPA_API_KEY,
      };
      let accessToken = this.getToken('access_token');
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }

      if (environment.isDevelopment()) {
        console.log('API Service fetch options:', options);
        console.log('API service headers sent:', headers);
      }

      const response = await axios({
        ...options,
        headers,
      });

      if (environment.isDevelopment()) {
        console.log('API Service fetch response status code:', response.status);
        console.log('API Service fetch response data:', response.data);
      }

      return response.data;
    } catch (error) {
      return await this.handleError(error, subsequent);
    }
  }

  async refreshAccessToken () {
    try {
      const response = await axios({
        url: `${this.domain}/v1/refresh_token`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'kopa-api-key': process.env.KOPA_API_KEY,
        },
        method: 'POST',
        data: JSON.stringify({
          refresh_token: this.getToken('refresh_token'),
        }),
      });
      this.setAccessToken(response.data.access_token);
      return await this.fetch(this.options, true);
    } catch (error) {
      await this.handleError(error, true);
    }
  }
}
