import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   UPDATE BLACKLIST
// ==========================
const hideHousematePostAction = (housematePostId) => ({
  type: 'HIDE_HOUSEMATE_POST',
  housematePostId,
});

const unhideHousematePostAction = (housematePostId) => ({
  type: 'UNHIDE_HOUSEMATE_POST',
  housematePostId,
});

// ==========================
//   RETRIEVE HOUSEMATE POSTS
// ==========================
const retrieveHousematePostsRequestLoading = () => ({
  type: 'RETRIEVE_HOUSEMATE_POSTS_LOADING',
});

const retrieveHousematePostsRequestSuccess = (data) => ({
  type: 'RETRIEVE_HOUSEMATE_POSTS_SUCCESS',
  housematePosts: data.housemate_posts,
});

const retrieveHousematePostsRequestFailure = () => ({
  type: 'RETRIEVE_HOUSEMATE_POSTS_FAILURE',
});

const retrieveHousematePostsRequest = (query) => async (dispatch) => {
  dispatch(retrieveHousematePostsRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/housemate_posts?${query}`,
      method: 'GET',
    });

    dispatch(retrieveHousematePostsRequestSuccess(response));

  } catch (error) {
    return ErrorHandler.requestError(
      dispatch,
      error,
      retrieveHousematePostsRequestFailure
    );
  }
};

// ================================
//   RETRIEVE HOUSEMATE POSTS COUNT
// ================================
const retrieveHousematePostsCountRequestLoading = () => ({
  type: 'RETRIEVE_HOUSEMATE_POSTS_COUNT_LOADING',
});

const retrieveHousematePostsCountRequestSuccess = (data) => ({
  type: 'RETRIEVE_HOUSEMATE_POSTS_COUNT_SUCCESS',
  totalCount: data.count,
});

const retrieveHousematePostsCountRequestFailure = () => ({
  type: 'RETRIEVE_HOUSEMATE_POSTS_COUNT_FAILURE',
});

const retrieveHousematePostsCountRequest = (query) => async (dispatch) => {
  dispatch(retrieveHousematePostsCountRequestLoading());

  try {
    const response = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/housemate_posts_count?${query}`,
      method: 'GET',
    });

    dispatch(retrieveHousematePostsCountRequestSuccess(response));

  } catch (error) {
    return ErrorHandler.requestError(
      dispatch,
      error,
      retrieveHousematePostsCountRequestFailure
    );
  }
};

export default {
  retrieveHousematePostsRequest,
  retrieveHousematePostsCountRequest,
  hideHousematePostAction,
  unhideHousematePostAction,
};
