import ErrorHandler from '@utilities/ErrorHandler';

// ===================================
//   CREATE REQUEST INVITES REQUEST
// ===================================
const createRequestInvitesRequestLoading = () => ({
  type: 'CREATE_REQUEST_INVITES_REQUEST_LOADING',
});

const createRequestInvitesRequestSuccess = (request) => ({
  type: 'CREATE_REQUEST_INVITES_REQUEST_SUCCESS',
  request,
});

const createRequestInvitesRequestFailure = (error) => ({
  type: 'CREATE_REQUEST_INVITES_REQUEST_FAILURE',
  error,
});

const createRequestInvitesRequest = (apiService, data) => async (dispatch) => {
  dispatch(createRequestInvitesRequestLoading());

  try {
    const response = await apiService.fetch({
      data,
      method: 'POST',
      url: `${process.env.API_ENDPOINT}/v1/units/${data.unit_id}/booking_request_invites`,
    });

    const hydratedBookingRequest = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${response.id}`,
      method: 'GET',
    });

    dispatch(createRequestInvitesRequestSuccess(response));
    dispatch(createRequestRequestSuccess(hydratedBookingRequest));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createRequestInvitesRequestFailure
    );
    throw error;
  }
};

// ===================================
//   CREATE REQUEST REQUEST
// ===================================
const createRequestRequestLoading = () => ({
  type: 'CREATE_REQUEST_REQUEST_LOADING',
});

const createRequestRequestSuccess = (request) => ({
  type: 'CREATE_REQUEST_REQUEST_SUCCESS',
  request,
});

const createRequestRequestFailure = (error) => ({
  type: 'CREATE_REQUEST_REQUEST_FAILURE',
  error,
});

const createRequestRequest = (apiService, data) => async (dispatch) => {
  dispatch(createRequestRequestLoading());

  try {
    const response = await apiService.fetch({
      data,
      method: 'POST',
      url: `${process.env.API_ENDPOINT}/v1/units/${data.unit_id}/booking_requests`,
    });

    const hydratedBookingRequest = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/booking_requests/${response.id}`,
      method: 'GET',
    });

    dispatch(createRequestRequestSuccess(hydratedBookingRequest));

    return response;
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createRequestRequestFailure
    );
    throw error;
  }
};


export default {
  createRequestInvitesRequest,
  createRequestRequest,
};
