import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   CREATE DIRECT BOOKING SUBSCRIPTION
// ==========================
const createSubscriptionRequestLoading = () => ({
  type: 'CREATE_DIRECT_BOOKING_SUBSCRIPTION_REQUEST_LOADING',
});

const createSubscriptionRequestSuccess = (subscription) => ({
  type: 'CREATE_DIRECT_BOOKING_SUBSCRIPTION_REQUEST_SUCCESS',
  subscription,
});

const createSubscriptionRequestFailure = () => ({
  type: 'CREATE_DIRECT_BOOKING_SUBSCRIPTION_REQUEST_FAILURE',
});

const createSubscriptionRequest = (userId, paymentMethodId, planKey, interval, quantity) => async (dispatch) => {
  dispatch(createSubscriptionRequestLoading());

  try {
    const subscriptionResponse = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/subscriptions`,
      method: 'POST',
      data: JSON.stringify({
        interval,
        payment_method_id: paymentMethodId,
        plan_key: planKey,
        quantity,
      }),
    });

    dispatch(createSubscriptionRequestSuccess(subscriptionResponse.body));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createSubscriptionRequestFailure
    );

    throw error;
  }
};

export default {
  createSubscriptionRequest,
};
