import React from 'react';
import PropTypes from 'prop-types';

const Share = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 11L0 11 0 23 22 23 22 11 14 11M11 .714L11 20M5 6.429L11 .429 17 6.429" transform="translate(-666 -295) translate(666 295) translate(1 1)" />
  </svg>
);

Share.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default Share;
