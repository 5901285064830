import React from 'react';
import PropTypes from 'prop-types';

const TablewareIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m24 46c12.15 0 22-9.8497 22-22s-9.8497-22-22-22c-9.8735 0-18.228 6.5042-21.012 15.462-0.45267 1.4563-0.75811 2.9775-0.8989 4.5461" />
      <path d="m34.82 34.579c2.5391-2.7562 4.1803-6.3508 4.1803-10.283 0-8.6805-7.1634-15.717-16-15.717" />
      <path d="m2 28h20v8c0 5.5228-4.4772 10-10 10s-10-4.4772-10-10v-8z" />
      <path d="m22 28h3c2.2091 0 4 1.7909 4 4s-1.7909 4-4 4h-3" />
    </g>
  </svg>
);

TablewareIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default TablewareIcon;
