import AccountDashboardActions from './AccountDashboard';
import AccountHousingSearchActions from './AccountHousingSearch';
import AccountInboxActions from './AccountInbox';
import AccountListingsActions from './AccountListings';
import AccountPaymentsActions from './AccountPayments';
import AccountPayoutsActions from './AccountPayouts';
import AccountRequestsActions from './AccountRequests';
import AvailabilityEventActions from './AvailabilityEvents';
import CalendarImportActions from './CalendarImports';
import CancellationPolicyActions from './CancellationPolicy';
import CommuteDestinationsActions from './CommuteDestinations';
import CompanyMembersActions from './CompanyMembers';
import CompanyReviewsActions from './CompanyReviews';
import CountryCodeActions from './CountryCodes';
import DocumentActions from './Documents';
import EducationActions from './Education';
import GeneratedSearchPageActions from './GeneratedSearchPage';
import HousematePostActions from './HousematePost';
import HousemateSearchActions from './HousemateSearch';
import ListingEditActions from './ListingEdit';
import ListingReviewsActions from './ListingReviews';
import ListingSearchActions from './ListingSearch';
import ListingViewActions from './ListingView';
import LeadActions from './Lead';
import MentionsActions from './Mentions';
import OccupancyViewActions from './OccupancyView';
import PaymentMethodActions from './PaymentMethods';
import PayoutMethodActions from './PayoutMethods';
import PhoneNumberActions from './PhoneNumbers';
import RecommendationsSurveyActions from './RecommendationsSurvey';
import RequestDetailsSelectionActions from './RequestDetailsSelection';
import RequestInvitesActions from './RequestInvites';
import RequestViewActions from './RequestView';
import SavedSearchesActions from './SavedSearches';
import ScreenSizeActions from './ScreenSizes';
import SearchActions from './Search';
import Sitemap from './Sitemap';
import SubscriptionsActions from './Subscriptions';
import TenancyGroupActions from './TenancyGroup';
import UnitRequestsActions from './UnitRequests';
import UserActions from './User';
import UserReviewsActions from './UserReviews';
import WorkExperiencesActions from './WorkExperiences';

export default {
  ...AccountDashboardActions,
  ...AccountHousingSearchActions,
  ...AccountInboxActions,
  ...AccountListingsActions,
  ...AccountPaymentsActions,
  ...AccountPayoutsActions,
  ...AccountRequestsActions,
  ...AvailabilityEventActions,
  ...CalendarImportActions,
  ...CancellationPolicyActions,
  ...CommuteDestinationsActions,
  ...CompanyMembersActions,
  ...CompanyReviewsActions,
  ...CountryCodeActions,
  ...DocumentActions,
  ...EducationActions,
  ...GeneratedSearchPageActions,
  ...HousematePostActions,
  ...HousemateSearchActions,
  ...ListingEditActions,
  ...ListingReviewsActions,
  ...ListingSearchActions,
  ...ListingViewActions,
  ...LeadActions,
  ...MentionsActions,
  ...OccupancyViewActions,
  ...PaymentMethodActions,
  ...PayoutMethodActions,
  ...PhoneNumberActions,
  ...RecommendationsSurveyActions,
  ...RequestDetailsSelectionActions,
  ...RequestInvitesActions,
  ...RequestViewActions,
  ...SavedSearchesActions,
  ...ScreenSizeActions,
  ...SearchActions,
  ...Sitemap,
  ...SubscriptionsActions,
  ...TenancyGroupActions,
  ...UnitRequestsActions,
  ...UserActions,
  ...UserReviewsActions,
  ...WorkExperiencesActions,
};
