import React from 'react';
import PropTypes from 'prop-types';

const FacebookIcon = (props) => (
  <svg aria-hidden="true" className={props.className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 2v4h-2c-.69 0-1 .81-1 1.5V10h3v4h-3v8h-4v-8H7v-4h3V6a4 4 0 0 1 4-4h3z" />
  </svg>
);

FacebookIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default FacebookIcon;
