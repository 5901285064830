import React from 'react';
import PropTypes from 'prop-types';

const LockIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.345 7.934V6.172c0-2.474-1.977-4.478-4.417-4.478-2.44 0-4.417 2.004-4.417 4.478v1.762M18.114 22.21H5.742c-1.147 0-2.077-.943-2.077-2.105V10.04c0-1.164.93-2.105 2.077-2.105h12.372c1.147 0 2.077.94 2.077 2.105v10.065c0 1.162-.93 2.105-2.076 2.105zM12 15v3.2" />
    <path d="M13.445 13.18c0 .847-.68 1.535-1.516 1.535-.838 0-1.518-.688-1.518-1.536 0-.85.68-1.538 1.517-1.538.836 0 1.515.688 1.515 1.537z" />
  </svg>
);

LockIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default LockIcon;
