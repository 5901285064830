import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   SAVE LANDING PAGE LEAD
// ==========================
const saveLandingPageLeadRequestLoading = () => ({
  type: 'SAVE_LANDING_PAGE_LEAD_REQUEST_LOADING',
});

const saveLandingPageLeadRequestSuccess = (lead) => ({
  type: 'SAVE_LANDING_PAGE_LEAD_REQUEST_SUCCESS',
  lead,
});

const saveLandingPageLeadRequestFailure = () => ({
  type: 'SAVE_LANDING_PAGE_LEAD_REQUEST_FAILURE',
});

const saveLandingPageLeadRequest = (lead) => async (dispatch) => {
  dispatch(saveLandingPageLeadRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/landing_page_lead`,
      method: 'POST',
      data: JSON.stringify(lead),
    });
    dispatch(saveLandingPageLeadRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      saveLandingPageLeadRequestFailure,
      true,
      'There was a problem submitting your email, please email hello@kopa.co.'
    );
    throw error;
  }
};

// ==========================
//   SAVE LEAD
// ==========================
const saveLeadRequestLoading = () => ({
  type: 'SAVE_LEAD_REQUEST_LOADING',
});

const saveLeadRequestSuccess = (user) => ({
  type: 'SAVE_LEAD_REQUEST_SUCCESS',
  user,
});

const saveLeadRequestFailure = () => ({
  type: 'SAVE_LEAD_REQUEST_FAILURE',
});

const saveLeadRequest = (lead) => async (dispatch) => {
  dispatch(saveLeadRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/leads`,
      method: 'POST',
      data: JSON.stringify(lead),
    });
    dispatch(saveLeadRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      saveLeadRequestFailure
    );
  }
};

export default {
  saveLandingPageLeadRequest,
  saveLeadRequest,
};
