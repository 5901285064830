import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ==========================
//   CREATE WORK_EXPERIENCE
// ==========================
const createWorkExperienceLoading = () => ({
  type: 'CREATE_WORK_EXPERIENCE_REQUEST_LOADING',
});

const createWorkExperienceSuccess = (workExperience) => ({
  type: 'CREATE_WORK_EXPERIENCE_REQUEST_SUCCESS',
  workExperience,
});

const createWorkExperienceFailure = () => ({
  type: 'CREATE_WORK_EXPERIENCE_REQUEST_FAILURE',
});

const createWorkExperienceRequest = (userId, workExperience) => async (dispatch) => {
  dispatch(createWorkExperienceLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/users/${userId}/work_experiences`,
      method: 'POST',
      data: JSON.stringify(workExperience),
    });
    dispatch(createWorkExperienceSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      createWorkExperienceFailure
    );
  }
};

// ==========================
//   UPDATE WORK_EXPERIENCE
// ==========================
const updateWorkExperienceLoading = () => ({
  type: 'UPDATE_WORK_EXPERIENCE_REQUEST_LOADING',
});

const updateWorkExperienceSuccess = (workExperience) => ({
  type: 'UPDATE_WORK_EXPERIENCE_REQUEST_SUCCESS',
  workExperience,
});

const updateWorkExperienceFailure = () => ({
  type: 'UPDATE_WORK_EXPERIENCE_REQUEST_FAILURE',
});

const updateWorkExperienceRequest = (workExperienceId, workExperience) => async (dispatch) => {
  dispatch(updateWorkExperienceLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/work_experiences/${workExperienceId}`,
      method: 'PUT',
      data: JSON.stringify(workExperience),
    });
    dispatch(updateWorkExperienceSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateWorkExperienceFailure
    );
  }
};

export default {
  createWorkExperienceRequest,
  updateWorkExperienceRequest,
};
