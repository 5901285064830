import React from 'react';
import PropTypes from 'prop-types';

const AirConditioningIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <g transform="translate(23 24) rotate(-30) translate(-29 -10)">
        <g transform="translate(1.5858 1)">
          <line x1="4.4142" x2="52.033" y1="9" y2="9" />
          <polyline points="13.828 4 13.828 14 3.8284 14" transform="translate(8.8284 9) rotate(-45) translate(-8.8284 -9)" />
          <polyline points="52 4 52 14 42 14" transform="translate(47 9) scale(-1 1) rotate(-45) translate(-47 -9)" />
        </g>
      </g>
      <g transform="translate(23 24) rotate(30) translate(-29 -10)">
        <g transform="translate(1.5858 1)">
          <line x1="4.4142" x2="52.033" y1="9" y2="9" />
          <polyline points="13.828 4 13.828 14 3.8284 14" transform="translate(8.8284 9) rotate(-45) translate(-8.8284 -9)" />
          <polyline points="52 4 52 14 42 14" transform="translate(47 9) scale(-1 1) rotate(-45) translate(-47 -9)" />
        </g>
      </g>
      <g transform="translate(23 24) rotate(90) translate(-29 -10)">
        <g transform="translate(1.5858 1)">
          <line x1="4.4142" x2="52.033" y1="9" y2="9" />
          <polyline points="13.828 4 13.828 14 3.8284 14" transform="translate(8.8284 9) rotate(-45) translate(-8.8284 -9)" />
          <polyline points="52 4 52 14 42 14" transform="translate(47 9) scale(-1 1) rotate(-45) translate(-47 -9)" />
        </g>
      </g>
    </g>
  </svg>
);

AirConditioningIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default AirConditioningIcon;
