import React from 'react';
import PropTypes from 'prop-types';

const BeddingIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="m38 6h4c1.1046 0 2 0.89543 2 2v36c0 1.1046-0.89543 2-2 2h-36c-1.1046 0-2-0.89543-2-2v-36c0-1.1046 0.89543-2 2-2h3.5" />
      <rect height="8" rx="1" width="12" x="10" y="2" />
      <rect height="8" rx="1" width="12" x="26" y="2" />
      <line x1="4" x2="44" y1="15" y2="15" />
      <line x1="4" x2="44" y1="22" y2="22" />
      <line x1="26" x2="22" y1="6" y2="6" />
    </g>
  </svg>
);

BeddingIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default BeddingIcon;
