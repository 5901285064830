import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  payoutLoading: false,
  payoutMethod: {},
});

const PayoutMethods = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('PayoutMethods').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('PayoutMethods') : state)
      );
    } else {
      return state;
    }

    // Create payout method actions
  case 'CREATE_PAYOUT_METHOD_REQUEST_LOADING':
    return state.merge({
      payoutLoading: true,
    });
  case 'CREATE_PAYOUT_METHOD_REQUEST_SUCCESS':
    return state.merge({
      payoutMethod: fromJS(action.payoutMethod),
      payoutLoading: false,
    });
  case 'CREATE_PAYOUT_METHOD_REQUEST_FAILURE':
    return state.merge({
      payoutLoading: false,
      error: action.error,
    });

    // Delete payout method actions
  case 'DELETE_PAYOUT_METHOD_REQUEST_LOADING':
    return state.merge({
      payoutLoading: true,
    });
  case 'DELETE_PAYOUT_METHOD_REQUEST_SUCCESS':
    return state.merge({
      payoutMethod: fromJS(action.payoutMethod),
      payoutLoading: false,
    });
  case 'DELETE_PAYOUT_METHOD_REQUEST_FAILURE':
    return state.merge({
      payoutLoading: false,
      error: action.error,
    });

  default:
    return state;
  }
};

export default PayoutMethods;
