import ApiService from '@services/ApiService';
import ErrorHandler from '@utilities/ErrorHandler';

const apiService = new ApiService();

// ======================================
//   RETRIEVE CANCELLATION POLICY REQUEST
// ======================================
const retrieveCancellationPolicyRequestLoading = () => ({
  type: 'RETRIEVE_CANCELLATION_POLICY_REQUEST_LOADING',
});

const retrieveCancellationPolicyRequestSuccess = (cancellationPolicy) => ({
  type: 'RETRIEVE_CANCELLATION_POLICY_REQUEST_SUCCESS',
  cancellationPolicy,
});

const retrieveCancellationPolicyRequestFailure = (error) => ({
  type: 'RETRIEVE_CANCELLATION_POLICY_REQUEST_FAILURE',
  error,
});

const retrieveCancellationPolicyRequest = (companyId) => async (dispatch) => {
  dispatch(retrieveCancellationPolicyRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/cancellation_policy`,
      method: 'GET',
    });
    dispatch(retrieveCancellationPolicyRequestSuccess(res));
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      retrieveCancellationPolicyRequestFailure,
      true
    );
  }
};

// ======================================
//   UPDATE CANCELLATION POLICY REQUEST
// ======================================
const updateCancellationPolicyRequestLoading = () => ({
  type: 'UPDATE_CANCELLATION_POLICY_REQUEST_LOADING',
});

const updateCancellationPolicyRequestSuccess = (cancellationPolicy) => ({
  type: 'UPDATE_CANCELLATION_POLICY_REQUEST_SUCCESS',
  cancellationPolicy,
});

const updateCancellationPolicyRequestFailure = (error) => ({
  type: 'UPDATE_CANCELLATION_POLICY_REQUEST_FAILURE',
  error,
});

const updateCancellationPolicyRequest = (companyId, cancellationPolicy) => async (dispatch) => {
  dispatch(updateCancellationPolicyRequestLoading());

  try {
    const res = await apiService.fetch({
      url: `${process.env.API_ENDPOINT}/v1/companies/${companyId}/cancellation_policy`,
      method: 'POST',
      data: JSON.stringify(cancellationPolicy),
    });

    setTimeout(() => {
      dispatch(updateCancellationPolicyRequestSuccess(res));
    }, 500);
  } catch (error) {
    ErrorHandler.requestError(
      dispatch,
      error,
      updateCancellationPolicyRequestFailure,
      true
    );
  }
};

export default {
  retrieveCancellationPolicyRequest,
  updateCancellationPolicyRequest,
};
