import React from 'react';
import PropTypes from 'prop-types';

const Marijuana = (props) => (
  <svg aria-hidden="true" className={props.className} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinejoin="round">
      <path d="m22 13.626c-3.2663-1.0983-5.3332-1.6026-6.2006-1.5128 1.1318-0.79268 1.4982-1.5562 2.0616-2.4239l1.8269-2.8131-3.6974 1.6355c-0.85808 0.46814-1.4592 0.63069-2.3303 1.6203 0.26477-1.5825 0.23307-3.0393-0.096585-4.2859l-1.0188-3.8457-1.0326 3.8592c-0.34122 1.2736-0.3703 2.7613-0.089872 4.3687-0.86964-1.0113-1.5263-1.2332-2.4128-1.7166l-3.6974-1.6355 1.8269 2.8131c0.56347 0.86768 0.92946 1.6313 2.0616 2.4239-0.8674-0.089813-2.9343 0.41445-6.2006 1.5128 3.5659 0.65263 5.7913 0.90026 6.6761 0.74291-0.43656 0.35522-1.0496 1.5657-1.839 3.6314l1.9783-1.1229c0.74769-0.42442 2.233-1.6021 2.6846-2.3492l0.04475 0.052964c0.47547 0.74896 1.9097 1.8818 2.6399 2.2963l1.9783 1.1229c-0.78969-2.0657-1.4027-3.2762-1.839-3.6314 0.8848 0.15735 3.1102-0.090287 6.6761-0.74291z" />
      <line x1="12.5" x2="12.5" y1="15" y2="23" />
    </g>
  </svg>
);

Marijuana.propTypes = {
  // required
  className: PropTypes.string.isRequired,
};

export default Marijuana;
