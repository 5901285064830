import React from 'react';
import PropTypes from 'prop-types';

const NotificationBellIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".914">
      <path d="M19.424 18.013c-1.158-.512-1.626-1.917-1.626-3.2v-5.76c0-3.104-2.268-5.876-5.358-6.17-3.544-.338-6.527 2.44-6.527 5.914v6.015c0 1.284-.62 2.69-1.7 3.2 0 0-1.603.43-1.603 1.66 0 .885.716 1.545 1.602 1.545h15.29c.883 0 1.6-.66 1.6-1.545 0-1.25-1.678-1.66-1.678-1.66z" /><path d="M13.54 2.77V2.4c0-.99-.8-1.79-1.79-1.79s-1.79.8-1.79 1.79v.37M9.96 21.217v.37c0 .99.8 1.792 1.79 1.792.988 0 1.79-.803 1.79-1.792v-.37" />
    </g>
  </svg>
);

NotificationBellIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default NotificationBellIcon;
