import React from 'react';
import PropTypes from 'prop-types';

const PetsIcon = (props) => (
  <svg aria-hidden="true" className={props.className} preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <g transform="translate(1 6)">
        <circle cx="41" cy="16" r="5" />
        <circle cx="31" cy="5" r="5" />
        <circle cx="15" cy="5" r="5" />
        <circle cx="5" cy="16" r="5" />
      </g>
      <path d="m21.989 23.727c0.80662 0.18192 1.5497 0.27288 2.2293 0.27288 0.62304 0 1.2928-0.076447 2.0092-0.22934 3.985-0.85043 7.9049 1.6906 8.7553 5.6756 0.14393 0.67445 0.19259 1.3657 0.14456 2.0537l-0.17371 2.488c-0.39369 5.6388-5.0828 10.012-10.735 10.012-5.6787 0-10.415-4.3411-10.909-9.9984l-0.21832-2.5016c-0.35129-4.0252 2.627-7.5731 6.6523-7.9244 0.75189-0.065619 1.5094-0.014497 2.2457 0.15154z" transform="translate(24 31.5) scale(1 -1) translate(-24 -31.5)" />
    </g>
  </svg>
);

PetsIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default PetsIcon;
