import { fromJS } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = fromJS({
  housemates: [],
  selectedUnitId: null,
  price: null,
});

const RequestDetailsSelection = (state = initialState, action) => {
  switch (action.type) {
  case HYDRATE:
    if (action.payload && !action.payload.get('RequestDetailsSelection').equals(initialState)) {
      return state.merge(
        fromJS(action.payload ? action.payload.get('RequestDetailsSelection') : state)
      );
    } else {
      return state;
    }

  case 'UPDATE_REQUEST_HOUSEMATES':
    return state.set('housemates', action.housemates);

  case 'UPDATE_REQUEST_DETAILS_SELECTION':
    return state.merge(action.details);

  default:
    return state;
  }
};

export default RequestDetailsSelection;
